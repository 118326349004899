import { useState, useEffect } from 'react';
import { SnapChartData } from 'type/snapshot';
import dayjs from 'dayjs';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import { intlGenerator } from 'utils/common';
import SnapShot from 'components/SnapShot';
import BarChart from 'components/BarChart';
import { getSelfCommissionsRequestConfig } from 'api/getV2RequestConfig';
import Spinner from 'components/Spinner';
import {
  DataPair, MyComissionsData
} from './type';
import { StyledLoadingContainer } from './style';

const getMyCommissionsColor = (value: number): string => {
  if (value > 300 || value === 300) {
    return '#00BA34';
  }
  if (value > 100 || value === 100) {
    return '#F98600';
  }
  return '#E92C2C';
};

const scaleWithCustomSize = (stepSizeY: number, isMoneyUnit = false):{x:object, y:object} => ({
  x: {
    ticks: {
      stepSize: 1,
      autoSkip: false,
      padding: 5
    },
    grid: {
      display: false
    }
  },
  y: {
    min: 0,
    suggestedMax: 500,
    ticks: {
      stepSize: 100,
      type: 'linear',
      display: true,
      padding: 8,
      callback(value:number) {
        return isMoneyUnit ? `${intlGenerator(value)}` : value;
      }
    }
  }
});

const optionsWithoutLegends = {
  scales: scaleWithCustomSize(50, true),
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      bodySpacing: 8,
      padding: 8,
      backgroundColor: '#F4F4F4',
      titleColor: '#585757',
      bodyColor: '#585757',
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label(context:any) {
          return ` ${intlGenerator(context.parsed.y, 2)}`;
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  }
};

const MyCommissions: React.FC = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [myCommissionsData, setmyCommissionsData] = useState<SnapChartData>();

  // my commission
  useEffect(() => {
    const transformmyCommissionsData = (transacRes:MyComissionsData): void => {
      const transformData = transacRes.data;
      setmyCommissionsData({
        labels: transformData.commissions.map(
          (ele:DataPair) => dayjs(ele.date).format('DD/MM')
        ),
        datasets: [
          {
            fill: false,
            backgroundColor: transformData.commissions.map(
              (ele:DataPair) => getMyCommissionsColor(ele.value)
            ),
            borderColor: transformData.commissions.map(
              (ele:DataPair) => getMyCommissionsColor(ele.value)
            ),
            pointRadius: 3,
            borderRadius: 1,
            borderWidth: 2,
            data: transformData.commissions.map((ele:DataPair) => ele.value)
          }]
      });
    };
    sendRequest(
      getSelfCommissionsRequestConfig(),
      transformmyCommissionsData
    );
  }, [t]);

  return (
    <SnapShot title={t('myCommissions')}>
      {
          myCommissionsData ?
            <BarChart chartData={myCommissionsData} options={optionsWithoutLegends} /> :
            (
              <StyledLoadingContainer>
                <Spinner />
              </StyledLoadingContainer>
            )
        }
    </SnapShot>
  );
};

export default MyCommissions;
