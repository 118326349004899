import { StyledFilterAndSearchContainer } from './style';

type FilterAndSearchBoxProps = {
  filterSlot: JSX.Element;
  searchSlot: JSX.Element;
};

const FilterAndSearchBox = ({
  filterSlot,
  searchSlot
}: FilterAndSearchBoxProps): JSX.Element => (
  <StyledFilterAndSearchContainer>
    {filterSlot}
    {searchSlot}
  </StyledFilterAndSearchContainer>
);

export default FilterAndSearchBox;
