import styled from 'styled-components';

export const StyledLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

     >div{
      transform: translate(-35px,30px);
    }

`;
