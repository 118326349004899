export const getAPIQueryStringByOption = <ApiOption>(
  option?: ApiOption
): string => {
  if (!option) {
    return '';
  }
  const keys = Object.keys(option);
  let queryString = '';
  keys.forEach((key) => {
    queryString += queryString.includes('?') ? '&' : '?';
    queryString += `${key}=${option[key as keyof ApiOption]}`;
  });
  return queryString;
};
