import styled from 'styled-components';

export const StyledNotification = styled.div`
  ${({ theme }) => theme.typography.SubTitle1};
  text-align: center;
  background-color: #f74141;
  color: ${({ theme }) => theme.palette.N500};
  padding: ${({ theme }) => `0 ${theme.spacing.s1}`};
  height: 16px;
  border-radius: 8px;
`;
