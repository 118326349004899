import * as React from "react";
import { SVGProps } from "react";

const SvgNetDeposits = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 52 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#NetDeposits_svg__a)">
      <path
        d="M26.912 42.94 34.916 48l17.085-9.887-1.158-.406-24.615 4.553.684.68ZM11.296 31.124c-2.163-1.243-5.658-1.243-7.806 0-2.148 1.243-2.136 3.259.026 4.502 2.161 1.243 5.656 1.243 7.804 0 2.148-1.242 2.136-3.259-.024-4.501v-.001ZM21.4 37.51c-2.047-1.178-5.356-1.178-7.391 0-2.035 1.177-2.023 3.085.025 4.263 2.048 1.177 5.356 1.177 7.39 0 2.035-1.178 2.025-3.087-.023-4.264ZM6.22 21.935l1.035 1.61L27.71 35.227a2.717 2.717 0 0 0 2.753-.012l18.797-11.137a.778.778 0 0 0 .244-1.108.78.78 0 0 0-.322-.268l-.773-.348-42.187-.42Z"
        fill="#DDD"
      />
      <path
        d="M16.873 37.306a1.07 1.07 0 0 0-.3.066l-.645-.371c.31-.146.645-.228.987-.243.427-.024.807.041 1.143.194l-.717.415a1.108 1.108 0 0 0-.468-.061Z"
        fill="#FFDF82"
      />
      <path
        d="M16.163 37.808a.306.306 0 0 1 .075-.216l1.242.715c-.343.09-.707.045-1.017-.125-.192-.11-.29-.233-.3-.374Z"
        fill="#E7BE43"
      />
      <path
        d="M17.977 37.94c.023-.094-.013-.183-.106-.267l.717-.415c.269.195.385.415.345.663-.033.207-.175.397-.422.566l-.652-.374a.345.345 0 0 0 .118-.174Z"
        fill="#FFDF82"
      />
      <path
        d="m18.825 38.667-.358.207-.324-.186-.664-.382-1.24-.713-.66-.379-.323-.186.358-.207.314.18.646.372 1.286.739.65.374.315.18Z"
        fill="#FFDF82"
      />
      <path
        d="m21.586 37.86-.002.925c-.002.666-.442 1.332-1.321 1.841-1.77 1.025-4.649 1.025-6.43 0-.897-.515-1.345-1.191-1.343-1.867l.003-.925c-.002.676.446 1.352 1.342 1.868 1.782 1.024 4.66 1.024 6.43 0 .88-.51 1.32-1.175 1.321-1.842Z"
        fill="#FBB666"
      />
      <path
        d="M20.244 35.993c1.78 1.024 1.79 2.684.02 3.709-1.77 1.024-4.648 1.024-6.43 0-1.781-1.025-1.79-2.685-.02-3.71 1.77-1.024 4.648-1.023 6.43 0Zm-5.559 3.216c1.308.752 3.423.751 4.722 0 1.298-.75 1.293-1.971-.015-2.723-1.307-.752-3.423-.752-4.722 0-1.3.752-1.293 1.972.015 2.725v-.002Z"
        fill="#FDCF99"
      />
      <path
        d="M19.393 36.485c1.308.752 1.313 1.972.014 2.723-1.298.752-3.414.753-4.72 0-1.308-.752-1.315-1.972-.016-2.725 1.298-.753 3.413-.75 4.722.002Zm-.927 2.389.358-.207-.314-.181c.247-.17.388-.36.423-.567.04-.247-.076-.467-.346-.662l-.716.415c.093.085.128.173.106.266a.345.345 0 0 1-.118.172l-1.286-.74a1.108 1.108 0 0 1 .768-.005l.716-.414a2.392 2.392 0 0 0-1.143-.193 2.58 2.58 0 0 0-.987.242l-.314-.18-.359.207.324.186c-.239.185-.36.393-.364.624-.008.289.171.538.542.752.37.213.806.317 1.31.312a2.76 2.76 0 0 0 1.076-.214l.323.186v.001Z"
        fill="#EBBE38"
      />
      <path
        d="M17.104 38.352c.127.003.253-.012.375-.045l.664.382c-.34.143-.707.215-1.077.214-.503.004-.94-.1-1.309-.313-.37-.213-.55-.463-.542-.751.005-.233.126-.439.365-.624l.658.379a.306.306 0 0 0-.075.216c.01.14.109.263.3.373.196.11.416.168.641.169Z"
        fill="#FFDF82"
      />
      <path
        d="M12.766 39.664c.08.126.172.245.275.354v-.928a2.203 2.203 0 0 1-.275-.354v.928ZM13.316 40.272c.088.073.18.142.276.204v-.925a3.31 3.31 0 0 1-.276-.205v.926ZM13.867 40.646c.089.052.18.1.275.145v-.927a4.618 4.618 0 0 1-.275-.143v.925ZM14.418 40.911c.089.039.182.073.276.105v-.926a3.833 3.833 0 0 1-.276-.104v.925ZM14.97 41.104c.092.03.185.054.278.077v-.925c-.095-.023-.186-.05-.277-.078v.926ZM15.523 41.245c.091.018.183.036.276.05v-.926c-.093-.013-.187-.033-.276-.05v.926ZM16.074 41.335l.276.034v-.928a4.812 4.812 0 0 1-.276-.03v.924ZM16.625 41.385c.091.005.182.01.276.01v-.926c-.091 0-.185-.005-.276-.009v.925ZM17.178 41.394c.093 0 .184-.004.275-.009v-.925a3.801 3.801 0 0 1-.275.009v.925ZM17.729 41.367c.09-.007.184-.018.275-.03v-.922l-.276.03v.922ZM18.281 41.298c.094-.014.187-.03.276-.048v-.925a4.88 4.88 0 0 1-.276.05v.923ZM18.83 41.186a5.06 5.06 0 0 0 .276-.075v-.923c-.09.027-.183.052-.276.075v.923ZM19.383 41.025c.093-.034.187-.067.276-.105v-.923a4.57 4.57 0 0 1-.276.104v.924ZM19.934 40.8c.095-.046.186-.094.275-.144v-.92c-.088.05-.18.097-.275.143v.92ZM20.482 40.49c.096-.064.188-.132.276-.205v-.923c-.088.074-.18.142-.276.204v.923ZM21.035 39.108v.92c.105-.107.197-.226.276-.353v-.919c-.08.127-.172.245-.276.352ZM16.873 36.08a1.09 1.09 0 0 0-.3.067l-.645-.371c.31-.146.645-.228.987-.243.427-.024.807.041 1.143.194l-.717.415a1.106 1.106 0 0 0-.468-.062ZM16.163 36.584a.306.306 0 0 1 .075-.216l1.242.712c-.343.09-.707.046-1.017-.124-.192-.11-.29-.233-.3-.372ZM17.977 36.714c.023-.094-.013-.182-.106-.266l.717-.415c.269.195.385.415.345.663-.034.207-.175.396-.422.567l-.652-.375a.347.347 0 0 0 .118-.174Z"
        fill="#F98600"
      />
      <path
        d="m18.825 37.442-.358.207-.324-.186-.664-.382-1.24-.713-.66-.379-.323-.186.358-.207.314.18.646.371 1.286.74.65.374.315.18Z"
        fill="#F98600"
      />
      <path
        d="m21.586 36.635-.002.924c-.002.667-.442 1.333-1.321 1.842-1.77 1.024-4.649 1.024-6.43 0-.897-.516-1.345-1.192-1.343-1.868l.003-.924c-.002.675.446 1.352 1.342 1.867 1.782 1.025 4.66 1.025 6.43 0 .88-.508 1.32-1.175 1.321-1.841Z"
        fill="#FBB666"
      />
      <path
        d="M20.244 34.767c1.78 1.025 1.79 2.685.02 3.71-1.77 1.024-4.648 1.024-6.43 0-1.781-1.025-1.79-2.685-.02-3.71 1.77-1.024 4.648-1.024 6.43 0Zm-5.559 3.217c1.308.752 3.423.75 4.722 0 1.298-.75 1.293-1.972-.015-2.723-1.307-.752-3.423-.752-4.722 0-1.3.751-1.293 1.971.015 2.725v-.002Z"
        fill="#FDCF99"
      />
      <path
        d="M19.393 35.26c1.308.751 1.313 1.971.014 2.723-1.298.752-3.414.753-4.72 0-1.308-.752-1.315-1.972-.016-2.725 1.298-.753 3.413-.75 4.722.002Zm-.927 2.389.358-.208-.314-.18c.247-.17.388-.36.423-.567.04-.248-.076-.468-.346-.662l-.716.414c.093.085.128.174.106.266a.346.346 0 0 1-.118.172l-1.286-.737a1.11 1.11 0 0 1 .768-.005l.716-.415a2.396 2.396 0 0 0-1.143-.194 2.579 2.579 0 0 0-.987.243l-.314-.18-.359.207.324.186c-.239.184-.36.393-.364.623-.008.289.171.54.542.752.37.213.806.317 1.31.313a2.759 2.759 0 0 0 1.076-.215l.323.186Z"
        fill="#F98600"
      />
      <path
        d="M17.105 37.126c.126.003.252-.012.374-.045l.664.381c-.32.14-.678.21-1.077.215-.503.005-.94-.1-1.309-.313-.37-.213-.55-.463-.542-.751.005-.233.126-.44.365-.624l.658.379a.306.306 0 0 0-.075.216c.01.139.109.263.3.372.196.111.417.17.642.17ZM12.766 38.44c.08.126.172.245.275.354v-.928a2.194 2.194 0 0 1-.275-.354v.928ZM13.316 39.046c.088.074.18.142.276.205v-.926a3.155 3.155 0 0 1-.276-.204v.925ZM13.867 39.42c.089.053.18.1.275.146v-.927a4.448 4.448 0 0 1-.275-.143v.925ZM14.418 39.686c.089.038.182.072.276.104v-.925c-.094-.033-.187-.067-.276-.105v.926ZM14.97 39.879c.092.03.185.054.278.077v-.925c-.095-.023-.186-.05-.277-.078v.926ZM15.523 40.02c.091.017.183.036.276.05v-.926a4.792 4.792 0 0 1-.276-.05v.926ZM16.074 40.11c.091.011.182.023.276.032v-.928a5.656 5.656 0 0 1-.276-.03v.926ZM16.625 40.16c.091.005.182.01.276.01v-.926a5.4 5.4 0 0 1-.276-.01v.926ZM17.178 40.168c.093 0 .184-.005.275-.009v-.926c-.09.007-.182.01-.275.01v.925ZM17.729 40.142a5.04 5.04 0 0 0 .275-.03v-.923c-.091.011-.184.02-.276.03v.923ZM18.281 40.072c.094-.014.187-.03.276-.048v-.925a5.065 5.065 0 0 1-.276.05v.923ZM18.83 39.96c.094-.022.187-.047.276-.074v-.924c-.09.028-.183.053-.276.075v.923ZM19.383 39.8c.093-.034.187-.067.276-.105v-.923a5.096 5.096 0 0 1-.276.105v.922ZM19.934 39.575c.095-.046.186-.093.275-.143v-.924a4.32 4.32 0 0 1-.275.143v.924ZM20.482 39.264c.098-.066.192-.133.276-.204v-.923c-.088.073-.18.14-.276.203v.924ZM21.035 37.883v.922a2.1 2.1 0 0 0 .276-.353v-.919a2.18 2.18 0 0 1-.276.35Z"
        fill="#F98600"
      />
      <path
        d="M6.27 31.29c-.103.007-.204.03-.3.066l-.646-.372c.31-.145.645-.228.987-.242.428-.024.808.041 1.143.194l-.716.415a1.105 1.105 0 0 0-.468-.062Z"
        fill="#FFDF82"
      />
      <path
        d="M5.559 31.793a.308.308 0 0 1 .075-.217l1.24.713a1.38 1.38 0 0 1-1.016-.124c-.19-.11-.29-.233-.299-.372Z"
        fill="#E7BE43"
      />
      <path
        d="M7.374 31.923c.022-.094-.013-.182-.106-.268l.716-.414c.27.195.385.414.346.662-.033.207-.175.397-.423.567l-.651-.375a.347.347 0 0 0 .118-.172Z"
        fill="#FFDF82"
      />
      <path
        d="m8.222 32.65-.359.208-.323-.186-.665-.382-1.24-.713-.659-.38-.324-.185.359-.208.314.181.646.372 1.285.739.652.374.314.18Z"
        fill="#FFDF82"
      />
      <path
        d="m10.983 31.844-.003.924c-.002.667-.442 1.333-1.321 1.842-1.77 1.024-4.649 1.024-6.43 0-.897-.516-1.344-1.193-1.342-1.868l.002-.924c-.002.675.446 1.352 1.342 1.867 1.782 1.024 4.66 1.024 6.43 0 .88-.508 1.32-1.174 1.322-1.841Z"
        fill="#FBB666"
      />
      <path
        d="M9.64 29.977c1.781 1.024 1.79 2.684.021 3.708-1.77 1.025-4.649 1.025-6.43 0-1.782-1.024-1.79-2.684-.02-3.709 1.77-1.024 4.647-1.024 6.429 0Zm-5.558 3.216c1.308.752 3.422.75 4.72 0 1.3-.75 1.295-1.972-.013-2.723-1.308-.751-3.424-.752-4.722 0-1.3.752-1.294 1.971.015 2.725v-.002Z"
        fill="#FDCF99"
      />
      <path
        d="M8.79 30.47c1.307.75 1.313 1.97.014 2.722-1.3.751-3.414.753-4.721 0-1.308-.753-1.314-1.973-.016-2.725 1.3-.753 3.414-.75 4.723.002Zm-.927 2.387.358-.208-.314-.18c.247-.17.388-.36.423-.567.039-.248-.077-.468-.346-.662l-.716.415c.093.086.129.174.106.268a.347.347 0 0 1-.118.172l-1.285-.739a1.106 1.106 0 0 1 .768-.005l.716-.414a2.39 2.39 0 0 0-1.143-.194 2.582 2.582 0 0 0-.987.242l-.314-.18-.359.207.324.186c-.24.185-.36.393-.364.624-.009.289.17.538.542.752.37.213.806.317 1.309.313a2.758 2.758 0 0 0 1.076-.215l.324.186v-.001Z"
        fill="#EBBE38"
      />
      <path
        d="M6.5 32.335a1.32 1.32 0 0 0 .373-.046l.664.382c-.32.14-.678.21-1.076.215-.503.004-.94-.1-1.31-.313-.37-.213-.55-.463-.541-.751.004-.233.125-.44.364-.624l.659.378a.308.308 0 0 0-.076.217c.01.139.11.263.3.372.197.111.418.17.643.17Z"
        fill="#FFDF82"
      />
      <path
        d="M2.162 33.648c.08.126.173.244.276.353v-.927a2.167 2.167 0 0 1-.276-.354v.928ZM2.713 34.255c.087.074.18.142.275.204v-.925a3.28 3.28 0 0 1-.275-.204v.925ZM3.264 34.63c.088.052.18.1.275.145v-.928a4.347 4.347 0 0 1-.275-.143v.926ZM3.814 34.895c.09.038.183.072.276.104v-.925a6.5 6.5 0 0 1-.276-.105v.926ZM4.367 35.088c.091.03.185.054.278.077v-.925c-.096-.023-.187-.05-.278-.078v.926ZM4.92 35.228c.091.018.182.037.276.05v-.926c-.094-.013-.187-.033-.276-.05v.926ZM5.47 35.319c.092.012.183.023.276.033v-.927a5.663 5.663 0 0 1-.275-.03v.924ZM6.021 35.37c.092.003.183.008.276.008v-.926c-.09 0-.184-.005-.276-.01v.927ZM6.574 35.378c.094 0 .185-.005.276-.009v-.925a3.73 3.73 0 0 1-.276.009v.925ZM7.125 35.35c.091-.006.185-.018.276-.029v-.923c-.091.011-.185.02-.276.03v.923ZM7.676 35.28c.093-.013.187-.029.275-.047v-.925a5.165 5.165 0 0 1-.275.05v.922ZM8.227 35.17c.093-.023.186-.048.275-.075v-.924a4.866 4.866 0 0 1-.275.075v.923ZM8.78 35.008c.093-.033.186-.066.275-.104v-.923a5.103 5.103 0 0 1-.276.104v.923ZM9.33 34.784c.096-.045.187-.093.276-.143v-.923c-.09.05-.18.098-.276.143v.923ZM9.88 34.473c.096-.063.188-.131.276-.204v-.923c-.087.073-.18.141-.275.204v.923ZM10.432 33.092v.92c.104-.107.196-.226.275-.353v-.919a2.16 2.16 0 0 1-.275.352Z"
        fill="#F98600"
      />
      <path
        d="M6.27 30.065c-.103.007-.204.03-.3.066l-.646-.371c.31-.146.645-.228.987-.243.428-.024.808.041 1.143.195l-.716.414a1.111 1.111 0 0 0-.468-.061Z"
        fill="#FFDF82"
      />
      <path
        d="M5.559 30.567a.307.307 0 0 1 .075-.216l1.24.713c-.342.09-.705.045-1.016-.124-.19-.11-.29-.234-.299-.373Z"
        fill="#E7BE43"
      />
      <path
        d="M7.374 30.696c.022-.094-.013-.182-.106-.266l.716-.414c.27.194.385.414.346.662-.033.207-.175.397-.423.565l-.651-.374a.346.346 0 0 0 .118-.173Z"
        fill="#FFDF82"
      />
      <path
        d="m8.222 31.425-.359.207-.323-.186-.665-.382-1.24-.713-.659-.379-.324-.186.359-.207.314.18.646.372 1.285.739.652.374.314.18Z"
        fill="#FFDF82"
      />
      <path
        d="m10.983 30.618-.003.925c-.002.667-.442 1.332-1.321 1.841-1.77 1.024-4.649 1.024-6.43 0-.897-.515-1.344-1.192-1.342-1.867l.002-.924c-.002.675.446 1.352 1.342 1.867 1.782 1.024 4.66 1.024 6.43 0 .88-.51 1.32-1.175 1.322-1.842Z"
        fill="#FBB666"
      />
      <path
        d="M9.64 28.75c1.781 1.025 1.79 2.686.021 3.71-1.77 1.023-4.649 1.024-6.43 0-1.782-1.025-1.79-2.685-.02-3.71 1.77-1.023 4.647-1.023 6.429 0Zm-5.558 3.217c1.308.752 3.422.751 4.72 0 1.3-.75 1.295-1.971-.013-2.723-1.308-.752-3.424-.751-4.722 0-1.3.752-1.294 1.972.015 2.725v-.002Z"
        fill="#FDCF99"
      />
      <path
        d="M8.79 29.243c1.307.752 1.313 1.972.014 2.723-1.3.752-3.414.753-4.721 0-1.308-.752-1.314-1.972-.016-2.725 1.3-.753 3.414-.75 4.723.002Zm-.927 2.39.358-.208-.314-.181c.247-.17.388-.36.423-.565.039-.248-.077-.468-.346-.662l-.716.413c.093.086.129.174.106.266a.346.346 0 0 1-.118.173L5.97 30.13a1.113 1.113 0 0 1 .768-.005l.717-.414a2.389 2.389 0 0 0-1.144-.194 2.58 2.58 0 0 0-.987.243l-.314-.181-.359.207.324.186c-.239.186-.36.393-.364.625-.008.288.171.538.542.75.37.214.806.318 1.31.314.369.001.735-.072 1.076-.215l.323.186Z"
        fill="#EBBE38"
      />
      <path
        d="M6.5 31.11c.126.002.252-.013.373-.046l.664.382c-.34.143-.707.216-1.076.214-.503.005-.94-.1-1.31-.313-.37-.213-.55-.463-.541-.75.004-.234.125-.44.364-.625l.659.379a.307.307 0 0 0-.076.216c.01.14.11.263.3.373.197.11.418.169.643.17Z"
        fill="#FFDF82"
      />
      <path
        d="M2.162 32.422c.08.126.173.245.276.354v-.928a2.179 2.179 0 0 1-.276-.354v.928ZM2.713 33.03c.087.073.18.141.275.204v-.926a3.352 3.352 0 0 1-.275-.204v.925ZM3.264 33.404c.088.052.18.1.275.145v-.928a4.51 4.51 0 0 1-.275-.143v.926ZM3.814 33.67c.09.038.183.072.276.104v-.926a4.866 4.866 0 0 1-.276-.104v.925ZM4.367 33.862c.091.03.185.055.278.077v-.925a4.803 4.803 0 0 1-.278-.078v.926ZM4.92 34.003c.091.018.182.036.276.05v-.926c-.094-.013-.187-.033-.276-.05v.926ZM5.47 34.093l.276.034v-.928a5.314 5.314 0 0 1-.275-.03v.924ZM6.021 34.143c.092.005.183.01.276.01v-.926c-.09 0-.184-.005-.276-.009v.925ZM6.574 34.153c.094 0 .185-.005.276-.01v-.925a3.73 3.73 0 0 1-.276.009v.926ZM7.125 34.125c.091-.007.185-.018.276-.03v-.923c-.091.012-.185.021-.276.03v.923ZM7.676 34.055c.093-.014.187-.03.275-.048v-.925a4.794 4.794 0 0 1-.275.05v.923ZM8.227 33.944a5.04 5.04 0 0 0 .275-.075v-.923a4.784 4.784 0 0 1-.275.075v.923ZM8.78 33.782c.093-.031.186-.066.275-.104v-.921a4.576 4.576 0 0 1-.276.104v.922ZM9.33 33.558a4.37 4.37 0 0 0 .276-.143v-.924c-.09.05-.18.097-.276.143v.924ZM9.88 33.247c.096-.063.188-.131.276-.204v-.923c-.087.074-.18.142-.275.204v.923ZM10.432 31.866v.92c.104-.107.197-.226.275-.353v-.919c-.08.127-.171.245-.275.352Z"
        fill="#F98600"
      />
      <path
        d="M6.27 28.838c-.103.008-.204.03-.3.066l-.646-.37c.31-.146.645-.229.987-.243.428-.025.808.04 1.143.193l-.717.417a1.107 1.107 0 0 0-.467-.063Z"
        fill="#FFDF82"
      />
      <path
        d="M5.559 29.342a.307.307 0 0 1 .075-.216l1.24.713c-.342.09-.706.045-1.016-.125-.19-.11-.29-.233-.299-.372Z"
        fill="#E7BE43"
      />
      <path
        d="M7.374 29.472c.022-.094-.013-.183-.106-.268l.716-.414c.27.194.385.414.346.662-.033.207-.175.396-.423.567l-.651-.375a.349.349 0 0 0 .118-.172Z"
        fill="#FFDF82"
      />
      <path
        d="m8.222 30.2-.359.207-.323-.186-.665-.382-1.24-.713-.659-.38-.324-.185.359-.208.314.181.646.371 1.285.74.652.374.314.18Z"
        fill="#FFDF82"
      />
      <path
        d="m10.983 29.393-.003.924c-.002.667-.442 1.333-1.321 1.842-1.77 1.024-4.649 1.024-6.43 0-.897-.516-1.344-1.192-1.342-1.868l.002-.924c-.002.675.446 1.352 1.342 1.867 1.782 1.024 4.66 1.024 6.43 0 .88-.509 1.32-1.174 1.322-1.841Z"
        fill="#FBB666"
      />
      <path
        d="M9.64 27.525c1.781 1.025 1.79 2.685.021 3.71-1.77 1.023-4.649 1.023-6.43 0-1.782-1.025-1.79-2.685-.02-3.71 1.77-1.024 4.647-1.024 6.429 0Zm-5.558 3.217c1.308.752 3.422.75 4.72 0 1.3-.751 1.295-1.972-.013-2.724-1.308-.751-3.424-.751-4.722 0-1.3.752-1.294 1.972.015 2.726v-.002Z"
        fill="#FDCF99"
      />
      <path
        d="M8.79 28.018c1.307.752 1.313 1.971.014 2.723-1.3.752-3.414.752-4.721 0-1.308-.753-1.314-1.972-.016-2.725 1.3-.753 3.414-.75 4.723.002Zm-.927 2.388.358-.207-.314-.18c.247-.17.388-.36.423-.568.039-.247-.077-.467-.346-.662l-.716.415c.093.085.129.174.106.268a.349.349 0 0 1-.118.172l-1.285-.739a1.106 1.106 0 0 1 .768-.005l.716-.414a2.39 2.39 0 0 0-1.143-.194 2.578 2.578 0 0 0-.987.242l-.314-.18-.359.207.324.186c-.24.185-.36.393-.364.624-.009.289.17.539.542.752.37.213.806.317 1.309.313.37 0 .736-.072 1.076-.215l.324.186Z"
        fill="#EBBE38"
      />
      <path
        d="M6.5 29.884c.126.003.252-.012.373-.045l.664.382c-.34.142-.707.215-1.076.214-.503.004-.94-.1-1.31-.313-.37-.213-.55-.463-.541-.752.004-.23.125-.439.364-.623l.659.378a.307.307 0 0 0-.076.217c.01.14.11.263.3.372.197.11.418.17.643.17Z"
        fill="#FFDF82"
      />
      <path
        d="M2.162 31.197c.08.126.173.244.276.353v-.928a2.17 2.17 0 0 1-.276-.353v.928ZM2.713 31.804c.087.074.18.142.275.204v-.925a3.325 3.325 0 0 1-.275-.204v.925ZM3.264 32.178c.088.053.18.1.275.146v-.928a4.196 4.196 0 0 1-.275-.143v.925ZM3.814 32.444c.09.038.183.072.276.104v-.925a6.64 6.64 0 0 1-.276-.105v.926ZM4.367 32.637c.091.029.185.054.278.077v-.926c-.096-.022-.187-.05-.278-.077v.926ZM4.92 32.777c.091.018.182.037.276.05v-.925c-.094-.014-.187-.034-.276-.05v.925ZM5.47 32.868c.092.011.183.023.276.033v-.927a5.663 5.663 0 0 1-.275-.03v.924ZM6.021 32.918c.092.005.183.009.276.009v-.925a5.4 5.4 0 0 1-.276-.01v.926ZM6.574 32.927c.094 0 .185-.004.276-.009v-.926c-.091.007-.182.01-.276.01v.925ZM7.125 32.9c.091-.007.185-.018.276-.03v-.923c-.091.011-.185.02-.276.03v.923ZM7.676 32.83c.093-.014.187-.03.275-.048v-.926a5.067 5.067 0 0 1-.275.05v.924ZM8.227 32.718c.093-.022.186-.047.275-.075v-.923a5.04 5.04 0 0 1-.275.075v.923ZM8.78 32.557c.093-.033.186-.066.275-.104v-.923a5.103 5.103 0 0 1-.276.104v.923ZM9.33 32.333c.096-.045.187-.093.276-.143v-.923c-.09.05-.18.097-.276.143v.923ZM9.88 32.022c.096-.063.188-.131.276-.204v-.923c-.087.074-.18.142-.275.205v.922ZM10.432 30.64v.92c.104-.107.196-.226.275-.354v-.918c-.08.127-.172.245-.275.353Z"
        fill="#F98600"
      />
      <path
        d="M6.27 27.613c-.103.007-.204.03-.3.066l-.646-.371c.31-.145.645-.228.987-.243.428-.024.808.041 1.143.194l-.716.415a1.105 1.105 0 0 0-.468-.061Z"
        fill="#FFDF82"
      />
      <path
        d="M5.559 28.117a.308.308 0 0 1 .075-.217l1.24.713c-.342.09-.706.046-1.016-.124-.19-.11-.29-.233-.299-.372Z"
        fill="#E7BE43"
      />
      <path
        d="M7.374 28.247c.022-.094-.013-.183-.106-.268l.716-.415c.27.195.385.415.346.662-.033.208-.175.397-.423.567l-.651-.374a.347.347 0 0 0 .118-.172Z"
        fill="#FFDF82"
      />
      <path
        d="m8.222 28.974-.359.207-.323-.186-.665-.382-1.24-.713-.659-.379-.324-.186.359-.207.314.18.646.372 1.285.74.652.373.314.181Z"
        fill="#FFDF82"
      />
      <path
        d="m10.983 28.167-.003.925c-.002.667-.442 1.332-1.321 1.841-1.77 1.024-4.649 1.024-6.43 0-.897-.515-1.344-1.192-1.342-1.867l.002-.925c-.002.675.446 1.352 1.342 1.868 1.782 1.024 4.66 1.024 6.43 0 .88-.51 1.32-1.174 1.322-1.842Z"
        fill="#FBB666"
      />
      <path
        d="M9.64 26.3c1.781 1.024 1.79 2.685.021 3.709-1.77 1.024-4.649 1.024-6.43 0-1.782-1.024-1.79-2.685-.02-3.71 1.77-1.023 4.647-1.023 6.429 0Zm-5.558 3.216c1.308.752 3.422.751 4.72 0 1.3-.75 1.295-1.971-.013-2.722-1.308-.752-3.424-.752-4.722 0-1.3.751-1.294 1.97.015 2.725v-.003Z"
        fill="#FDCF99"
      />
      <path
        d="M8.79 26.793c1.307.751 1.313 1.971.014 2.722-1.3.752-3.414.753-4.721 0-1.308-.752-1.314-1.972-.016-2.725 1.3-.752 3.414-.75 4.723.003Zm-.927 2.388.358-.207-.314-.18c.247-.17.388-.36.423-.568.039-.247-.077-.467-.346-.662l-.716.415c.093.085.129.174.106.268a.347.347 0 0 1-.118.172l-1.285-.74a1.106 1.106 0 0 1 .768-.005l.716-.414a2.392 2.392 0 0 0-1.143-.194 2.582 2.582 0 0 0-.987.243l-.314-.181-.359.207.324.187c-.24.184-.36.392-.364.623-.009.289.17.539.542.752.37.214.806.317 1.309.313a2.758 2.758 0 0 0 1.076-.215l.324.186Z"
        fill="#EBBE38"
      />
      <path
        d="M6.5 28.659c.126.002.252-.013.373-.046l.664.382c-.32.139-.678.21-1.076.214-.503.005-.94-.1-1.31-.312-.37-.214-.55-.463-.541-.752.004-.233.125-.439.364-.624l.659.379a.308.308 0 0 0-.076.217c.01.138.11.262.3.372.197.11.418.169.643.17Z"
        fill="#FFDF82"
      />
      <path
        d="M2.162 29.97c.08.127.173.246.276.355v-.928a2.167 2.167 0 0 1-.276-.354v.928ZM2.713 30.579c.087.073.18.142.275.204v-.926a3.28 3.28 0 0 1-.275-.204v.926ZM3.264 30.953c.088.052.18.1.275.145v-.927a4.4 4.4 0 0 1-.275-.143v.925ZM3.814 31.218c.09.039.183.073.276.105v-.926c-.093-.033-.187-.066-.276-.104v.925ZM4.367 31.411c.091.03.185.054.278.077v-.925c-.096-.022-.187-.05-.278-.077v.925ZM4.92 31.552c.091.018.182.036.276.05v-.926c-.094-.013-.187-.033-.276-.05v.926ZM5.47 31.642l.276.034v-.928a5.663 5.663 0 0 1-.275-.03v.924ZM6.021 31.693c.092.005.183.01.276.01v-.927c-.09 0-.184-.004-.276-.009v.926ZM6.574 31.702c.094 0 .185-.005.276-.01v-.925c-.091.007-.182.01-.276.01v.925ZM7.125 31.674c.091-.007.185-.018.276-.03v-.922c-.091.011-.185.02-.276.029v.923ZM7.676 31.604c.093-.014.187-.03.275-.048v-.926a4.791 4.791 0 0 1-.275.05v.924ZM8.227 31.494c.093-.023.186-.048.275-.075v-.923a4.784 4.784 0 0 1-.275.075v.923ZM8.78 31.332c.093-.032.186-.067.275-.105v-.923a5.021 5.021 0 0 1-.276.105v.923ZM9.33 31.107a4.71 4.71 0 0 0 .276-.142v-.924c-.09.05-.18.098-.276.143v.923ZM9.88 30.796c.096-.063.188-.132.276-.205v-.922a2.86 2.86 0 0 1-.275.204v.923ZM10.432 29.415v.92c.104-.107.196-.225.275-.353v-.918a2.16 2.16 0 0 1-.275.351Z"
        fill="#F98600"
      />
      <mask id="NetDeposits_svg__b" fill="#fff">
        <path d="M6.27 26.387c-.103.008-.204.03-.3.066l-.646-.37c.31-.146.645-.229.987-.243.428-.025.808.04 1.143.194l-.716.414a1.105 1.105 0 0 0-.468-.061Z" />
      </mask>
      <path
        d="M6.27 26.387c-.103.008-.204.03-.3.066l-.646-.37c.31-.146.645-.229.987-.243.428-.025.808.04 1.143.194l-.716.414a1.105 1.105 0 0 0-.468-.061Z"
        fill="#F98600"
      />
      <path
        d="m5.97 26.453-.499.867.41.236.442-.167-.353-.936Zm-.646-.37-.425-.906-1.736.816 1.663.956.498-.867Zm.987-.243.043.999h.014l-.057-1Zm1.143.194.501.866 1.679-.971-1.764-.805-.416.91Zm-.716.414-.339.941.437.157.402-.232-.5-.866Zm-.542-1.058a2.077 2.077 0 0 0-.58.128l.707 1.87a.08.08 0 0 1 .021-.004l-.148-1.994Zm.272.197-.645-.372-.997 1.734.645.371.997-1.733Zm-.718 1.4c.189-.089.394-.14.604-.148l-.086-1.998c-.474.02-.94.134-1.37.336l.852 1.81Zm.618-.149c.29-.016.504.03.671.105l.83-1.819a3.384 3.384 0 0 0-1.615-.283l.114 1.997Zm.586-1.67-.717.414 1.001 1.732.717-.415-1.001-1.731Zm.122.34a2.107 2.107 0 0 0-.89-.117l.169 1.992c.015 0 .03.001.044.006l.677-1.882Z"
        fill="#F98600"
        mask="url(#NetDeposits_svg__b)"
      />
      <mask id="NetDeposits_svg__c" fill="#fff">
        <path d="M5.559 26.891a.307.307 0 0 1 .075-.216l1.24.713c-.342.09-.705.045-1.016-.125-.19-.11-.29-.233-.299-.372Z" />
      </mask>
      <path
        d="M5.559 26.891a.307.307 0 0 1 .075-.216l1.24.713c-.342.09-.705.045-1.016-.125-.19-.11-.29-.233-.299-.372Z"
        fill="#F98600"
      />
      <path
        d="m5.559 26.891-.999.05v.009l.001.008.998-.067Zm.075-.216.498-.867-.713-.41-.54.62.755.657Zm1.24.713.254.967 2.36-.619-2.115-1.215-.498.867Zm-1.016-.125-.498.867.01.006.009.005.48-.877Zm.7-.421a.693.693 0 0 1-.17.489L4.88 26.018a1.307 1.307 0 0 0-.32.923l1.998-.1Zm-1.422.7 1.24.713.997-1.734-1.24-.713-.997 1.733ZM6.62 26.42a.386.386 0 0 1-.283-.034l-.96 1.755c.535.292 1.161.369 1.75.214l-.507-1.934Zm-.264-.023c-.026-.015.011 0 .063.065.06.074.126.199.137.362l-1.996.134c.043.632.492.996.799 1.172l.997-1.733Z"
        fill="#F98600"
        mask="url(#NetDeposits_svg__c)"
      />
      <mask id="NetDeposits_svg__d" fill="#fff">
        <path d="M7.374 27.02c.022-.093-.013-.181-.106-.267l.716-.414c.27.194.385.414.346.662-.033.207-.175.397-.423.565l-.651-.374a.346.346 0 0 0 .118-.171Z" />
      </mask>
      <path
        d="M7.374 27.02c.022-.093-.013-.181-.106-.267l.716-.414c.27.194.385.414.346.662-.033.207-.175.397-.423.565l-.651-.374a.346.346 0 0 0 .118-.171Z"
        fill="#F98600"
      />
      <path
        d="m7.374 27.02.955.296.01-.03.007-.03-.972-.235Zm-.106-.267-.501-.865-1.181.683 1.007.92.675-.738Zm.716-.414.587-.81-.526-.38-.561.324.5.866ZM8.33 27l.987.159v-.003L8.33 27Zm-.423.565-.498.867.542.312.518-.352-.562-.827Zm-.651-.374-.616-.788-1.17.914 1.288.74.498-.866Zm1.09.064c.136-.563-.141-1-.404-1.24l-1.35 1.476a.646.646 0 0 1-.157-.239.78.78 0 0 1-.033-.467l1.944.47Zm-.578.363.717-.415-1.001-1.73-.717.414 1.001 1.73Zm-.37-.47c.05.036.023.03-.011-.036a.44.44 0 0 1-.045-.268l1.976.312c.118-.746-.293-1.3-.747-1.628l-1.173 1.62Zm-.055-.307a.383.383 0 0 1 .056-.143c.01-.015.002.002-.054.04l1.124 1.654c.376-.255.755-.654.848-1.233l-1.974-.318Zm1.062-.143-.65-.374-.997 1.734.65.374.997-1.734Zm-.534 1.28c.217-.168.377-.4.458-.663l-1.91-.59a.653.653 0 0 1 .221-.322l1.231 1.576Z"
        fill="#F98600"
        mask="url(#NetDeposits_svg__d)"
      />
      <mask id="NetDeposits_svg__e" fill="#fff">
        <path d="m8.222 27.748-.359.208-.323-.186-.665-.382-1.24-.713-.659-.379-.324-.186.359-.207.314.18.646.371 1.285.74.652.374.314.18Z" />
      </mask>
      <path
        d="m8.222 27.748-.359.208-.323-.186-.665-.382-1.24-.713-.659-.379-.324-.186.359-.207.314.18.646.371 1.285.74.652.374.314.18Z"
        fill="#F98600"
      />
      <path
        d="m8.222 27.748.5.866 1.502-.869-1.504-.864-.498.867Zm-.359.208-.499.867.5.287.5-.289-.5-.865Zm-.323-.186.499-.867-.5.867Zm-.665-.382-.498.867.498-.867Zm-1.24-.713.499-.867-.499.867Zm-.659-.379-.498.867.498-.867Zm-.324-.186-.5-.866-1.501.869 1.503.864.498-.867Zm.359-.207.498-.867-.5-.288-.499.289.5.866Zm.314.18-.498.867.498-.867Zm.646.371.498-.867-.498.867Zm1.285.74-.498.866.498-.867Zm.652.374-.499.867.499-.867Zm-.187-.685-.359.207 1.002 1.731.358-.207-1.001-1.731Zm.641.206-.323-.186-.998 1.733.323.187.998-1.734Zm-.324-.186-.664-.382-.997 1.734.665.382.996-1.734Zm-.664-.382-1.24-.713-.997 1.734 1.24.713.997-1.734Zm-1.24-.713-.66-.379-.996 1.734.659.379.997-1.734Zm-.66-.379-.323-.186-.997 1.734.324.186.996-1.734Zm-.32 1.547.358-.208-1.002-1.731-.358.207 1.001 1.732Zm-.642-.206.315.18.997-1.733-.315-.181-.997 1.733Zm.315.18.645.371.997-1.734-.646-.37-.996 1.733Zm.645.371 1.286.74.997-1.735-1.286-.739-.997 1.734Zm1.286.74.651.374.997-1.734-.651-.375-.997 1.734Zm.651.374.314.18.997-1.734-.314-.18-.997 1.734Z"
        fill="#F98600"
        mask="url(#NetDeposits_svg__e)"
      />
      <path
        d="m10.983 26.94-.003.925c-.002.668-.442 1.333-1.321 1.842-1.77 1.024-4.649 1.024-6.43 0-.897-.515-1.344-1.192-1.342-1.867l.002-.925c-.002.676.446 1.352 1.342 1.867 1.782 1.025 4.66 1.025 6.43 0 .88-.507 1.32-1.173 1.322-1.841Z"
        fill="#FBB666"
      />
      <path
        d="M9.64 25.074c1.781 1.025 1.79 2.685.021 3.71-1.77 1.023-4.649 1.024-6.43 0-1.782-1.025-1.79-2.685-.02-3.71 1.77-1.024 4.647-1.025 6.429 0Zm-5.558 3.217c1.308.752 3.422.75 4.72 0 1.3-.75 1.295-1.971-.013-2.723-1.308-.752-3.424-.752-4.722 0-1.3.751-1.294 1.971.015 2.725v-.002Z"
        fill="#FDCF99"
      />
      <path
        d="M8.54 26c.564.324.733.68.734.931.001.18-.084.414-.34.65l-.237-.136c.06-.109.104-.228.126-.358v-.006c.068-.427-.117-.773-.4-1.028l.104-.06.013.007Zm-4.942.925c-.001-.18.083-.413.337-.65l.286.164a1.154 1.154 0 0 0-.11.47c-.014.55.348.94.794 1.197l.03.017a3.297 3.297 0 0 1-.603-.266c-.563-.324-.733-.68-.734-.932Z"
        fill="#F98600"
        stroke="#F98600"
      />
      <mask id="NetDeposits_svg__f" fill="#fff">
        <path d="M6.5 27.433c.126.003.252-.012.373-.045l.664.382c-.34.142-.707.215-1.076.214-.503.004-.94-.1-1.31-.313-.37-.213-.55-.463-.541-.751.004-.233.125-.44.364-.624l.659.378a.307.307 0 0 0-.076.217c.01.14.11.263.3.372.197.111.418.17.643.17Z" />
      </mask>
      <path
        d="M6.5 27.433c.126.003.252-.012.373-.045l.664.382c-.34.142-.707.215-1.076.214-.503.004-.94-.1-1.31-.313-.37-.213-.55-.463-.541-.751.004-.233.125-.44.364-.624l.659.378a.307.307 0 0 0-.076.217c.01.14.11.263.3.372.197.111.418.17.643.17Z"
        fill="#F98600"
      />
      <path
        d="m6.5 27.433.023-1H6.502l-.002 1Zm.373-.045.499-.867-.36-.207-.4.108.261.966Zm.664.382.387.922 1.864-.782-1.752-1.007-.499.867Zm-1.076.214.003-1h-.011l.008 1Zm-1.31-.313.499-.867-.498.867Zm-.541-.751 1 .029v-.01l-1-.02Zm.364-.624.498-.867-.58-.334-.53.41.612.79Zm.659.378.754.657.798-.917-1.054-.606-.498.866Zm-.076.217-.998.05v.017l.998-.067Zm.3.372-.496.868.005.003.492-.87Zm.62 1.17c.222.005.443-.022.657-.08l-.522-1.93a.311.311 0 0 1-.089.01l-.046 2Zm-.102-.178.664.382.997-1.734-.664-.382-.997 1.734Zm.776-1.408a1.76 1.76 0 0 1-.687.137l-.007 2a3.76 3.76 0 0 0 1.467-.292l-.773-1.845Zm-.698.137c-.357.003-.613-.07-.803-.18l-.997 1.734c.55.316 1.168.451 1.816.446l-.016-2Zm-.803-.18a.686.686 0 0 1-.097-.065c-.016-.013-.013-.014-.003 0a.376.376 0 0 1 .06.21l-2-.058c-.024.817.521 1.347 1.043 1.647l.997-1.734Zm-.04.134a.34.34 0 0 1-.043.156c-.018.032-.025.026.02-.008l-1.226-1.58c-.4.309-.74.773-.751 1.395l2 .037Zm-1.135.225.659.378.997-1.733-.659-.38-.997 1.735Zm.403-1.145a1.307 1.307 0 0 0-.32.923l1.998-.1a.693.693 0 0 1-.169.49l-1.509-1.313Zm-.318.94c.042.633.493.997.801 1.173l.994-1.735c-.027-.015.01 0 .063.065.06.074.126.2.137.363l-1.995.134Zm.806 1.176a2.31 2.31 0 0 0 1.132.299l.004-2a.31.31 0 0 1-.152-.04l-.984 1.741Z"
        fill="#F98600"
        mask="url(#NetDeposits_svg__f)"
      />
      <path
        d="M2.162 28.745c.08.127.173.246.276.354v-.928a2.179 2.179 0 0 1-.276-.354v.928ZM2.713 29.353c.087.074.18.142.275.204v-.925a3.374 3.374 0 0 1-.275-.204v.925ZM3.264 29.727c.088.053.18.1.275.146v-.928a4.567 4.567 0 0 1-.275-.143v.925ZM3.814 29.993c.09.039.183.072.276.104v-.925a6.5 6.5 0 0 1-.276-.105v.926ZM4.367 30.186c.091.03.185.054.278.077v-.926c-.096-.022-.187-.05-.278-.077v.926ZM4.92 30.326c.091.019.182.037.276.05v-.925c-.094-.014-.187-.033-.276-.05v.925ZM5.47 30.417c.092.011.183.023.276.033v-.927a4.817 4.817 0 0 1-.275-.03v.924ZM6.021 30.467c.092.005.183.009.276.009v-.925c-.09 0-.184-.005-.276-.01v.926ZM6.574 30.476a5.4 5.4 0 0 0 .276-.009v-.925a3.73 3.73 0 0 1-.276.009v.925ZM7.125 30.449c.091-.007.185-.018.276-.03v-.923c-.091.011-.185.02-.276.03v.923ZM7.676 30.379c.093-.014.187-.03.275-.048v-.925a4.88 4.88 0 0 1-.275.05v.923ZM8.227 30.268c.093-.023.186-.048.275-.076v-.922a4.784 4.784 0 0 1-.275.074v.924ZM8.78 30.107c.093-.034.186-.067.275-.105v-.923a4.644 4.644 0 0 1-.276.104v.924ZM9.33 29.882a4.37 4.37 0 0 0 .276-.143v-.923c-.09.05-.18.097-.276.143v.923ZM9.88 29.57a3.24 3.24 0 0 0 .276-.203v-.923c-.087.073-.18.142-.275.204v.923ZM10.432 28.19v.92a2.07 2.07 0 0 0 .275-.353v-.919c-.08.127-.171.244-.275.352Z"
        fill="#F98600"
      />
      <path
        d="m19.093 6.77.358.11-.036.334-1.117.642-.357-.108.035-.337 1.117-.641ZM13.389 10.064l1.116-.642.358.11-.036.335-1.116.642-.357-.109.035-.336ZM14.726 11.91l1.115-.642.358.11-.035.337-1.51.868.072-.673ZM20.52 3.963l-1.18.68-.717-.219 1.576-.905.356.108-.035.336ZM21.626 7.93l1.18-.679.358.108-.037.337-1.18.678-.357-.11.036-.335ZM20.236 6.009l-.036.334-.358-.107-1.23-1.7.71.21.914 1.263ZM18.017 4.771l.358.108L19.5 6.433l-.037.337-.358-.11-1.125-1.554.037-.335ZM19.531 7.251l.036-.336.36.11 1.122 1.552-.036.337-.358-.11L19.53 7.25ZM16.734 5.822l1.125 1.554-.035.336-.36-.11L16.34 6.05l.037-.336.357.109ZM15.84 6.435l.36.109.912 1.262-.036.337-.358-.11-.913-1.262.035-.336ZM15.69 6.74l-1.18.678-.387-.093.065-.351 1.18-.679.357.109-.035.336ZM17.027 8.587l-1.18.678-.386-.092.063-.352 1.181-.68.358.111-.036.335ZM16.861 10.669l1.181-.679.357.109-.035.336-1.181.679-.386-.094.064-.351Z"
        fill="#fff"
      />
      <path
        d="M24.96 7.665 20.94 2.11c-.13-.18-.362-.249-.52-.158L2.917 12.016c-.157.09-.182.308-.052.487l4.02 5.555c.128.178.361.25.519.159L24.907 8.152c.157-.09.183-.309.052-.488Zm-3.49-2.263.36.109-.038.335-1.116.642-.357-.109.036-.336 1.115-.641ZM20.2 3.517l.356.109-.035.336-1.181.679-.716-.218 1.575-.906Zm-.876 1.227.914 1.263-.036.335-.359-.108-1.23-1.7.71.21Zm-2.945.97.357.108 1.124 1.554-.035.336-.359-.11-1.125-1.554.038-.335Zm-.539.721.36.11.913 1.262-.037.337-.358-.11-.913-1.263.034-.336h.001Zm1.224 1.817-.036.335-1.18.68-.386-.093.064-.353 1.18-.679.358.11ZM14.19 6.975l1.181-.68.357.11-.035.335-1.182.679-.386-.094.064-.351h.001Zm-.549.74.358.108.915 1.263-.037.337-.358-.11-.914-1.263.036-.335Zm1.224 1.817-.036.335-1.117.642-.357-.109.035-.336 1.117-.642.358.11Zm-2.819-1.325 1.117-.642.357.109-.035.336-1.117.642-.358-.11.036-.335Zm-1.504 1.496-.357.205-.368-.104.047-.341.752-.432-.073.672h-.001Zm2.942 3.553-.36-.11-1.174-1.623.037-.336-.36-.109-.973-1.346.08-.665 1.29 1.783-.037.337.358.108 1.175 1.624-.036.337Zm2.682-1.54-1.51.868.072-.674 1.115-.64.358.11-.033.335h-.002Zm2.2-1.28-1.18.679-.386-.094.064-.351 1.18-.679.358.109-.036.336Zm.047-.446-.356-.108-.911-1.259.036-.335.358.108.911 1.259-.038.335Zm1.002-2.775-1.117.642-.357-.109.035-.336 1.117-.642.358.11-.036.335Zm.048-.444-.358-.11-1.123-1.555.036-.335.358.108L19.5 6.433l-.036.337-.002.001Zm1.55 2.144-.357-.11-1.124-1.553.036-.336.359.11 1.123 1.552-.036.337Zm2.116-1.218-1.18.678-.358-.11.036-.335 1.18-.678.358.108-.037.337Zm.047-.446-.356-.109-.911-1.258.036-.335.358.108.911 1.258-.038.336Zm-9.478 3.367-.357-.109-.039.336 1.235 1.703.07-.671-.909-1.259Z"
        fill="#4C679B"
      />
      <path
        d="m9.862 9.462.751-.432-.073.671-.357.206-.369-.104.048-.34ZM13.122 13.146l-1.175-1.624.038-.335.358.107 1.175 1.624-.037.337-.359-.11Z"
        fill="#fff"
      />
      <path
        d="M22.182 1.027c-.67-.925-1.88-1.293-2.7-.822L1.519 10.535c-.821.471-.943 1.604-.273 2.53l5.61 7.752L27.792 8.779l-5.611-7.752Zm2.833 7.009a.267.267 0 0 1-.108.117L7.404 18.216a.32.32 0 0 1-.236.03.428.428 0 0 1-.172-.075.485.485 0 0 1-.113-.113l-4.02-5.555a.463.463 0 0 1-.057-.104c-.059-.153-.018-.31.11-.383L20.419 1.953a.31.31 0 0 1 .199-.037.476.476 0 0 1 .32.195l4.021 5.554a.435.435 0 0 1 .057.103.35.35 0 0 1-.001.267v.001Z"
        fill="#0084FF"
      />
      <path
        d="M22.182 1.027c-.67-.925-1.88-1.293-2.7-.822L1.519 10.535c-.821.471-.943 1.604-.273 2.53l5.61 7.752L27.792 8.779l-5.611-7.752Zm2.833 7.009a.267.267 0 0 1-.108.117L7.404 18.216a.32.32 0 0 1-.236.03.428.428 0 0 1-.172-.075.485.485 0 0 1-.113-.113l-4.02-5.555a.463.463 0 0 1-.057-.104c-.059-.153-.018-.31.11-.383L20.419 1.953a.31.31 0 0 1 .199-.037.476.476 0 0 1 .32.195l4.021 5.554a.435.435 0 0 1 .057.103.35.35 0 0 1-.001.267v.001Z"
        fill="#fff"
        fillOpacity={0.2}
      />
      <path
        d="m21.944 5.548-.036.335.911 1.258.357.11.037-.337-.91-1.258-.359-.108ZM20.354 6.044l1.115-.64.36.109-.038.335-1.116.641-.357-.108.036-.337ZM17.145 8.622l.036-.335.358.108.91 1.259-.037.336-.357-.11-.91-1.258ZM13.64 7.715l.358.107.914 1.264-.037.337-.357-.11-.915-1.264.037-.334ZM13.483 8.01l-1.117.641-.358-.11.036-.334 1.117-.643.357.11-.035.335ZM10.732 9.066l1.29 1.784-.036.337-.36-.11-.974-1.346.08-.665Z"
        fill="#fff"
      />
      <path
        d="m.382 11.659.811-.87c-.516.553-.518 1.489.05 2.275l-.811.87c-.568-.785-.566-1.722-.05-2.275Z"
        fill="#0084FF"
      />
      <path
        d="m.382 11.659.811-.87c-.516.553-.518 1.489.05 2.275l-.811.87c-.568-.785-.566-1.722-.05-2.275Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="m27.79 8.78-.812.869L6.04 21.686l.812-.87L27.79 8.78Z"
        fill="#6F95E4"
      />
      <path
        d="m6.852 20.817-.811.87-5.61-7.752.812-.87 5.61 7.752Z"
        fill="#0084FF"
      />
      <path
        d="m6.852 20.817-.811.87-5.61-7.752.812-.87 5.61 7.752Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="m13.341 10.508-.038.336 1.234 1.704.07-.672-.908-1.259-.358-.109Z"
        fill="#fff"
      />
      <path
        d="m48.592 21.233-.005 1.7c0 .308-.203.614-.609.849l.005-1.7c.405-.235.608-.541.609-.849Z"
        fill="#0084FF"
      />
      <path
        d="m48.592 21.233-.005 1.7c0 .308-.203.614-.609.849l.005-1.7c.405-.235.608-.541.609-.849Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="m47.982 22.081-.005 1.701L30.045 34.16l.005-1.7L47.982 22.08Z"
        fill="#0084FF"
      />
      <path
        d="m47.982 22.081-.005 1.701L30.045 34.16l.005-1.7L47.982 22.08Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="m27.088 32.46-.005 1.7L6.83 22.515l.005-1.7 20.253 11.644Z"
        fill="#0084FF"
      />
      <path
        d="m27.088 32.46-.005 1.7L6.83 22.515l.005-1.7 20.253 11.644Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="m30.05 32.46-.004 1.7c-.814.47-2.142.47-2.962 0l.005-1.7c.82.47 2.147.47 2.962 0Z"
        fill="#0084FF"
      />
      <path
        d="m30.05 32.46-.004 1.7c-.814.47-2.142.47-2.962 0l.005-1.7c.82.47 2.147.47 2.962 0Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="M47.972 20.373c.82.471.826 1.236.01 1.708L30.049 32.46c-.814.471-2.142.471-2.961 0L6.834 20.815 27.718 8.728l20.254 11.645Z"
        fill="#0084FF"
      />
      <path d="M31.622 11.957v.026a.527.527 0 0 0 0-.027Z" fill="#6F95E4" />
      <path
        d="M31.62 11.983v.482c-.002.155-.102.307-.305.424l-1.908 1.105c-.409.236-1.07.238-1.481 0l-1.923-1.103c-.205-.119-.31-.275-.308-.432v-.509c0 .157.105.31.31.43l1.921 1.105c.41.236 1.074.236 1.48 0l1.911-1.105a.934.934 0 0 0 .147-.106.589.589 0 0 0 .12-.159.424.424 0 0 0 .038-.132Z"
        fill="#BBB"
      />
      <path
        d="M31.62 11.957v.026a.435.435 0 0 1-.038.132.427.427 0 0 1-.047.08.602.602 0 0 1-.071.079.94.94 0 0 1-.147.106l-1.91 1.105c-.407.236-1.07.236-1.481 0l-1.921-1.105c-.205-.12-.31-.273-.31-.43 0-.156.103-.309.306-.425l1.908-1.104c.409-.238 1.07-.236 1.481 0l1.923 1.106c.21.12.312.28.306.44l.002-.01Z"
        fill="#fff"
      />
      <path
        d="m26.536 14.9-.002.508c0 .155-.103.31-.306.426l-1.906 1.105c-.409.236-1.068.236-1.48 0l-1.924-1.105c-.208-.119-.31-.275-.31-.432l.001-.509c0 .156.103.31.31.43l1.921 1.105c.41.236 1.073.236 1.48 0l1.91-1.106c.203-.116.306-.27.306-.423Z"
        fill="#BBB"
      />
      <path
        d="M26.535 14.9c0 .152-.102.306-.305.423l-1.91 1.105c-.407.236-1.07.236-1.48 0l-1.92-1.105c-.208-.119-.31-.274-.31-.43 0-.156.1-.306.303-.423l1.908-1.106c.409-.235 1.073-.235 1.483 0l1.923 1.106c.208.119.31.274.308.43Z"
        fill="#fff"
      />
      <path
        d="m21.451 17.843-.002.508c0 .155-.103.309-.306.426l-1.908 1.105c-.408.236-1.07.236-1.48 0l-1.923-1.105c-.206-.119-.31-.273-.309-.43v-.509c0 .157.105.311.31.43l1.921 1.103c.411.238 1.075.236 1.483 0l1.907-1.104a.65.65 0 0 0 .262-.253.364.364 0 0 0 .045-.171Z"
        fill="#BBB"
      />
      <path
        d="M21.451 17.842c0 .06-.015.119-.044.172a.65.65 0 0 1-.262.252l-1.908 1.105c-.408.236-1.072.238-1.483 0l-1.92-1.103c-.206-.119-.31-.273-.31-.43 0-.156.102-.308.306-.425l1.907-1.106c.409-.235 1.073-.235 1.484 0l1.92 1.106c.207.119.31.275.31.43Z"
        fill="#fff"
      />
      <path d="M16.363 20.786v.027a.518.518 0 0 0 0-.027Z" fill="#6F95E4" />
      <path
        d="M16.363 20.813v.484c-.002.152-.102.306-.306.423l-1.908 1.106c-.408.235-1.07.235-1.48 0l-1.923-1.106c-.206-.119-.31-.273-.31-.43l.002-.508c0 .156.104.31.31.43l1.92 1.105c.41.235 1.075.232 1.481-.002l1.91-1.106a.845.845 0 0 0 .184-.144.494.494 0 0 0 .06-.081.17.17 0 0 0 .022-.04.37.37 0 0 0 .038-.132Z"
        fill="#BBB"
      />
      <path
        d="M16.363 20.786v.027c0 .009-.002.018-.004.027a.358.358 0 0 1-.055.144.719.719 0 0 1-.06.081.842.842 0 0 1-.185.144l-1.91 1.106c-.406.235-1.07.237-1.48.002l-1.921-1.106c-.206-.119-.31-.273-.31-.43 0-.156.102-.308.305-.425l1.909-1.106c.408-.235 1.07-.235 1.48.003l1.923 1.103c.21.121.312.28.306.44l.002-.01Z"
        fill="#fff"
      />
      <path d="M36.486 14.879Z" fill="#6F95E4" />
      <path
        d="m36.506 14.764-.002.509c0 .154-.1.306-.304.425l-1.91 1.104c-.407.235-1.07.237-1.481.002l-1.92-1.106c-.208-.119-.31-.275-.31-.43l.001-.51c0 .156.103.31.31.429l1.92 1.106c.412.235 1.073.235 1.482 0l1.91-1.106a.94.94 0 0 0 .134-.094.505.505 0 0 0 .15-.214.362.362 0 0 0 .02-.115Z"
        fill="#BBB"
      />
      <path
        d="M36.506 14.764c0 .039-.007.078-.02.115a.506.506 0 0 1-.15.214.945.945 0 0 1-.134.094l-1.91 1.106c-.409.235-1.07.235-1.481 0l-1.92-1.106c-.208-.119-.31-.273-.31-.43 0-.156.1-.306.303-.425l1.91-1.104c.409-.235 1.07-.235 1.48 0l1.922 1.106c.206.119.31.273.31.43Z"
        fill="#fff"
      />
      <path d="M31.42 17.711Z" fill="#6F95E4" />
      <path
        d="m31.42 17.711-.002.505c0 .154-.103.308-.306.425l-1.908 1.106c-.409.236-1.07.236-1.481 0l-1.92-1.105c-.208-.12-.31-.276-.31-.433v-.507c0 .154.104.308.31.427l1.922 1.106c.41.236 1.072.236 1.479 0l1.91-1.106c.15-.087.247-.196.285-.308a.33.33 0 0 0 .02-.11Z"
        fill="#BBB"
      />
      <path
        d="M31.42 17.711a.33.33 0 0 1-.021.11c-.038.113-.134.222-.285.31l-1.91 1.105c-.407.236-1.068.236-1.48 0l-1.922-1.106c-.205-.119-.31-.273-.31-.427 0-.155.103-.309.306-.426l1.908-1.105c.408-.236 1.073-.236 1.483 0l1.92 1.105c.21.12.313.278.31.434Z"
        fill="#fff"
      />
      <path
        d="M26.332 20.65v.508c-.002.154-.103.309-.306.425l-1.91 1.106c-.406.236-1.07.236-1.481 0l-1.92-1.106c-.208-.116-.31-.273-.31-.43l.001-.508c0 .154.103.31.31.43l1.921 1.105c.41.236 1.073.233 1.481-.002l1.91-1.104c.201-.118.304-.27.304-.424Z"
        fill="#BBB"
      />
      <path
        d="M26.332 20.65c0 .154-.103.307-.304.426l-1.91 1.103c-.408.236-1.07.238-1.481.002l-1.92-1.105c-.208-.12-.31-.276-.31-.43 0-.155.1-.309.303-.426l1.91-1.105c.409-.236 1.073-.236 1.483 0l1.921 1.105c.207.12.31.276.308.43Z"
        fill="#fff"
      />
      <path
        d="m21.246 23.593-.002.511c0 .154-.1.307-.304.424l-1.91 1.105c-.407.236-1.07.236-1.481 0l-1.92-1.104c-.208-.118-.31-.275-.31-.432l.001-.508c0 .154.103.31.31.43l1.921 1.105c.41.235 1.072.235 1.48-.002l1.911-1.104a.737.737 0 0 0 .228-.2.37.37 0 0 0 .075-.225Z"
        fill="#BBB"
      />
      <path
        d="M21.246 23.593a.372.372 0 0 1-.076.225.738.738 0 0 1-.228.2l-1.91 1.104c-.409.238-1.07.238-1.481.002l-1.92-1.105c-.208-.12-.31-.276-.31-.43 0-.154.1-.309.303-.426l1.91-1.105c.407-.236 1.07-.233 1.481.002l1.92 1.103c.208.12.31.276.31.43Z"
        fill="#fff"
      />
      <path
        d="M35.47 17.621a.156.156 0 0 1-.005-.027v-.025c0 .017.002.035.004.052ZM46.273 20.379v.027a.418.418 0 0 0 0-.027Z"
        fill="#5D7EBF"
      />
      <path
        d="M46.273 20.406v.484c-.002.152-.103.306-.306.423L44.06 22.42c-.409.235-1.07.235-1.48 0l-6.806-3.913c-.207-.12-.31-.276-.31-.43l.002-.482c0 .01.002.018.004.027a.454.454 0 0 0 .117.229.608.608 0 0 0 .116.1c.02.015.046.033.07.046l6.805 3.912c.41.238 1.074.235 1.483 0l1.908-1.106a.792.792 0 0 0 .218-.185.364.364 0 0 0 .048-.08.332.332 0 0 0 .034-.104v-.004a.106.106 0 0 0 .005-.023Z"
        fill="#ED5656"
      />
      <path
        d="M46.273 20.379v.027a.112.112 0 0 1-.004.023v.004a.334.334 0 0 1-.034.105.35.35 0 0 1-.048.079.633.633 0 0 1-.136.131.776.776 0 0 1-.082.054l-1.908 1.106c-.408.235-1.072.238-1.483 0l-6.805-3.911a.994.994 0 0 1-.072-.046.612.612 0 0 1-.115-.1.455.455 0 0 1-.117-.229.156.156 0 0 1-.004-.027v-.026l.002.008c-.006-.157.092-.315.3-.436l1.91-1.105c.407-.236 1.071-.236 1.482 0l6.805 3.913c.21.12.312.28.306.44 0-.004.003-.006.003-.01Z"
        fill="#F6ABAB"
      />
      <path
        d="M36.303 20.515v.51c-.002.155-.103.309-.306.425l-1.91 1.104c-.407.235-1.068.235-1.479 0l-1.923-1.106c-.205-.119-.31-.275-.31-.43l.002-.51c0 .156.103.312.31.432l1.923 1.103c.41.238 1.07.238 1.479 0l1.91-1.103a.734.734 0 0 0 .228-.2.395.395 0 0 0 .076-.225Z"
        fill="#BBB"
      />
      <path
        d="M36.302 20.515a.395.395 0 0 1-.075.225.735.735 0 0 1-.228.2l-1.91 1.103c-.41.238-1.069.238-1.48 0l-1.922-1.103c-.208-.119-.31-.275-.31-.432 0-.157.1-.307.304-.423l1.91-1.106c.406-.236 1.07-.236 1.48 0l1.923 1.106c.208.119.31.275.308.43Z"
        fill="#fff"
      />
      <path
        d="m31.216 23.457-.002.512c0 .152-.1.306-.303.423L29 25.497c-.409.236-1.07.238-1.481 0l-1.92-1.103c-.209-.119-.31-.275-.31-.432l.001-.482a.346.346 0 0 0 .044.148.42.42 0 0 0 .103.134.64.64 0 0 0 .09.075c.02.015.046.031.071.046l1.923 1.105c.41.236 1.072.236 1.48-.002l1.909-1.103a.594.594 0 0 0 .07-.046.695.695 0 0 0 .116-.1.415.415 0 0 0 .12-.28Z"
        fill="#BBB"
      />
      <path
        d="M25.291 23.457a.363.363 0 0 0 0 .023v-.023ZM25.413 23.737Z"
        fill="#6F95E4"
      />
      <path
        d="M31.217 23.457a.415.415 0 0 1-.12.28.69.69 0 0 1-.115.1.59.59 0 0 1-.071.046l-1.908 1.103c-.408.238-1.07.238-1.481.002l-1.923-1.105a1.11 1.11 0 0 1-.071-.046.645.645 0 0 1-.115-.1.423.423 0 0 1-.103-.165.35.35 0 0 1-.019-.092v-.023.009c-.005-.158.093-.317.303-.438l1.91-1.104c.406-.237 1.07-.237 1.48 0l1.924 1.104c.208.119.311.275.309.43Z"
        fill="#fff"
      />
      <path d="M20.205 26.4a.165.165 0 0 0 0 .024V26.4Z" fill="#6F95E4" />
      <path
        d="m26.13 26.407-.001.505c0 .154-.103.306-.304.423l-1.91 1.106c-.409.235-1.07.237-1.48 0l-1.922-1.104c-.207-.119-.31-.275-.31-.432l.003-.482.003.021v.007c0 .004 0 .008.003.012a.333.333 0 0 0 .038.109.409.409 0 0 0 .102.133.642.642 0 0 0 .09.075c.021.014.046.031.071.046l1.923 1.105c.41.236 1.072.236 1.48 0l1.91-1.105c.15-.088.246-.196.284-.309a.326.326 0 0 0 .02-.11Z"
        fill="#BBB"
      />
      <path
        d="M26.13 26.407a.33.33 0 0 1-.02.11c-.038.113-.134.222-.285.31l-1.909 1.105c-.408.236-1.07.236-1.48 0l-1.923-1.106a1.144 1.144 0 0 1-.071-.046.64.64 0 0 1-.115-.1.41.41 0 0 1-.078-.108.325.325 0 0 1-.04-.121v-.006a.074.074 0 0 1-.004-.021V26.4l.002.008c-.006-.159.092-.317.3-.438l1.908-1.103c.409-.238 1.07-.236 1.482 0l1.922 1.105c.211.119.314.277.312.434Z"
        fill="#fff"
      />
      <path
        d="m41.187 23.322-.002.511c0 .152-.103.307-.304.423l-1.909 1.106c-.409.236-1.068.236-1.479 0l-1.925-1.106c-.207-.119-.31-.273-.31-.43l.002-.508c0 .154.103.31.31.43l1.92 1.105c.411.236 1.073.236 1.482 0l1.908-1.105c.204-.117.307-.272.307-.426Z"
        fill="#BBB"
      />
      <path
        d="M41.186 23.322c0 .155-.102.309-.306.426l-1.909 1.105c-.408.236-1.07.236-1.48 0l-1.922-1.105c-.207-.12-.31-.276-.31-.43 0-.154.1-.309.304-.426l1.908-1.103c.409-.238 1.073-.238 1.484 0l1.922 1.103c.208.12.311.276.31.43Z"
        fill="#fff"
      />
      <path
        d="m36.101 26.265-.002.511c0 .154-.102.307-.305.424l-1.909 1.105c-.408.236-1.07.238-1.48.002l-1.923-1.105c-.206-.12-.31-.276-.308-.433v-.506c0 .154.105.308.31.427l1.922 1.106c.411.235 1.073.235 1.481 0l1.909-1.106c.202-.115.305-.27.305-.425Z"
        fill="#BBB"
      />
      <path
        d="M36.101 26.265c0 .154-.102.309-.305.425l-1.909 1.106c-.408.236-1.07.236-1.48 0l-1.923-1.106c-.205-.118-.31-.273-.31-.427 0-.154.102-.309.306-.428l1.908-1.103c.408-.236 1.072-.236 1.483 0l1.92 1.103c.205.12.31.275.31.43Z"
        fill="#fff"
      />
      <path d="M31.014 29.21v.027a.555.555 0 0 0 0-.027Z" fill="#6F95E4" />
      <path
        d="M31.014 29.237v.482c-.003.155-.103.307-.306.426L28.8 31.248c-.409.236-1.07.238-1.481.002l-1.923-1.105c-.205-.12-.31-.276-.308-.43v-.509c0 .155.105.309.31.428l1.92 1.105c.411.236 1.075.236 1.482 0l1.91-1.105c.15-.088.247-.196.285-.309a.486.486 0 0 0 .019-.088Z"
        fill="#BBB"
      />
      <path
        d="M31.014 29.21v.027a.49.49 0 0 1-.019.088c-.038.113-.134.221-.285.309l-1.91 1.105c-.407.236-1.07.236-1.481 0l-1.921-1.106c-.205-.12-.31-.273-.31-.428 0-.154.103-.309.306-.425l1.908-1.106c.408-.236 1.07-.236 1.481 0l1.923 1.106c.21.12.312.28.305.44 0-.003.003-.006.003-.01Z"
        fill="#fff"
      />
      <path
        d="m7.544 21.22-.315 1.525-.298-.171a2.274 2.274 0 0 1-.71-.64l-.534-.738.711-1.01.146.21c.191.276.442.506.734.673l.266.152Z"
        fill="#0084FF"
      />
      <path
        d="m7.544 21.22-.315 1.525-.298-.171a2.274 2.274 0 0 1-.71-.64l-.534-.738.711-1.01.146.21c.191.276.442.506.734.673l.266.152Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="M44.063 31.587a2.787 2.787 0 0 1-.777.294c-.548.12-1.116.12-1.665 0a2.811 2.811 0 0 1-.78-.294l1.606-.93 1.616.93Z"
        fill="#00BA34"
      />
      <path
        d="M49.07 34.467a1.52 1.52 0 0 0-.507.449l-5.278-3.035c.272-.058.534-.157.776-.294l5.008 2.88ZM41.62 31.881l-5.671 3.282-.646-.37 5.537-3.206c.244.137.507.236.78.294Z"
        fill="#00BA34"
      />
      <path
        d="m50.847 35.488-1.597.925a2.01 2.01 0 0 1-.17-.088 1.693 1.693 0 0 1-.411-.321c-.307-.338-.343-.737-.106-1.088.11-.164.278-.317.506-.449l1.778 1.021Z"
        fill="#00BA34"
      />
      <path
        d="M48.564 34.917c-.237.351-.2.75.106 1.088l-5.611 3.247-.898-.516c.681-.85.41-1.894-.811-2.596-1.221-.702-3.03-.855-4.503-.459l-.898-.516 5.672-3.282c.548.12 1.117.12 1.665 0l5.278 3.034Zm-5.53.248c.537.309 1.402.308 1.934 0s.53-.807-.006-1.115c-.537-.309-1.402-.307-1.933 0-.53.307-.53.807.005 1.114v.001Z"
        fill="#66D685"
      />
      <path
        d="M44.961 34.048c.537.308.538.807.007 1.115-.532.308-1.397.309-1.934 0-.537-.308-.537-.807-.005-1.114.532-.308 1.397-.309 1.932-.001Z"
        fill="#F4F4F4"
      />
      <path
        d="m50.847 35.489-.007 2.219-1.596.923.007-2.218 1.596-.924Z"
        fill="#66D685"
      />
      <path
        d="m35.949 35.163.898.516a4.7 4.7 0 0 0-1.135.46c-.338.195-.601.418-.792.655l-.898-.516-.645-.372 1.926-1.114.646.371Z"
        fill="#F4F4F4"
      />
      <path
        d="m39.083 36.92-.505.294a.859.859 0 0 0-.806.058c-.127.074-.2.146-.224.218-.023.072.013.136.106.19a.443.443 0 0 0 .257.053c.12-.012.24-.043.352-.089l.354-.136c.3-.117.562-.178.789-.185.216-.008.43.044.618.152.2.115.3.247.302.399.002.152-.09.303-.283.454l.371.213-.424.246-.372-.213a2.22 2.22 0 0 1-.858.186 1.752 1.752 0 0 1-.808-.178l.5-.29c.15.06.311.082.47.062.175-.02.344-.075.495-.164.126-.073.2-.143.224-.208.023-.065-.004-.122-.087-.17-.123-.07-.307-.06-.549.033l-.46.18c-.542.216-1.005.213-1.39-.008-.205-.118-.309-.256-.31-.412 0-.155.096-.308.295-.455l-.378-.218.425-.245.388.223c.24-.098.497-.15.757-.155.26-.004.517.053.751.166Z"
        fill="#0BCB85"
      />
      <path
        d="M34.023 36.278 28.35 39.56a1.534 1.534 0 0 0-.512-.449l5.54-3.206.645.372Z"
        fill="#00BA34"
      />
      <path
        d="m49.25 36.413-.006 2.218-5.547 3.21.007-2.219 5.546-3.21Z"
        fill="#66D685"
      />
      <path
        d="M49.08 36.325c.056.033.113.06.171.087l-5.547 3.21-.645-.371 5.611-3.248c.12.128.258.236.41.322Z"
        fill="#00BA34"
      />
      <path
        d="M42.16 38.735c-.19.237-.454.46-.793.656a4.7 4.7 0 0 1-1.132.458c-1.473.397-3.283.243-4.506-.46-1.222-.702-1.492-1.744-.81-2.595.19-.237.454-.46.792-.655a4.7 4.7 0 0 1 1.135-.46c1.473-.395 3.283-.242 4.503.459 1.22.701 1.493 1.745.811 2.597Zm-2.17.052.425-.246-.37-.213c.191-.151.286-.304.283-.454-.004-.15-.104-.284-.303-.399a1.15 1.15 0 0 0-.618-.152c-.226.007-.49.068-.789.185l-.354.136c-.113.046-.231.076-.352.09a.443.443 0 0 1-.257-.055c-.093-.053-.127-.116-.105-.189.021-.073.096-.145.223-.218a.858.858 0 0 1 .806-.058l.505-.293a1.68 1.68 0 0 0-.75-.166 2.11 2.11 0 0 0-.757.154l-.389-.223-.424.246.378.217c-.2.148-.297.3-.295.456 0 .156.105.294.31.411.384.222.848.225 1.39.01l.46-.181c.242-.093.426-.104.549-.033.083.047.113.104.087.17-.026.065-.098.135-.224.208-.151.088-.32.144-.495.163a.943.943 0 0 1-.47-.061l-.5.29c.251.123.528.184.808.178a2.22 2.22 0 0 0 .858-.187l.372.213h-.001Z"
        fill="#F4F4F4"
      />
      <path
        d="m43.06 39.25.645.372-1.926 1.115-.645-.372-.898-.516a4.7 4.7 0 0 0 1.133-.458c.338-.196.602-.419.793-.656l.897.516Z"
        fill="#F4F4F4"
      />
      <path
        d="m43.703 39.622-.006 2.219-1.925 1.115.006-2.22 1.925-1.114Z"
        fill="#DDD"
      />
      <path
        d="M27.838 39.112c.229.133.4.285.512.449.21.308.213.652.006.96-.11.163-.28.317-.508.45l-1.616-.931 1.606-.928Z"
        fill="#00BA34"
      />
      <path
        d="m27.849 40.97-.007 2.22-1.615-.93.006-2.218 1.616.928Z"
        fill="#12A23A"
      />
      <path
        d="M35.731 39.39c1.222.702 3.032.855 4.506.46l.897.515-5.612 3.249a3.837 3.837 0 0 0-1.885-.059l-5.28-3.035a.814.814 0 0 0-.005-.96l5.673-3.282.898.516c-.684.85-.412 1.894.808 2.596Zm-3.611 2.09c.536.309 1.402.309 1.932 0 .53-.308.53-.807-.007-1.115-.536-.309-1.399-.308-1.932 0-.532.307-.529.806.007 1.113v.002Z"
        fill="#66D685"
      />
      <path
        d="M34.044 40.365c.537.309.54.807.007 1.116-.534.308-1.396.307-1.933 0-.536-.308-.538-.807-.006-1.114.532-.307 1.397-.31 1.932-.002Z"
        fill="#F4F4F4"
      />
      <path
        d="m28.356 40.52 5.278 3.035a2.78 2.78 0 0 0-.778.294l-5.008-2.88c.228-.131.398-.285.508-.449Z"
        fill="#00BA34"
      />
      <path
        d="m32.856 43.85-.006 2.219-5.008-2.88.006-2.218 5.008 2.879Z"
        fill="#12A23A"
      />
      <path
        d="m41.78 40.737-.007 2.219-5.546 3.21.006-2.22 5.547-3.21ZM36.231 43.947l-.006 2.218-1.598.925.006-2.219 1.598-.924Z"
        fill="#66D685"
      />
      <path
        d="m35.52 43.614 5.612-3.248.646.37-5.547 3.21a2.02 2.02 0 0 0-.154-.097 2.657 2.657 0 0 0-.557-.235Z"
        fill="#00BA34"
      />
      <path
        d="M36.078 43.85c.055.033.106.064.154.096l-1.6.925-1.776-1.022c.242-.136.505-.235.778-.294a3.837 3.837 0 0 1 1.885.059c.195.057.382.136.559.236Z"
        fill="#00BA34"
      />
      <path
        d="m34.635 44.871-.007 2.22-1.776-1.022.006-2.22 1.777 1.023Z"
        fill="#12A23A"
      />
      <path
        d="M30.85 43.349c.646.335 1.285.685 1.922 1.037.32.173.634.356.952.534l.95.538h-.078l1.004-.569c.336-.188.668-.382 1.006-.565l1.011-.556c.34-.181.678-.366 1.02-.543-.325.207-.654.405-.981.608l-.99.594c-.33.199-.664.39-.996.584l-.996.581-.04.023-.038-.023-.943-.55c-.313-.184-.63-.365-.94-.553a86.34 86.34 0 0 1-1.863-1.14Z"
        fill="#12A23A"
      />
      <path
        d="M30.85 44.492a81.28 81.28 0 0 1 1.922 1.038c.32.173.634.356.952.534l.95.538h-.078l1.709-.974c.57-.324 1.138-.653 1.71-.971l1.716-.962 1.725-.948-1.687 1.013-1.694 1c-.564.334-1.135.659-1.7.989l-1.702.986-.038.023-.04-.023-.942-.55c-.314-.184-.63-.366-.94-.553a84.27 84.27 0 0 1-1.863-1.14Z"
        fill="#12A23A"
      />
      <path
        d="M28.371 42.517c1.06.572 2.111 1.16 3.16 1.749.527.292 1.048.593 1.572.89l1.568.895h-.077l1.004-.57c.336-.188.668-.382 1.006-.565l1.011-.556c.34-.181.677-.366 1.02-.543-.325.207-.655.405-.982.608l-.99.594c-.328.2-.663.39-.995.585l-.996.58-.039.022-.038-.022-1.562-.906c-.52-.303-1.043-.602-1.56-.91a220.488 220.488 0 0 1-3.102-1.851ZM26.227 40.623c.559.251 1.094.543 1.623.845.53.301 1.05.621 1.547.977-.559-.25-1.094-.542-1.624-.844a17.84 17.84 0 0 1-1.546-.978Z"
        fill="#66D685"
      />
      <path
        d="M44.027 40.09a62.344 62.344 0 0 1 1.676-1.03c.562-.336 1.13-.664 1.696-.997.571-.32 1.14-.648 1.714-.963l.864-.472c.29-.153.578-.31.87-.458-.274.179-.555.349-.833.523l-.842.509c-.563.337-1.131.665-1.697.997-.571.321-1.139.65-1.714.964-.574.315-1.149.628-1.734.928ZM47.398 38.856c.272-.192.552-.37.833-.546.281-.176.567-.345.853-.512.286-.167.577-.327.872-.48l.442-.23c.15-.07.297-.147.45-.215-.134.098-.275.187-.413.28l-.42.267c-.28.177-.567.344-.853.51-.286.168-.577.328-.871.481-.295.153-.59.308-.893.445Z"
        fill="#12A23A"
      />
    </g>
    <defs>
      <clipPath id="NetDeposits_svg__a">
        <path fill="#fff" d="M0 0h52v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNetDeposits;
