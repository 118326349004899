import { useLocation } from 'react-router-dom';
import IconComponent from 'components/Icons';
import NotificationBadge from 'components/NotificationBadge';
import { useStore } from 'store';
import { StyledNavLink } from './style';

type MenuItemProps = {
  identifier: string;
  url: string;
  title: string;
  notification: number;
  iconName?: string;
};

const MenuItem = ({
  identifier,
  url,
  title,
  notification,
  iconName = 'Bullet'
}: MenuItemProps): JSX.Element => {
  const location = useLocation();
  const isSideMenuOpen = useStore((state) => state.isMenuOpen);

  const generateIframeRefreshUrl = (): string => {
    const searchParams = new URLSearchParams(url.split('?')[1]);

    const randomValue = new Uint32Array(1);
    window.crypto.getRandomValues(randomValue);
    const rnd = ((randomValue[0] % 100000) + 1).toString();

    searchParams.append('rnd', rnd);

    return `${url.split('?')[0]}?${searchParams.toString()}`;
  };

  const navigationUrl = url.split('?')[0] === location.pathname ? generateIframeRefreshUrl() : url;

  return (
    <StyledNavLink to={navigationUrl} aria-label={`${identifier} Menu Item`}>
      <IconComponent name={iconName} />
      {isSideMenuOpen && (
        <>
          {title}
          {notification > 0 && (
            <NotificationBadge
              messagesNumber={notification}
              style={{ marginLeft: 'auto' }}
            />
          )}
        </>
      )}
    </StyledNavLink>
  );
};

export default MenuItem;
