import { SVGProps } from 'react';

const SvgCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M12.222 1.333a2.445 2.445 0 0 1 2.441 2.315l.004.13v8.444a2.445 2.445 0 0 1-2.315 2.441l-.13.004H3.778a2.445 2.445 0 0 1-2.441-2.315l-.004-.13V3.778a2.445 2.445 0 0 1 2.315-2.441l.13-.004h8.444Zm-1.75 4.862a.667.667 0 0 0-.943 0L7.333 8.39l-.862-.861-.062-.056a.667.667 0 0 0-.88.998l1.333 1.334.063.055a.667.667 0 0 0 .88-.055l2.666-2.667.056-.063a.667.667 0 0 0-.056-.88Z"
      fill="#18C083"
    />
  </svg>
);

export default SvgCheckboxChecked;
