/*
 * Zerologix Design System - Themes & Colors
 * https://www.figma.com/file/zOJ4pl5Hy103USPuCZRPQd/ZeroLogix-Design-System?type=design&node-id=624-38232&t=obwmdD1Gov5ME0ji-0
 * https://www.figma.com/file/zOJ4pl5Hy103USPuCZRPQd/ZeroLogix-Design-System?type=design&node-id=5-5976&t=obwmdD1Gov5ME0ji-0
 */
const palette = {
  // LogixPanel
  P50: '#E5F8F1',
  P75: '#CCF1E3',
  P100: '#99E3C8',
  P200: '#66D4AC',
  P300: '#33C691',
  P400: '#00B875',
  P500: '#00935E',
  P600: '#006E46',
  P700: '#004A2F',
  P800: '#002517',
  // Neutral
  N50: '#000000',
  N75: '#1C1C1C',
  N100: '#2A2A2A',
  N200: '#3E3E3E',
  N300: '#5C5C5C',
  N350: '#8C8C8C',
  N400: '#BBBBBB',
  N450: '#969696',
  N500: '#DDDDDD',
  N600: '#F4F4F4',
  N650: '#FBFBFB',
  N700: '#FFFFFF',
  // Extended
  G400: '#00BA34',
  R400: '#E92C2C',
  O400: '#F98600',
  // Others
  F400: '#0282D0'
};

export type Palette = typeof palette;
export default palette;
