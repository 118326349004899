import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { logixPanelRootURL } from 'api/config';
import { usePersistStore } from 'store';
import { OldPagesIframe, IframeWrapper } from './styled';

const OldpageShower = ():JSX.Element => {
  const location = useLocation();
  const { lang } = usePersistStore();
  const [maximize, setMaximize] = useState<boolean>(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      const message = event.data;
      if (message.action === 'maximize') {
        setMaximize((prevMaximize) => !prevMaximize);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      setMaximize(false);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    setMaximize(false);
  }, [location]);

  const iframeLangUrl = `${logixPanelRootURL}/${lang}${(location.pathname + location.search)}`;

  const iframe = (
    <OldPagesIframe
      aria-label="iframe for the old version pages"
      key={location.pathname}
      src={iframeLangUrl}
      maximize={maximize}
    />
  );
  /*
    have to replace a new iframe everytime the src change
    otherwise clicking previous page button will just shows
    the previous iframe of url that was pushed to iframe,
    instead of going to the previous page.
  */
  return <IframeWrapper>{iframe}</IframeWrapper>;
};

export default OldpageShower;
