import { css } from 'styled-components';

const rem = (px: number): string => `${px / 16}rem`;

/*
 * Zerologix Design System - Typography
 * https://www.figma.com/file/3OCGs3pj7c2qjgpIfpJyqt/Zerologix-Typography-%26-Language-Pack?type=design&node-id=2-53&t=obwmdD1Gov5ME0ji-0
 */

const typography = {
  H1: css`
    font-size: ${rem(36)};
    line-height: ${rem(48)};
  `,
  H2: css`
    font-size: ${rem(28)};
    line-height: ${rem(40)};
  `,
  H3: css`
    font-size: ${rem(24)};
    line-height: ${rem(36)};
  `,
  H4: css`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `,
  H5: css`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  `,
  H6: css`
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  `,
  Body1: css`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  `,
  Body2: css`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `,
  SubTitle1: css`
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  `,
  SubTitle2: css`
    font-size: ${rem(10)};
    line-height: ${rem(16)};
  `
};

export type Typography = typeof typography;
export default typography;
