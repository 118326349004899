import styled from 'styled-components';

export const StyledGreetings = styled.div`
  color: #2A2A2A;
`;

export const StyledMainGreetingWrap = styled.div`
  display:flex;
  align-items:center;
  margin-bottom:16px;
`;

export const StyledMainGreetings = styled.div`
  margin-left: 16px;
  font-size:28px;
  text-transform: capitalize;
  font-weight:bold;

`;

export const StyledMessage = styled.div`
  font-size:16px;
  font-weight: 400;
`;
