import { useState, useEffect } from 'react';
import { SnapChartData } from 'type/snapshot';
import BarChart from 'components/BarChart';
import dayjs from 'dayjs';
import { stackChartGenerator } from 'utils/chart';
import { colorGenerator, intlGenerator } from 'utils/common';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import SnapShot from 'components/SnapShot';
import { LegendItem } from 'chart.js';
import { getTeamCommissionsRequestConfig } from 'api/getV2RequestConfig';
import Spinner from 'components/Spinner';
import { useAuth } from 'store/context/hooks';
import {
  TeamCommissionsData, MemberObj, DataPair
} from './type';
import { StyledToggleTxt, StyledLoadingContainer } from './style';

const TeamCommissions: React.FC = () => {
  const teamToggleMaxMember = 8;
  const stackKey = 'team commissions';
  const stackColorArray = [
    '#004F99',
    '#0084FF',
    '#66B5FF',
    '#C76B00',
    '#F98600',
    '#FDCF99',
    '#FDCF99',
    '#f5744d'
  ];

  const [teamComData, setTeamComData] = useState<SnapChartData>();
  const { sendRequest } = useHttp();
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const [isMemberExpand, setIsMemberExpand] = useState<boolean>(false);

  const { userInfo } = useAuth();

  const stackBarOptions = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          padding: 5
        },
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        suggestedMax: 500,
        ticks: {
          padding: 8,
          callback(value:number) {
            return `${intlGenerator(value)}`;
          }
        }
      }
    },
    plugins: {
      legend: {
        align: 'start',
        reverse: true,
        labels: {
          position: 'left',
          usePointStyle: true,
          boxWidth: 40,
          boxHeight: 40,
          pointStyle: 'rectRounded',
          filter(legendItem:LegendItem) {
            if (isMemberExpand) {
              return legendItem;
            }
            return (
              stackColorArray.includes(legendItem.fillStyle ? legendItem.fillStyle.toString() : '')
            );
          }
        }
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      tooltip: {
        mode: 'index',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        itemSort(a:any, b:any) {
          return b.datasetIndex - a.datasetIndex;
        },
        intersect: false,
        bodySpacing: 8,
        padding: 8,
        backgroundColor: '#F4F4F4',
        titleColor: '#585757',
        bodyColor: '#585757',
        callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label(context:any) {
            const label = context.dataset.label || '';
            return `  ${label.trim()} : ${intlGenerator(context.parsed.y)}`;
          }
        },
        enabled: false,

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        external(context:any) {
        // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-custom-tooltip');
          const { chart, tooltip } = context;
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-custom-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          } else {
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }
          chart.canvas.parentNode.appendChild(tooltipEl);

          // Hide if no tooltip

          const tooltipModel = context.tooltip;

          // Set caret Position
          // tooltipEl.classList.remove('above', 'below', 'no-transform');
          // if (tooltipModel.yAlign) {
          //   tooltipEl.classList.add(tooltipModel.yAlign);
          // } else {
          //   tooltipEl.classList.add('no-transform');
          // }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          function getBody(bodyItem:any):string[] {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<thead>';

            titleLines.forEach((title:string) => {
              innerHtml += `<tr><th style="text-align: left">${title}</th></tr>`;
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach((body:string, i:number) => {
              const colors = tooltipModel.labelColors[i];
              let style = `background:${colors.backgroundColor}`;
              style += `; border-color:${colors.borderColor}`;
              style += '; border-width: 4px';
              style += '; height: 14px';
              style += '; width: 14px';
              style += '; display: inline-block';
              style += '; margin-right: 8px';
              const span = `<span style="${style}"></span>`;
              innerHtml += `
              <tr>
                <td style="display: flex; align-items: center">
                  ${span}${body}
                </td>
              </tr>
            `;
            });
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            if (tableRoot) tableRoot.innerHTML = innerHtml;
          }

          // Display, position, and set styles for font
          const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
          tooltipEl.style.opacity = '1';
          tooltipEl.style.display = 'block';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.left = `${positionX + tooltipModel.caretX}px`;
          tooltipEl.style.top = `${positionY + tooltipModel.caretY}px`;
          tooltipEl.style.fontSize = '12px';
          tooltipEl.style.font = tooltip.options.bodyFont.string;
          tooltipEl.style.backgroundColor = '#F4F4F4';
          tooltipEl.style.color = '#585757';
          tooltipEl.style.maxHeight = '220px';
          tooltipEl.style.width = `${tooltipModel.width + 40}px`;
          tooltipEl.style.overflow = 'scroll';
          tooltipEl.style.transform = 'translate(20px, -20px)';
          tooltipEl.style.transition = 'all .2s ease';
          tooltipEl.style.padding = `${tooltip.options.padding}px`;
          tooltipEl.style.paddingBottom = '0px';
          tooltipEl.style.paddingRight = '0px';
          tooltipEl.style.zIndex = '10';

          if (tooltipModel.opacity === 0) {
            setTimeout(() => {
              if (tooltipEl && tooltipEl.getAttribute('aria-label') !== 'on-tooltip') {
                tooltipEl.setAttribute('aria-label', 'outside-bar');
                tooltipEl.style.display = 'none';
                tooltipEl.style.opacity = '0';
              }
            }, 0);
          } else if (tooltipModel.opacity === 1) {
            tooltipEl.setAttribute('aria-label', 'on-bar');
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true
      }
    }
  };

  useEffect(() => {
    const tooltipEl = document.getElementById('chartjs-custom-tooltip');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mouseleaveHandler = (e:any):void => {
      if (tooltipEl) {
        if (e.target.ariaLabel === 'on-bar') {
          tooltipEl.style.display = 'block';
          tooltipEl.style.opacity = '1';
        } else if (e.target.ariaLabel === 'on-tooltip') {
          tooltipEl.style.display = 'none';
          tooltipEl.style.opacity = '0';
          tooltipEl.setAttribute('arial-label', 'outside-bar');
        }
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mouseEnterHandler = (e:any):void => {
      if (tooltipEl) {
        tooltipEl.style.display = 'block';
        tooltipEl.style.opacity = '1';
        e.target.ariaLabel = 'on-tooltip';
      }
    };
    if (tooltipEl) {
      tooltipEl.addEventListener('mouseleave', (e) => {
        mouseleaveHandler(e);
      });

      tooltipEl.addEventListener('mouseenter', (e) => {
        mouseEnterHandler(e);
      });
    }
    return () => {
      if (tooltipEl) {
        tooltipEl.removeEventListener('mouseleave', (e) => {
          mouseleaveHandler(e);
        });

        tooltipEl.removeEventListener('mouseenter', (e) => {
          mouseEnterHandler(e);
        });
      }
    };
  }, []);

  useEffect(() => {
    const transformTeamComData = (teamComnRes: TeamCommissionsData):void => {
      const transactionResData = teamComnRes.data;
      setTeamComData({
        labels: transactionResData.members[0].commissions
          .map((ele:DataPair) => dayjs(ele.date).format('DD/MM')),
        datasets: stackChartGenerator(
          transactionResData.members.sort((a:MemberObj, b:MemberObj) => (a.role > b.role ? 1 : -1)),
          stackKey,
          colorGenerator(stackColorArray, 20),
          t('lead')
        )
      });
    };
    sendRequest(
      getTeamCommissionsRequestConfig(),
      transformTeamComData
    );
  }, [t]);

  return (
    <SnapShot
      title={t('teamCommssions')}
      link={userInfo?.teamRole === 'lead' ? '/salestarget/team/member' : '/salestarget/team/target'}
      className="teamCommissionSnapshot"
      note={(teamComData?.datasets.length > teamToggleMaxMember) ?
        (
          <StyledToggleTxt onClick={() => setIsMemberExpand(!isMemberExpand)}>
            { isMemberExpand ? t('collapse') : t('expand') }
          </StyledToggleTxt>
        ) : null}
    >
      { teamComData ?
        <BarChart chartData={teamComData} options={stackBarOptions} /> : (
          <StyledLoadingContainer>
            <Spinner />
          </StyledLoadingContainer>
        )}
    </SnapShot>

  );
};

export default TeamCommissions;
