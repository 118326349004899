import cx from 'classnames';
import { strLen } from 'utils/common';
import {
  StyledTable, StyledTableWrap, StyledTHead, StyledTBody, StyledTdContent
} from './style';

interface column {
  title: string;
  key: string;
}

interface unitDataSource {
   key:string;
  [key:string]: string | undefined;
}

interface TableProps {
  className?: string;
  columns: column[];
  showTooltip?:string[];
  dataSource?: unitDataSource[]
}

const Table: React.FC<TableProps> = (props) => {
  const {
    columns, className, dataSource, showTooltip, ...other
  } = props;
  return (
    <StyledTable
      className={cx('table', className)}
      {...other}
    >
      <StyledTableWrap>
        <StyledTHead>
          <tr>
            {columns.map((columnObj:column) => (
              <th key={columnObj.key}>{columnObj.title}</th>
            ))}
          </tr>
        </StyledTHead>

        <StyledTBody>
          {
            dataSource?.map((dataObj:unitDataSource) => (
              <tr key={dataObj.key} className={dataObj?.rowClassName}>
                {columns.map((columnObj:column) => {
                  const text = dataObj[columnObj?.key];
                  const isShow = !!(text && (strLen(text) > 25) &&
                   showTooltip?.includes(columnObj.key));
                  return (
                    <StyledTdContent
                      className={`${columnObj.key}-tdContent`}
                      key={dataObj[columnObj.key]}
                      toolTipTxt={text}
                      isShowToolTip={isShow}
                      data-content={text}
                    >
                      <span>
                        {dataObj[columnObj.key]}
                      </span>
                    </StyledTdContent>
                  );
                })}
              </tr>
            ))

          }
        </StyledTBody>
      </StyledTableWrap>
    </StyledTable>
  );
};

export default Table;
