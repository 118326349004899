import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconComponent from 'components/Icons';
import useClickOutside from 'hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import { Divider } from '@mui/material';
import { ResponseData, getTeamsRequestConfig } from 'api/getV2RequestConfig';
import useHttp from 'hooks/useHttp';
import { QueryDataType } from '..';

type DropdownProps = {
  queryData: QueryDataType;
  onChange: (teams: string) => void;
};

type TeamsResponse = ResponseData & {
  data: {
    teams: string[]
  }
};

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownLabel = styled.label`
  margin-right: 8px;
`;

const DropdownSelectWrapper = styled.div`
  position: relative;
`;

const DropdownSelect = styled.div`
  box-sizing: content-box;
  display: flex;
  width: 340px;
  height: 24px;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DDD;

  color: #BBB;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  cursor: pointer;

  span {
    color: #1c1c1c;
  }
`;

const DropdownSelectTextWrap = styled.div`
  flex: 1;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DropdownOptionWrap = styled.div`
  position: absolute;
  z-index: 1;
  top: 40px;
  left: -8px;
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 16px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12), 0px 0px 7px 0px rgba(0, 0, 0, 0.20);
`;

const DropdownOption = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: #3E3E3E;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  & + & {
    margin-top: 16px;
  }
  cursor: pointer;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

const Checkbox = (props: { checked: boolean, indeterminate?: boolean }): JSX.Element => {
  const { checked, indeterminate } = props;
  if (indeterminate) {
    return <IconComponent name="CheckboxIndeterminate" />;
  }

  if (checked) {
    return <IconComponent name="CheckboxChecked" />;
  }

  return <IconComponent name="CheckboxUnChecked" />;
};

const TeamDropdown = ({ queryData, onChange }: DropdownProps): React.ReactElement => {
  const { t } = useTranslation('salesRevenue', {
    i18n: logixPanelInstance
  });
  const ref = React.useRef<HTMLDivElement>(null);
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [teamList, updateTeamList] = React.useState<string[]>([]);

  const handleSelect = (newTeamName: string): void => {
    setSelectedTeams((prevSelectedTeams) => (
      prevSelectedTeams.includes(newTeamName) ?
        prevSelectedTeams.filter((teamName) => teamName !== newTeamName) :
        [...prevSelectedTeams, newTeamName]));
  };

  const onSelectAll = (): void => {
    setSelectedTeams((prevTeams) => {
      if (prevTeams.length === teamList.length) {
        return [];
      }
      return teamList;
    });
  };

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    onChange(selectedTeams.join(','));
  }, [selectedTeams]);

  const { sendRequest } = useHttp();

  const fetchTeam = (): void => {
    sendRequest(
      getTeamsRequestConfig(queryData.period),
      (res: TeamsResponse) => {
        updateTeamList(res.data.teams);
      }
    );
  };

  const queryRef = React.useRef<string | null>(null);

  useEffect(() => {
    queryRef.current = queryData.period;
    fetchTeam();
  }, []);

  useEffect(() => {
    if (isOpen && queryData.period !== queryRef.current) {
      fetchTeam();
      queryRef.current = queryData.period;
    }
  }, [queryData, isOpen]);

  return (
    <DropdownWrapper>
      <DropdownLabel htmlFor="team-select">
        {t('teams')}
        :
      </DropdownLabel>
      <DropdownSelectWrapper>
        <DropdownSelect
          id="team-select"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <DropdownSelectTextWrap>
            {selectedTeams.length ? (
              <span>
                {selectedTeams.filter((teamName) => teamList.includes(teamName))
                  .join(', ') }
              </span>
            ) :
              'Please select' }
          </DropdownSelectTextWrap>
          <IconComponent width="16" height="16" name="ArrowDown" />
        </DropdownSelect>
        {isOpen && (
          <DropdownOptionWrap ref={ref}>
            <DropdownOption
              key="select-all"
              onClick={(e) => {
                e.stopPropagation();
                onSelectAll();
              }}
            >
              <Checkbox
                indeterminate={!!selectedTeams.length && selectedTeams.length !== teamList.length}
                checked={selectedTeams.length === teamList.length}
              />
              Select All
            </DropdownOption>
            <StyledDivider />
            {teamList.map((team) => (
              <DropdownOption
                key={team}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(team);
                }}
              >
                <Checkbox checked={selectedTeams.includes(team)} />
                {team}
              </DropdownOption>
            ))}
          </DropdownOptionWrap>
        )}
      </DropdownSelectWrapper>
    </DropdownWrapper>
  );
};

export default TeamDropdown;
