import styled, { css } from 'styled-components';
import DropdownSelect from 'components/DropdownSelect';

export const StyledFilterAndSearchContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.N500};
    border-radius: 8px;
    background-color: ${theme.palette.N700};
    padding: ${theme.spacing.s6} ${theme.spacing.s4};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.s6};
  `}
  @media (max-width: 1800px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const baseButton = css`
  ${({ theme }) => `
    ${theme.typography.Body2};
    border-radius: 4px;
    padding: ${theme.spacing.s1} ${theme.spacing.s4};
    cursor: pointer;
  `};
`;
export const StyledButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background-color: ${theme.palette.P400};
    color: ${theme.palette.N700};
    ${baseButton};
    height: 28px;
  `}
`;
export const ExportButton = styled.button`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.P400};
    background: none;
    color: ${theme.palette.P400};
    ${baseButton};
    display: flex;
    align-items: center;
    gap: 4px;
    svg path {
      fill: ${theme.palette.P400};
    }
  `}
`;

export const StyledOutlineDropdownSelect = styled(DropdownSelect)`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.N500};
    border-radius: 8px;
  `}
`;
