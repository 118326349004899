import styled from 'styled-components';
import { commonSnapShotWrap } from 'styles/common';

export const StyledBarChart = styled.div`
  position: relative;
  .paper{
      ${commonSnapShotWrap}
  }

`;
