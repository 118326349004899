import styled, { css } from 'styled-components';

export const StyledDateRangePicker = styled.div`
  ${({ theme }) => css`
    .react-datepicker__input-container input {
      border: 1px solid ${theme.palette.N500};
      border-radius: 8px;
      padding: ${theme.spacing.s1} ${theme.spacing.s3};
      width: 100%;
      min-width: 220px;
      color: ${theme.palette.N100};
      ${theme.typography.Body2};

      &::placeholder {
        color: ${theme.palette.N400};
      }
    }

    .react-datepicker {
      border: none;
      box-shadow: rgb(223 223 223 / 80%) 0px 0px 20px;
    }

    .react-datepicker__header {
      background-color: rgba(0, 0, 0, 0.04);
      border-bottom: none;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker-popper {
      padding-top: 0;
      z-index: 2;
    }

    .react-datepicker__day {
      border-radius: 100%;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background-color: ${`${theme.palette.P400}1A`};
      color: ${theme.palette.N100};
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end {
      background-color: ${theme.palette.P400};
      color: ${theme.palette.N700};
    }

    .react-datepicker__navigation-icon::before {
      border-width: 2px 2px 0 0;
      width: 6px;
      height: 6px;
      top: 12px;
    }

    .react-datepicker__close-icon::after {
      width: 12px;
      height: 12px;
      background-color: ${theme.palette.N600};
      color: ${theme.palette.N400};
    }
  `}
`;
