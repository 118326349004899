import styled from 'styled-components';

export const StyledPageSubTitle = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const StyledSection = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 36px;
  color: var(--neutral-n-200, #3E3E3E);
`;
