import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';

export enum Type {
  Income = 'income',
  Expense = 'expense',
  Commission = 'commission'
}

export enum Role {
  Wallet = 'wallet',
  Copier = 'copier',
}

export enum Status {
  Pending,
  Approved,
  Rejected,
  AutoApproved
}

export type FilterDataType = {
  role: Type | 'all';
  status: Status | 'all';
  dateRange: { start: Date | null; end: Date | null };
};

type FilterOptions<V> = Array<{
  value: V;
  name: string;
}>
type RoleFilterOptions = FilterOptions<Role | 'all'>
type StatusFilterOptions = FilterOptions<Status | 'all'>

interface TranslationUtils {
  roleFilterOptions: RoleFilterOptions;
  statusFilterOptions: StatusFilterOptions;
}

const useTranslationUtils = (): TranslationUtils => {
  const { t } = useTranslation('tradingCupSubscription', {
    i18n: logixPanelInstance
  });
  const { t: accountT } = useTranslation('tradingCupAccounts');
  const roleFilterOptions: {
    value: Role | 'all';
    name: string;
  }[] = [
    {
      value: 'all',
      name: accountT('all')
    },
    {
      value: Role.Copier,
      name: accountT('copier')
    },
    {
      value: Role.Wallet,
      name: 'Wallet'
    }
  ];

  const statusFilterOptions: StatusFilterOptions = [
    {
      value: Status.Pending,
      name: t('pending')
    },
    {
      value: Status.Approved,
      name: t('approved')
    },
    {
      value: Status.Rejected,
      name: t('rejected')
    },
    {
      value: Status.AutoApproved,
      name: t('autoApproved')
    }
  ];

  return {
    roleFilterOptions,
    statusFilterOptions
  };
};

export default useTranslationUtils;
