/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import palette from 'styles/palette';
import IconComponent from 'components/Icons';
import {
  StyledCellDropdownSelect,
  StyledExternalLink,
  StyledTableCell,
  StyledTableRow
} from 'components/DataTable/style';
import { AccountRowData, Status } from '../../hooks/useMAMApplicationsData';
import {
  StyledCreateAccountButton,
  StyledDeleteButton,
  StyledDocumentField,
  StyledDownloadLink,
  StyledStatusCircle,
  StyledUploadButton
} from './style';

const setUpFeeOptions = [
  {
    value: 0,
    name: '$0'
  },
  {
    value: 100,
    name: '$100'
  }
];

export type TableRowProps = {
  rowData: AccountRowData;
  onSetUpFeeChange: (value: AccountRowData) => void;
  onStatusChange: (value: AccountRowData) => void;
  onFileDelete: (value: AccountRowData) => void;
  onFileUpload: (id: AccountRowData['id'], file: File) => void;
  onClick: () => void;
};

const TableRow = ({
  rowData,
  onSetUpFeeChange,
  onStatusChange,
  onFileDelete,
  onFileUpload,
  onClick
}: TableRowProps): JSX.Element => {
  const { t } = useTranslation('mamAccounts', {
    i18n: logixPanelInstance
  });

  const statusOptions = [
    {
      value: Status.Active,
      name: t('active')
    },
    {
      value: Status.Approve,
      name: t('approve')
    },
    {
      value: Status.Pending,
      name: t('pending')
    },
    {
      value: Status.Reject,
      name: t('reject')
    }
  ];

  return (
    <StyledTableRow>
      <StyledTableCell $width="49px" $stickyLeft="0px" aria-label="id column">
        {rowData.id}
      </StyledTableCell>
      <StyledTableCell
        $stickyLeft="49px"
        $boxShadowPosition="right"
        align="left"
        aria-label="name column"
      >
        <StyledExternalLink
          href={rowData.traderUrl}
          target="_blank"
          rel="noreferrer"
          underline={false}
        >
          {rowData.name}
        </StyledExternalLink>
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="application date column">
        {rowData.applicationDate}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="email column">
        {rowData.email}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="agent column">
        {rowData.agentUrl ? (
          <StyledExternalLink
            href={rowData.agentUrl}
            target="_blank"
            rel="noreferrer"
          >
            {rowData.agentId}
          </StyledExternalLink>
        ) : (
          rowData.agentId
        )}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="account type column">
        {rowData.accountType}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="allocation column">
        {rowData.allocation}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="platform column">
        {rowData.platform}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="currency column">
        {rowData.currency}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="leverage column">
        {rowData.leverage}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="set up fee column">
        <StyledCellDropdownSelect
          selectedValue={rowData.setUpFee}
          options={setUpFeeOptions}
          onSelect={(value) => {
            if (value !== rowData.setUpFee) {
              onSetUpFeeChange({ ...rowData, setUpFee: value });
            }
          }}
          isDisabled={
            rowData.status === Status.Active ||
            rowData.status === Status.Approve
          }
        />
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="management fee column">
        {rowData.managementFee}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="performance fee column">
        {rowData.performanceFee}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="updated date column">
        {rowData.updatedDate}
      </StyledTableCell>
      <StyledTableCell
        $width="135px"
        $stickyRight={`${163 + 137}px`}
        $boxShadowPosition="left"
        align="left"
        aria-label="status column"
      >
        <StyledCellDropdownSelect
          selectedValue={rowData.status}
          options={statusOptions}
          onSelect={(value) => {
            if (value !== rowData.status && value !== Status.Active) {
              onStatusChange({ ...rowData, status: value });
            }
          }}
          prefix={(
            <StyledStatusCircle
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              status={rowData.status}
            >
              <circle cx="50" cy="50" r="50" fill="currentColor" />
            </StyledStatusCircle>
          )}
        />
      </StyledTableCell>
      <StyledTableCell
        $width="137px"
        $stickyRight="163px"
        align="left"
        style={{ color: palette.N400 }}
        aria-label="create account column"
      >
        {rowData.status === Status.Active && (rowData.mt_id ?
          <StyledDownloadLink target="_blank" href={rowData.mt_url || '#'}>{rowData.mt_id}</StyledDownloadLink> :
          t('create'))}
        {rowData.status === Status.Approve && (
          <StyledCreateAccountButton onClick={onClick}>
            {t('create')}
          </StyledCreateAccountButton>
        )}
        {(rowData.status === Status.Reject ||
          rowData.status === Status.Pending) &&
          '-'}
      </StyledTableCell>
      <StyledTableCell
        $width="163px"
        $stickyRight="0px"
        align="left"
        aria-label="upload license column"
      >
        {rowData.licenseUrl ? (
          <StyledDocumentField>
            <StyledDownloadLink href={rowData.licenseUrl} download>
              {rowData.licenseFileName}
            </StyledDownloadLink>
            <StyledDeleteButton
              onClick={() => onFileDelete({ ...rowData })}
              aria-label="delete license"
            >
              <IconComponent name="Delete" />
            </StyledDeleteButton>
          </StyledDocumentField>
        ) : (
          <StyledUploadButton aria-label="upload license">
            <label htmlFor={`license-${rowData.id}`}>
              <IconComponent name="CirclePlus" />
              <input
                type="file"
                name={`license-${rowData.id}`}
                id={`license-${rowData.id}`}
                hidden
                onChange={(event) => {
                  if (event.target.files) {
                    onFileUpload(rowData.id, event.target.files?.[0]);
                  }
                }}
                aria-label="input license file"
              />
            </label>
          </StyledUploadButton>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TableRow;
