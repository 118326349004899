import * as React from 'react';
import cx from 'classnames';
import { CommonProps } from 'type/component';
import { StyledAvatar, StyledNameIcon } from './style';

export interface AvatarProps extends CommonProps {
  onClick?: () => void
  size?: number
  shape?: 'circle' | 'square'
  color?: string
  src?: string
  alt?: string
  title?: string
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    size = 32, shape, src, title, className, ...other
  } = props;
  return (
    <StyledAvatar
      className={cx('Avatar', 'lp-avatar', shape, className)}
      size={size}
      src={src || ''}
      {...other}
    >
      {!src && title && (
      // if no src show 2 characters of title
      <StyledNameIcon size={size}>{title.substring(0, 2)}</StyledNameIcon>
      )}
    </StyledAvatar>
  );
};

Avatar.defaultProps = {
  size: 48,
  color: 'darkgray'
  // alt: '載入失效',
};

export default Avatar;
