import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { langObj } from 'i18n/lang';
import logixPanelInstance from 'i18n';
import { usePersistStore } from 'store';
import DropDown from 'components/DropDown';
import IconComponent from 'components/Icons';
import useHttp from 'hooks/useHttp';
import { getLogoRequestConfig } from 'api/getV2RequestConfig';
import {
  StyledHeaderWrap, StyledSettingWrap, StyledLangIcon, StyledItemWrap
} from './style';

interface tasksObjType {
  data:{ logo:string }
}

const Header = ():JSX.Element => {
  const { i18n } = useTranslation('common', { i18n: logixPanelInstance });
  const { changeLang, lang } = usePersistStore();
  const [logoPath, setLogoPath] = useState('');
  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    const transformTasks = (tasksObj:tasksObjType): void => {
      if (tasksObj.data) {
        setLogoPath(tasksObj.data.logo);
      }
    };
    sendRequest(getLogoRequestConfig(), transformTasks);
  }, [sendRequest]);

  const changeLangHandler = (newLang: string):void => {
    i18n.changeLanguage(newLang);
    changeLang(newLang);
  };

  // console.log('logixPanelInstance', logixPanelInstance);

  const customLangMenu = [
    <StyledItemWrap
      tabIndex={0}
      role="button"
      aria-label="select English"
      onClick={() => changeLangHandler('en')}
    >
      <StyledLangIcon>
        {lang === 'en' && <IconComponent name="Check" />}
      </StyledLangIcon>
      <span>
        English
      </span>
    </StyledItemWrap>,
    <StyledItemWrap
      tabIndex={0}
      role="button"
      aria-label="select Chinese"
      onClick={() => changeLangHandler('zh')}
    >
      <StyledLangIcon>
        {lang === 'zh' && <IconComponent name="Check" />}
      </StyledLangIcon>
      <span>
        简体中文
      </span>
    </StyledItemWrap>
  ];

  return (
    <StyledHeaderWrap>
      <div>
        {!isLoading && <img style={{ width: 87, height: 28 }} src={logoPath} alt="icon" />}
      </div>

      <StyledSettingWrap>
        <DropDown customMenu={customLangMenu} aria-label="language dropdown">
          <span>
            <IconComponent name="Lang" />
          </span>
          <span>{langObj[lang]}</span>
          <span>
            <IconComponent name="TriangleDown" />
          </span>
        </DropDown>
      </StyledSettingWrap>
    </StyledHeaderWrap>
  );
};

export default Header;
