import { useTranslation } from 'react-i18next';
import { StyledTableCell, StyledTableRow } from 'components/DataTable/style';
import logixPanelInstance from 'i18n';

const TableHeadRow = (): JSX.Element => {
  const { t } = useTranslation('mamAccounts', {
    i18n: logixPanelInstance
  });

  return (
    <StyledTableRow aria-label="table head row">
      <StyledTableCell $width="49px" $stickyLeft="0px">
        #
      </StyledTableCell>
      <StyledTableCell
        $stickyLeft="49px"
        $boxShadowPosition="right"
        align="left"
      >
        {t('name')}
      </StyledTableCell>
      <StyledTableCell align="left">{t('applicationDate')}</StyledTableCell>
      <StyledTableCell align="left">{t('emailAddress')}</StyledTableCell>
      <StyledTableCell align="left">{t('agentId')}</StyledTableCell>
      <StyledTableCell align="left">{t('accountType')}</StyledTableCell>
      <StyledTableCell align="left">{t('allocation')}</StyledTableCell>
      <StyledTableCell align="left">{t('platform')}</StyledTableCell>
      <StyledTableCell align="left">{t('currency')}</StyledTableCell>
      <StyledTableCell align="left">{t('leverage')}</StyledTableCell>
      <StyledTableCell align="left">{t('setUpFee')}</StyledTableCell>
      <StyledTableCell align="left">{t('managementFee')}</StyledTableCell>
      <StyledTableCell align="left">{t('performanceFee')}</StyledTableCell>
      <StyledTableCell align="left">{t('updatedDate')}</StyledTableCell>
      <StyledTableCell
        $width="135px"
        $stickyRight={`${163 + 137}px`}
        $boxShadowPosition="left"
        align="left"
      >
        {t('status')}
      </StyledTableCell>
      <StyledTableCell $width="137px" $stickyRight="163px" align="left">
        {t('createAccount')}
      </StyledTableCell>
      <StyledTableCell $width="163px" $stickyRight="0px" align="left">
        {t('uploadedDocument')}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TableHeadRow;
