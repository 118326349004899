import {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import useHttp from 'hooks/useHttp';
import { getMAMApplicationsRequestConfig } from 'api/getV2RequestConfig';

export enum Status {
  Reject = -1,
  Pending = 0,
  Approve = 1,
  Active = 2,
}

export type AccountRowData = {
  id: number;
  name: string;
  traderUrl: string;
  applicationDate: string;
  email: string;
  agentId: string | null;
  agentUrl: string | null;
  accountType: string;
  allocation: string;
  platform: string;
  currency: string;
  leverage: string;
  setUpFee: number;
  managementFee: string;
  performanceFee: string;
  updatedDate: string;
  status: Status;
  licenseFileName: string | null;
  licenseUrl: string | null;
  mt_id: number | null;
  mt_url: string | null;
};

const useMAMApplicationsData = (
  currentPage: number,
  rowsPerPage: number,
  currentStatus: Status | null,
  searchInput: string,
  lang: string
): {
  rows: AccountRowData[];
  setRows: Dispatch<SetStateAction<AccountRowData[]>>;
  totalPageCount: number;
} => {
  const { sendRequest } = useHttp();

  const [rows, setRows] = useState<AccountRowData[]>([]);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);

  useEffect(() => {
    sendRequest(
      getMAMApplicationsRequestConfig(
        currentStatus,
        currentPage,
        rowsPerPage,
        searchInput,
        lang
      ),
      (responseData: {
        data: {
          Name: string;
          account_type: string;
          agent_id: string | null;
          agent_url?: string;
          allocation_name: string;
          application_date: string;
          currency: string;
          email: string;
          id: number;
          leverage: string;
          license_filename: string | null;
          license_url: string | null;
          management_fee: string;
          performance_fee: string;
          platform: string;
          setup_fee: string;
          status: number;
          trader_id: number;
          trader_url: string;
          updated_date: string;
          mt_id: number | null;
          mt_url: string | null;
        }[];
        paginate: {
          baseURL: string;
          currentPage: number;
          itemPerPage: number;
          lastPage: number;
          nextPageURL: string | null;
          prevPageURL: string | null;
          totalRecord: number;
        };
      }) => {
        // console.log(responseData);
        const accountRowsData: AccountRowData[] = responseData.data.map(
          (item) => ({
            id: item.id,
            name: item.Name,
            traderUrl: item.trader_url,
            applicationDate: item.application_date,
            email: item.email,
            agentId: item.agent_id,
            agentUrl: item?.agent_url || null,
            accountType: item.account_type,
            allocation: item.allocation_name,
            platform: item.platform,
            currency: item.currency,
            leverage: item.leverage,
            setUpFee: Number(item.setup_fee),
            managementFee: item.management_fee,
            performanceFee: item.performance_fee,
            updatedDate: item.updated_date,
            status: item.status,
            licenseFileName: item.license_filename,
            licenseUrl: item.license_url,
            mt_id: item.mt_id,
            mt_url: item.mt_url
          })
        );
        setRows(accountRowsData);
        setTotalPageCount(responseData.paginate.lastPage);
      }
    );
  }, [sendRequest, currentPage, rowsPerPage, currentStatus, searchInput, lang]);

  return { rows, setRows, totalPageCount };
};

export default useMAMApplicationsData;
