import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export type AlertToastProps = {
  isOpen: boolean;
  handleClose: () => void;
  severity: AlertColor;
  content: string;
};

const AlertToast = ({
  isOpen,
  handleClose,
  severity,
  content
}: AlertToastProps): JSX.Element => (
  <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={severity}>
      {content}
    </Alert>
  </Snackbar>
);

export default AlertToast;
