import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import useHttp from 'hooks/useHttp';
import {
  getBonusSettingsRequestConfig,
  postExchangeRateRequestConfig
} from 'api/getV2RequestConfig';
import AlertToast, { AlertToastProps } from 'components/AlertToast';
import TooltipIcon from '../../../../assets/icons/Tooltip.svg';
import {
  StyledCreditContainer,
  StyledPageSubTitle,
  StyledCreditTooltip,
  StyledButton,
  StyledButtonContainer,
  StyledCancelButton,
  StyledSection,
  StyledTable,
  StyledTableHeader,
  StyledTableHeaderRow,
  StyledTableRow,
  StyledTableHeaderCell,
  StyledTableBody,
  StyledTableBodyCell
} from './style';

interface ExchangeRates {
  [currency: string]: string;
}

type ExchangeRatesResponse = {
  status_code: number;
  message: string;
  data: {
    bonus_usd_exchange_rates: ExchangeRates;
  };
  errors: null | string[];
};

const CurrencyExchangeTable = (): JSX.Element => {
  const { t, i18n } = useTranslation('bonusSettings', {
    i18n: logixPanelInstance
  });

  const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedRates, setEditedRates] = useState<ExchangeRates>({
    ...exchangeRates
  });
  const [focusedInputIndex, setFocusedInputIndex] = useState<string>('');

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsEditing(true);
  };
  const { sendRequest } = useHttp();
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertToastProps['severity'];
    content: string;
  }>({
    type: 'success',
    content: ''
  });
  const [isResponseMessageToastOpen, setIsResponseMessageToastOpen] =
    useState<boolean>(false);

  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsEditing(false);
    setEditedRates(exchangeRates);
  };

  const handleInputChange = (currency: string, value: string): void => {
    const formattedValue = value.replace(/^0+(?=\d+\.\d+)/, '');
    if (/^-?\d*(\.\d{0,2})?$/.test(value)) {
      setEditedRates((prevRates) => ({
        ...prevRates,
        [currency]: formattedValue
      }));
    }
  };

  const handleInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    currency: string
  ): void => {
    if ((e.key === '.' || e.key === '-') && editedRates[currency] === '') {
      e.preventDefault();
    }
  };
  const handleInputFocus = (index: number): void => {
    setFocusedInputIndex(index.toString());
  };

  const fetchData = (): void => {
    sendRequest(
      getBonusSettingsRequestConfig(),
      (responseData: ExchangeRatesResponse) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setIsResponseMessageToastOpen(true);
        const bonusRates = responseData.data.bonus_usd_exchange_rates || {};
        setExchangeRates(bonusRates);
        setEditedRates(bonusRates);
      },
      (errorMessage) => {
        setResponseMessage({ type: 'error', content: errorMessage });
        setIsResponseMessageToastOpen(true);
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const isAnyRateEmpty = ():boolean => {
    for (const rate in editedRates) {
      if (editedRates[rate] === '') {
        return true;
      }
    } return false;
  };

  const handleSaveClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const requestData = { bonus_usd_exchange_rates: editedRates };
    const isRatesChanged =
      JSON.stringify(exchangeRates) !== JSON.stringify(editedRates);
    setExchangeRates({ ...editedRates });
    setIsEditing(false);
    if (isRatesChanged) {
      sendRequest(
        postExchangeRateRequestConfig(i18n.language, requestData),
        (responseData: ExchangeRatesResponse) => {
          setResponseMessage({
            type: 'success',
            content: responseData.message
          });
          setIsResponseMessageToastOpen(true);
          setExchangeRates(responseData.data.bonus_usd_exchange_rates);
        },
        (errorMessage) => {
          setResponseMessage({ type: 'error', content: errorMessage });
          setIsResponseMessageToastOpen(true);
        }
      );
    }
  };

  const renderTableRows = (): JSX.Element[] => Object.keys(exchangeRates).map((currency, index) => (
    <StyledTableRow key={currency}>
      <StyledTableBodyCell>{currency}</StyledTableBodyCell>
      <StyledTableBodyCell
        isEditing={isEditing}
        isFocused={parseFloat(focusedInputIndex) === index}
      >
        {isEditing ? (
          <input
            type="text"
            value={editedRates[currency]}
            onChange={(e) => handleInputChange(currency, e.target.value)}
            onFocus={() => handleInputFocus(index)}
            onKeyDown={(e) => handleInputKeyDown(e, currency)}
          />
        ) : (
          exchangeRates[currency]
        )}
      </StyledTableBodyCell>
    </StyledTableRow>
  ));

  return (
    <div>
      <StyledCreditContainer>
        <StyledCreditTooltip>
          <StyledPageSubTitle>{t('creditBalanceTransfer')}</StyledPageSubTitle>
          <Tooltip title={t('tooltip')} arrow>
            <img src={TooltipIcon} alt="icon" />
          </Tooltip>
        </StyledCreditTooltip>
        <StyledButtonContainer>
          {isEditing && (
            <StyledCancelButton onClick={handleCancel}>
              {t('cancel')}
            </StyledCancelButton>
          )}
          {isEditing ? (
            <StyledButton disabled={isAnyRateEmpty()} onClick={handleSaveClick}>{t('save')}</StyledButton>
          ) : (
            <StyledButton onClick={handleEditClick}>{t('edit')}</StyledButton>
          )}
        </StyledButtonContainer>
      </StyledCreditContainer>
      <StyledSection>{t('calculation')}</StyledSection>
      <StyledTable>
        <StyledTableHeader>
          <StyledTableHeaderRow>
            <StyledTableHeaderCell>{t('baseCurrency')}</StyledTableHeaderCell>
            <StyledTableHeaderCell>{t('exchangeRate')}</StyledTableHeaderCell>
          </StyledTableHeaderRow>
        </StyledTableHeader>
        <StyledTableBody>{renderTableRows()}</StyledTableBody>
      </StyledTable>
      <AlertToast
        isOpen={isResponseMessageToastOpen}
        handleClose={() => setIsResponseMessageToastOpen(false)}
        severity={responseMessage.type}
        content={responseMessage.content}
      />
    </div>
  );
};

export default CurrencyExchangeTable;
