import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import { useAuth } from 'store/context/hooks';
import Avatar from 'components/Avatar';
import useV1Http from 'hooks/useV1Http';
import useHttp from 'hooks/useHttp';
import { handleLogoutRedirect } from 'utils/common';
import { getV2LogoutRequestConfig } from 'api/getV2RequestConfig';
import { getV1LogoutRequestConfig } from 'api/getV1RequestConfig';
import IconComponent from 'components/Icons';
import { useStore } from 'store';
import {
  StyledButton,
  StyledIconWrapper,
  StyledMenu,
  StyledMenuItem
} from './style';

const UserMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('sideMenu', { i18n: logixPanelInstance });
  const { userInfo } = useAuth();

  const isSideMenuOpen = useStore((state) => state.isMenuOpen);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const [v1Logout, setV1Logout] = useState<boolean>(false);
  const [logout, setLogout] = useState<boolean>(false);

  const { sendRequest: sendV1Request, error: v1Error } = useV1Http();
  const { sendRequest, error } = useHttp();

  useEffect(() => {
    if (v1Logout && logout) {
      handleLogoutRedirect();
    }
  }, [v1Logout, logout]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (v1Error) console.error('v1Error', v1Error);
    if (error) {
      setLogout(true);
      localStorage.removeItem('jwt-token');
    }
  }, [v1Error, error]);

  const handleLogout = (): void => {
    const handleV1Out = (data: { [key: string]: string }): void => {
      if (data.success) {
        setV1Logout(true);
        const handleOut = (v2Data: { [key: string]: string }): void => {
          if (v2Data.success) {
            setLogout(true);
            localStorage.removeItem('jwt-token');
          }
        };

        sendRequest(getV2LogoutRequestConfig(), handleOut);
      }
    };

    sendV1Request(getV1LogoutRequestConfig(), handleV1Out);
  };

  return (
    <div>
      <StyledButton
        id="user-menu-button"
        aria-label="Open User Menu"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
      >
        {userInfo?.headLogo && (
          <Avatar
            src={userInfo?.headLogo}
            size={32}
          />
        )}
        {isSideMenuOpen && (
          <>
            <div>{userInfo?.name}</div>
            <StyledIconWrapper aria-label="Arrow Right Icon">
              <IconComponent name="ArrowDown" />
            </StyledIconWrapper>
          </>
        )}
      </StyledButton>
      <StyledMenu
        id="user-menu"
        aria-label="User Menu"
        aria-labelledby="user-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <StyledMenuItem
          aria-label="Settings User Menu Item"
          onClick={() => {
            handleClose();
            navigate('/user/edit');
          }}
        >
          <IconComponent name="Setting" />
          {t('settings')}
        </StyledMenuItem>
        <StyledMenuItem
          aria-label="Logout User Menu Item"
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          <IconComponent name="SignOut" />
          {t('logout')}
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default UserMenu;
