import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import useHttp from 'hooks/useHttp';
import IconComponent from 'components/Icons';
import {
  getTeamRequestConfig,
  ResponseData
} from 'api/getV2RequestConfig';
import { toCamelCaseObject } from 'utils/common';
// import EnchorWrap from './EnchorWrap';
// import Tooltip from './Tooltip';

function roundOff(value: number, decimalPlaces: number): string {
  const factor = 10 ** decimalPlaces;
  const rounded = Math.round(value * factor) / factor;
  return rounded.toFixed(decimalPlaces);
}

type TeamMemberRevenue = {
  accountManagerName: string
  tradingPl: number
  swap: number
  adminFee: number
  agentRebates: number
  rebates: number
  bonus: number
  goodWill: number
  salesRevenue: number
}

type TeamMemberRevenueResponse = {
  account_manager_id: number
  account_manager_name: string
  trading_pl: number
  swap: number
  admin_fee: number
  agent_rebates: number
  rebates: number
  bonus: number
  good_will: number
  sales_revenue: number
}

type TeamRevenueResponse = ResponseData & {
  data: {
    period: string
    revenue_data: TeamMemberRevenueResponse[]
  };
};

export type TeamRevenue = {
  teamName: string;
  tradingPl: number;
  swap: number;
  adminFee: number;
  agentRebates: number;
  rebates: number;
  bonus: number;
  goodWill: number;
  salesRevenue: number;
  children?: TeamMemberRevenue[];
}

export type TotalRevenue = {
  tradingPl: number
  swap: number
  adminFee: number
  rebates: number
  agentRebates: number
  bonus: number
  goodWill: number
  salesRevenue: number
}

type SalesRevenueTableProps = {
  data: TeamRevenue[]
  period: string
  total: TotalRevenue
}

const TableWrap = styled.div`
  display: flex;
  overflow: auto;
`;

const StyledIconComponent = styled(IconComponent)`
  cursor: pointer;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  overflow: hidden;
`;

const StyledThead = styled.thead`
  background-color: #FAFAFA;
`;

const StyledThDiv = styled.div<{ indent?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  ${(props) => props.indent && 'padding-left: 20px;'}
`;

const StyledTh = styled.th`
  position: relative;
  padding: 8px 16px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.06);

  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  &:first-child {
    padding: 0;
    border-left: 0;
    border-top: 0;
    border-top-left-radius: 8px;
    div {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 1px);
      padding: 8px 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      border-top-left-radius: 8px;
    }
  }
  &:last-child {
    padding: 0;
    border-right: 0;
    border-top: 0;
    border-top-right-radius: 8px;
    ${StyledThDiv} {
      position: relative;
      top: -1px;
      width: 100%;
      padding: 8px 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      border-top-right-radius: 8px;
    }
  }
`;

const StyledTd = styled.td<{ isMinus?: boolean }>`
  min-width: 145px;
  word-wrap: break-word;
  padding: 8px 16px;
  text-align: left;
  background-color: #fff;
  ${(props) => props.isMinus && 'color: #E92C2C;'}
  &:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    background-color: #FAFAFA;
  }
`;

const StyledTr = styled.tr`
  ${StyledTd} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    &:first-child {
      color:#00B875;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  &:last-child {
    ${StyledTd} {
      font-weight: 600;
      &:first-child {
        color: #000;
        position: relative;
        padding: 0;
        background-color: #FAFAFA;
        border-left: 0;
        border-bottom: 0;
        border-bottom-left-radius: 8px;
        div {
          position: absolute;
          top: 0px;
          left: -1px;
          height: 100%;
          width: calc(100% + 1px);
          padding: 8px 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          border-left: 1px solid rgba(0, 0, 0, 0.06);
          border-bottom-left-radius: 8px;
        }
      }
      &:last-child {
        position: relative;
        padding: 0;
        border-right: 0;
        border-bottom: 0;
        border-bottom-right-radius: 8px;
        div {
          position: absolute;
          top: 0px;
          left: -1px;
          height: 100%;
          width: calc(100% + 1px);
          padding: 8px 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          border-right: 1px solid rgba(0, 0, 0, 0.06);
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

const DealMinusTd = ({ children }: { children: string }): JSX.Element => (
  <StyledTd isMinus={Number(children) < 0}>
    {children}
  </StyledTd>
);

const titles = ['team', 'tradingP&L', 'swap', 'commission', 'adminFee', 'agentRebates', 'bonus', 'goodwill', 'salesRevenue(USD)'];

const SalesRevenueTable = (props: SalesRevenueTableProps): JSX.Element => {
  const { t } = useTranslation('salesRevenue', {
    i18n: logixPanelInstance
  });
  const { sendRequest } = useHttp();
  const { data, total, period } = props;
  const [teams, updateTeams] = useState<TeamRevenue[]>(() => data);
  const [openedList, updateOpenedList] = useState<string[]>([]);

  const onFetchChildrenTeam = (newTeamName: string): void => {
    sendRequest(
      getTeamRequestConfig({ period, teamName: encodeURIComponent(newTeamName) }),
      (responseData: TeamRevenueResponse) => {
        const { data: { revenue_data: revenueData } } = responseData;
        updateTeams((prevTeams) => {
          const teamIdx = prevTeams.findIndex(({ teamName }) => teamName === newTeamName);
          if (teamIdx === -1) return prevTeams;
          const newTeams = [...prevTeams];
          newTeams[teamIdx] = {
            ...newTeams[teamIdx],
            children: revenueData.map((item) => (
              toCamelCaseObject<TeamMemberRevenueResponse, TeamMemberRevenue>(item)
            ))
          };
          return newTeams;
        });
      }
    );
  };

  const onToggleChildTeam = (newTeamName: string): void => {
    if (openedList.includes(newTeamName)) {
      updateOpenedList((prevOpenedList) => prevOpenedList
        .filter((teamName) => teamName !== newTeamName));
      updateTeams((prevTeams) => prevTeams.map((team) => (
        team.teamName === newTeamName ? { ...team, children: undefined } : team
      )));
    } else {
      updateOpenedList((prevOpenedList) => [...prevOpenedList, newTeamName]);
      onFetchChildrenTeam(newTeamName);
    }
  };

  const renderChildren = (row: TeamMemberRevenue[]): JSX.Element => (
    <>
      {row.map((member) => (
        <StyledTr key={member.accountManagerName}>
          <StyledTd>
            <StyledThDiv indent>
              {member.accountManagerName}
            </StyledThDiv>
          </StyledTd>
          <DealMinusTd>{roundOff(member.tradingPl, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.swap, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.rebates, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.adminFee, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.agentRebates, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.bonus, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.goodWill, 2)}</DealMinusTd>
          <DealMinusTd>{roundOff(member.salesRevenue, 2)}</DealMinusTd>
        </StyledTr>
      ))}
    </>
  );

  const renderSummary = (): JSX.Element => (
    <StyledTr key="summary">
      <StyledTd>
        <StyledThDiv>
          {t('total')}
        </StyledThDiv>
      </StyledTd>
      <DealMinusTd>{roundOff(total.tradingPl, 2)}</DealMinusTd>
      <DealMinusTd>{roundOff(total.swap, 2)}</DealMinusTd>
      <DealMinusTd>{roundOff(total.rebates, 2)}</DealMinusTd>
      <DealMinusTd>{roundOff(total.adminFee, 2)}</DealMinusTd>
      <DealMinusTd>{roundOff(total.agentRebates, 2)}</DealMinusTd>
      <DealMinusTd>{roundOff(total.bonus, 2)}</DealMinusTd>
      <DealMinusTd>{roundOff(total.goodWill, 2)}</DealMinusTd>
      <StyledTd isMinus={total.salesRevenue < 0}>
        <StyledThDiv>
          {roundOff(total.salesRevenue, 2)}
        </StyledThDiv>
      </StyledTd>
    </StyledTr>
  );

  useEffect(() => {
    updateTeams(data);
    updateOpenedList([]);
  }, [data]);

  return (
    <TableWrap>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            {titles.map((title) => (
              <StyledTh key={title}>
                <StyledThDiv>
                  {t(title)}
                  {/* {title === 'salesRevenue(USD)' && (
                  <Tooltip tooltipText={t('tradingProfitsTooltip')}>
                    <EnchorWrap>
                      <IconComponent name="Info" />
                    </EnchorWrap>
                  </Tooltip>
                  )} */}
                </StyledThDiv>
              </StyledTh>
            ))}
          </StyledTr>
        </StyledThead>
        <tbody>
          {teams.map((row) => [
            <StyledTr key={row.teamName}>
              <StyledTd>
                <StyledThDiv>
                  <StyledIconComponent
                    onClick={() => onToggleChildTeam(row.teamName)}
                    name={openedList.includes(row.teamName) ? 'CircleMinus' : 'CirclePlus'}
                  />
                  {row.teamName}
                </StyledThDiv>
              </StyledTd>
              <DealMinusTd>{roundOff(row.tradingPl, 2)}</DealMinusTd>
              <DealMinusTd>{roundOff(row.swap, 2)}</DealMinusTd>
              <DealMinusTd>{roundOff(row.rebates, 2)}</DealMinusTd>
              <DealMinusTd>{roundOff(row.adminFee, 2)}</DealMinusTd>
              <DealMinusTd>{roundOff(row.agentRebates, 2)}</DealMinusTd>
              <DealMinusTd>{roundOff(row.bonus, 2)}</DealMinusTd>
              <DealMinusTd>{roundOff(row.goodWill, 2)}</DealMinusTd>
              <StyledTd isMinus={row.salesRevenue < 0}>
                <StyledThDiv>
                  {roundOff(row.salesRevenue, 2)}
                </StyledThDiv>
              </StyledTd>
            </StyledTr>,
            row.children && renderChildren(row.children)
          ])}
          {renderSummary()}
        </tbody>
      </StyledTable>
    </TableWrap>
  );
};

export default SalesRevenueTable;
