import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing.s2};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};
  color: ${({ theme }) => theme.palette.N500};
  ${({ theme }) => theme.typography.Body2};
  border-radius: 4px;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.palette.N200};
  }

  &.active {
    color: #18c083;
  }
`;
