import { SVGProps } from 'react';

const SvgPie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.73601 3.13943C8.17824 3.53825 6.75321 4.34086 5.60469 5.46628C4.45616 6.5917 3.62476 8.00012 3.19436 9.54946C2.76397 11.0988 2.7498 12.7342 3.1533 14.2908C3.55679 15.8474 4.36367 17.27 5.49253 18.4151C6.62139 19.5603 8.0323 20.3874 9.58293 20.8132C11.1335 21.2389 12.769 21.2482 14.3244 20.84C15.8797 20.4319 17.2999 19.6207 18.4417 18.4884C19.5834 17.3561 20.4063 15.9427 20.8274 14.3909"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1429 12C21.1429 9.57516 20.1796 7.24964 18.465 5.53502C16.7504 3.8204 14.4248 2.85714 12 2.85714V12H21.1429Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgPie;
