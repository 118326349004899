import styled, { css } from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledAccordion = styled(Accordion)`
  all: initial;
  margin: 0 !important;
`;

type StyledAccordionSummaryProps = {
  $isActive: boolean;
} & AccordionSummaryProps;

export const StyledAccordionSummary = styled(
  AccordionSummary
)<StyledAccordionSummaryProps>`
  ${({ theme, $isActive }) => css`
    min-height: unset !important;
    gap: ${theme.spacing.s1};
    padding: ${theme.spacing.s2};
    border-radius: 4px;
    color: ${theme.palette.N500};
    white-space: nowrap;

    &:hover {
      background-color: ${theme.palette.N200};
    }

    &.Mui-expanded {
      .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(0deg);
      }
    }

    ${$isActive &&
    `
      background-color: ${theme.palette.N75};
      color: #18C083;
    `}

    .MuiAccordionSummary-content {
      margin: 0;
      display: flex;
      align-items: center;
      gap: ${theme.spacing.s2};
      ${theme.typography.Body2};
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: inherit;
      transform: rotate(-90deg);
    }
  `}
`;

type StyledIconWrapper = {
  showNotification: boolean;
};

export const StyledIconWrapper = styled.div<StyledIconWrapper>`
  line-height: 0;
  position: relative;

  ${({ showNotification }) => showNotification &&
    `&::after {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #f74141;
        position: absolute;
        bottom: 2px;
        right: -2px;
    }`}
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  all: initial;
`;
