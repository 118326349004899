import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import zhCN from 'date-fns/locale/zh-CN';
import 'react-datepicker/dist/react-datepicker.css';
import { Locale } from 'i18n/lang';
import logixPanelInstance from 'i18n';
import { StyledDateRangePicker } from './style';

registerLocale(Locale.zhCN, zhCN);

export type DateRangePickerProps = {
  onRangeChange: ({
    startDate,
    endDate
  }: {
    startDate: Date;
    endDate: Date;
  }) => void;
};

const DateRangePicker = ({
  onRangeChange
}: DateRangePickerProps): JSX.Element => {
  const { i18n } = useTranslation('common', {
    i18n: logixPanelInstance
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (dates: any): void => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onRangeChange({ startDate: start, endDate: end });
  };

  return (
    <StyledDateRangePicker>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        placeholderText="MM/DD/YYYY – MM/DD/YYYY"
        locale={i18n.language}
        selectsRange
        disabledKeyboardNavigation
        isClearable
      />
    </StyledDateRangePicker>
  );
};

export default DateRangePicker;
