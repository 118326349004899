export const V1_API_PATH = Object.freeze({
  SIDEMENU_LIST: '/api/permissions/sidebar',
  DASHBOARD_LIST: '/api/permissions/dashboard',
  USER_LOGOUT: '/auth/api-logout',
  FETCH_PENDING: '/ajax/fetchPendings'
});

export const V2_API_PATH = Object.freeze({
  USER_PROFILE: '/api/v2/admin/profile',
  COMPANY_LOGO: '/api/v2/settings/logo',
  USER_LOGOUT: '/api/v1/admin-logout',
  DEPOSIT_FUNDS_MONTHLY: '/api/v2/deposit-funds/monthly-summary',
  DEPOSIT_FUNDS_TRANSACTION: '/api/v2/transactions',
  TRADERS: '/api/v2/traders',
  TRADERS_CLIENT_APPLICATION: '/api/v2/traders/client-applications',
  CAMPAIGNS: '/api/v2/campaigns',
  LOTTERY_RECORDS: '/api/v2/lottery-records',
  TEAM_COMMISSIONS: '/api/v2/commissions/team/weekly',
  SELF_COMMISSIONS: '/api/v2/commissions/self/weekly',
  ACCOUNTS: '/api/v2/accounts',
  WEBINARS: '/api/v2/webinars/courses',
  MAM_APPLICATIONS: '/api/v2/mamaccapplication',
  TRADING_CUP_ACCOUNTS: '/api/v2/tradingcup/accounts',
  BONUS_SETTINGS: '/api/v2/treasury/bonus-settings',
  SUBSCRIPTION: '/api/v2/tradingcup/subscriptions',
  SALES_REVENUE: '/api/v2/sales-revenue',
  SALES_REVENUE_TEAM: '/api/v2/sales-target-teams'
});
