/* eslint-disable @typescript-eslint/no-explicit-any */
import { V2_API_PATH } from 'api/apiPath';
import { Status as MAMApplicationStatus } from 'pages/MAMAccounts/hooks/useMAMApplicationsData';
import {
  Role,
  Status as TradingCupAccountStatus
} from 'pages/TradingCupAccounts/hooks/useTradingCupAccountsData';
import { Status as SubscriptionStatus } from 'pages/TradingCupSubscription/typesUtils';
import { getAPIQueryStringByOption } from 'utils/api';

export type ResponseData = {
  status: string
  message: string
}

interface requestType {
  method?: string;
  url: string;
  baseURL?: string;
  headers?: object;
  data?: object;
  responseType?: string
}
interface ExchangeRates {
  [currency: string]: string;
}

const commonObj = {
  baseURL: process.env.REACT_APP_BASE_URL
};

export const getUserProfileRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.USER_PROFILE
});

export const getLogoRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.COMPANY_LOGO
});

export const getV2LogoutRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.USER_LOGOUT,
  method: 'POST'
});

export const getDepositFundsMonthlyRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.DEPOSIT_FUNDS_MONTHLY
});

export const getDepositFundsTransactionRequestConfig = (
  scope: string,
  status: string,
  period: string
): requestType => ({
  ...commonObj,
  url: `${V2_API_PATH.DEPOSIT_FUNDS_TRANSACTION}?scope=${scope}&status=${status}&period=${period}`
});

export const getTradersRequestConfig = (
  summarize: string,
  period: string
): requestType => ({
  ...commonObj,
  url: `${V2_API_PATH.TRADERS}?summarize=${summarize}&period=${period}`
});

export const getTradersClientApplicationRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.TRADERS_CLIENT_APPLICATION
});

export const getCampaignsRequestConfig = (
  state: string,
  lang: string
): requestType => ({
  ...commonObj,
  url: `${V2_API_PATH.CAMPAIGNS}?state=${state}&lang=${lang}`
});

export const getLotteryRecordsRequestConfig = (
  status: string,
  period: string,
  summarize: string
): requestType => ({
  ...commonObj,
  url: `${V2_API_PATH.LOTTERY_RECORDS}?status=${status}&period=${period}&summarize=${summarize}`
});

export const getTeamCommissionsRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.TEAM_COMMISSIONS
});

export const getSelfCommissionsRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.SELF_COMMISSIONS
});

export const getAccountsRequestConfig = (
  summarize: string,
  period: string
): requestType => ({
  ...commonObj,
  url: `${V2_API_PATH.ACCOUNTS}?summarize=${summarize}&period=${period}`
});

export const getWebinarsRequestConfig = (
  lang = 'en',
  platform = '[AWS]client-cloudhub',
  period = 'recent',
  orderBy = 'start_time:desc'
): requestType => ({
  ...commonObj,
  // eslint-disable-next-line max-len
  url: `${V2_API_PATH.WEBINARS}?platform[]=${platform}&period=${period}&lang=${lang}&orderBy=${orderBy}`
});

export const getMAMApplicationsRequestConfig = (
  status: MAMApplicationStatus | null,
  page: number,
  perPage: number,
  searchKeyword: string,
  lang: string
): requestType => {
  const statusQueryString = status !== null ? `status=${status}&` : '';
  const searchKeywordQueryString = searchKeyword ?
    `&searchKeyword=${searchKeyword}` :
    '';

  return {
    ...commonObj,
    url: `${V2_API_PATH.MAM_APPLICATIONS}?${statusQueryString}page=${page}&perPage=${perPage}${searchKeywordQueryString}`,
    headers: {
      'Accept-Language': lang
    }
  };
};

export const getMAMformDetail = (
  selectedId: number | undefined
): requestType => ({
  ...commonObj,
  method: 'GET',
  url: `${V2_API_PATH.MAM_APPLICATIONS}/${selectedId}`
});

export const getMAMformData = (lang: string): requestType => ({
  ...commonObj,
  method: 'GET',
  url: `${V2_API_PATH.MAM_APPLICATIONS}/getAddFormList`,
  headers: {
    'Accept-Language': lang
  }
});

export const getMAMApplicationUpdateRecordRequestConfig = (
  id: number,
  body: {
    status?: MAMApplicationStatus;
    setup_fee?: number;
  },
  lang: string
): requestType => ({
  ...commonObj,
  method: 'PUT',
  url: `${V2_API_PATH.MAM_APPLICATIONS}/${id}`,
  data: body,
  headers: {
    'Accept-Language': lang
  }
});

export const getMAMApplicationUploadLicenseRequestConfig = (
  id: number,
  file: File,
  lang: string
): requestType => {
  const formData = new FormData();
  formData.set('license', file);

  return {
    ...commonObj,
    method: 'POST',
    url: `${V2_API_PATH.MAM_APPLICATIONS}/license/${id}`,
    data: formData,
    headers: {
      'Accept-Language': lang
    }
  };
};
export const postMAMformData = (
  selectedId: number | undefined,
  body: {
    mt_id?: number;
    server_flag?: number | null;
  },
  lang: string
): requestType => ({
  ...commonObj,
  method: 'POST',
  url: `${V2_API_PATH.MAM_APPLICATIONS}/${selectedId}`,
  data: body,
  headers: {
    'Accept-Language': lang
  }
});

export const getMAMApplicationDeleteLicenseRequestConfig = (
  id: number,
  lang: string
): requestType => ({
  ...commonObj,
  method: 'DELETE',
  url: `${V2_API_PATH.MAM_APPLICATIONS}/${id}`,
  headers: {
    'Accept-Language': lang
  }
});

export const getTradingCupAccountsRequestConfig = (
  role: Role | 'all',
  status: TradingCupAccountStatus | 'all',
  joinStartDate: string | null,
  joinEndDate: string | null,
  searchKeyword: string,
  page: number,
  perPage: number,
  lang: string
): requestType => {
  const searchKeywordQueryString = searchKeyword ?
    `&searchKeyword=${searchKeyword}` :
    '';
  const joinStartDateQueryString = joinStartDate ?
    `&join_start_date=${joinStartDate}` :
    '';
  const joinEndDateQueryString = joinEndDate ?
    `&join_end_date=${joinEndDate}` :
    '';

  return {
    ...commonObj,
    url: `${V2_API_PATH.TRADING_CUP_ACCOUNTS}?role=${role}&status=${status}&page=${page}&perPage=${perPage}${searchKeywordQueryString}${joinStartDateQueryString}${joinEndDateQueryString}`,
    headers: {
      'Accept-Language': lang
    }
  };
};

export type SubscriptionqueryOptions = {
  role?: Role;
  status?: SubscriptionStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  keyword?: string;
  page?: number;
  perPage?: number;
}

export const getTradingCupSubscriptionRequestConfig = (
  lang: string,
  queryOptions: SubscriptionqueryOptions
): requestType => {
  const queryString = getAPIQueryStringByOption<SubscriptionqueryOptions>(queryOptions);

  return {
    ...commonObj,
    url: `${V2_API_PATH.SUBSCRIPTION}${queryString}`,
    headers: {
      'Accept-Language': lang
    }
  };
};
export const getTradingCupSubscriptionExportRequestConfig = (
  lang: string,
  queryOptions: SubscriptionqueryOptions
): requestType => {
  const queryString = getAPIQueryStringByOption<SubscriptionqueryOptions>(queryOptions);

  return {
    ...commonObj,
    url: `${V2_API_PATH.SUBSCRIPTION}/csv${queryString}`,
    headers: {
      'Accept-Language': lang
    },
    responseType: 'blob'
  };
};

export const getBonusSettingsRequestConfig = (): requestType => ({
  ...commonObj,
  url: V2_API_PATH.BONUS_SETTINGS
});

export const postExchangeRateRequestConfig = (
  lang: string,
  body: {
    bonus_usd_exchange_rates?: ExchangeRates;
  }
): requestType => ({
  ...commonObj,
  method: 'POST',
  url: V2_API_PATH.BONUS_SETTINGS,
  data: body,
  headers: {
    'Accept-Language': lang
  }
});

export const postSubscriptionRequestConfig = (
  orderId: number,
  body: {
    status: SubscriptionStatus
  },
  lang: string
):requestType => ({
  ...commonObj,
  method: 'PATCH',
  url: `${V2_API_PATH.SUBSCRIPTION}/${orderId}/decisions`,
  data: body,
  headers: {
    'Accept-language': lang
  }

});
export const getSalesRevenueRequestConfig = ({
  period,
  teams
}: { period: string, teams: string}): requestType => {
  const queryTeams = teams ? `&teams=${encodeURIComponent(teams)}` : '';
  const queryPeriod = period ? `period=${period}` : '';
  return ({
    ...commonObj,
    url: `${V2_API_PATH.SALES_REVENUE}?${queryPeriod}${queryTeams}`
  });
};

export const getTeamsRequestConfig = (period: string): requestType => {
  const queryPeriod = period ? `period=${period}` : '';
  return {
    ...commonObj,
    url: `${V2_API_PATH.SALES_REVENUE}/teams?${queryPeriod}`
  };
};

export const getTeamRequestConfig = ({
  period,
  teamName
}: { period: string, teamName: string}): requestType => {
  const queryPeriod = period ? `period=${period}` : '';
  return ({
    ...commonObj,
    url: `${V2_API_PATH.SALES_REVENUE}/team/${teamName}?${queryPeriod}`
  });
};
