import * as React from 'react';
import { SVGProps } from 'react';

const SvgLeftDoubleArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M12.854 12.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 1 1 .708.708L8.207 8l4.647 4.646ZM3.207 8l4.647-4.646a.5.5 0 1 0-.708-.708l-5 5a.5.5 0 0 0 0 .708l5 5a.5.5 0 0 0 .708-.708L3.207 8Z"
      fill="#BBB"
    />
  </svg>
);

export default SvgLeftDoubleArrow;
