import { SVGProps } from 'react';

const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.04299 5.87147C4.27173 5.05512 5.91009 4.57043 7.68752 4.57043C9.30931 4.57043 10.8153 4.97395 12 5.66475C13.1847 4.97395 14.6907 4.57043 16.3125 4.57043C18.091 4.57043 19.7283 5.05513 20.9571 5.87147C21.1244 5.98267 21.225 6.17028 21.225 6.37123V18.8296C21.225 19.0507 21.1034 19.2539 20.9086 19.3583C20.7137 19.4628 20.4772 19.4517 20.293 19.3293C19.283 18.6583 17.8825 18.2288 16.3125 18.2288C14.7435 18.2288 13.342 18.6583 12.3321 19.3293C12.1309 19.463 11.8692 19.463 11.668 19.3293C10.6581 18.6583 9.25659 18.2288 7.68752 18.2288C6.11846 18.2288 4.71698 18.6583 3.70705 19.3293C3.52289 19.4517 3.28635 19.4628 3.09149 19.3583C2.89663 19.2539 2.77502 19.0507 2.77502 18.8296V6.37123C2.77502 6.17028 2.87562 5.98267 3.04299 5.87147ZM11.4 6.70401C10.4168 6.12957 9.12221 5.77043 7.68752 5.77043C6.25284 5.77043 4.95828 6.12957 3.97502 6.70401V17.8106C5.05056 17.3122 6.32839 17.0288 7.68752 17.0288C9.04666 17.0288 10.3245 17.3122 11.4 17.8106V6.70401ZM12.6 17.8106C13.6756 17.3122 14.9534 17.0288 16.3125 17.0288C17.6724 17.0288 18.9497 17.3122 20.025 17.8105V6.704C19.0419 6.12955 17.748 5.77043 16.3125 5.77043C14.8778 5.77043 13.5833 6.12957 12.6 6.70401V17.8106Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBook;
