import { useState, useCallback } from 'react';
import axios from 'api/axios';
import type { AxiosError } from 'axios';
import { httpType } from './types';

const useHttp = (): httpType => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendRequest = useCallback(
    async (requestConfig, applyData, errorCallback) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios({
          method: requestConfig.method ? requestConfig.method : 'GET',
          url: requestConfig.url,
          baseURL: requestConfig.baseURL ?
            requestConfig.baseURL :
            process.env.REACT_APP_API_URL,
          headers: requestConfig.headers || {},
          data: requestConfig.data || {},
          withCredentials: true,
          responseType: requestConfig.responseType || 'json'
        });
        if (response.status !== 200) {
          throw new Error('Request failed!');
        }
        applyData(response.data);
      } catch (err) {
        const axiosError = err as AxiosError;
        const errorMessage = axiosError.response?.data?.message || axiosError.message;
        setError(errorMessage);
        errorCallback(errorMessage);
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    error,
    sendRequest
  };
};

export default useHttp;
