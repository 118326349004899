import { SVGProps } from 'react';

const SvgFileTrayFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5385 4H6.46154C5.33654 4 4.60096 4.63636 4.38462 5.81818L3 12.7273V17.8182C3.0016 18.3963 3.22093 18.9503 3.61008 19.3591C3.99923 19.7679 4.52658 19.9983 5.07692 20H18.9231C19.4734 19.9983 20.0008 19.7679 20.3899 19.3591C20.7791 18.9503 20.9984 18.3963 21 17.8182V12.7273L19.6154 5.81818C19.399 4.59091 18.6202 4 17.5385 4Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <path
      d="M3 13H9.23077M14.7692 13H21M9.23077 13C9.23077 13.7956 9.52253 14.5587 10.0419 15.1213C10.5612 15.6839 11.2656 16 12 16C12.7344 16 13.4388 15.6839 13.9581 15.1213C14.4775 14.5587 14.7692 13.7956 14.7692 13M7.15385 7H16.8462M6.46154 10H17.5385"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgFileTrayFull;
