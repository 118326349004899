import * as React from "react";
import { SVGProps } from "react";

const SvgLuckyDrawApprovals = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.934 20.002v17.863c0 2.047-1.073 4.077-3.236 5.82-.627.507-1.298.96-2.004 1.354-.713.403-1.454.756-2.217 1.058-7.014 2.855-16.848 2.499-23.152-1.058-3.547-1.986-5.259-4.58-5.24-7.174V20.002h35.85Z"
      fill="#0084FF"
    />
    <path
      d="M37.934 20.002v17.863c0 2.047-1.073 4.077-3.236 5.82-.627.507-1.298.96-2.004 1.354-.713.403-1.454.756-2.217 1.058-7.014 2.855-16.848 2.499-23.152-1.058-3.547-1.986-5.259-4.58-5.24-7.174V20.002h35.85Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      d="M37.933 20.002v6.637a13.46 13.46 0 0 1-3.245 2.68l-.55.313c-1.169.654-2.398 1.2-3.67 1.63-7.706 2.699-17.94 2.152-24.607-1.63a14.135 14.135 0 0 1-3.804-2.993v-6.637h35.876Z"
      fill="#168FFF"
    />
    <path
      d="M34.688 29.32v14.365a14.19 14.19 0 0 1-2.004 1.354 17.9 17.9 0 0 1-2.217 1.058V31.263a21.868 21.868 0 0 0 3.67-1.631l.55-.313Z"
      fill="#E92C2C"
    />
    <path
      d="M34.688 25.12v4.19l-.55.313c-1.17.654-2.398 1.2-3.671 1.631v-6.133h4.22Z"
      fill="#BA2323"
    />
    <path
      d="M5.31 29.32v14.365c.628.507 1.298.96 2.005 1.354.712.402 1.453.756 2.216 1.058V31.263a21.87 21.87 0 0 1-3.67-1.631l-.55-.313Z"
      fill="#E92C2C"
    />
    <path
      d="M5.31 25.12v4.19l.55.313a21.87 21.87 0 0 0 3.671 1.631v-6.133h-4.22Z"
      fill="#BA2323"
    />
    <path
      d="M39.99 15.084v4.12c0 2.369-1.267 4.728-3.883 6.723-.618.471-1.27.897-1.95 1.276-.734.409-1.492.774-2.27 1.093-7.813 3.28-18.923 2.923-26.008-1.093-3.92-2.212-5.853-5.11-5.844-7.999v-4.12h39.956Z"
      fill="#0084FF"
    />
    <path
      d="M39.99 15.084v4.12c0 2.369-1.267 4.728-3.883 6.723-.618.471-1.27.897-1.95 1.276-.734.409-1.492.774-2.27 1.093-7.813 3.28-18.923 2.923-26.008-1.093-3.92-2.212-5.853-5.11-5.844-7.999v-4.12h39.956Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      d="M36.09 18.718v7.21c-.618.47-1.27.896-1.95 1.275a20.93 20.93 0 0 1-2.27 1.093v-9.543l4.22-.035ZM3.902 18.718v7.21a15.58 15.58 0 0 0 1.951 1.275c.732.412 1.49.777 2.27 1.093v-9.543l-4.22-.035Z"
      fill="#E92C2C"
    />
    <path
      d="M36.09 21.798c-.618.474-1.27.903-1.951 1.284-.735.409-1.493.777-2.27 1.102a33.033 33.033 0 0 1-23.738 0 21.004 21.004 0 0 1-2.27-1.102 15.6 15.6 0 0 1-1.95-1.284c-5.214-3.982-5.214-9.43 0-13.412a15.606 15.606 0 0 1 1.95-1.284c.732-.412 1.49-.777 2.27-1.093a32.942 32.942 0 0 1 23.738 0c.779.32 1.537.684 2.27 1.093.68.382 1.333.81 1.95 1.284 5.215 3.982 5.215 9.43 0 13.412Z"
      fill="#0084FF"
    />
    <path
      d="M36.09 21.798c-.618.474-1.27.903-1.951 1.284-.735.409-1.493.777-2.27 1.102a33.033 33.033 0 0 1-23.738 0 21.004 21.004 0 0 1-2.27-1.102 15.6 15.6 0 0 1-1.95-1.284c-5.214-3.982-5.214-9.43 0-13.412a15.606 15.606 0 0 1 1.95-1.284c.732-.412 1.49-.777 2.27-1.093a32.942 32.942 0 0 1 23.738 0c.779.32 1.537.684 2.27 1.093.68.382 1.333.81 1.95 1.284 5.215 3.982 5.215 9.43 0 13.412Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      d="m24.217 15.084 11.873 6.714c-.617.474-1.27.903-1.95 1.284a22.75 22.75 0 0 1-2.27 1.102l-11.874-6.715-11.864 6.715a21.004 21.004 0 0 1-2.27-1.102 15.61 15.61 0 0 1-1.95-1.284l11.864-6.714L3.902 8.386a15.607 15.607 0 0 1 1.951-1.284c.732-.412 1.49-.777 2.27-1.093l11.865 6.706 11.881-6.68a20.95 20.95 0 0 1 2.27 1.093c.682.382 1.334.81 1.951 1.284l-11.873 6.672Z"
      fill="#ED5656"
    />
    <path
      d="M24.296 24.253c-.444-2.785-4.31-9.464-4.31-9.464l2.315-.27s5.32 3.748 6.845 6.125l-4.85 3.61Z"
      fill="#BA2323"
    />
    <path
      d="M15.685 24.253c.444-2.785 4.31-9.464 4.31-9.464l-2.306-.27s-5.32 3.748-6.845 6.125l4.841 3.61Z"
      fill="#BA2323"
    />
    <path
      d="M33.802 1.333C31.23-1.148 26.06-.064 22.275 3.736c-3.787 3.8-2.253 11.339-2.253 11.339s7.741 1.388 11.528-2.403c3.786-3.791 4.823-8.875 2.252-11.339Zm-12.52 12.51s-.595-4.841 2.092-7.513c2.687-2.672 6.118-3.652 7.643-2.177 1.526 1.474.595 4.832-2.101 7.512-2.696 2.681-7.635 2.178-7.635 2.178Z"
      fill="#E92C2C"
    />
    <path
      d="M8.45 12.672c3.805 3.791 11.528 2.403 11.528 2.403s1.56-7.548-2.252-11.339C13.913-.054 8.752-1.148 6.198 1.333c-2.554 2.481-1.56 7.54 2.252 11.339Zm2.66-1.05C8.45 8.942 7.485 5.55 9.019 4.11c1.534-1.44 4.948-.503 7.643 2.178 2.696 2.68 2.049 7.556 2.049 7.556s-4.957.451-7.635-2.22h.036Z"
      fill="#E92C2C"
    />
  </svg>
);

export default SvgLuckyDrawApprovals;
