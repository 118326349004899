/* eslint-disable indent */
import styled from 'styled-components';
import { AvatarProps } from '.';

const background = (props: AvatarProps): string => (props.src ?
    `background-image: url(${props.src});
         background-repeat: no-repeat;
         background-size: contain;` :
    `background-color: ${props.color};`);

const boxLayout = (size: number): string => `display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
    color: azure;
    font-size: ${size}px;`;

export const StyledAvatar = styled.div<AvatarProps>`
  cursor: pointer;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: ${(props) => `${props.size}px`};
  ${(props) => background(props)}
  overflow: hidden;
  background-position: center center;
  background-size: cover;

  &::after {
    content: '${(props) => props.alt}';
    background-color: darkgray;
    position: relative;
    ${(props) => (props.size ? boxLayout(props.size / 3.4) : boxLayout(32 / 3.4))}
    text-align: center;
    padding: 2px;
    box-sizing: border-box;
    z-index: -1;
  }

  &.square {
    border-radius: 4px;
  }
`;

export const StyledNameIcon = styled.div`
  ${(props: { size: number }) => boxLayout(props.size / 2.3)};
  z-index: 1;
`;
