import * as React from "react";
import { SVGProps } from "react";

const SvgCampaigns = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Campaigns_svg__a)" fill="#F4F4F4">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Zm0 1A8 8 0 1 0 8-.001 8 8 0 0 0 8 16Z" />
      <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10Zm0 1A6 6 0 1 0 8 1.999 6 6 0 0 0 8 14Z" />
      <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
      <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
    </g>
    <defs>
      <clipPath id="Campaigns_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCampaigns;
