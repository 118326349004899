import * as React from 'react';
import styled from 'styled-components';

const BreadcrumbWrapper = styled.div`
  color: ${({ theme }) => theme.palette.N350};
`;

const BreadcrumbItem = styled.a<{ isActive?: boolean }>`
  margin: 0 8px;
  color: ${(props) => (props.isActive ? props.theme.palette.N200 : props.theme.palette.N350)};
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
`;

const SalesRevenueBreadcrumb = (): React.ReactElement => (
  <BreadcrumbWrapper>
    <BreadcrumbItem href="/">Home</BreadcrumbItem>
    /
    <BreadcrumbItem href="/sales-management">Sales Management</BreadcrumbItem>
    /
    <BreadcrumbItem href="/sales-revenue" isActive>Sales Revenue</BreadcrumbItem>
  </BreadcrumbWrapper>
);

export default SalesRevenueBreadcrumb;
