import { SVGProps } from 'react';

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16155 5.16152C3.64914 4.67392 4.31046 4.39999 5.00003 4.39999H19C19.6896 4.39999 20.3509 4.67392 20.8385 5.16152C21.3261 5.64911 21.6 6.31043 21.6 6.99999V7.99069C21.6001 7.99653 21.6001 8.00236 21.6 8.00819V17C21.6 17.6896 21.3261 18.3509 20.8385 18.8385C20.3509 19.3261 19.6896 19.6 19 19.6H5.00003C4.31046 19.6 3.64914 19.3261 3.16155 18.8385C2.67395 18.3509 2.40003 17.6896 2.40003 17V8.0082C2.39994 8.00236 2.39994 7.99653 2.40003 7.99069V6.99999C2.40003 6.31043 2.67395 5.64911 3.16155 5.16152ZM3.60003 9.1211V17C3.60003 17.3713 3.74753 17.7274 4.01008 17.9899C4.27263 18.2525 4.62872 18.4 5.00003 18.4H19C19.3713 18.4 19.7274 18.2525 19.99 17.9899C20.2525 17.7274 20.4 17.3713 20.4 17V9.1211L13.443 13.7591C13.443 13.7591 13.4429 13.7592 13.4428 13.7592C13.0156 14.0442 12.5136 14.1963 12 14.1963C11.4865 14.1963 10.9844 14.0442 10.5572 13.7592C10.5571 13.7592 10.5571 13.7591 10.557 13.7591L3.60003 9.1211ZM20.4 7.67888L12.7772 12.7608L12.777 12.7609C12.547 12.9144 12.2766 12.9963 12 12.9963C11.7235 12.9963 11.4531 12.9144 11.223 12.7609L11.2228 12.7608L3.60003 7.67888V6.99999C3.60003 6.62869 3.74753 6.2726 4.01008 6.01004C4.27263 5.74749 4.62872 5.59999 5.00003 5.59999H19C19.3713 5.59999 19.7274 5.74749 19.99 6.01004C20.2525 6.2726 20.4 6.62869 20.4 6.99999V7.67888Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEmail;
