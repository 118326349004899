import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Popover from '@mui/material/Popover';

interface TooltipProps {
  tooltipText: string;
  children: JSX.Element;
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div`
  text-align: left;
  width: 231px;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 4px;

  white-space: wrap;
`;

const TooltipWrapper = styled.div`
  display: flex;
`;

const Tooltip = ({
  children,
  tooltipText
}: TooltipProps): JSX.Element => {
  const anchorElementRef = useRef<HTMLElement>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const el = anchorElementRef.current;
    if (!el) {
      return;
    }
    const open = (): void => {
      setTimeout(() => {
        setOpen(el.matches(':hover'));
      }, 100);
    };
    const close = (): void => {
      setOpen(false);
    };
    el.addEventListener('mouseenter', open);
    el.addEventListener('mouseleave', close);
    // eslint-disable-next-line consistent-return
    return () => {
      el.removeEventListener('mouseenter', open);
      el.removeEventListener('mouseleave', close);
    };
  }, [anchorElementRef]);

  return (
    <TooltipContainer>
      <TooltipWrapper>
        {React.cloneElement(children, { ref: anchorElementRef })}
        <Popover
          sx={{
            pointerEvents: 'none'
          }}
          open={isOpen}
          anchorEl={anchorElementRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <TooltipText>{tooltipText}</TooltipText>
        </Popover>
      </TooltipWrapper>
    </TooltipContainer>
  );
};

export default Tooltip;
