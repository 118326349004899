import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconComponent from 'components/Icons';
import logixPanelInstance from 'i18n';
import { StyledButton } from 'components/FilterAndSearchBox/style';
import { StyledSearchBar, StyledSearchInput } from './style';

type SearchBarProps = {
  placeholder: string;
  onSearch: (value: string) => void;
};

const SearchBar = ({ placeholder, onSearch }: SearchBarProps): JSX.Element => {
  const { t } = useTranslation('common', {
    i18n: logixPanelInstance
  });
  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <StyledSearchBar>
      <StyledSearchInput>
        <IconComponent name="Search" />
        <input ref={searchInputRef} type="text" placeholder={placeholder} />
      </StyledSearchInput>
      <StyledButton
        onClick={() => onSearch(searchInputRef.current?.value || '')}
      >
        {t('search')}
      </StyledButton>
    </StyledSearchBar>
  );
};

export default SearchBar;
