import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as materialCreateTheme,
  THEME_ID
} from '@mui/material/styles';
import SideMenu from 'components/SideMenu';
import { headerHeight } from 'components/Header/style';
import OldPagesIframe from 'pages/OldPageShower';
import Header from 'components/Header';
import Admin from 'pages/Admin';
import Dashboard from 'pages/Dashboard';
import MAMAccounts from 'pages/MAMAccounts';
import BonusSettings from 'pages/BonusSettings';
import RedirectToLocal from 'pages/RedirectToLocal';
import TradingCupSubscription from 'pages/TradingCupSubscription';
import SalesRevenue from 'pages/SalesRevenue';
import { lightTheme, darkTheme } from 'styles/theme';
import { useStore } from 'store';
import { GlobalStyle } from 'styles/global_css';
import { useAuth } from 'store/context/hooks';
import useHttp from 'hooks/useHttp';
import Polling from 'components/Polling';
import LoadingSpinner from 'components/Spinner';
import { getUserProfileRequestConfig } from 'api/getV2RequestConfig';

import TradingCupAccounts from 'pages/TradingCupAccounts';
import pJson from '../package.json';

const materialTheme = materialCreateTheme();

const StyledAppContainer = styled.main`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

const StyledRightSideWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledContent = styled.div`
  height: calc(100% - ${headerHeight}px);
`;

const StyledSpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const App = (): JSX.Element => {
  const { theme, isAuthorized } = useStore();

  const { onLogin } = useAuth();

  const { sendRequest } = useHttp();

  useEffect(() => {
    console.info(
      `%c Current Version: ${pJson.version}`,
      'background: #222; color: #bada55'
    );
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      const handleOnLogin = (userRes: {
        data: {
          company_name: string;
          team_role: string;
          head_logo: null | string;
        };
      }): void => {
        const userData = {
          companyName: userRes.data.company_name,
          teamRole: userRes.data.team_role,
          headLogo: userRes.data.head_logo,
          ...userRes.data
        };

        onLogin({
          isFirstLogin: false,
          ...userData
        });
      };
      sendRequest(getUserProfileRequestConfig(), handleOnLogin);
    }
  }, [sendRequest, onLogin, isAuthorized]);

  return (
    <StyledAppContainer>
      <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
          <GlobalStyle />
          <BrowserRouter>
            {isAuthorized ? (
              <>
                <SideMenu />
                <StyledRightSideWrap>
                  <Header />
                  <StyledContent>
                    <Routes>
                      <Route path="*" element={<OldPagesIframe />} />
                      <Route path="/admin" element={<Admin />} />
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/trading_cup/accounts" element={<TradingCupAccounts />} />
                      <Route path="/trading_cup/subscription" element={<TradingCupSubscription />} />
                      <Route path="/salestarget/sales-revenue" element={<SalesRevenue />} />
                      <Route path="/mamacc" element={<MAMAccounts />} />
                      <Route path="/finance/BonusSetting" element={<BonusSettings />} />
                      <Route path="/test" element={process.env.REACT_APP_ENV === 'dev' ? <RedirectToLocal /> : <OldPagesIframe />} />
                    </Routes>
                  </StyledContent>
                </StyledRightSideWrap>
              </>
            ) : (
              <StyledSpinnerContainer>
                <LoadingSpinner />
              </StyledSpinnerContainer>
            )}
            <Polling />
          </BrowserRouter>
        </MaterialThemeProvider>
      </ThemeProvider>
    </StyledAppContainer>
  );
};

export default App;
