import { useEffect } from 'react';
import { setCookie } from 'utils/getCookie';
import Spinner from 'components/Spinner';
import { StyledLoadingContainer } from './style';

const RedirectToLocal = (): JSX.Element => {
  useEffect(() => {
    const token = localStorage.getItem('jwt-token') || '';
    setCookie('jwt-token', token, 7200, `${process.env.REACT_APP_COOKIE_DOMAIN}`);
    window.location.href = 'https://test.zerologixtesting.com:3000';
  }, []);

  return (
    <StyledLoadingContainer>
      <Spinner />
    </StyledLoadingContainer>
  );
};

export default RedirectToLocal;
