import axios, { AxiosPromise } from 'axios';

const newAPIPath = process.env.REACT_APP_BASE_URL;
const oldBaseURL = process.env.REACT_APP_LOGIX_PANEL_OLD_SITE_URL;

export const refreshToken = (): AxiosPromise => axios({
  method: 'POST',
  url: `${newAPIPath}/api/v1/admin-refresh-token`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
    'Content-Type': 'application/json'
  },
  timeout: 150000
});

export const v1Logout = (): AxiosPromise => axios({
  method: 'GET',
  url: `${oldBaseURL}/auth/api-logout`,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true,
  timeout: 150000
});

export const v2Logout = (): AxiosPromise => axios({
  method: 'POST',
  url: `${newAPIPath}/api/v1/admin-logout`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
    'Content-Type': 'application/json'
  },
  withCredentials: true,
  timeout: 150000
});
