import { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import SnapShot from 'components/SnapShot';
import Table from 'components/Table';
import { tHeaderGenerator } from 'utils/common';
import cx from 'classnames';
import Spinner from 'components/Spinner';
import { getCampaignsRequestConfig } from 'api/getV2RequestConfig';
import {
  FilteredCampaignGroup, CampaignData
} from './type';
import { filterCampaignData, campaignObj } from './index';
import { StyledNoActiveCampaign, StyledLoadingContainer } from './style';

// client Application
const ZhCampaign: React.FC = () => {
  const { t, i18n } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [zhCampaignData, setZhCampaignData] = useState<FilteredCampaignGroup>();

  useEffect(() => {
    const transformZhCampaignData = (campaignRes:CampaignData): void => {
      const zhFilteredData = filterCampaignData(campaignRes.data);
      setZhCampaignData(zhFilteredData);
    };
    sendRequest(
      getCampaignsRequestConfig('unexpired', 'mandarin'),
      transformZhCampaignData
    );
  }, []);

  return (
    <SnapShot
      title={t('activeCampaignsZh')}
      link="/campaigns?language=mandarin"
      className="campaign"
      note={zhCampaignData && zhCampaignData?.expiringSoonNum > 0 ?
        (
          <div className={cx({ expiringSoon: !!zhCampaignData.expiringSoonNum })}>
            { `${zhCampaignData.expiringSoonNum} ` }
            {t('expiringCampaign')}
          </div>
        ) : null}
    >
      { zhCampaignData && zhCampaignData?.filteredCampaignData.length > 0 ?
        (
          <Table
            columns={tHeaderGenerator(i18n.language, campaignObj)}
            showTooltip={['campaign']}
            dataSource={zhCampaignData.filteredCampaignData}
          />
        ) :
        (zhCampaignData?.filteredCampaignData.length === 0 &&
          (
          <StyledNoActiveCampaign>
            { t('noActiveCampaign') }
          </StyledNoActiveCampaign>
          )
        )}
      {!zhCampaignData &&
          (
          <StyledLoadingContainer>
            <Spinner />
          </StyledLoadingContainer>
          )}
    </SnapShot>
  );
};

export default ZhCampaign;
