import { useMemo, useState } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useTradingCupSubscriptionData, { FilterData, SubscriptionRowData } from 'pages/TradingCupSubscription/hooks/useTradingCupSubscriptionData';
import IconComponent from 'components/Icons';
import {
  StyledPageContainer,
  StyledPageTitle
} from 'components/PageLayout/style';
import {
  StyledButton,
  StyledOutlineDropdownSelect,
  ExportButton
} from 'components/FilterAndSearchBox/style';
import DataTable from 'components/DataTable';
import Pagination from 'components/Pagination';
import RowsPerPageSelect from 'components/RowsPerPageSelect';
import SearchBar from 'components/FilterAndSearchBox/components/SearchBar';
import FilterAndSearchBox from 'components/FilterAndSearchBox';
import DateRangePicker from 'components/DateRangePicker';
import FilterField from 'components/FilterAndSearchBox/components/FilterField';
import useHttp from 'hooks/useHttp';
import { postSubscriptionRequestConfig } from 'api/getV2RequestConfig';
import AlertToast, { AlertToastProps } from 'components/AlertToast';
import styled from 'styled-components';
import { StyledFilterContainer } from '../TradingCupAccounts/style';
import TableHeadRow from './components/TableHeadRow';
import TableRow from './components/TableRow';
import useTranslationUtils from './typesUtils';

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};
`;

const TradingCupSubscription = (): JSX.Element => {
  const { t, i18n } = useTranslation('tradingCupSubscription', {
    i18n: logixPanelInstance
  });
  const {
    statusFilterOptions,
    roleFilterOptions
  } = useTranslationUtils();
  const { sendRequest } = useHttp();
  const { t: accountT } = useTranslation('tradingCupAccounts');
  const { t: commonT } = useTranslation('common');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number>(100);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterData, setFilterData] = useState<FilterData>({
    role: 'all',
    status: 'all',
    dateRange: { start: null, end: null }
  });
  const {
    rows,
    totalPageCount,
    fetchData,
    setRows,
    onExportClick
  } = useTradingCupSubscriptionData(
    filterData,
    searchInput,
    currentPage,
    currentRowsPerPage,
    i18n.language
  );
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertToastProps['severity'];
    content: string;
  }>({
    type: 'success',
    content: ''
  });
  const [isResponseMessageToastOpen, setIsResponseMessageToastOpen] = useState<boolean>(false);

  const perPageOptions = [
    {
      value: 50,
      name: '50'
    },
    {
      value: 100,
      name: '100'
    }
  ];

  const updateSubscription = (
    orderId: SubscriptionRowData['orderId'],
    updatedRowIndex: number,
    status: SubscriptionRowData['status']
  ): void => {
    sendRequest(
      postSubscriptionRequestConfig(
        orderId,
        { status },
        i18n.language
      ),
      (responseData: { message: string }) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setIsResponseMessageToastOpen(true);

        setRows((prevState) => {
          const updatedRows = [...prevState];
          updatedRows[updatedRowIndex] = {
            ...prevState[updatedRowIndex],
            status
          };
          return updatedRows;
        });
      },
      (errorMessage) => {
        setResponseMessage({ type: 'error', content: errorMessage });
        setIsResponseMessageToastOpen(true);
      }
    );
  };

  const tableRows = useMemo(
    () => rows.map((row, index) => (
      <TableRow
        key={row.orderId}
        rowData={row}
        onActionChange={(value) => {
          updateSubscription(value.orderId, index, value.status);
        }}
      />
    )),
    [rows]
  );

  return (
    <StyledPageContainer>
      <StyledPageTitle>{t('pageTitle')}</StyledPageTitle>
      <FilterAndSearchBox
        filterSlot={(
          <StyledFilterContainer>
            <FilterField label={accountT('role')}>
              <StyledOutlineDropdownSelect
                selectedValue={filterData.role}
                options={roleFilterOptions}
                onSelect={(value) => setFilterData((prevState) => ({ ...prevState, role: value }))}
                width="100px"
              />
            </FilterField>
            <FilterField label={accountT('status')}>
              <StyledOutlineDropdownSelect
                selectedValue={filterData.status}
                options={[{
                  value: 'all',
                  name: accountT('all')
                }, ...statusFilterOptions]}
                onSelect={(value) => setFilterData((prevState) => ({
                  ...prevState,
                  status: value
                }))}
                width="130px"
              />
            </FilterField>
            <FilterField label={accountT('timeRange')}>
              <DateRangePicker
                onRangeChange={(value) => setFilterData((prevState) => ({
                  ...prevState,
                  dateRange: { start: value.startDate, end: value.endDate }
                }))}
              />
            </FilterField>
            <StyledButton onClick={fetchData}>{accountT('applyFilter')}</StyledButton>
          </StyledFilterContainer>
        )}
        searchSlot={(
          <SearchWrapper>
            <SearchBar
              placeholder={t('searchPlaceholder')}
              onSearch={(value) => setSearchInput(value)}
            />
            <ExportButton onClick={onExportClick}>
              <span>{commonT('export')}</span>
              <IconComponent name="Upload" />
            </ExportButton>
          </SearchWrapper>
        )}
      />
      <DataTable
        tableHeadSlot={<TableHeadRow />}
        tableBodySlot={tableRows}
        tableFooterSlot={(
          <>
            <Pagination
              total={totalPageCount}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
            />
            <RowsPerPageSelect
              count={currentRowsPerPage}
              customOptions={perPageOptions}
              onSelect={(value) => setCurrentRowsPerPage(value)}
            />
          </>
        )}
      />
      <AlertToast
        isOpen={isResponseMessageToastOpen}
        handleClose={() => setIsResponseMessageToastOpen(false)}
        severity={responseMessage.type}
        content={responseMessage.content}
      />
    </StyledPageContainer>
  );
};

export default TradingCupSubscription;
