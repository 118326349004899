import React, { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import SnapShot from 'components/SnapShot';
import { SnapChartData } from 'type/snapshot';
import BarChart from 'components/BarChart';
import dayjs from 'dayjs';
import Spinner from 'components/Spinner';
import { calcTotalItem, numberWithCommas } from 'utils/common';
import { getTradersRequestConfig } from 'api/getV2RequestConfig';
import { DataPair, SummaryResType } from './type';
import { barOptions } from './index';
import { StyledLoadingContainer } from './style';

const generalBarColorSet = ['#00BA34', '#0084FF', '#F98600', '#FDCF99'];

const RegisAccType: React.FC = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [regisClientTypeData, setRegisClientTypeData] = useState<SnapChartData>();
  const [regisClientTypeTotal, setRegisClientTypeTotal] = useState<number>(0);

  // Registration Account & Client Type
  useEffect(() => {
    const transformClientTypeData = (clientTypeRes: SummaryResType):void => {
      const resClientData = clientTypeRes.summarize.data;
      const resKeysArr = Object.keys(resClientData);

      setRegisClientTypeData({
        labels: resClientData[resKeysArr[0]]
          .map((ele:DataPair) => dayjs(ele.date).format('DD/MM')),
        datasets: resKeysArr.map((eachKey, idx) => ({
          label: t(eachKey),
          fill: false,
          backgroundColor: generalBarColorSet[idx],
          pointRadius: 2,
          borderRadius: 5,
          data: resClientData[resKeysArr[idx]].map((ele:DataPair) => ele.value)
        }))
      });

      setRegisClientTypeTotal(() => calcTotalItem(resClientData));
    };

    sendRequest(
      getTradersRequestConfig('type', 'weekly'),
      transformClientTypeData
    );
  }, [t]);

  return (
    <SnapShot
      title={t('registrationClientType')}
      ariaLabel="Registration Client Type"
      note={(
        <div className="total">
          {' '}
          {t('total')}
          {': '}
          {numberWithCommas(regisClientTypeTotal)}
          {' '}
        </div>
)}
    >
      { regisClientTypeData ? (
        <BarChart
          chartData={regisClientTypeData}
          options={barOptions}
        />
      ) : (
        <StyledLoadingContainer>
          <Spinner />
        </StyledLoadingContainer>
      )}

    </SnapShot>
  );
};

export default RegisAccType;
