import { useState, useEffect } from 'react';
import { SnapChartData } from 'type/snapshot';
import dayjs from 'dayjs';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import SnapShot from 'components/SnapShot';
import LineChart from 'components/LineChart';
import Spinner from 'components/Spinner';
import { getDepositFundsTransactionRequestConfig } from 'api/getV2RequestConfig';
import {
  DataPair, GeneralDataRes
} from './type';
import { commonOptions } from './index';
import { StyledLoadingContainer } from './style';

const myTransacColorSet = ['#00BA34', '#F98600'];
// client Application
const MyClientTransaction: React.FC = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [myClientTranData, setMyClientTranData] = useState<SnapChartData>();

  useEffect(() => {
    const transformMyClientData = (transacRes:GeneralDataRes): void => {
      const transactionResData = transacRes.data;
      const resKeysArr = Object.keys(transactionResData);
      setMyClientTranData({
        labels: transactionResData[resKeysArr[0]]
          .map((ele:DataPair) => dayjs(ele.date).format('DD/MM')),
        datasets: resKeysArr.map((eachKey, idx) => ({
          label: t(eachKey),
          fill: false,
          backgroundColor: myTransacColorSet[idx],
          borderColor: myTransacColorSet[idx],
          pointRadius: 3,
          borderRadius: 1,
          borderWidth: 2,
          data: transactionResData[resKeysArr[idx]].map((ele:DataPair) => ele.value)
        }))
      });
    };
    sendRequest(
      getDepositFundsTransactionRequestConfig('self', 'approved', 'weekly'),
      transformMyClientData
    );
  }, [t]);

  return (

    <SnapShot
      title={t('myClientTransaction')}
      link="/salestarget/team/monthlyTrades"
    >
      {
          myClientTranData ?
            <LineChart chartData={myClientTranData} options={commonOptions} /> :
            (
              <StyledLoadingContainer>
                <Spinner />
              </StyledLoadingContainer>
            )
        }

    </SnapShot>
  );
};

export default MyClientTransaction;
