import { useState, useCallback } from 'react';
import axios from 'api/v1Axios';
import { httpType } from './types';

const useLegacyHttp = ():httpType => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios({
        method: requestConfig.method ? requestConfig.method : 'GET',
        url: requestConfig.url,
        baseURL: requestConfig.baseURL ?
          requestConfig.baseURL :
          process.env.REACT_APP_LOGIX_PANEL_OLD_SITE_URL,
        headers: requestConfig.headers || {},
        data: requestConfig.data || {},
        withCredentials: true
      });
      if (!response.data) {
        throw new Error('Request failed!');
      }
      applyData(response.data);
    } catch (err) {
      let errorMessage = 'Something went wrong!';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setError(errorMessage);
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    sendRequest
  };
};

export default useLegacyHttp;
