import * as React from "react";
import { SVGProps } from "react";

const SvgDrizzle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Zm0 1A8 8 0 1 0 8-.001 8 8 0 0 0 8 16Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgDrizzle;
