import styled from 'styled-components';

const commonOrange = '#F79520';
export const StyledSnapshotContainer = styled.div`
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap:32px;

  .snapShot{
    &.campaign, &.webinar {
      min-height:322px;
    }
  }

  .total {
    color: #5C5C5C;
  }
  .expiringSoon {
    color:${commonOrange};
  }
`;

export const StyledNoActiveCampaign = styled.div`
    text-align: center;
    margin-top: 85px;
    font-size: 22px;
`;

export const StyledToggleTxt = styled.div`
    font-size: 14px;
    margin-right:5px;
    color: ${commonOrange};
    cursor:pointer;
`;

export const StyledLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

     >div{
      transform: translate(-35px,30px);
    }

`;

export const StyledWebinarContainer = styled.div`
  .attendees-tdContent {
        padding-left: 5px;
  }
`;
