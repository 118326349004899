import * as React from "react";
import { SVGProps } from "react";

const SvgPendingClientApplications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m22.914 21.755 3.553 1.762c.931-.26 1.875-.47 2.829-.63a9.722 9.722 0 0 1 1.042-.605l.233-.111-5.512-2.732-.078-.035a14.508 14.508 0 0 1-2.067 2.351Z"
      fill="#0084FF"
    />
    <path
      d="m22.914 21.755 3.553 1.762c.931-.26 1.875-.47 2.829-.63a9.722 9.722 0 0 1 1.042-.605l.233-.111-5.512-2.732-.078-.035a14.508 14.508 0 0 1-2.067 2.351Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      d="m26.467 23.517-5.84 10.338-5.855-4.542 5.848 4.555 5.847-10.351Z"
      fill="#87D1F4"
    />
    <path
      d="M10.712 32.536a54.002 54.002 0 0 1 4.066-3.223l-7.124-3.53a53.295 53.295 0 0 0-4.066 3.22C1.46 30.867 0 34.083 0 36.914v7.548l7.125 3.532v-7.555c-.007-2.83 1.453-6.042 3.587-7.902Z"
      fill="#0084FF"
    />
    <path
      d="M10.712 32.536a54.002 54.002 0 0 1 4.066-3.223l-7.124-3.53a53.295 53.295 0 0 0-4.066 3.22C1.46 30.867 0 34.083 0 36.914v7.548l7.125 3.532v-7.555c-.007-2.83 1.453-6.042 3.587-7.902Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      d="M34.12 27.058a6.797 6.797 0 0 0-.207-1.717v7.896c0 .816-.536 1.746-1.195 2.071l-9.904 4.91a10.157 10.157 0 0 1-.587-3.574c.007-1.143.14-2.282.398-3.396l-11.754 5.825-3.75 8.923L34.125 34.61c-.003-.012-.003-4.128-.004-7.552Z"
      fill="#0084FF"
    />
    <path
      d="M22.624 33.245c.952-4.208 3.533-8.288 6.663-10.357-.954.16-1.898.37-2.829.629l-5.847 10.352-5.848-4.56a53.56 53.56 0 0 0-4.066 3.222c-2.119 1.865-3.58 5.077-3.58 7.907v7.549l3.753-8.914 11.754-5.828Z"
      fill="#0084FF"
    />
    <path
      d="m17.09 23.974-2.863 5.069.545.27 2.603-5.295a3.635 3.635 0 0 1-.285-.044Z"
      fill="#D6EEF8"
    />
    <path
      d="m26.467 23.517-3.553-1.762a10.193 10.193 0 0 1-2.294 1.597c-1.166.577-2.27.786-3.253.666l-2.596 5.296 5.857 4.541 5.839-10.338Z"
      fill="#0084FF"
    />
    <path
      d="m26.467 23.517-3.553-1.762a10.193 10.193 0 0 1-2.294 1.597c-1.166.577-2.27.786-3.253.666l-2.596 5.296 5.857 4.541 5.839-10.338Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      d="M13.118 17.508c0-5.277 3.361-11.227 7.501-13.28 1.733-.858 3.332-.898 4.6-.267L18.096.429c-1.27-.63-2.867-.588-4.6.27-4.14 2.045-7.502 8.003-7.502 13.28 0 3.07 1.137 5.238 2.903 6.114l7.124 3.53c-1.766-.88-2.903-3.047-2.903-6.115Z"
      fill="#0084FF"
    />
    <path
      d="M13.118 17.508c0-5.277 3.361-11.227 7.501-13.28 1.733-.858 3.332-.898 4.6-.267L18.096.429c-1.27-.63-2.867-.588-4.6.27-4.14 2.045-7.502 8.003-7.502 13.28 0 3.07 1.137 5.238 2.903 6.114l7.124 3.53c-1.766-.88-2.903-3.047-2.903-6.115Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      d="M23.402 19.176c.535-.07 1.08.008 1.572.228 1.904-2.673 3.14-6.133 3.14-9.333 0-3.069-1.138-5.236-2.902-6.11l-9.2 19.658c.337.167.697.287 1.067.355l2.253-3.99a27.289 27.289 0 0 1 4.07-.808Z"
      fill="#0084FF"
    />
    <path
      d="M23.401 19.176a27.287 27.287 0 0 0-4.067.809l3.572 1.77a14.504 14.504 0 0 0 2.062-2.359 2.918 2.918 0 0 0-1.567-.22Z"
      fill="#0084FF"
    />
    <path
      d="m19.342 19.985-2.252 3.99c.092.017.184.032.277.043l1.975-4.033Z"
      fill="#0084FF"
    />
    <path
      d="M17.375 24.018c.983.118 2.087-.09 3.253-.666a10.19 10.19 0 0 0 2.294-1.597l-3.58-1.77-1.967 4.033ZM20.62 4.228c-4.14 2.053-7.5 8.003-7.5 13.28 0 3.068 1.137 5.235 2.902 6.11l9.2-19.657c-1.274-.637-2.87-.591-4.602.267ZM29.295 22.887c.414-.07.827-.13 1.237-.179a2.895 2.895 0 0 1 1.65.263l-1.613-.8-.233.111c-.36.18-.707.383-1.041.605Z"
      fill="#0084FF"
    />
    <path
      d="M29.295 22.887c.414-.07.827-.13 1.237-.179a2.895 2.895 0 0 1 1.65.263l-1.613-.8-.233.111c-.36.18-.707.383-1.041.605Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      d="m32.181 22.97-5.185 6.48v1.628l-4.372 2.167a15.448 15.448 0 0 0-.398 3.395 10.16 10.16 0 0 0 .587 3.575l9.905-4.91c.658-.325 1.194-1.258 1.194-2.071v-7.893c-.3-1.136-.907-1.963-1.73-2.37Z"
      fill="#0084FF"
    />
    <path
      d="m32.181 22.97-5.185 6.48v1.628l-4.372 2.167a15.448 15.448 0 0 0-.398 3.395 10.16 10.16 0 0 0 .587 3.575l9.905-4.91c.658-.325 1.194-1.258 1.194-2.071v-7.893c-.3-1.136-.907-1.963-1.73-2.37Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      d="m26.997 29.45 5.185-6.479a2.894 2.894 0 0 0-1.65-.263c-.41.05-.822.11-1.237.18-3.138 2.07-5.71 6.149-6.662 10.357l4.372-2.167-.008-1.628Z"
      fill="#0084FF"
    />
    <path
      d="m26.997 29.45 5.185-6.479a2.894 2.894 0 0 0-1.65-.263c-.41.05-.822.11-1.237.18-3.138 2.07-5.71 6.149-6.662 10.357l4.372-2.167-.008-1.628Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.03 24.114c4.472-2.216 8.101.612 8.101 6.311 0 5.7-3.626 12.128-8.1 14.344-4.476 2.216-8.103-.611-8.103-6.31 0-5.7 3.634-12.127 8.102-14.345Z"
      fill="#00BA34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.12 24.613a5.786 5.786 0 0 1 1.299 1.923l-6.7 12.518c-.188.415-.502.76-.9.986-.34.169-.644.136-.858-.053L29.43 36.82a.692.692 0 0 1-.119-.107 1.185 1.185 0 0 1-.249-.786c0-.82.522-1.744 1.166-2.063.328-.158.634-.137.839.039l.04.036 2.72 2.433 6.294-11.75-.001-.01Z"
      fill="#00952A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.914 25.354c.186-.434.509-.795.92-1.03.644-.315 1.166.087 1.166.907-.006.358-.09.71-.245 1.033a2.705 2.705 0 0 1-.139.258L36.45 39.91a2.152 2.152 0 0 1-.9.987c-.34.167-.644.134-.858-.055l-3.528-3.16a.756.756 0 0 1-.119-.108 1.185 1.185 0 0 1-.249-.786c0-.82.522-1.745 1.166-2.063.329-.157.627-.137.84.038l.039.036 2.72 2.434 6.358-11.868-.005-.011Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.914 25.354c.186-.434.509-.795.92-1.03.644-.315 1.166.087 1.166.907-.006.358-.09.71-.245 1.033a2.705 2.705 0 0 1-.139.258L36.45 39.91a2.152 2.152 0 0 1-.9.987c-.34.167-.644.134-.858-.055l-3.528-3.16a.756.756 0 0 1-.119-.108 1.185 1.185 0 0 1-.249-.786c0-.82.522-1.745 1.166-2.063.329-.157.627-.137.84.038l.039.036 2.72 2.434 6.358-11.868-.005-.011Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m34.835 40.94-1.098-.544a.606.606 0 0 1-.141-.096l-3.531-3.164a.756.756 0 0 1-.12-.107 1.185 1.185 0 0 1-.248-.787c0-.82.523-1.744 1.166-2.063a.786.786 0 0 1 .734-.033l1.038.516a.81.81 0 0 0-.675.061c-.644.315-1.166 1.243-1.166 2.064-.014.283.075.562.25.786a.76.76 0 0 0 .118.107l3.528 3.16c.043.039.091.071.143.097l.002.003Zm-.218-4.56 6.2-11.572c.185-.433.508-.795.92-1.03a.799.799 0 0 1 .715-.043l1.098.544a.799.799 0 0 0-.716.043 2.173 2.173 0 0 0-.92 1.03L35.556 37.22l-.939-.842Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m34.835 40.94-1.098-.544a.606.606 0 0 1-.141-.096l-3.531-3.164a.756.756 0 0 1-.12-.107 1.185 1.185 0 0 1-.248-.787c0-.82.523-1.744 1.166-2.063a.786.786 0 0 1 .734-.033l1.038.516a.81.81 0 0 0-.675.061c-.644.315-1.166 1.243-1.166 2.064-.014.283.075.562.25.786a.76.76 0 0 0 .118.107l3.528 3.16c.043.039.091.071.143.097l.002.003Zm-.218-4.56 6.2-11.572c.185-.433.508-.795.92-1.03a.799.799 0 0 1 .715-.043l1.098.544a.799.799 0 0 0-.716.043 2.173 2.173 0 0 0-.92 1.03L35.556 37.22l-.939-.842Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m29.065 45.06-2.733-1.354c-1.904-.943-3.135-3.288-3.135-6.605 0-5.699 3.631-12.126 8.103-14.342 1.87-.928 3.594-.972 4.966-.291L39 23.822c-1.372-.68-3.096-.63-4.966.29-4.472 2.218-8.103 8.65-8.103 14.345 0 3.313 1.228 5.661 3.135 6.605v-.002Z"
      fill="#99E3AE"
    />
  </svg>
);

export default SvgPendingClientApplications;
