import axios from 'axios';
import { v2Logout } from 'api/common';
import { handleLogoutRedirect } from 'utils/common';
import { apiConfig } from './config';

const customInstance = axios.create({
  baseURL: apiConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 150000
});

customInstance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);
customInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response: { status } } = error;
    if (status === 401) {
      const handelRedirect = ():void => {
        localStorage.removeItem('jwt-token');
        handleLogoutRedirect();
      };
      v2Logout().then(() => {
        handelRedirect();
      }).catch(() => {
        handelRedirect();
      });
    }
    return Promise.reject(error);
  }
);

export default customInstance;
