import styled from 'styled-components';
import { commonSnapShotWrap } from 'styles/common';

export const StyledSnapShot = styled.div`

  .paper {
    min-height:322px;
    ${commonSnapShotWrap}
  }

`;

export const StyledCanvasWrap = styled.div`

`;

export const StyledHeaderWrap = styled.div`
  margin-bottom: 16px;
  margin-top:8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .titleWrap{
    display: flex;
    align-items: center;

    >span{
      font-size:20px;
    }
    .linkIcon{
      height:20px;
      margin-left: 4px;
     font-size: 18px;
    }
  }
`;

export const StyledTotalWrap = styled.div`
  position: relative;
`;

export const StyledIconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
