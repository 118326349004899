import IconComponent from 'components/Icons';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogCloseButton,
  StyledDialogContent,
  StyledDialogHeader
} from './style';

type AlertDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  contentSlot: JSX.Element;
  actionSlot: JSX.Element;
};

const AlertDialog = ({
  isOpen,
  handleClose,
  title,
  contentSlot,
  actionSlot
}: AlertDialogProps): JSX.Element => (
  <StyledDialog open={isOpen} onClose={handleClose}>
    <StyledDialogHeader>
      <h5 aria-label="alert dialog title">{title}</h5>
      <StyledDialogCloseButton
        onClick={handleClose}
        aria-label="close alert dialog"
      >
        <IconComponent name="Close" />
      </StyledDialogCloseButton>
    </StyledDialogHeader>
    <StyledDialogContent aria-label="alert dialog content">
      {contentSlot}
    </StyledDialogContent>
    <StyledDialogActions aria-label="alert dialog actions">
      {actionSlot}
    </StyledDialogActions>
  </StyledDialog>
);

export default AlertDialog;
