/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import cx from 'classnames';
import { pluginGenerator } from 'utils/chart';
import { StyledBarChart } from './style';

interface BarChartProps {
  chartData: any;
  className?: string;
  options?: any;
  ariaLabel?:string;
}

const defaultPlugin = pluginGenerator('bar-chart-plugin');

const BarChart:React.FC<BarChartProps> = (props) => {
  const {
    chartData, className, options, ariaLabel
  } = props;

  return (
    <StyledBarChart
      className={cx('bar-chart', className)}
      aria-label={`component bar
      chart ${ariaLabel}`}
    >
      <Bar data={chartData} options={options} plugins={defaultPlugin} />
    </StyledBarChart>

  );
};

export default BarChart;
