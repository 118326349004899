import React, {
  useRef, useState, useMemo, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import styled from 'styled-components';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import IconComponent from 'components/Icons';
import useClickOutside from 'hooks/useClickOutside';

dayjs.extend(quarterOfYear);

const toDigi = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export enum TimeRange {
  month = 'month',
  quarter = 'quarter',
  year = 'year'
}

type QuarterPanelProps = {
  sleetedYear: number;
  onChange: (date: string) => void;
};

type MonthPanelProps = QuarterPanelProps
type YearPanelProps = QuarterPanelProps

type TimeRangePickerProps = {
  type: TimeRange
  onChange: (date: string) => void;
};

type StyledComponentProps = {
  active: boolean;
};

const QuarterPanelWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const YearPanelWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 20px;
`;
const MonthPanelWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 20px;
`;

const TimeRangeWrap = styled.div`
  position: relative;
`;

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
`;

const DateInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 4px 10px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DDD;
  cursor: pointer;
`;

const PanelWrap = styled.div`
  position: absolute;
  transform: translateX(-25%);
  z-index: 1;
  top: 40px;
  display: flex;
  width: 320px;
  padding: 16px;
  flex-direction: column;
  align-items: center;

  border-radius: 4px;
  background: #fff;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12), 0px 0px 7px 0px rgba(0, 0, 0, 0.20);
`;

const StyleQuarterItem = styled.div<StyledComponentProps>`
  width: 64px;
  text-align: center;
  color: ${(props) => (props.active ? '#1C1C1C' : '#CCC')};
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
`;
const StyleMonthItem = styled.div<StyledComponentProps>`
  text-align: center;
  color: ${(props) => (props.active ? '#1C1C1C' : '#CCC')};
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
`;
const StyleYearItem = styled.div<StyledComponentProps>`
  text-align: center;
  color: ${(props) => (props.active ? '#1C1C1C' : '#CCC')};
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
`;

const StyledButton = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const QuarterPanel: React.FC<QuarterPanelProps> = ({ sleetedYear, onChange }) => {
  const { t } = useTranslation('salesRevenue', {
    i18n: logixPanelInstance
  });
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  const curYear = dayjs().year();
  const curQuarter = dayjs().quarter();
  const checkIsActive = (idx: number): boolean => sleetedYear < curYear ||
    (sleetedYear === curYear && curQuarter >= idx + 1);
  return (
    <QuarterPanelWrap>
      {quarters.map((q, idx) => (
        <StyleQuarterItem
          key={q}
          active={checkIsActive(idx)}
          onClick={() => {
            if (!checkIsActive(idx)) return;
            onChange(`${sleetedYear}-${q}`);
          }}
        >
          {t(q)}
        </StyleQuarterItem>
      ))}
    </QuarterPanelWrap>
  );
};

const MonthPanel: React.FC<MonthPanelProps> = ({ sleetedYear, onChange }) => {
  const { t } = useTranslation('salesRevenue', {
    i18n: logixPanelInstance
  });
  const curYear = dayjs().year();
  const curMonth = dayjs().month();
  const checkIsActive = (idx: number): boolean => sleetedYear < curYear ||
  (sleetedYear === curYear && curMonth >= idx);
  return (
    <MonthPanelWrap>
      {months.map((m, idx) => (
        <StyleMonthItem
          key={m}
          active={checkIsActive(idx)}
          onClick={() => {
            if (!checkIsActive(idx)) return;
            onChange(`${sleetedYear}-${toDigi(idx + 1)}`);
          }}
        >
          {t(`${m}`)}
        </StyleMonthItem>
      ))}
    </MonthPanelWrap>
  );
};

const YearPanel: React.FC<YearPanelProps> = ({ sleetedYear, onChange }) => {
  const curYear = dayjs().year();
  const last12Year = useMemo(() => sleetedYear - 10, [sleetedYear]);
  const years = Array.from({ length: sleetedYear + 2 - last12Year }, (_, i) => last12Year + i);
  const checkIsActive = (y: number): boolean => y <= curYear;
  return (
    <YearPanelWrap>
      {years.map((y) => (
        <StyleYearItem
          key={y}
          active={checkIsActive(y)}
          onClick={() => {
            if (!checkIsActive(y)) return;
            onChange(`${y}`);
          }}
        >
          {y}
        </StyleYearItem>
      ))}
    </YearPanelWrap>
  );
};

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({ type, onChange }) => {
  const ref = useRef(null);
  const [showPanel, setShowPanel] = useState(false);
  const [sleetedYear, updateYear] = useState(dayjs().year());

  const getMonthDefaultDate = (): string => `${dayjs().year()}-${toDigi(dayjs().month() + 1)}`;
  const getQuarterDefaultDate = (): string => `${dayjs().year()}-Q${dayjs().quarter()}`;
  const getYearDefaultDate = (): number => dayjs().year();

  const [displayDate, setDisplayDate] = useState(() => {
    if (type === 'month') return getMonthDefaultDate();
    if (type === 'quarter') getQuarterDefaultDate();
    return getYearDefaultDate();
  });
  const last12Year = useMemo(() => sleetedYear - 10, [sleetedYear]);
  const toNextYear = (): void => updateYear(sleetedYear + 1);
  const toLastYear = (): void => updateYear(sleetedYear - 1);

  const onSelectDate = (date: string): void => {
    setDisplayDate(date);
    setShowPanel(false);
  };

  useEffect(() => {
    if (type === 'month') {
      setDisplayDate(getMonthDefaultDate());
    } else if (type === 'quarter') {
      setDisplayDate(getQuarterDefaultDate());
    } else if (type === 'year') {
      setDisplayDate(getYearDefaultDate());
    }
  }, [type]);

  useClickOutside(ref, () => setShowPanel(false));

  useEffect(() => {
    onChange(displayDate.toString());
  }, [displayDate]);

  return (
    <TimeRangeWrap>
      <DateInputWrap
        onClick={(e) => {
          e.stopPropagation();
          setShowPanel(!showPanel);
        }}
      >
        <span>{`${displayDate}`}</span>
        <IconComponent name="Calendar" />
      </DateInputWrap>
      {showPanel && (
        <PanelWrap ref={ref}>
          <PanelHeader>
            <StyledButton type="button" onClick={toLastYear}>
              <IconComponent name="LeftDoubleArrow" />
            </StyledButton>
            {`${type === 'year' ? `${last12Year}-${sleetedYear + 1}` : sleetedYear}`}
            <StyledButton type="button" onClick={toNextYear}>
              <IconComponent name="RightDoubleArrow" />
            </StyledButton>
          </PanelHeader>
          {type === 'quarter' && <QuarterPanel sleetedYear={sleetedYear} onChange={onSelectDate} />}
          {type === 'month' && <MonthPanel sleetedYear={sleetedYear} onChange={onSelectDate} />}
          {type === 'year' && <YearPanel sleetedYear={sleetedYear} onChange={onSelectDate} />}
        </PanelWrap>
      )}
    </TimeRangeWrap>
  );
};

export default TimeRangePicker;
