import { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import SnapShot from 'components/SnapShot';
import { SnapChartData } from 'type/snapshot';
import BarChart from 'components/BarChart';
import dayjs from 'dayjs';
import {
  calcTotalItem, intlGenerator, financialFormat, emptyStrGenerator
} from 'utils/common';
import { getLotteryRecordsRequestConfig } from 'api/getV2RequestConfig';
import Spinner from 'components/Spinner';
import { DataPair, SummaryResType } from './type';
import { StyledLoadingContainer } from './style';

const commonLegend = {
  align: 'start',
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    position: 'middle',
    padding: 10
  }
};

const scaleWithCustomSize = (stepSizeY: number, isMoneyUnit = false)
:{x:object, y:object} => ({
  x: {
    ticks: {
      stepSize: 1,
      autoSkip: false,
      padding: 5
    },
    grid: {
      display: false
    }
  },
  y: {
    min: 0,
    suggestedMax: 500,
    ticks: {
      stepSize: 100,
      type: 'linear',
      display: true,
      padding: 8,
      callback(value:number) {
        return isMoneyUnit ? `${intlGenerator(value)}` : value;
      }
    }
  }
});

const commonOptions = {
  scales: {
    ...scaleWithCustomSize(5),
    y: {
      min: 0,
      suggestedMax: 60,
      ticks: {
        stepSize: 5,
        type: 'linear',
        display: true,
        padding: 8
      }
    }
  },
  plugins: {
    legend: commonLegend,

    tooltip: {
      mode: 'index',
      intersect: false,
      bodySpacing: 8,
      padding: 8,
      backgroundColor: '#F4F4F4',
      titleColor: '#585757',
      bodyColor: '#585757',
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label(context:any) {
          const label = context.dataset.label || '';
          return `  ${label.trim()} : ${context.parsed.y}`;
        }
      }
    }
  }
};

const bonusOptions = {
  scales: {
    ...scaleWithCustomSize(5),
    y: {
      min: 0,
      suggestedMax: 180,
      ticks: {
        stepSize: 30,
        type: 'linear',
        display: true,
        padding: 8
      }
    }
  },
  plugins: {
    legend: commonLegend,
    tooltip: {
      ...commonOptions.plugins.tooltip,
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label(context:any) {
          const label = context.dataset.label || '';
          return `  ${label.trim()} : ${financialFormat(context.parsed.y)}`;
        }
      }
    }
  }
};

const LuckyDrawBonus: React.FC = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [luckyDrawBonusData, setLuckyDrawBonusData] = useState<SnapChartData>();
  const [luckyDrawBonusTotal, setLuckyDrawBonusTotal] = useState<number>(0);

  const luckyDrawColorSet = ['#00BA34', '#0084FF', '#F98600', '#E92C2C'];
  // LP2-1156 Lucky Draw Bonuses
  useEffect(() => {
    const transformLuckyBonusData = (luckDrawRes:SummaryResType):void => {
      const luckDrawData = luckDrawRes.summarize.data;
      const resKeysArr = Object.keys(luckDrawData);

      setLuckyDrawBonusData(
        {
          labels: luckDrawData[resKeysArr[0]]
            .map((ele:DataPair) => dayjs(ele.date).format('DD/MM')),
          datasets: resKeysArr.map((eachKey, idx) => ({
            label: `${eachKey}${emptyStrGenerator(2)}`,
            fill: false,
            backgroundColor: luckyDrawColorSet[idx],
            pointRadius: 2,
            borderRadius: 5,
            data: luckDrawData[resKeysArr[idx]].map((ele:DataPair) => ele.value)
          }))
        }
      );

      setLuckyDrawBonusTotal(() => calcTotalItem(luckDrawData));
    };
    sendRequest(
      getLotteryRecordsRequestConfig('approved', 'weekly', 'cash_amount'),
      transformLuckyBonusData
    );
  }, []);

  return (
    <SnapShot
      title={t('luckyDrawBonuses')}
      ariaLabel="Lucky Draw Bonus"
      note={(
        <div className="total">
          {t('totalUsd')}
          {': '}
          {intlGenerator(luckyDrawBonusTotal, 2)}
        </div>
)}
    >
      { luckyDrawBonusData ?
        <BarChart chartData={luckyDrawBonusData} options={bonusOptions} /> :
        (
          <StyledLoadingContainer>
            <Spinner />
          </StyledLoadingContainer>
        )}
    </SnapShot>
  );
};

export default LuckyDrawBonus;
