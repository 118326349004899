import styled from 'styled-components';

interface StyledTableBodyCellProps {
  isEditing?: boolean;
  isFocused?: boolean;
}

export const StyledCreditContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledPageSubTitle = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const StyledCreditTooltip = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

export const StyledButton = styled.button`
  padding: 4px 15px;
  border-radius: 4px;
  border: 1px;
  color: #ffffff;
  background-color: #00b875;
  border: 1px solid var(--logix-panel-light-p-400, #00b875);
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const StyledCancelButton = styled(StyledButton)`
  background-color: #ffffff;
  color: #000000d9;
  border: 1px solid var(--neutral-5, #d9d9d9);
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledSection = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 36px;
  color: var(--neutral-n-200, #3e3e3e);
`;

export const StyledTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-n-600, #f4f4f4);
`;

export const StyledTableHeader = styled.thead`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px 8px 0 0;
`;

export const StyledTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  &:nth-child(odd) {
    background-color: var(--neutral-n-700, #fff);
  }
  &:nth-child(even) {
    background: var(--neutral-n-650, #fbfbfb);
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export const StyledTableHeaderRow = styled.tr`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

export const StyledTableHeaderCell = styled.th`
  padding: 10px;
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  align-self: stretch;
  width: 50%;
  color: var(--neutral-n-100, #2a2a2a);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: var(--neutral-n-650, #fbfbfb);
  border-radius: 8px;
`;

export const StyledTableCell = styled.td`
  padding: 10px;
`;

export const StyledTableBody = styled.tbody`
  width: 100%;
`;

export const StyledTableBodyCell = styled.th<StyledTableBodyCellProps>`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  color: var(--neutral-n-200, #3e3e3e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 50%;
  input[type='text'] {
    ${(props) => props.isEditing &&
      `border: 1px solid var(--neutral-n-450, #CCC); 
       width: 100%; 
       border-radius: 4px; 
       display: flex;
       padding: 4px 8px;
       align-items: center;
       gap: 8px;
       color: var(--neutral-n-200, #3E3E3E);
       align-self: stretch;`}
    ${(props) => props.isFocused &&
      `border-color: #00B875;
      `}
  }
`;
