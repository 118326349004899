import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IconComponent from 'components/Icons';
import NotificationBadge from 'components/NotificationBadge';
import { useStore } from 'store';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledIconWrapper
} from './style';
import MenuItem from '../MenuItem';

export type MenuAccordionProps = {
  identifier: string;
  title: string;
  notification: number;
  iconName: string;
  menuItems: Array<{
    id: string;
    url: string;
    title: string;
    notification: number;
  }>;
};

const MenuAccordion = ({
  identifier,
  title,
  notification,
  iconName,
  menuItems
}: MenuAccordionProps): JSX.Element => {
  const location = useLocation();

  const isSideMenuOpen = useStore((state) => state.isMenuOpen);
  const { toggleMenu } = useStore();

  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const hasActiveRoute = !!menuItems.find(
    (item) => item.url.split('?')[0] === location.pathname
  );

  useEffect(() => {
    if (!isSideMenuOpen) {
      setIsAccordionOpen(false);
    }
    setIsAccordionOpen(hasActiveRoute);
  }, [isSideMenuOpen]);

  return (
    <StyledAccordion expanded={isAccordionOpen} aria-label={`${identifier} Menu Accordion`}>
      <StyledAccordionSummary
        expandIcon={isSideMenuOpen && <IconComponent name="ArrowDown" />}
        aria-controls={`panel-${identifier}-content`}
        aria-label={`${identifier} Menu Accordion Summary`}
        id={`panel-${identifier}-header`}
        $isActive={hasActiveRoute}
        onClick={() => {
          if (!isSideMenuOpen) {
            toggleMenu();
          }
          setIsAccordionOpen((prevState) => !prevState);
        }}
      >
        <StyledIconWrapper
          showNotification={!isSideMenuOpen && notification > 0}
        >
          <IconComponent name={iconName} />
        </StyledIconWrapper>
        {isSideMenuOpen && (
          <>
            {title}
            {notification > 0 && (
              <NotificationBadge
                messagesNumber={notification}
                style={{ marginLeft: 'auto' }}
              />
            )}
          </>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails aria-label={`${identifier} Menu Accordion Details`}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            identifier={`${identifier}-${item.id}`}
            url={item.url}
            title={item.title}
            notification={item.notification}
          />
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default MenuAccordion;
