import styled from 'styled-components';

export const StyledShortcut = styled.div`
  .shortcutPaper{
    width: 100%;
    height: 122px;
  }
`;

export const StyledShortcutContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const StyledMessage = styled.div<{ size: number }>`
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  font-size: 48px;
  >div{
    width: calc(100% - 80px);
    font-size: ${(props) => props.size}px;
    line-height: ${(props) => props.size}px;
    margin-left: 16px;
    margin-right: 16px;
    color: #5C5C5C;
    overflow-wrap: break-word;
  }
`;

export const StyledRedirectIcon = styled.div`
  font-size: 16px;
  &:hover{
    cursor: pointer;
  }
`;

export const StyledName = styled.div`
  font-size: 14px;
  color: #5C5C5C;
`;
