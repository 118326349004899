import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  StyledDataTable,
  StyledDataTableFooter,
  StyledTableHead
} from './style';

type DataTableProps = {
  tableHeadSlot: JSX.Element;
  tableBodySlot: JSX.Element[];
  tableFooterSlot: JSX.Element;
};

const DataTable = ({
  tableHeadSlot,
  tableBodySlot,
  tableFooterSlot
}: DataTableProps): JSX.Element => (
  <StyledDataTable>
    <TableContainer>
      <Table sx={{ borderCollapse: 'initial', minWidth: 650 }}>
        <StyledTableHead>{tableHeadSlot}</StyledTableHead>
        <TableBody>{tableBodySlot}</TableBody>
      </Table>
    </TableContainer>
    <StyledDataTableFooter>{tableFooterSlot}</StyledDataTableFooter>
  </StyledDataTable>
);

export default DataTable;
