import { SVGProps } from 'react';

const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.41427 3.05551C2.02374 2.66499 1.46972 2.58584 1.17683 2.87873C0.883935 3.17163 0.963081 3.72565 1.35361 4.11617L4.18203 6.9446C4.42532 7.18789 4.73206 7.31033 4.99959 7.29726C5.26722 7.31048 5.57416 7.18803 5.81757 6.94462L8.646 4.11619C9.03652 3.72567 9.11567 3.17165 8.82277 2.87875C8.52988 2.58586 7.97586 2.66501 7.58534 3.05553L4.99981 5.64106L2.41427 3.05551Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgArrowDown;
