import styled, { css } from 'styled-components';

export const StyledSearchBar = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.s4};
  `}
`;

export const StyledSearchInput = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.N500};
    border-radius: 8px;
    padding: ${theme.spacing.s1} ${theme.spacing.s3};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.s3};

    input {
      border: none;
      width: 260px;
      color: ${theme.palette.N100};

      &::placeholder {
        color: ${theme.palette.N400};
      }
    }
  `}
`;
