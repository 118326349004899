import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import { StyledAdminDiv } from './styled';

interface IPropsAdmin {
  title?: string
}

const PageAdmin: React.FC<IPropsAdmin> = () => {
  const { t } = useTranslation('common', { i18n: logixPanelInstance });

  return (
    <StyledAdminDiv>
      <h1>{t('greetings')}</h1>
    </StyledAdminDiv>
  );
};

export default PageAdmin;
