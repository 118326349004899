import IconComponent from 'components/Icons';
import Paper from 'components/Paper';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/Spinner';
import {
  StyledShortcut, StyledShortcutContent, StyledMessage, StyledRedirectIcon, StyledName
} from './style';

interface ShortcutProps{
  name: string;
  className?: string;
  url?: string;
  value: number | string;
  ariaLabel?: string;
  isLoading?: boolean
}

const Shortcut: React.FC<ShortcutProps> = (props) => {
  const {
    name, className, url = '/', value = 0, ariaLabel, isLoading = false
  } = props;
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const navigate = useNavigate();

  const calcFontSize = (targetNumber: number | string): number => {
    if (targetNumber) {
      const len = targetNumber.toString().length;
      if (len > 8 && len <= 10) {
        return 28;
      }
      if (len > 10 && len <= 12) {
        return 24;
      }
      if (len > 12 && len <= 14) {
        return 22;
      }
      if (len > 14) {
        return 18;
      }
    }
    return 36;
  };

  return (
    <StyledShortcut className={className} aria-label={`${ariaLabel} shortcut`}>
      <Paper className="shortcutPaper" elevation={6} aria-label={`${ariaLabel} shortcut paper`}>
        <StyledShortcutContent>
          <StyledMessage size={calcFontSize(value)}>
            <IconComponent name={name} aria-label={`${ariaLabel} shortcut icon`} />
            <div aria-label={`${ariaLabel} shortcut number`}>
              {
                isLoading ?
                  <LoadingSpinner type="MoonLoader" /> :
                  value
              }
            </div>
          </StyledMessage>
          <StyledRedirectIcon
            onClick={() => navigate(url)}
            aria-label={`${ariaLabel} shortcut redirect`}
          >
            <IconComponent name="BoxArrowUpLeft" />
          </StyledRedirectIcon>
        </StyledShortcutContent>
        <StyledName aria-label={`${ariaLabel} shortcut text`}>
          {t(name)}
        </StyledName>
      </Paper>
    </StyledShortcut>
  );
};

export default Shortcut;
