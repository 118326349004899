import React, { SVGProps } from 'react';
import {
  Dashboard,
  Setting,
  Menu,
  Clock,
  Close,
  UserList,
  Check,
  TriangleDown,
  ArrowDown,
  Compliance,
  Drizzle,
  Email,
  Glasses,
  Graph,
  Log,
  Notification,
  Pie,
  Speaker,
  Ticket,
  Banknote,
  Zerologix,
  Campaigns,
  Lang,
  NewNotifications,
  PendingTickets,
  DocumentResubmissions,
  PendingClientApplications,
  PendingDeposits,
  PendingWithdrawals,
  PendingBankAccounts,
  LuckyDrawApprovals,
  ActiveCampaigns,
  NewQualifiedTraders,
  NetDeposits,
  BoxArrowUpLeft,
  Exclamation,
  Search,
  CirclePlus,
  CircleMinus,
  Delete,
  ClipboardTask,
  Crosshair,
  Book,
  MegaphoneLoud,
  FileTrayFull,
  UserAdmin,
  MenuExpand,
  MenuCollapse,
  SignOut,
  Bullet,
  TradingCup,
  CheckboxChecked,
  CheckboxUnChecked,
  CheckboxIndeterminate,
  LeftDoubleArrow,
  RightDoubleArrow,
  Calendar,
  Upload,
  Info
} from 'iconComponents';

interface Props extends SVGProps<SVGElement> {
  name: string;
}

type Icons = {
  [key: string]: React.ComponentType;
};

const iconObj: Icons = {
  Dashboard,
  Setting,
  Menu,
  Clock,
  Close,
  UserList,
  Check,
  TriangleDown,
  ArrowDown,
  Compliance,
  Drizzle,
  Email,
  Glasses,
  Graph,
  Log,
  Notification,
  Pie,
  Speaker,
  Ticket,
  Banknote,
  Zerologix,
  Campaigns,
  Lang,
  NewNotifications,
  PendingTickets,
  DocumentResubmissions,
  PendingClientApplications,
  PendingDeposits,
  PendingWithdrawals,
  PendingBankAccounts,
  LuckyDrawApprovals,
  ActiveCampaigns,
  NewQualifiedTraders,
  NetDeposits,
  BoxArrowUpLeft,
  Exclamation,
  Search,
  CirclePlus,
  CircleMinus,
  Delete,
  ClipboardTask,
  Crosshair,
  Book,
  MegaphoneLoud,
  FileTrayFull,
  UserAdmin,
  MenuExpand,
  MenuCollapse,
  SignOut,
  Bullet,
  TradingCup,
  CheckboxChecked,
  CheckboxUnChecked,
  CheckboxIndeterminate,
  LeftDoubleArrow,
  RightDoubleArrow,
  Calendar,
  Upload,
  Info
};

const IconComponent = ({ name, ...props }: Props): JSX.Element => {
  const Icon = iconObj[name] || null;
  return Icon && <Icon {...props} />;
};

export default IconComponent;
