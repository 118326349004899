/* eslint-disable  @typescript-eslint/no-explicit-any */
import { emptyStrGenerator } from 'utils/common';

export const pluginGenerator = (id: string): any => [{
  id,
  beforeInit(chart: any): any {
    const originalFit = chart.legend.fit;
    // eslint-disable-next-line no-param-reassign
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 25;
    };
  }
}];

export const stackChartGenerator = (
  chartMemberData: any,
  stackKey: string,
  colorSet: string[],
  leadText: string
): any => {
  const arr = [];
  for (let i = 0; i < chartMemberData.length; i += 1) {
    arr.unshift({
      stack: stackKey,
      label: chartMemberData[i].role === 'lead' ?
        `${chartMemberData[i].name} (${leadText}) ${emptyStrGenerator(4)}` :
        `${chartMemberData[i].name} ${emptyStrGenerator(4)}`,
      data: chartMemberData[i].commissions
        .map((memberData: { value: string }) => memberData.value),
      color: colorSet[i],
      backgroundColor: colorSet[i],
      borderColor: colorSet[i]

    });
  }
  return arr;
};
