import { useState, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import dayjs from 'dayjs';
import useHttp from 'hooks/useHttp';
import {
  getSalesRevenueRequestConfig,
  ResponseData
} from 'api/getV2RequestConfig';
import IconComponent from 'components/Icons';
import { toCamelCaseObject } from 'utils/common';
import { V2_API_PATH } from 'api/apiPath';
import SalesRevenueBreadcrumb from './components/SalesRevenueBreadcrumb';
import TeamSelector from './components/TeamSelector';
import TimeRange from './components/TimeRange';
import Tooltip from './components/Tooltip';
import EnchorWrap from './components/EnchorWrap';
import SalesRevenueTable, { TeamRevenue, TotalRevenue } from './components/SalesRevenueTable';

const toDigi = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

type TotalSalesRevenue = {
  trading_pl: number
  swap: number
  admin_fee: number
  rebates: number
  agent_rebates: number
  bonus: number
  good_will: number
  sales_revenue: number
}

type SalesRevenueData = {
  team_id: number
  team_name: string
  trading_pl: number
  swap: number
  admin_fee: number
  rebates: number
  agent_rebates: number
  bonus: number
  good_will: number
  sales_revenue: number
}

type SalesRevenueListResponse = ResponseData & {
  data: {
    period: string
    total: TotalSalesRevenue
    revenue_data: SalesRevenueData[]
    last_updated_time: string;
  };
};

export type QueryDataType = {
  teams: string
  period: string
}

const StyledPageContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.s8} ${theme.spacing.s6} ${theme.spacing.s12};
    height: 100%;
    overflow: auto;
    background-color: ${theme.palette.N650};
  `}
`;

const Title = styled.div`
  margin: 14px 0 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
`;

const HandlerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
`;

const StyledButton = styled.button`
  padding: 4px 15px;
  min-width: 70px;
  min-height: 40px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #00b875;
  border: 1px solid #00b875;
  color: #ffffff;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ApplyFilterButton = styled(StyledButton)`
  margin-left: 32px;
  white-space: nowrap;
`;

const ExportButton = styled(StyledButton)`
  margin-left: 16px;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

const LastSyncTime = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 24px;
  div {
    display: flex;
    align-items: center;
    gap: 4px;
    display: flex;
    align-items: center;
  }
`;

const SalesRevenue = (): JSX.Element => {
  const { t } = useTranslation('salesRevenue', {
    i18n: logixPanelInstance
  });
  const [queryData, updateQueryData] = useState<QueryDataType>({
    teams: '',
    period: `${dayjs().year()}-${toDigi(dayjs().month() + 1)}`
  });
  const [resData, updateResDate] = useState<SalesRevenueListResponse>();

  const { data } = resData || {};
  const {
    revenue_data: revenueData,
    total,
    last_updated_time: lastUpdatedTime
  } = data || {};
  const tableDate: TeamRevenue[] = useMemo(() => (revenueData ?
    revenueData.map(
      (item: SalesRevenueData) => (
        toCamelCaseObject<SalesRevenueData, TeamRevenue>(item)
      )
    ) :
    []), [revenueData]);
  const { sendRequest } = useHttp();

  const updateTimeRange = (period: string): void => {
    updateQueryData((prevQueryData) => ({ ...prevQueryData, period }));
  };

  const updateQueryTeams = (queryTeams: string): void => {
    updateQueryData((prevQueryData) => ({ ...prevQueryData, teams: queryTeams }));
  };

  const onExportClick = (): void => {
    const queryTeams = queryData.teams ? `&teams=${encodeURIComponent(queryData.teams)}` : '';
    const queryPeriod = queryData.period ? `period=${queryData.period}` : '';
    const url = `${process.env.REACT_APP_BASE_URL}${V2_API_PATH.SALES_REVENUE}/csv?${queryPeriod}${queryTeams}`;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('jwt-token')}`);

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = `sales_revenue_${queryData.period}.csv`;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  const fetchData = (): void => {
    sendRequest(
      getSalesRevenueRequestConfig(queryData),
      (responseData: SalesRevenueListResponse) => {
        updateResDate(responseData);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StyledPageContainer>
      <SalesRevenueBreadcrumb />
      <Title>{t('salesRevenueReport')}</Title>
      <LastSyncTime>
        <div>
          {t('lastSyncTime')}
          <Tooltip tooltipText={t('lastSyncTimeTooltip')}>
            <EnchorWrap>
              <IconComponent name="Info" />
            </EnchorWrap>
          </Tooltip>
          :
        </div>
        <span>
          {lastUpdatedTime}
        </span>
      </LastSyncTime>
      <HandlerWrap>
        <TimeRange onChange={updateTimeRange} />
        <TeamSelector queryData={queryData} onChange={updateQueryTeams} />
        <div style={{
          display: 'flex', flexWrap: 'wrap', flex: '1', justifyContent: 'space-between'
        }}
        >
          <ApplyFilterButton onClick={fetchData}>{t('applyFilter')}</ApplyFilterButton>
          <ExportButton onClick={onExportClick}>
            <span>{t('export')}</span>
            <IconComponent name="Upload" />
          </ExportButton>
        </div>
      </HandlerWrap>
      {total && (
        <SalesRevenueTable
          data={tableDate}
          period={queryData.period}
          total={toCamelCaseObject<TotalSalesRevenue, TotalRevenue>(total)}
        />
      )}
    </StyledPageContainer>
  );
};

export default SalesRevenue;
