import { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import SnapShot from 'components/SnapShot';
import Table from 'components/Table';
import { tHeaderGenerator } from 'utils/common';
import { getWebinarsRequestConfig } from 'api/getV2RequestConfig';
import useHttp from 'hooks/useHttp';
import Spinner from 'components/Spinner';
import { GeneralKeyPair, WebinarResObj } from './type';
import { filterWebinarData, webinarObj } from './index';

import { StyledNoActiveCampaign, StyledWebinarContainer, StyledLoadingContainer } from './style';

// Eng Webinar
const EnWebinar: React.FC = () => {
  const { t, i18n } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [enWebinarData, setEnWebinarData] = useState<GeneralKeyPair[]>();

  useEffect(() => {
    const transformEnWebinar = (webinarRes:WebinarResObj): void => {
      const enFilteredData = filterWebinarData(webinarRes.courses);
      setEnWebinarData(enFilteredData);
    };
    sendRequest(
      getWebinarsRequestConfig('en', process.env.REACT_APP_WEBINAR_PLATFORM),
      transformEnWebinar
    );
  }, [sendRequest]);

  return (

    <StyledWebinarContainer>
      <SnapShot
        title={t('recentWebinarEng')}
        link="/training/courses"
        className="webinar"
      >
        {enWebinarData && enWebinarData?.length > 0 ?
          (
            <Table
              columns={tHeaderGenerator(i18n.language, webinarObj)}
              dataSource={enWebinarData}
              showTooltip={['webinar']}
            />
          ) :
          (enWebinarData?.length === 0 &&
            (
            <StyledNoActiveCampaign>
              { t('noRecentWebinar') }
            </StyledNoActiveCampaign>
            )
          )}
        { !enWebinarData && (
        <StyledLoadingContainer>
          <Spinner />
        </StyledLoadingContainer>
        )}

      </SnapShot>
    </StyledWebinarContainer>

  );
};

export default EnWebinar;
