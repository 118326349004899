export const themeList = ['light', 'dark']; // temporarily only has charcoal theme

export const fetchPendingsDataConfig = {
  general: 'general',
  notifications: 'newNotification',
  active_campaigns: 'activeCampaigns',

  trader: 'clients',
  pending_individual: 'individualAccounts',
  pending_corporate: 'corporateAccounts',
  pending_joint: 'jointAccounts',

  task: 'tasks',
  pending_gateway_restriction_violations: 'gatewayRestrictionViolations',
  pending_agent: 'agents',
  pending_subacc: 'subAccounts',
  pending_LogixTrader: 'LogixTraderAccounts',
  pending_ACYVerse: 'ACYVerseAccounts',
  pending_wholesale_declaration: 'wholesaleDeclarations',
  pending_person_doc: 'individualDocuments',
  pending_company_doc: 'corporateDocuments',
  pending_person_video: 'individualVideo',
  pending_company_video: 'corporateVideo',
  pending_mamacc: 'mamAccounts',
  treasury: 'treasury',
  pending_deposit: 'deposits',
  pending_withdraw: 'withdrawals',
  pending_card: 'bankAccountApproval',
  pending_bonus: 'bonus',
  pending_transfer: 'internalTransfers',
  pending_level_trader: 'traderLevels',
  pending_trader_claim_back: 'traderClaimBack',
  pending_bonus_withdrawal: 'bonusWithdrawal',
  pending_bonus_setting: 'bonusSetting',
  pending_level_agent: 'agentLevels',
  pending_custom_lottery: 'lotteryRecords',
  pending_fees_charges: 'feesAndCharges',
  total: 'treasury',

  tickets: 'tickets',

  compliance: 'compliance',
  aml_kyc: 'AMLKYC',
  dtcc_report: 'DTCCReport'

};

export const enGreetings = {
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening'
};

export const zhGreetings = {
  morning: '早上',
  afternoon: '下午',
  evening: '晚上'
};
