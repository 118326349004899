import {
  StyledExternalLink,
  StyledTableCell,
  StyledTableRow
} from 'components/DataTable/style';
import {
  AccountRowData,
  Role
} from 'pages/TradingCupAccounts/hooks/useTradingCupAccountsData';

export type TableRowProps = {
  rowData: AccountRowData;
};

const TableRow = ({ rowData }: TableRowProps): JSX.Element => (
  <StyledTableRow>
    <StyledTableCell align="left" aria-label="tradingcup id column">
      {rowData.tradingcupId}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="strategy name column">
      {rowData.strategyName}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="create date column">
      {rowData.createDate}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="name column">
      {rowData.traderUrl ? (
        <StyledExternalLink
          href={rowData.traderUrl}
          target="_blank"
          rel="noreferrer"
          underline={false}
        >
          {rowData.name}
        </StyledExternalLink>
      ) : (
        rowData.name
      )}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="email column">
      {rowData.email}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="trading account id column">
      {rowData.tradingAccountId}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="role column">
      {rowData.role === Role.Signal || rowData.role === Role.PreSignal ? (
        <StyledExternalLink
          href={rowData.signalUrl}
          target="_blank"
          rel="noreferrer"
          underline={false}
        >
          {rowData.role}
        </StyledExternalLink>
      ) : (
        rowData.role
      )}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="account number column">
      <StyledExternalLink
        href={rowData.accountLink}
        target="_blank"
        rel="noreferrer"
        underline={false}
      >
        {rowData.accountNumber}
      </StyledExternalLink>
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="server name column">
      {rowData.serverName}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="platform column">
      {rowData.platform}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="account type column">
      {rowData.accountType}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="group column">
      {rowData.group}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="base currency column">
      {rowData.baseCurrency}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="leverage column">
      {rowData.leverage}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="regulation column">
      {rowData.regulation}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="category column">
      {rowData.category}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="opt-in date column">
      {rowData.optInDate}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="opt-out date column">
      {rowData.optOutDate}
    </StyledTableCell>
    <StyledTableCell align="left" aria-label="status column">
      {rowData.status}
    </StyledTableCell>
  </StyledTableRow>
);

export default TableRow;
