import { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import SnapShot from 'components/SnapShot';
import Table from 'components/Table';
import { tHeaderGenerator } from 'utils/common';
import { getWebinarsRequestConfig } from 'api/getV2RequestConfig';
import useHttp from 'hooks/useHttp';
import Spinner from 'components/Spinner';
import { GeneralKeyPair, WebinarResObj } from './type';
import { filterWebinarData, webinarObj } from './index';
import { StyledNoActiveCampaign, StyledWebinarContainer, StyledLoadingContainer } from './style';

// Chn Webinar
const ZhWebinar: React.FC = () => {
  const { t, i18n } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [zhWebinarData, setZhWebinarData] = useState<GeneralKeyPair[]>();

  useEffect(() => {
    const transformZhWebinar = (webinarRes:WebinarResObj): void => {
      const zhFilteredData = filterWebinarData(webinarRes.courses);
      setZhWebinarData(zhFilteredData);
    };
    sendRequest(
      getWebinarsRequestConfig('zh', process.env.REACT_APP_WEBINAR_PLATFORM),
      transformZhWebinar
    );
  }, []);

  return (

    <StyledWebinarContainer>
      <SnapShot
        title={t('recentWebinarZh')}
        link="/training/courses?language=zh"
        className="webinar"
      >
        {zhWebinarData && zhWebinarData?.length > 0 ?
          (
            <Table
              columns={tHeaderGenerator(i18n.language, webinarObj)}
              dataSource={zhWebinarData}
              showTooltip={['webinar']}
            />
          ) :
          (zhWebinarData?.length === 0 &&
            (
            <StyledNoActiveCampaign>
              { t('noRecentWebinar') }
            </StyledNoActiveCampaign>
            )
          )}

        {!zhWebinarData && (
        <StyledLoadingContainer>
          <Spinner />
        </StyledLoadingContainer>
        )}
      </SnapShot>
    </StyledWebinarContainer>

  );
};

export default ZhWebinar;
