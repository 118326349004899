import { SVGProps } from 'react';

const SvgGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.40002 3.49999C2.40002 3.16862 2.66865 2.89999 3.00002 2.89999H4.00002H20H21C21.3314 2.89999 21.6 3.16862 21.6 3.49999C21.6 3.83136 21.3314 4.09999 21 4.09999H20.6V15.5C20.6 15.9243 20.4315 16.3313 20.1314 16.6314C19.8313 16.9314 19.4244 17.1 19 17.1H13.4486L16.4243 20.0757C16.6586 20.31 16.6586 20.6899 16.4243 20.9243C16.19 21.1586 15.8101 21.1586 15.5758 20.9243L12 17.3485L8.42429 20.9243C8.18997 21.1586 7.81007 21.1586 7.57576 20.9243C7.34145 20.6899 7.34145 20.31 7.57576 20.0757L10.5515 17.1H5.00002C4.57568 17.1 4.16871 16.9314 3.86865 16.6314C3.5686 16.3313 3.40002 15.9243 3.40002 15.5V4.09999H3.00002C2.66865 4.09999 2.40002 3.83136 2.40002 3.49999ZM4.60002 4.09999V15.5C4.60002 15.6061 4.64217 15.7078 4.71718 15.7828C4.7922 15.8579 4.89394 15.9 5.00002 15.9H19C19.1061 15.9 19.2079 15.8579 19.2829 15.7828C19.3579 15.7078 19.4 15.6061 19.4 15.5V4.09999H4.60002ZM17.4243 7.07573C17.6586 7.31004 17.6586 7.68994 17.4243 7.92426L13.4243 11.9243C13.19 12.1586 12.8101 12.1586 12.5758 11.9243L10 9.34852L7.42429 11.9243C7.18997 12.1586 6.81007 12.1586 6.57576 11.9243C6.34145 11.6899 6.34145 11.31 6.57576 11.0757L9.57576 8.07573C9.81007 7.84141 10.19 7.84141 10.4243 8.07573L13 10.6515L16.5758 7.07573C16.8101 6.84142 17.19 6.84142 17.4243 7.07573Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGraph;
