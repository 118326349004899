/* eslint-disable indent */
import styled, { keyframes } from 'styled-components';
import { SpinnerProps } from './index';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const StyledSpinnerBox = styled.div`
    position: relative;
    display: inline-block;
    @keyframes loading {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

const sizeGenerator = (size: string): string => {
    switch (size) {
        case 'large':
            return `
               top: 38px;
                left: 80px;
                width: 25px;
                height: 7px;
                `;

        case 'middle':
            return `
                top: 38px;
                left: 48px;
                width: 20px;
                height: 4px;
            `;

        default:
            return `
                top: 38px;
                left: 50px;
                width: 12px;
                height:5px;
            `;
    }
};

const animateGenerator = (num: number): string => {
    let strContainer = '';
    let curRotate = 0;
    const rotateUnit = 45;
    let curDelay = -1.1;
    for (let i = 1; i <= num; i += 1) {
        strContainer += `
            div:nth-child(${i}) {
                transform: rotate(${curRotate}deg);
                animation-delay:${curDelay}s;
            }`;
        curRotate += rotateUnit;
        curDelay += 0.1;
    }
    return strContainer;
};

export const StyledLoadingWrap = styled.div<SpinnerProps>`
        display: inline-block;
        position: relative;

        div {
            position: relative;
            transform-origin: 41px 40px;
            animation: loading 1.2s linear infinite;
            ${(props) => props.size && sizeGenerator(props.size)}
            height:auto;
            top: auto;
            left:auto;
            :after {
                content: '';
                display: block;
                position: absolute;
                 ${(props) => props.size && sizeGenerator(props.size)};
                border-radius: 50%;
                background: ${(props) => (props.bgColor ? props.bgColor : '#989898')};

            }

        }
        ${animateGenerator(9)}
`;

export const StyledSpinnerContainer = styled.div`
    font-size: 14px;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
    border-top: 0.2em solid #767676;
    border-radius: 50%;
    width: 2.28571429rem;
    height: 2.28571429rem;
    animation: ${spin} 0.6s linear infinite;
`;
