import styled from 'styled-components';

export const StyledDashboard = styled.div`
  height: 100%;
  background: ${(props) => props.theme.common.background};
  padding: 32px 32px 0 32px;
  overflow-x: hidden;
  .lp-greetings{
    margin-top: 53px;
  }
`;

export const StyledDashbooardContainer = styled.div`
  max-width:1024px;
  margin: auto;
`;

export const StyledSubtitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #2A2A2A;
  margin: 36px 0 16px 0;
`;

export const StyledShortcutContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px -16px 0 -16px;

  .dashboar-shortcut{
    box-sizing: border-box;
    margin: 16px;
    width: calc(33.3% - 32px);
  }
`;
