/* eslint-disable @typescript-eslint/no-non-null-assertion */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enTranslation } from 'i18n/translations/en';
import { zhTranslation } from 'i18n/translations/zh';

const logixPanelInstance = i18next.createInstance();

logixPanelInstance.use(initReactI18next).init({
  resources: {
    en: enTranslation,
    zh: zhTranslation
  },
  lng: localStorage.getItem('langStorage') ?
    JSON.parse(localStorage.getItem('langStorage')!).state.lang : 'en',
  fallbackLng: 'en',
  debug: false,

  interpolation: {
    escapeValue: false
  }
});

export default logixPanelInstance;
