import common from './common.json';
import sideMenu from './sideMenu.json';
import dashboard from './dashboard.json';
import mamAccounts from './mamAccounts.json';
import tradingCupAccounts from './tradingCupAccounts.json';
import bonusSettings from './bonusSettings.json';
import tradingCupSubscription from './tradingCupSubscription.json';
import salesRevenue from './salesRevenue.json';

export const zhTranslation = {
  common,
  sideMenu,
  dashboard,
  mamAccounts,
  tradingCupAccounts,
  bonusSettings,
  tradingCupSubscription,
  salesRevenue
};
