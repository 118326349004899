import * as React from "react";
import { SVGProps } from "react";

const SvgSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 1.5a1.5 1.5 0 1 1 3 0v11a1.5 1.5 0 1 1-3 0v-11Zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237c.363.02.726.049 1.088.085 2.053.204 4.038.668 5.912 1.56V2.224Zm-8 7.841V3.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 6v2c0 1.106.896 1.996 1.994 2.009A68.346 68.346 0 0 1 4 10.065Zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48c.564.014 1.128.035 1.692.064.327.017.65.037.966.06Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgSpeaker;
