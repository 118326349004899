import styled, { css } from 'styled-components';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';

type StyledDropdownButtonProps = {
  $width?: `${number}px`;
  $isGreyOut: boolean;
  $isDisabled: boolean;
} & ButtonProps;

export const StyledDropdownButton = styled(Button)<StyledDropdownButtonProps>`
  ${({
    theme, $width, $isGreyOut, $isDisabled
  }) => css`
    text-transform: initial;
    padding: ${theme.spacing.s1} ${theme.spacing.s3} ${theme.spacing.s1}
      ${theme.spacing.s2};
    background-color: transparent !important;
    ${theme.typography.Body2};
    color: ${$isGreyOut ? theme.palette.N400 : theme.palette.N200};

    ${$width &&
    `
        width: ${$width};
  
        .MuiButton-endIcon {
          margin-left: auto;
        }
      `}

    ${$isDisabled &&
    `
        color: ${theme.palette.N400};
        pointer-events: none;
        cursor: default;
  
        .MuiButton-endIcon {
          visibility: hidden;
        }
      `}
  `}
`;

export const StyledButtonPrefix = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.s2};
  `}
`;

type StyledDropdownMenuProps = {
  $width?: `${number}px`;
} & MenuProps;

export const StyledDropdownMenu = styled(Menu)<StyledDropdownMenuProps>`
  ${({ theme, $width }) => css`
    .MuiPaper-root {
      ${$width && `width: ${$width}`};
      box-shadow: 0px 0px 20px rgba(223, 223, 223, 0.8);
    }

    .MuiMenuItem-root {
      ${theme.typography.Body2};
      color: ${theme.palette.N200};
      &.disabled {
        color: ${theme.palette.N400};
        pointer-events: none;
      }
    }
  `}
`;
