import styled, { css } from 'styled-components';

export const StyledForm = styled.div`
    ${({ theme }) => css`
    margin-top: ${theme.spacing.s1};
    padding: ${theme.spacing.s1} ${theme.spacing.s2};
    gap: ${theme.spacing.s4};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 140px;
    margin-bottom: ${theme.spacing.s9};
  `}
`;
export const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 24px) / 2);
  text-align: left;
  align-items: flex-start;
`;
export const StyledLabel = styled.label`
    ${({ theme }) => css`
    width: ${theme.spacing.s24};
    display: flex;
    margin-bottom: ${theme.spacing.s1};
    text-transform: capitalize;
    font-weight: 600;
    width: 100%;
  `}
`;
export const StyledInput = styled.input`
   ${({ theme }) => css`
    width: 100%;
    height: ${theme.spacing.s12};
    padding: ${theme.spacing.s3} ${theme.spacing.s4};
    border-radius: ${theme.spacing.s2};
    border: 1px solid var(--neutral-n-400, #BBB);
  `}
`;
export const StyledSelectContainer = styled.div`
  ${({ theme }) => css`
  display: flex;
  width: 100%;
  position: relative;
    svg {
      position: absolute;
      right: ${theme.spacing.s4};
      top: 28%;
      }
  `}
`;

export const StyledSelect = styled.select`
 ${({ theme }) => css`
    width: 100%;
    height: ${theme.spacing.s12};
    padding: ${theme.spacing.s3} ${theme.spacing.s4};
    border-radius: ${theme.spacing.s2};
    border: 1px solid var(--neutral-n-400, #BBB);
    position: relative;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  `}
`;

export const StyledButtonContainer = styled.div`
 ${({ theme }) => css`
    display: flex;
    position: absolute;
    right: ${theme.spacing.s8};
    top: 260px;
  `}
`;
export const StyledInputDisabled = styled(StyledInput)`
  color: gray;
  cursor: not-allowed;
`;

export const StyledInfoRow = styled.div`
  width: 100%;
  display: flex;
  
`;
export const StyledInfoColumn = styled.div`
  width: 45%;
`;
export const StyledError = styled.p`
  color: red;
`;
