import { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import { tHeaderGenerator } from 'utils/common';
import SnapShot from 'components/SnapShot';
import Table from 'components/Table';
import cx from 'classnames';
import { getCampaignsRequestConfig } from 'api/getV2RequestConfig';
import Spinner from 'components/Spinner';
import {
  FilteredCampaignGroup, CampaignData
} from './type';
import { filterCampaignData, campaignObj } from './index';
import { StyledNoActiveCampaign, StyledLoadingContainer } from './style';

// client Application
const EnCampaign: React.FC = () => {
  const { t, i18n } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [enCampaignData, setEnCampaignData] = useState<FilteredCampaignGroup>();

  useEffect(() => {
    const transformEnCampaignData = (campaignRes:CampaignData): void => {
      const enFilteredData = filterCampaignData(campaignRes.data);
      setEnCampaignData(enFilteredData);
    };
    sendRequest(
      getCampaignsRequestConfig('unexpired', 'english'),
      transformEnCampaignData
    );
  }, []);

  return (

    <SnapShot
      title={t('activeCampaignsEng')}
      link="/campaigns "
      className="campaign"
      note={
       enCampaignData && enCampaignData?.expiringSoonNum > 0 ?
         (
           <div className={cx({ expiringSoon: !!enCampaignData?.expiringSoonNum })}>
             { `${enCampaignData?.expiringSoonNum} `}
             {t('expiringCampaign')}
           </div>
         ) : null
}
    >
      {enCampaignData && enCampaignData?.filteredCampaignData.length > 0 ?
        (
          <Table
            columns={tHeaderGenerator(i18n.language, campaignObj)}
            dataSource={enCampaignData.filteredCampaignData}
            showTooltip={['campaign']}
          />
        ) :
        (enCampaignData?.filteredCampaignData.length === 0 &&
          (
          <StyledNoActiveCampaign>
            { t('noActiveCampaign') }
          </StyledNoActiveCampaign>
          )
        )}
      { !enCampaignData && (
      <StyledLoadingContainer>
        <Spinner />
      </StyledLoadingContainer>
      )}
    </SnapShot>

  );
};

export default EnCampaign;
