/* eslint-disable max-len */
import {
  StyledCellDropdownSelect,
  StyledExternalLink,
  StyledTableCell,
  StyledTableRow
} from 'components/DataTable/style';
import useTranslationUtils, { Status } from 'pages/TradingCupSubscription/typesUtils';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { SubscriptionRowData } from '../../hooks/useTradingCupSubscriptionData';

export type TableRowProps = {
  rowData: SubscriptionRowData;
  onActionChange: (value: SubscriptionRowData) => void;
};

const Capitalized = styled.span`
  text-transform: capitalize;
`;

const TableRow = ({
  rowData,
  onActionChange
}: TableRowProps): JSX.Element => {
  const { statusFilterOptions } = useTranslationUtils();

  return (
    <StyledTableRow>
      <StyledTableCell $width="68px" aria-label="transaction column" align="center">
        {rowData.transactionId}
      </StyledTableCell>
      <StyledTableCell
        $width="82px"
        align="center"
        aria-label="order column"
      >
        {rowData.orderId}
      </StyledTableCell>
      <StyledTableCell
        $width="165px"
        align="left"
        aria-label="charge date column"
      >
        {rowData.chargeTime}
      </StyledTableCell>
      <StyledTableCell
        $width="137px"
        align="left"
        aria-label="trading cup id column"
        $boxShadowPosition="right"
      >
        {rowData.tradingCupId}
      </StyledTableCell>
      <StyledTableCell
        align="left"
        aria-label="name column"
      >
        {rowData.nameLink ? (
          <StyledExternalLink
            href={rowData.nameLink}
            target="_blank"
            rel="noreferrer"
            underline={false}
          >
            {rowData.name}
          </StyledExternalLink>
        ) : (
          rowData.name
        )}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="email column">
        {rowData.email}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="role column">
        <Capitalized>
          {rowData.role}
        </Capitalized>
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="mtId column">
        <StyledExternalLink
          href={rowData.metaTraderIdLink}
          target="_blank"
          rel="noreferrer"
          underline={false}
        >
          {rowData.metaTraderId}
        </StyledExternalLink>
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="server column">
        {rowData.serverName}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="platform column">
        {rowData.platform}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="subscription fee column">
        {rowData.subscriptionFee}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="commission column">
        {rowData.commission}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="commission charge column">
        {rowData.commissionCharge}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="actual charge column">
        {rowData.actualCharge}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="base currency column">
        {rowData.baseCurrency}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="actual exchange rate column">
        {rowData.actualExchangeRate}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="settled exchange rate column">
        {rowData.settledExchangeRate}
      </StyledTableCell>
      <StyledTableCell align="left" aria-label="exchange charge column">
        {rowData.exchangeCharge}
      </StyledTableCell>
      <StyledTableCell
        $width="140px"
        aria-label="status column"
      >
        <StyledCellDropdownSelect
          selectedValue={rowData.status}
          options={statusFilterOptions.map((o) => ({ ...o, disabled: o.value === Status.AutoApproved }))}
          onSelect={(value) => {
            if (value !== rowData.status) {
              onActionChange({ ...rowData, status: value });
            }
          }}
        />
      </StyledTableCell>
      <Tooltip title={rowData.admin} arrow>
        <StyledTableCell
          $width="77px"
          align="left"
          aria-label="admin column"
          $shouldEllipsis
        >
          {rowData.admin}
        </StyledTableCell>
      </Tooltip>
      <Tooltip title={rowData.comments} arrow>
        <StyledTableCell
          $width="157px"
          $shouldEllipsis
          align="left"
          aria-label="comments column"
        >
          {rowData.comments}
        </StyledTableCell>
      </Tooltip>
    </StyledTableRow>
  );
};

export default TableRow;
