import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import { useEffect, useState } from 'react';
import Reminder from 'components/Reminder';
import Greetings from 'components/Greetings';

import { useAuth } from 'store/context/hooks';
import { toggleReminder } from 'utils/common';
import dayjs from 'dayjs';
import { GreetingType } from 'type/common';
import ShortcutContainer from 'container/ShortcutContainer';
import SnapshotContainer from 'container/SnapshotContainer';
import useHttp from 'hooks/useHttp';
import { getDashboardListRequestConfig } from 'api/getV1RequestConfig';

import {
  StyledDashboard, StyledSubtitle, StyledDashbooardContainer,
  StyledShortcutContainer
} from './style';

interface PageDashboardProps {
  title?: string;
}

type GreeingValType = {
  name: string;
  company:string;
}

type DashbaordListType = {
  shortcuts: {
    ariaLabel: string,
    name: string,
    notificationID: string,
    url: string
  }[];
  snapshots: string[];
}

const PageDashboard: React.FC<PageDashboardProps> = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const [showReminder, setShowReminder] = useState(true);
  const [dashboardList, setDashboardList] = useState<null | DashbaordListType>(null);
  const { isLogin, userInfo } = useAuth();
  const currentHr = Number(dayjs().format('HH'));
  const endDate = dayjs('2022-08-17 12:00');
  const { sendRequest } = useHttp();

  const greetingVal:GreeingValType = {
    name: userInfo?.name as string,
    company: userInfo?.companyName as string
  };

  const setGreetingTime = (currentHour:number):string => {
    let greetingType = 'morning' as keyof GreetingType;
    if (currentHour > 17) {
      greetingType = 'evening';
    } else if (currentHour > 11) {
      greetingType = 'afternoon';
    }
    return greetingType;
  };

  const greetingTime = t(`greetingTime.${setGreetingTime(currentHr)}`);

  const handleDeleteReminder = (): void => {
    setShowReminder(false);
    toggleReminder(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem('reminder') === 'closed') {
      setShowReminder(false);
    }
  }, [isLogin]);

  useEffect(() => {
    const isAfterEndDate = dayjs().isAfter(endDate);
    if (isAfterEndDate) {
      setShowReminder(false);
    }
  }, []);

  useEffect(() => {
    const transformData = (res: DashbaordListType):void => {
      if (res) setDashboardList(res);
    };
    sendRequest(
      getDashboardListRequestConfig(),
      transformData
    );
  }, []);

  return (
    <StyledDashboard>
      <StyledDashbooardContainer>
        {showReminder &&
        <Reminder message={t('reminder')} onDelete={handleDeleteReminder} />}

        {userInfo?.headLogo &&
        (
        <Greetings
          avatarSrc={userInfo?.headLogo}
          title={t('title', { time: greetingTime, name: greetingVal.name })}
          message={t('message', { company: greetingVal.company })}
        />
        )}
        {
          dashboardList && (
            <>
              {dashboardList.shortcuts && (
                <>
                  <StyledSubtitle>
                    {t('shortcuts')}
                  </StyledSubtitle>
                  <StyledShortcutContainer>
                    {
                      dashboardList.shortcuts
                        .map((item) => (<ShortcutContainer key={item.name} info={item} />))
                    }
                  </StyledShortcutContainer>
                </>
              )}
              {dashboardList.snapshots && (
                <>
                  <StyledSubtitle>
                    {t('weeklySnapshots')}
                  </StyledSubtitle>

                  <SnapshotContainer list={dashboardList.snapshots} />
                </>
              )}
            </>
          )
        }

      </StyledDashbooardContainer>
    </StyledDashboard>
  );
};

export default PageDashboard;
