import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import DropdownSelect from 'components/DropdownSelect';
import { OptionType } from 'type/common';
import { StyledRowsPerPageController } from './style';

const defaultPerPageOptions = [
  {
    value: 10,
    name: '10'
  },
  {
    value: 15,
    name: '15'
  },
  {
    value: 25,
    name: '25'
  },
  {
    value: 50,
    name: '50'
  },
  {
    value: 100,
    name: '100'
  }
];

type RowsPerPageSelectProps = {
  count: number;
  customOptions?: OptionType[];
  onSelect: (value: number) => void;
};

const RowsPerPageSelect = ({
  count,
  customOptions = defaultPerPageOptions,
  onSelect
}: RowsPerPageSelectProps): JSX.Element => {
  const { t } = useTranslation('common', {
    i18n: logixPanelInstance
  });

  return (
    <StyledRowsPerPageController>
      {t('rowsPerPage')}
      :
      <DropdownSelect
        selectedValue={count}
        options={customOptions}
        onSelect={onSelect}
      />
    </StyledRowsPerPageController>
  );
};

export default RowsPerPageSelect;
