import IconComponent from 'components/Icons';
import { StyledReminder, StyledDeleteIcon, StyledMessageWrap } from './style';

export interface ReminderProps {
  onDelete?: ()=>void;
  message?: string;
}

const Reminder: React.FC<ReminderProps> = (props) => {
  const { message, onDelete, ...others } = props;

  return (
    <StyledReminder {...others}>
      <StyledMessageWrap>
        <IconComponent name="Exclamation" />
        <div aria-label="reminder message">
          {message}
        </div>
      </StyledMessageWrap>

      <StyledDeleteIcon aria-label="close reminder" onClick={() => onDelete && onDelete()}>
        <IconComponent name="Close" />
      </StyledDeleteIcon>
    </StyledReminder>
  );
};

Reminder.defaultProps = {
  message: `Introducing the new LogixPanel look and feel.
   Keep an eye out for updates across the platform in the future.`
};

export default Reminder;
