/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback, useEffect, useState, useRef, Children
} from 'react';
import cx from 'classnames';
import { CommonProps } from 'type/component';
import {
  StyledItem,
  StyledMenuList,
  StyledTitle,
  StyledDropDown
} from './style';

export interface DropDownProps extends CommonProps {
  disabled?: boolean;
  menu?: { title: string; href: string }[];
  customMenu?: React.ReactNode[];
  ifOpenMenu?: boolean;
  ulRef?: React.MutableRefObject<HTMLUListElement>;
  onChangeState?: (state: boolean) => void;
}

const ClickOutside = (ref: any, callback: () => void):void => {
  const handleClickOutside = useCallback(
    (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    },
    [callback, ref]
  );
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
};

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    menu, disabled = false, children, customMenu = null,
    ifOpenMenu, ulRef, className, onChangeState
  } = props;

  const [show, setShow] = useState(false);
  const [top, setTop] = useState(25);

  const dropdownRef = useRef(null);
  const titleRef = useRef<any>(null);

  ClickOutside(dropdownRef, () => setShow(false));

  useEffect(() => {
    const handleSetTop = ():void => {
      setTop(titleRef.current.getBoundingClientRect().height);
    };
    window.addEventListener('load', handleSetTop);
    return () => {
      window.removeEventListener('load', handleSetTop);
    };
  }, []);

  useEffect(() => {
    setTop(titleRef.current.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    if (ifOpenMenu) {
      setShow(ifOpenMenu);
    }
  }, [ifOpenMenu]);

  useEffect(() => {
    if (onChangeState) {
      onChangeState(show);
    }
  }, [show, onChangeState]);

  const renderMenu = ():React.ReactNode => {
    if (customMenu) {
      return Children.map(customMenu, (child) => (
        <StyledItem className="item" key="item_CustomChild">
          {child}
        </StyledItem>
      ));
    }
    return menu?.map((item: { title: string; href: string }) => (
      <StyledItem className="item" key={`item_${item.title}`}>
        <a href={item.href}>{item.title}</a>
      </StyledItem>
    ));
  };

  return (
    <StyledDropDown
      className={cx('dropDown', 'lp-dropdown', className)}
      ref={dropdownRef}
      onClick={() => !disabled && setShow(!show)}
      {...props}
    >
      <StyledTitle className="title" ref={titleRef} disabled={disabled}>
        {children}
      </StyledTitle>
      <StyledMenuList
        data-testid="lp-dropdownUl"
        aria-label="dropdown list"
        className="menu-list"
        show={show}
        top={top}
        ref={ulRef}
      >
        {renderMenu()}
      </StyledMenuList>
    </StyledDropDown>
  );
};

DropDown.defaultProps = {
  disabled: false,
  menu: [
    {
      title: 'Test01',
      href: '#'
    }
  ]
};

export default DropDown;
