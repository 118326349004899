import React, { useState, useMemo, useCallback } from 'react';
import { getCookie, deleteCookie } from 'utils/getCookie';
import axios from 'api/axios';
import { AuthContextType, userInfoType } from '../types';

export const AuthContext = React.createContext<AuthContextType>({
  isLogin: false,
  userInfo: {
    isFirstLogin: true,
    name: '',
    companyName: ''
  },
  onLogout: () => undefined,
  onLogin: () => undefined
});

const AuthContextProvider:React.FC = (props) => {
  const { children } = props;

  const [userInfo, setUserInfo] = useState<userInfoType >({
    isFirstLogin: false,
    name: '',
    companyName: ''
  });
  const [isLogin, setIsLogin] = useState<boolean>(false);

  const onLogin = useCallback((info:userInfoType):void => {
    setUserInfo(info);
    setIsLogin(true);
  }, []);

  const onLogout = ():void => {
    setUserInfo({
      isFirstLogin: false,
      name: '',
      companyName: ''
    });
    setIsLogin(false);
  };

  const contextValue:AuthContextType = useMemo(() => ({
    isLogin,
    userInfo,
    onLogin,
    onLogout
  }), [isLogin, userInfo, onLogin]);

  const checkTokenHandler = ():void => {
    const cookie = getCookie('jwt-token');
    if (cookie) {
      localStorage.setItem('jwt-token', cookie);
      if (process.env.REACT_APP_DELETE_JWT_COOKIE_FEATURE === 'active') {
        deleteCookie('jwt-token', process.env.REACT_APP_COOKIE_DOMAIN);
      }
    }
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('jwt-token')}`;
  };

  checkTokenHandler();

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
