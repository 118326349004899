import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  // Wait for designer to decide global layout


  * {
    box-sizing: border-box;
    outline:none;
    font-family: Noto Sans, 'Noto Sans SC', 'Poppins', sans-serif;
  }

  body {
    padding: 0;
    margin: 0;
  }

  a {
    cursor: pointer;
  }

`;
