import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import {
  StyledPageContainer,
  StyledPageTitle
} from 'components/PageLayout/style';
import Pagination from 'components/Pagination';
import RowsPerPageSelect from 'components/RowsPerPageSelect';
import SearchBar from 'components/FilterAndSearchBox/components/SearchBar';
import FilterAndSearchBox from 'components/FilterAndSearchBox';
import {
  StyledButton,
  StyledOutlineDropdownSelect
} from 'components/FilterAndSearchBox/style';
import DateRangePicker from 'components/DateRangePicker';
import FilterField from 'components/FilterAndSearchBox/components/FilterField';
import logixPanelInstance from 'i18n';
import useTradingCupAccountsData, {
  FilterData,
  Role,
  Status
} from 'pages/TradingCupAccounts/hooks/useTradingCupAccountsData';
import TableRow from './components/TableRow';
import { StyledFilterContainer } from './style';
import TableHeadRow from './components/TableHeadRow';

const TradingCupAccounts = (): JSX.Element => {
  const { t, i18n } = useTranslation('tradingCupAccounts', {
    i18n: logixPanelInstance
  });
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterData, setFilterData] = useState<FilterData>({
    role: 'all',
    status: 'all',
    dateRange: { start: null, end: null }
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number>(100);

  const roleFilterOptions = [
    {
      value: 'all',
      name: t('all')
    },
    {
      value: Role.Copier,
      name: t('copier')
    },
    {
      value: Role.Signal,
      name: t('signal')
    }
  ];

  const statusFilterOptions = [
    {
      value: 'all',
      name: t('all')
    },
    {
      value: Status.OptIn,
      name: t('optIn')
    },
    {
      value: Status.OptOut,
      name: t('optOut')
    }
  ];

  const { rows, totalPageCount, fetchData } = useTradingCupAccountsData(
    filterData,
    searchInput,
    currentPage,
    currentRowsPerPage,
    i18n.language
  );

  const tableRows = useMemo(
    () => rows.map((row) => <TableRow key={row.id} rowData={row} />),
    [rows]
  );

  return (
    <StyledPageContainer>
      <StyledPageTitle>{t('pageTitle')}</StyledPageTitle>
      <FilterAndSearchBox
        filterSlot={(
          <StyledFilterContainer>
            <FilterField label={t('role')}>
              <StyledOutlineDropdownSelect
                selectedValue={filterData.role}
                options={roleFilterOptions}
                onSelect={(value) => setFilterData((prevState) => ({ ...prevState, role: value }))}
                width="100px"
              />
            </FilterField>
            <FilterField label={t('status')}>
              <StyledOutlineDropdownSelect
                selectedValue={filterData.status}
                options={statusFilterOptions}
                onSelect={(value) => setFilterData((prevState) => ({
                  ...prevState,
                  status: value
                }))}
                width="100px"
              />
            </FilterField>
            <FilterField label={t('timeRange')}>
              <DateRangePicker
                onRangeChange={(value) => setFilterData((prevState) => ({
                  ...prevState,
                  dateRange: { start: value.startDate, end: value.endDate }
                }))}
              />
            </FilterField>
            <StyledButton onClick={fetchData}>{t('applyFilter')}</StyledButton>
          </StyledFilterContainer>
        )}
        searchSlot={(
          <SearchBar
            placeholder={t('searchPlaceholder')}
            onSearch={(value) => setSearchInput(value)}
          />
        )}
      />

      <DataTable
        tableHeadSlot={<TableHeadRow />}
        tableBodySlot={tableRows}
        tableFooterSlot={(
          <>
            <Pagination
              total={totalPageCount}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
            />
            <RowsPerPageSelect
              count={currentRowsPerPage}
              onSelect={(value) => setCurrentRowsPerPage(value)}
            />
          </>
        )}
      />
    </StyledPageContainer>
  );
};

export default TradingCupAccounts;
