import * as React from "react";
import { SVGProps } from "react";

const SvgActiveCampaigns = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m51.912 15.738 2.54 1.268-3.34 7.888-.712 8.084a.734.734 0 0 1-.409.594l-3.238 1.587-1.188 2.81-2.543-1.272-.081-.046a.664.664 0 0 1-.29-.611.156.156 0 0 1 .038-.09 4.056 4.056 0 0 0 1.141-2.216 1.16 1.16 0 0 0-.333-1.02.535.535 0 0 1-.087-.718 3.598 3.598 0 0 0 .719-1.706 1.16 1.16 0 0 0-.33-1.023.533.533 0 0 1-.088-.718 3.575 3.575 0 0 0 .719-1.706 1.16 1.16 0 0 0-.333-1.02.535.535 0 0 1-.087-.718 3.577 3.577 0 0 0 .721-1.706c.05-.58-.156-.973-.536-1.159a.539.539 0 0 1-.29-.518c.058-.733.183-2.161.183-2.161l7.82-3.83.004.007Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m51.912 15.738 2.54 1.268-3.34 7.888-.712 8.084a.734.734 0 0 1-.409.594l-3.238 1.587-1.188 2.81-2.543-1.272-.081-.046a.664.664 0 0 1-.29-.611.156.156 0 0 1 .038-.09 4.056 4.056 0 0 0 1.141-2.216 1.16 1.16 0 0 0-.333-1.02.535.535 0 0 1-.087-.718 3.598 3.598 0 0 0 .719-1.706 1.16 1.16 0 0 0-.33-1.023.533.533 0 0 1-.088-.718 3.575 3.575 0 0 0 .719-1.706 1.16 1.16 0 0 0-.333-1.02.535.535 0 0 1-.087-.718 3.577 3.577 0 0 0 .721-1.706c.05-.58-.156-.973-.536-1.159a.539.539 0 0 1-.29-.518c.058-.733.183-2.161.183-2.161l7.82-3.83.004.007Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43.516 32.723 3.423 1.712-.133.704-.046.02-.128.307-2.85-1.396c.028-.11.047-.223.058-.336a1.158 1.158 0 0 0-.324-1.011Zm.382-3.366 3.641 1.822v1.002l-3.42-1.709c0-.058.017-.116.02-.17a1.231 1.231 0 0 0-.241-.933v-.012Zm.29-3.444 3.623 1.81v1.003l-3.403-1.7c0-.061.017-.122.023-.183a1.21 1.21 0 0 0-.243-.93Zm.118-3.606 4.563 2.282v1.904L44.482 24.3c.131-.285.216-.59.25-.901a1.107 1.107 0 0 0-.427-1.092Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43.516 32.723 3.423 1.712-.133.704-.046.02-.128.307-2.85-1.396c.028-.11.047-.223.058-.336a1.158 1.158 0 0 0-.324-1.011Zm.382-3.366 3.641 1.822v1.002l-3.42-1.709c0-.058.017-.116.02-.17a1.231 1.231 0 0 0-.241-.933v-.012Zm.29-3.444 3.623 1.81v1.003l-3.403-1.7c0-.061.017-.122.023-.183a1.21 1.21 0 0 0-.243-.93Zm.118-3.606 4.563 2.282v1.904L44.482 24.3c.131-.285.216-.59.25-.901a1.107 1.107 0 0 0-.427-1.092Z"
      fill="#000"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.456 17.006 52.953 34.25a.733.733 0 0 1-.408.594l-6.373 3.129a.678.678 0 0 1-.675-.05.672.672 0 0 1-.29-.61.162.162 0 0 1 .041-.09c.632-.681 1.078-1.504 1.138-2.217a1.159 1.159 0 0 0-.33-1.02.539.539 0 0 1-.09-.717 3.577 3.577 0 0 0 .722-1.707 1.158 1.158 0 0 0-.334-1.022.542.542 0 0 1-.086-.718c.383-.493.63-1.077.715-1.695a1.158 1.158 0 0 0-.333-1.02.536.536 0 0 1-.087-.718 3.577 3.577 0 0 0 .721-1.706c.05-.58-.156-.973-.535-1.159a.533.533 0 0 1-.29-.518c.058-.733.182-2.16.182-2.16l7.821-3.833-.006-.006Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.456 17.006 52.953 34.25a.733.733 0 0 1-.408.594l-6.373 3.129a.678.678 0 0 1-.675-.05.672.672 0 0 1-.29-.61.162.162 0 0 1 .041-.09c.632-.681 1.078-1.504 1.138-2.217a1.159 1.159 0 0 0-.33-1.02.539.539 0 0 1-.09-.717 3.577 3.577 0 0 0 .722-1.707 1.158 1.158 0 0 0-.334-1.022.542.542 0 0 1-.086-.718c.383-.493.63-1.077.715-1.695a1.158 1.158 0 0 0-.333-1.02.536.536 0 0 1-.087-.718 3.577 3.577 0 0 0 .721-1.706c.05-.58-.156-.973-.535-1.159a.533.533 0 0 1-.29-.518c.058-.733.182-2.16.182-2.16l7.821-3.833-.006-.006Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9 28.08c-.389-.191-.705.055-.705.553a1.5 1.5 0 0 0 .704 1.251c.385.194.701-.052.701-.55a1.51 1.51 0 0 0-.7-1.254Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9 28.08c-.389-.191-.705.055-.705.553a1.5 1.5 0 0 0 .704 1.251c.385.194.701-.052.701-.55a1.51 1.51 0 0 0-.7-1.254Z"
      fill="#000"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43.45 28.057 1.676-.837a.475.475 0 0 1 .429.026 1.5 1.5 0 0 1 .701 1.254.605.605 0 0 1-.27.58l-1.668.83a.602.602 0 0 0 .273-.579 1.51 1.51 0 0 0-.702-1.254.48.48 0 0 0-.437-.023l-.003.003Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43.45 28.057 1.676-.837a.475.475 0 0 1 .429.026 1.5 1.5 0 0 1 .701 1.254.605.605 0 0 1-.27.58l-1.668.83a.602.602 0 0 0 .273-.579 1.51 1.51 0 0 0-.702-1.254.48.48 0 0 0-.437-.023l-.003.003Z"
      fill="#000"
      fillOpacity={0.6}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.09 20.885c.073-.835-.39-1.257-1.037-.942l-2.317 1.144c-.643.316-1.225 1.252-1.298 2.086l-1.005 11.326c-.072.834.391 1.257 1.034.941l2.335-1.144c.643-.316 1.225-1.251 1.298-2.086l.99-11.325ZM44.863 5.185C41.627 3.566 39 5.631 39 9.794c0 4.162 2.627 8.852 5.863 10.47 3.235 1.62 5.865-.445 5.865-4.608 0-4.162-2.627-8.855-5.865-10.47Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.863 5.185C41.627 3.566 39 5.631 39 9.794c0 4.162 2.627 8.852 5.863 10.47 3.235 1.62 5.865-.445 5.865-4.608 0-4.162-2.627-8.855-5.865-10.47Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.087 5.07c1.02-.598 2.337-.606 3.765.115 3.239 1.616 5.866 6.309 5.866 10.471 0 2.529-.968 4.281-2.453 4.907 1.277-.744 2.088-2.41 2.088-4.724 0-4.16-2.627-8.852-5.866-10.471-1.271-.638-2.447-.704-3.409-.29l.009-.009Z"
      fill="#FEF0F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m48.46 20.48 9.269-4.636c1.379-.689 2.268-2.4 2.268-4.82 0-4.162-2.627-8.854-5.866-10.473-1.355-.675-2.607-.71-3.594-.212l-9.27 4.635c.994-.496 2.242-.464 3.595.214 3.239 1.616 5.866 6.309 5.866 10.471 0 2.422-.886 4.13-2.268 4.823v-.003Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.705 7.143a2.535 2.535 0 0 1 1.596.252c2.155 1.029 4.33 4.403 4.33 7.636 0 1.662-.463 2.943-1.205 3.655-7.884 5.362-12.165 12.374-16.455 21.264L15.176 7.195C25.65 9.327 33.78 9.582 42.693 7.143h.012Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.705 7.143a2.535 2.535 0 0 1 1.596.252c2.155 1.029 4.33 4.403 4.33 7.636 0 1.662-.463 2.943-1.205 3.655-7.884 5.362-12.165 12.374-16.455 21.264L15.176 7.195C25.65 9.327 33.78 9.582 42.693 7.143h.012Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.053 19.378 15.176 7.195C25.65 9.327 33.78 9.582 42.693 7.143a2.534 2.534 0 0 1 1.596.252c1.211.58 2.428 1.898 3.262 3.514-3.647 3.803-9.136 6.873-15.427 8.209a28.412 28.412 0 0 1-11.083.26h.012Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.053 19.378 15.176 7.195C25.65 9.327 33.78 9.582 42.693 7.143a2.534 2.534 0 0 1 1.596.252c1.211.58 2.428 1.898 3.262 3.514-3.647 3.803-9.136 6.873-15.427 8.209a28.412 28.412 0 0 1-11.083.26h.012Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.12 7.948C6.775 3.778 0 9.108 0 19.825s6.775 22.828 15.12 26.999c8.345 4.17 15.118-1.16 15.118-11.876 0-10.718-6.776-22.828-15.118-27Z"
      fill="#A93C41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.12 7.948C6.775 3.778 0 9.108 0 19.825s6.775 22.828 15.12 26.999c8.345 4.17 15.118-1.16 15.118-11.876 0-10.718-6.776-22.828-15.118-27Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.12 7.948C6.775 3.778 0 9.108 0 19.825s6.775 22.828 15.12 26.999c8.345 4.17 15.118-1.16 15.118-11.876 0-10.718-6.776-22.828-15.118-27Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.754 7.447 2.288-1.141c2.56-1.283 5.779-1.2 9.27.547 8.341 4.183 15.131 16.27 15.131 27 0 6.239-2.291 10.647-5.848 12.429l-2.19 1.092c3.56-1.779 5.848-6.187 5.848-12.427 0-10.717-6.775-22.828-15.117-26.999-3.537-1.77-6.793-1.83-9.368-.5h-.014Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.642 22.217c-2.109-1.051-3.818.29-3.818 3.004s1.71 5.767 3.818 6.821c2.109 1.055 3.82-.29 3.82-3.003 0-2.715-1.72-5.768-3.82-6.822Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.642 22.217c-2.109-1.051-3.818.29-3.818 3.004s1.71 5.767 3.818 6.821c2.109 1.055 3.82-.29 3.82-3.003 0-2.715-1.72-5.768-3.82-6.822Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.563 23.691c-1.489-.744-2.7.206-2.7 2.123 0 1.918 1.211 4.079 2.7 4.823 1.489.745 2.702-.205 2.702-2.123 0-1.917-1.21-4.075-2.702-4.823Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.563 23.691c-1.489-.744-2.7.206-2.7 2.123 0 1.918 1.211 4.079 2.7 4.823 1.489.745 2.702-.205 2.702-2.123 0-1.917-1.21-4.075-2.702-4.823Z"
      fill="#000"
      fillOpacity={0.6}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.854 15.89a36.09 36.09 0 0 1 5.379 11.567l-11.256 4.724c.898-.452 1.477-1.564 1.477-3.142 0-2.709-1.712-5.768-3.82-6.822-.87-.44-1.695-.46-2.341-.136l10.56-6.19Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.12 7.948C6.775 3.778 0 9.108 0 19.825s6.775 22.828 15.12 26.999c8.345 4.17 15.118-1.16 15.118-11.876 0-10.718-6.776-22.828-15.118-27Zm0 3.187c-6.972-3.476-12.632.961-12.632 9.923s5.66 19.075 12.632 22.56c6.972 3.484 12.632-.963 12.632-9.928 0-8.964-5.663-19.07-12.632-22.555Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.12 7.948C6.775 3.778 0 9.108 0 19.825s6.775 22.828 15.12 26.999c8.345 4.17 15.118-1.16 15.118-11.876 0-10.718-6.776-22.828-15.118-27Zm0 3.187c-6.972-3.476-12.632.961-12.632 9.923s5.66 19.075 12.632 22.56c6.972 3.484 12.632-.963 12.632-9.928 0-8.964-5.663-19.07-12.632-22.555Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.546 7.56c2.607-1.448 5.94-1.425 9.559.391 8.342 4.171 15.117 16.27 15.117 27 0 6.415-2.422 10.896-6.155 12.574 3.386-1.875 5.55-6.21 5.55-12.27 0-10.718-6.776-22.829-15.12-27-3.355-1.677-6.454-1.822-8.965-.695h.014Zm1.526 3.29c-2.8 1.58-4.585 5.188-4.585 10.223 0 8.965 5.66 19.074 12.632 22.559 2.778 1.39 5.35 1.517 7.439.602-2.187 1.231-4.991 1.228-8.047-.29C7.539 40.468 1.879 30.351 1.879 21.386c0-5.388 2.048-9.145 5.193-10.527v-.008Z"
      fill="#FEF0F0"
    />
  </svg>
);

export default SvgActiveCampaigns;
