import * as React from "react";
import { SVGProps } from "react";

const SvgNewQualifiedTraders = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.451 33.237c.413.109.9.035 1.398-.225.553-.289 1.039-.058 1.142.543.103.605.387 1.055.796 1.29l-3.044-1.452a1.353 1.353 0 0 1-.292-.156ZM15.443 1.61c.299-.083.562-.253.755-.486.696-.772 1.527-1.182 2.237-1.117.175.016.345.065.5.144l3.21 1.524-2.015 2.029-.962-.789c-.428.325-.887.356-1.159.046a1.398 1.398 0 0 0-.363-.295l-.413.52-1.446-.358c-.704.411-1.354 1.153-1.777 2.042-.33.69-.934 1.143-1.424 1.067-.697-.107-1.527.271-2.237 1.018a5.288 5.288 0 0 0-1.362 2.77c-.103.7-.589 1.394-1.142 1.634-.787.34-1.542 1.114-2.033 2.085-.492.97-.661 2.022-.458 2.835.144.57-.126 1.367-.632 1.872a5.295 5.295 0 0 0-1.424 2.737c-.176 1.002.024 1.873.539 2.338.36.33.36 1.07 0 1.744-.517.96-.715 2.018-.54 2.852.176.834.706 1.345 1.428 1.38.507.022.776.561.633 1.269-.207 1.006-.035 1.896.457 2.398.112.115.244.21.391.282l-3.235-1.545a1.375 1.375 0 0 1-.391-.28c-.491-.502-.66-1.392-.456-2.399.142-.706-.127-1.246-.634-1.268-.723-.032-1.252-.544-1.427-1.38-.176-.835.023-1.892.538-2.851.361-.674.361-1.415 0-1.745-.517-.469-.714-1.336-.538-2.337a5.298 5.298 0 0 1 1.427-2.74c.507-.507.776-1.303.634-1.873-.207-.812-.035-1.865.456-2.835.492-.971 1.246-1.745 2.034-2.086.554-.24 1.039-.933 1.141-1.632a5.286 5.286 0 0 1 1.363-2.77c.71-.747 1.54-1.126 2.236-1.018.49.074 1.095-.379 1.424-1.068.468-.98 1.21-1.782 1.997-2.157.607-.29 1.188-.302 1.636-.048l1.032.493V1.61Zm6.894.185c.084.021.166.05.244.088l3.234 1.544c-.46-.22-1.042-.177-1.642.137-.553.288-1.038.058-1.142-.543-.095-.554-.34-.978-.694-1.226Zm-4.864.788.173.083a1.364 1.364 0 0 0-.173-.083Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.451 33.237c.413.109.9.035 1.398-.225.553-.289 1.039-.058 1.142.543.103.605.387 1.055.796 1.29l-3.044-1.452a1.353 1.353 0 0 1-.292-.156ZM15.443 1.61c.299-.083.562-.253.755-.486.696-.772 1.527-1.182 2.237-1.117.175.016.345.065.5.144l3.21 1.524-2.015 2.029-.962-.789c-.428.325-.887.356-1.159.046a1.398 1.398 0 0 0-.363-.295l-.413.52-1.446-.358c-.704.411-1.354 1.153-1.777 2.042-.33.69-.934 1.143-1.424 1.067-.697-.107-1.527.271-2.237 1.018a5.288 5.288 0 0 0-1.362 2.77c-.103.7-.589 1.394-1.142 1.634-.787.34-1.542 1.114-2.033 2.085-.492.97-.661 2.022-.458 2.835.144.57-.126 1.367-.632 1.872a5.295 5.295 0 0 0-1.424 2.737c-.176 1.002.024 1.873.539 2.338.36.33.36 1.07 0 1.744-.517.96-.715 2.018-.54 2.852.176.834.706 1.345 1.428 1.38.507.022.776.561.633 1.269-.207 1.006-.035 1.896.457 2.398.112.115.244.21.391.282l-3.235-1.545a1.375 1.375 0 0 1-.391-.28c-.491-.502-.66-1.392-.456-2.399.142-.706-.127-1.246-.634-1.268-.723-.032-1.252-.544-1.427-1.38-.176-.835.023-1.892.538-2.851.361-.674.361-1.415 0-1.745-.517-.469-.714-1.336-.538-2.337a5.298 5.298 0 0 1 1.427-2.74c.507-.507.776-1.303.634-1.873-.207-.812-.035-1.865.456-2.835.492-.971 1.246-1.745 2.034-2.086.554-.24 1.039-.933 1.141-1.632a5.286 5.286 0 0 1 1.363-2.77c.71-.747 1.54-1.126 2.236-1.018.49.074 1.095-.379 1.424-1.068.468-.98 1.21-1.782 1.997-2.157.607-.29 1.188-.302 1.636-.048l1.032.493V1.61Zm6.894.185c.084.021.166.05.244.088l3.234 1.544c-.46-.22-1.042-.177-1.642.137-.553.288-1.038.058-1.142-.543-.095-.554-.34-.978-.694-1.226Zm-4.864.788.173.083a1.364 1.364 0 0 0-.173-.083Z"
      fill="#fff"
      fillOpacity={0.4}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.271 21.528 4.404-3.45c.348 7.606 2.34 14.587 5.848 20.03l-3.367-.234-.93 5.086-1.416-.672C16.965 36.897 14.903 31.392 14.27 26c-2.354 5.66-1.733 19.388-1.16 20.865l-1.184-.566-2.685-2.365L6.501 48l-1.185-.566c.09-10.348 1.681-19.427 4.377-24.946l5.576-.958.002-.002Z"
      fill="#FDCF99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.456 22.094 4.402-3.45c.348 7.605 2.341 14.587 5.849 20.03l-3.367-.23-2.108 4.519c-2.845-5.391-4.907-10.896-5.54-16.287-2.053 5.987-2.615 12.813-2.582 20.187l-2.684-2.366-3.924 3.5c.09-10.349 1.68-19.428 4.377-24.946l5.575-.958.002.001Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46 30.097c.657-2.722 1.47-5.104 2.417-7.045l5.576-.958 4.403-3.45a48.102 48.102 0 0 0 1.012 7.942 4.856 4.856 0 0 1-.202.227c-.71.747-1.54 1.126-2.237 1.018-.49-.075-1.095.379-1.423 1.067-.382.8-.946 1.479-1.568 1.909a34.689 34.689 0 0 1-.75-4.134c-.5 1.467-.913 2.96-1.24 4.47a1.377 1.377 0 0 1-.439-.337c-.33-.376-.934-.252-1.424.295-.697.773-1.527 1.183-2.237 1.118-.71-.065-1.216-.616-1.362-1.47-.062-.361-.261-.592-.53-.65l.004-.002Z"
      fill="#C76B00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.014 4.863c.468-.981 1.21-1.781 1.998-2.158.787-.376 1.529-.283 1.997.252.329.374.93.25 1.423-.296.697-.772 1.527-1.183 2.238-1.117.71.066 1.215.615 1.362 1.47.103.601.588.832 1.142.543.786-.411 1.541-.357 2.033.145.491.501.66 1.391.457 2.398-.143.707.126 1.246.634 1.269.723.033 1.252.543 1.427 1.38.176.837-.025 1.892-.539 2.851-.36.673-.36 1.415 0 1.745.516.468.715 1.335.539 2.337a5.295 5.295 0 0 1-1.427 2.74c-.508.507-.777 1.303-.634 1.872.207.812.034 1.864-.457 2.834-.492.971-1.247 1.745-2.033 2.086-.554.24-1.04.933-1.142 1.633a5.292 5.292 0 0 1-1.362 2.77c-.71.747-1.54 1.125-2.238 1.019-.49-.076-1.094.378-1.423 1.067-.468.985-1.21 1.78-1.997 2.157-.788.377-1.53.283-1.998-.25-.329-.376-.934-.252-1.423.295-.697.771-1.527 1.182-2.238 1.116-.71-.066-1.215-.615-1.362-1.47-.103-.6-.588-.83-1.142-.542-.787.41-1.541.357-2.033-.145-.491-.502-.66-1.392-.457-2.398.143-.708-.126-1.247-.633-1.27-.723-.032-1.252-.542-1.427-1.38-.176-.836.025-1.892.539-2.85.36-.675.36-1.416 0-1.745-.516-.468-.715-1.335-.54-2.338a5.295 5.295 0 0 1 1.428-2.739c.507-.507.776-1.304.633-1.872-.207-.812-.034-1.865.457-2.835.492-.97 1.246-1.744 2.033-2.085.554-.24 1.039-.934 1.142-1.633a5.288 5.288 0 0 1 1.362-2.77c.71-.748 1.54-1.125 2.238-1.019.49.076 1.094-.378 1.424-1.067Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.011 7.228c5.178-2.47 9.381.682 9.381 7.035s-4.203 13.517-9.38 15.988c-5.178 2.471-9.381-.68-9.381-7.035 0-6.354 4.203-13.518 9.38-15.988Zm0 1.018c4.72-2.252 8.55.622 8.55 6.413 0 5.792-3.83 12.32-8.55 14.575-4.72 2.255-8.55-.62-8.55-6.412 0-5.791 3.83-12.32 8.55-14.576Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.011 7.228c5.178-2.47 9.381.682 9.381 7.035s-4.203 13.517-9.38 15.988c-5.178 2.471-9.381-.68-9.381-7.035 0-6.354 4.203-13.518 9.38-15.988Zm0 1.018c4.72-2.252 8.55.622 8.55 6.413 0 5.792-3.83 12.32-8.55 14.575-4.72 2.255-8.55-.62-8.55-6.412 0-5.791 3.83-12.32 8.55-14.576Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.072 3.293c.435.48.764 1.04.965 1.645L15.172 24.475c-.342.673-.858 1.243-1.437 1.52-.542.258-1.032.206-1.37-.085l-.006-.005-5.638-4.865a1.058 1.058 0 0 1-.19-.165c-.25-.27-.398-.69-.398-1.22 0-1.263.834-2.685 1.858-3.175.526-.252 1-.21 1.343.059.022.017.044.036.066.056l4.346 3.745 9.428-16.943c.206.306.583.383 1 .167.275-.151.581-.244.898-.27Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.072 3.293c.435.48.764 1.04.965 1.645L15.172 24.475c-.342.673-.858 1.243-1.437 1.52-.542.258-1.032.206-1.37-.085l-.006-.005-5.638-4.865a1.058 1.058 0 0 1-.19-.165c-.25-.27-.398-.69-.398-1.22 0-1.263.834-2.685 1.858-3.175.526-.252 1-.21 1.343.059.022.017.044.036.066.056l4.346 3.745 9.428-16.943c.206.306.583.383 1 .167.275-.151.581-.244.898-.27Z"
      fill="#000"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05 16.457c.258-.126.552-.168.839-.122a17.242 17.242 0 0 0-1.383 5.381l-.723-.626a19.254 19.254 0 0 1 1.27-4.637l-.004.004Zm13.276-9.74c1.033.38 1.91 1.076 2.582 2.046l-.585 1.051c-.651-1.005-1.53-1.712-2.573-2.07l.572-1.026h.004Z"
      fill="#0084FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05 16.457c.258-.126.552-.168.839-.122a17.242 17.242 0 0 0-1.383 5.381l-.723-.626a19.254 19.254 0 0 1 1.27-4.637l-.004.004Zm13.276-9.74c1.033.38 1.91 1.076 2.582 2.046l-.585 1.051c-.651-1.005-1.53-1.712-2.573-2.07l.572-1.026h.004Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.67 3.4c.341-.7.873-1.299 1.471-1.584C29.174 1.324 30 1.951 30 3.213a3.691 3.691 0 0 1-.394 1.59c-.066.136-.14.267-.22.395L17.933 25.796c-.341.672-.859 1.242-1.437 1.518-.541.258-1.032.207-1.37-.084l-.005-.006-5.638-4.864a1.055 1.055 0 0 1-.191-.165c-.249-.27-.397-.69-.397-1.221 0-1.262.834-2.684 1.858-3.175.526-.25 1.002-.21 1.342.06.022.017.043.036.065.056l4.347 3.745L26.667 3.4h.004Z"
      fill="#66D685"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.36 27.381-1.756-.837a1.042 1.042 0 0 1-.227-.147l-.007-.006-5.638-4.865a1.13 1.13 0 0 1-.191-.165c-.249-.27-.396-.69-.396-1.22 0-1.262.834-2.684 1.858-3.175.444-.212.852-.216 1.172-.05l1.662.793c-.304-.12-.676-.099-1.078.092-1.032.493-1.858 1.913-1.858 3.175 0 .532.149.951.397 1.221.057.062.121.117.191.165l5.638 4.865.006.005c.068.06.145.11.227.148v.001Zm-.349-7.013 9.907-17.805c.341-.7.873-1.299 1.47-1.583.43-.206.826-.216 1.142-.065l1.755.837c-.316-.15-.712-.14-1.142.064-.598.285-1.13.887-1.47 1.584l-10.16 18.26-1.502-1.292Z"
      fill="#00BA34"
    />
  </svg>
);

export default SvgNewQualifiedTraders;
