import { SVGProps } from 'react';

const SvgUserAdmin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.33333 12C10.5331 12 11.6838 11.5259 12.5321 10.682C13.3805 9.83807 13.8571 8.69347 13.8571 7.5C13.8571 6.30653 13.3805 5.16193 12.5321 4.31802C11.6838 3.47411 10.5331 3 9.33333 3C8.13354 3 6.9829 3.47411 6.13452 4.31802C5.28614 5.16193 4.80952 6.30653 4.80952 7.5C4.80952 8.69347 5.28614 9.83807 6.13452 10.682C6.9829 11.5259 8.13354 12 9.33333 12ZM9.33333 12C11.1546 12 12.7461 12.684 13.878 13.8207M9.33333 12C5.71429 12 3 14.7 3 18.3V20.1M22 14.7L19.7381 12.45L14.7619 17.4M17.6842 14.4931L19.9461 16.7413M11.1429 18.75C11.1431 19.347 11.3817 19.9194 11.8063 20.3414C12.2308 20.7633 12.8064 21.0002 13.4066 21C14.0067 20.9998 14.5822 20.7624 15.0064 20.3401C15.4305 19.9178 15.6687 19.3452 15.6685 18.7482C15.6682 18.1512 15.4296 17.5788 15.0051 17.1568C14.5805 16.7349 14.0049 16.498 13.4048 16.4982C12.8046 16.4984 12.2292 16.7358 11.805 17.1581C11.3808 17.5804 11.1426 18.153 11.1429 18.75Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </svg>
);

export default SvgUserAdmin;
