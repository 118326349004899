import styled from 'styled-components';

export const SIDE_MENU_OPEN_WIDTH = 280;
export const SIDE_MENU_CLOSE_WIDTH = 72;

export const StyledMenuContainer = styled.section<{ isMenuOpen: boolean }>`
  flex: 0 0
    ${({ isMenuOpen }) => `${isMenuOpen ? SIDE_MENU_OPEN_WIDTH : SIDE_MENU_CLOSE_WIDTH}px`};
  transition: all 0.3s;
  background-color: #282828;
  display: flex;
  flex-direction: column;
`;

export const StyledMenuHeader = styled.div`
  padding: ${({ theme }) => `${theme.spacing.s4} ${theme.spacing.s5}`};
`;

export const StyledMenuToggleButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  line-height: 0;
  color: #18c083;
  cursor: pointer;
`;

type StyledMenuBodyProps = {
  showScrollbar: boolean;
};

export const StyledMenuBody = styled.div<StyledMenuBodyProps>`
  padding: ${({ theme }) => `${theme.spacing.s4} 0 ${theme.spacing.s4} ${theme.spacing.s4}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s2};
  overflow: hidden auto;
  scrollbar-color: ${({ theme, showScrollbar }) => (showScrollbar ? theme.palette.N300 : '#282828')};
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme, showScrollbar }) => (showScrollbar ? theme.palette.N300 : '#282828')};
    border-radius: 40px;
    border: 5px solid #282828;
  }
`;

export const StyledMenuFooter = styled.div`
  margin-top: auto;
  padding: ${({ theme }) => `${theme.spacing.s6} ${theme.spacing.s4}`};
`;
