import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import {
  StyledPageContainer,
  StyledPageTitle
} from 'components/PageLayout/style';
import {
  StyledPageSubTitle,
  StyledSection
} from './style';
import CurrencyExchangeTable from './components/CurrencyExchangeTable';

const BonusSettings = (): JSX.Element => {
  const { t } = useTranslation('bonusSettings', {
    i18n: logixPanelInstance
  });

  return (
    <StyledPageContainer>
      <StyledPageTitle>{t('pageTitle')}</StyledPageTitle>
      <StyledPageSubTitle>{t('subTitle')}</StyledPageSubTitle>
      <StyledSection>{t('criteriaSection')}</StyledSection>
      <CurrencyExchangeTable />
    </StyledPageContainer>
  );
};

export default BonusSettings;
