import { SVGProps } from 'react';

const Upload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.5 7.00003V13C13.5 13.2652 13.3946 13.5196 13.2071 13.7071C13.0196 13.8947 12.7652 14 12.5 14H3.5C3.23478 14 2.98043 13.8947 2.79289 13.7071C2.60536 13.5196 2.5 13.2652 2.5 13V7.00003C2.5 6.73481 2.60536 6.48046 2.79289 6.29292C2.98043 6.10538 3.23478 6.00003 3.5 6.00003H5C5.13261 6.00003 5.25979 6.05271 5.35355 6.14647C5.44732 6.24024 5.5 6.36742 5.5 6.50003C5.5 6.63264 5.44732 6.75981 5.35355 6.85358C5.25979 6.94735 5.13261 7.00003 5 7.00003H3.5V13H12.5V7.00003H11C10.8674 7.00003 10.7402 6.94735 10.6464 6.85358C10.5527 6.75981 10.5 6.63264 10.5 6.50003C10.5 6.36742 10.5527 6.24024 10.6464 6.14647C10.7402 6.05271 10.8674 6.00003 11 6.00003H12.5C12.7652 6.00003 13.0196 6.10538 13.2071 6.29292C13.3946 6.48046 13.5 6.73481 13.5 7.00003ZM5.85375 4.35378L7.5 2.7069V8.50003C7.5 8.63264 7.55268 8.75981 7.64645 8.85358C7.74021 8.94735 7.86739 9.00003 8 9.00003C8.13261 9.00003 8.25979 8.94735 8.35355 8.85358C8.44732 8.75981 8.5 8.63264 8.5 8.50003V2.7069L10.1462 4.35378C10.2401 4.4476 10.3673 4.50031 10.5 4.50031C10.6327 4.50031 10.7599 4.4476 10.8538 4.35378C10.9476 4.25996 11.0003 4.13271 11.0003 4.00003C11.0003 3.86735 10.9476 3.7401 10.8538 3.64628L8.35375 1.14628C8.30731 1.09979 8.25217 1.06291 8.19147 1.03775C8.13077 1.01259 8.06571 0.999634 8 0.999634C7.93429 0.999634 7.86923 1.01259 7.80853 1.03775C7.74783 1.06291 7.69269 1.09979 7.64625 1.14628L5.14625 3.64628C5.05243 3.7401 4.99972 3.86735 4.99972 4.00003C4.99972 4.13271 5.05243 4.25996 5.14625 4.35378C5.24007 4.4476 5.36732 4.50031 5.5 4.50031C5.63268 4.50031 5.75993 4.4476 5.85375 4.35378Z"
      fill="white"
    />
  </svg>
);

export default Upload;
