import { useState, useEffect } from 'react';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import SnapShot from 'components/SnapShot';
import { SnapChartData } from 'type/snapshot';
import BarChart from 'components/BarChart';
import dayjs from 'dayjs';
import { calcTotalItem, numberWithCommas } from 'utils/common';
import { getAccountsRequestConfig } from 'api/getV2RequestConfig';
import Spinner from 'components/Spinner';
import { DataPair, SummaryResType } from './type';
import { barOptions } from './index';
import { StyledLoadingContainer } from './style';

const generalBarColorSet = ['#00BA34', '#0084FF', '#F98600', '#FDCF99'];

const RegisAccType: React.FC = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [regisAccTypeData, setRegisAccTypeData] = useState<SnapChartData>();
  const [regisAccTypeTotal, setRegisAccTypeTotal] = useState<number>(0);

  // Registration Account & Client Type
  useEffect(() => {
    const transformAccTypeData = (accTypeRes:SummaryResType): void => {
      const resAccData = accTypeRes.summarize.data;
      const resKeysArr = Object.keys(resAccData);
      setRegisAccTypeData({
        labels: resAccData[resKeysArr[0]]
          .map((ele:DataPair) => dayjs(ele.date).format('DD/MM')),
        datasets: resKeysArr.map((eachKey, idx) => ({
          label: t(eachKey),
          fill: false,
          backgroundColor: generalBarColorSet[idx],
          pointRadius: 2,
          borderRadius: 5,
          data: resAccData[resKeysArr[idx]].map((ele:DataPair) => ele.value)
        }))
      });

      setRegisAccTypeTotal(() => calcTotalItem(resAccData));
    };

    sendRequest(
      getAccountsRequestConfig('account_type', 'weekly'),
      transformAccTypeData
    );
  }, [t]);

  return (
    <SnapShot
      title={t('registrationAccType')}
      ariaLabel="Registration Account Type"
      note={(
        <div className="total">
          {' '}
          {t('total')}
          {': '}
          {numberWithCommas(regisAccTypeTotal)}
        </div>
)}
    >
      { regisAccTypeData ? <BarChart chartData={regisAccTypeData} options={barOptions} /> :
        (
          <StyledLoadingContainer>
            <Spinner />
          </StyledLoadingContainer>
        )}
    </SnapShot>
  );
};

export default RegisAccType;
