import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import TimeRangePicker, { TimeRange as TimeRangeType } from './TimeRangePicker';

type DurationProps = {
  active?: boolean;
};

type TimeRangePropsType = {
  onChange: (date: string) => void;
};

const TimeRangeWrap = styled.div`
  display: flex;
  margin-right: 32px;
  align-items: center;
`;
const Title = styled.div`
  margin-right: 8px;
`;

const Durations = styled.div`
  display: flex;
  margin-right: 16px;
`;

const Duration = styled.div<DurationProps>`
  box-sizing: content-box;
  min-width: 48px;
  padding: 4px 10px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-right: none;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: none;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    ${(props) => (props.active ? css`
      border-right: 1px solid #18C083;
      color:#18C083;
      border-color: #18C083;
      & + div {
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.08);
      }
    ` : '')};
  }

  &:nth-child(2) {
    ${(props) => (props.active ? css`
    color: #18C083;
    border-color: #18C083;
    border-right: 1px solid #18C083;
  ` : '')};
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    ${(props) => (props.active ? css`
      border-left: 1px solid #18C083;
      color:#18C083;
      border-color: #18C083;
    ` : '')};
  }

`;

const TimeRange: React.FC<TimeRangePropsType> = ({ onChange }) => {
  const [type, setType] = useState(TimeRangeType.month);
  const { t } = useTranslation('salesRevenue', {
    i18n: logixPanelInstance
  });
  return (
    <TimeRangeWrap>
      <Title>{t('timeRange')}</Title>
      <Durations>
        <Duration
          active={type === TimeRangeType.month}
          onClick={() => setType(TimeRangeType.month)}
        >
          {t('mtd')}
        </Duration>
        <Duration
          active={type === TimeRangeType.quarter}
          onClick={() => setType(TimeRangeType.quarter)}
        >
          {t('qtd')}
        </Duration>
        <Duration
          active={type === TimeRangeType.year}
          onClick={() => setType(TimeRangeType.year)}
        >
          {t('ytd')}
        </Duration>
      </Durations>
      <TimeRangePicker type={type} onChange={onChange} />
    </TimeRangeWrap>
  );
};

export default TimeRange;
