/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconComponent from 'components/Icons';
import { useNotificationStore, useStore } from 'store';
import useHttp from 'hooks/useHttp';
import { getSideMenuRequestConfig } from 'api/getV1RequestConfig';
import logixPanelInstance from 'i18n';
import {
  StyledMenuContainer,
  StyledMenuHeader,
  StyledMenuBody,
  StyledMenuToggleButton,
  StyledMenuFooter
} from './style';
import UserMenu from './components/UserMenu';
import MenuItem from './components/MenuItem';
import MenuAccordion, { MenuAccordionProps } from './components/MenuAccordion';

const menuIconNameMap = {
  dashboard: 'Dashboard',
  logixPanelSettings: 'Zerologix',
  tradingPlatformSettings: 'Graph',
  adminSettings: 'Setting',
  clients: 'UserList',
  treasury: 'Banknote',
  tasks: 'ClipboardTask',
  salesManagement: 'Pie',
  marketing: 'Crosshair',
  training: 'Book',
  tickets: 'Ticket',
  campaigns: 'MegaphoneLoud',
  emailManager: 'Email',
  activityLog: 'FileTrayFull',
  compliance: 'UserAdmin',
  Tradingcup: 'TradingCup'
};

type SideMenuItem = {
  id: string;
  type: 'item' | 'group';
  icon: string;
  url?: string;
  items?: Array<{
    id: string;
    type: 'item';
    url: string;
  }>;
};

type SideMenuList = Array<SideMenuItem>;

const SideMenu = (): JSX.Element => {
  const { t } = useTranslation('sideMenu', { i18n: logixPanelInstance });

  const notification = useNotificationStore((state) => state.notification);
  const isSideMenuOpen = useStore((state) => state.isMenuOpen);
  const { toggleMenu } = useStore();

  const [isMenuBodyScrolling, setIsMenuBodyScrolling] =
    useState<boolean>(false);

  const [sideMenuList, setSideMenuList] = useState<SideMenuList>([]);

  let scrollbarTimeout: NodeJS.Timeout;
  const autoScroll = (): void => {
    if (scrollbarTimeout) {
      clearTimeout(scrollbarTimeout);
    }
    scrollbarTimeout = setTimeout(() => {
      setIsMenuBodyScrolling(false);
    }, 500);
    setIsMenuBodyScrolling(true);
  };

  const {
    sendRequest: sendSideMenuRequest,
    error: sideMenuError,
    isLoading: sideMenuIsLoading
  } = useHttp();

  useEffect(() => {
    const getList = (data: any): void => {
      if (data) {
        const menu = data.menu.map((menuItem: SideMenuItem) => ({
          ...menuItem,
          icon: menuIconNameMap[menuItem.id as keyof typeof menuIconNameMap]
        }));
        setSideMenuList(menu);
      }
    };
    sendSideMenuRequest(getSideMenuRequestConfig(), getList);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (sideMenuError) console.error('v1Error', sideMenuError);
  }, [sideMenuError]);

  return (
    <StyledMenuContainer
      aria-label="Side Menu"
      isMenuOpen={isSideMenuOpen}
    >
      <StyledMenuHeader>
        <StyledMenuToggleButton
          aria-label="Toggle Side Menu"
          onClick={toggleMenu}
        >
          <IconComponent
            name={isSideMenuOpen ? 'MenuCollapse' : 'MenuExpand'}
          />
        </StyledMenuToggleButton>
      </StyledMenuHeader>

      <StyledMenuBody
        aria-label="Side Menu Body"
        showScrollbar={isMenuBodyScrolling}
        onScroll={autoScroll}
      >
        {!sideMenuIsLoading &&
          sideMenuList.map((menuItem: SideMenuItem) => (menuItem.type === 'group' ? (
            <MenuAccordion
              key={menuItem.id}
              identifier={menuItem.id}
              title={t(menuItem.id)}
              notification={notification[menuItem.id]}
              iconName={menuItem.icon}
              menuItems={
                  menuItem.items?.map((item) => ({
                    id: item.id,
                    url: item.url,
                    title: t(item.id),
                    notification: notification[item.id]
                  })) as MenuAccordionProps['menuItems']
                }
            />
          ) : (
            <MenuItem
              key={menuItem.id}
              identifier={menuItem.id}
              url={menuItem.url as string}
              title={t(menuItem.id)}
              notification={notification[menuItem.id]}
              iconName={menuItem.icon}
            />
          )))}
      </StyledMenuBody>

      <StyledMenuFooter>
        <UserMenu />
      </StyledMenuFooter>
    </StyledMenuContainer>
  );
};

export default SideMenu;
