import { useMemo, useState, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import logixPanelInstance from 'i18n';
import useHttp from 'hooks/useHttp';
import {
  getMAMApplicationDeleteLicenseRequestConfig,
  getMAMApplicationUpdateRecordRequestConfig,
  getMAMApplicationUploadLicenseRequestConfig,
  postMAMformData,
  getMAMformData
} from 'api/getV2RequestConfig';
import AlertToast, { AlertToastProps } from 'components/AlertToast';
import AlertDialog from 'components/AlertDialog';
import RowsPerPageSelect from 'components/RowsPerPageSelect';
import TableRow from 'pages/MAMAccounts/components/TableRow';
import {
  StyledPageContainer,
  StyledPageTitle
} from 'components/PageLayout/style';
import DataTable from 'components/DataTable';
import Pagination from 'components/Pagination';
import FilterAndSearchBox from 'components/FilterAndSearchBox';
import SearchBar from 'components/FilterAndSearchBox/components/SearchBar';
import FilterField from 'components/FilterAndSearchBox/components/FilterField';
import { StyledOutlineDropdownSelect } from 'components/FilterAndSearchBox/style';
import useMAMApplicationsData, {
  AccountRowData,
  Status
} from './hooks/useMAMApplicationsData';
import {
  StyledForm,
  StyledLabelContainer,
  StyledLabel,
  StyledInput,
  StyledButtonContainer,
  StyledSelectContainer,
  StyledError
} from './style';
import { StyledDialogActionButton } from '../../components/AlertDialog/style';
import TableHeadRow from './components/TableHeadRow';

const MAMAccounts: React.FC = () => {
  const { t, i18n } = useTranslation('mamAccounts', {
    i18n: logixPanelInstance
  });

  const [searchInput, setSearchInput] = useState<string>('');
  const [currentStatus, setCurrentStatus] = useState<Status | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number>(10);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const { control, handleSubmit, reset } = useForm<SubmitData>();
  const { rows, setRows, totalPageCount } = useMAMApplicationsData(
    currentPage,
    currentRowsPerPage,
    currentStatus,
    searchInput,
    i18n.language
  );

  const [stagingRowData, setStagingRowData] = useState<AccountRowData | null>(
    null
  );

  const [isSetUpFeeDialogOpen, setIsSetUpFeeDialogOpen] =
    useState<boolean>(false);
  const [isDeleteFileDialogOpen, setIsDeleteFileDialogOpen] =
    useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newFormData, setNewFormData] = useState<NewFormData>({
    server_flag: [],
    mt_id: 0
  });

  const [formData, setFormData] = useState<FormData | null>(null);

  const [responseMessage, setResponseMessage] = useState<{
    type: AlertToastProps['severity'];
    content: string;
  }>({
    type: 'success',
    content: ''
  });
  const [isResponseMessageToastOpen, setIsResponseMessageToastOpen] =
    useState<boolean>(false);

  const { sendRequest } = useHttp();

  const statusFilterOptions = [
    {
      value: null,
      name: t('pleaseSelect')
    },
    {
      value: Status.Active,
      name: t('active')
    },
    {
      value: Status.Approve,
      name: t('approve')
    },
    {
      value: Status.Pending,
      name: t('pending')
    },
    {
      value: Status.Reject,
      name: t('reject')
    }
  ];

  const customSelectStyles = {
    width: '356px',
    height: '48px',
    fontSize: '14px',
    fontFamily: 'Noto Sans',
    borderRadius: '8px',
    lineHeight: '24px',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
      border: '1px solid #BBB'
    },
    '&:hover': {
      '&& fieldset': {
        border: '1px solid #BBB'
      }
    }
  };

  const customMenuItemStyles = {
    fontSize: '14px',
    fontFamily: 'Noto Sans',
    lineHeight: '24px'
  };
  const submitRecord = (
    id: AccountRowData['id'],
    status: AccountRowData['status'],
    setUpFee: AccountRowData['setUpFee']
  ): void => {
    sendRequest(
      getMAMApplicationUpdateRecordRequestConfig(
        id,
        { status, setup_fee: setUpFee },
        i18n.language
      ),
      (responseData: { message: string }) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setIsResponseMessageToastOpen(true);

        setRows((prevState) => {
          const updatedRowIndex = prevState.findIndex((row) => row.id === id);
          const updatedRows = [...prevState];
          updatedRows[updatedRowIndex] = {
            ...prevState[updatedRowIndex],
            status,
            setUpFee
          };
          return updatedRows;
        });

        setIsSetUpFeeDialogOpen(false);
        setStagingRowData(null);
      },
      (errorMessage) => {
        setResponseMessage({ type: 'error', content: errorMessage });
        setIsResponseMessageToastOpen(true);
      }
    );
  };
  interface FormData {
    server_list: {
      id: number;
      name: string;
    }[];
    status_code: number;
  }
  interface NewFormData {
    server_flag: string[];
    mt_id: number;
  }

  interface GetAddFormListResponse {
    server_list: {
      id: number;
      name: string;
    }[];
    errors: {
      server_flag: string[];
    };
    mt_id: number | null;
    mt_url: string | null;
    message: string;
    status_code: number;
  }

  interface SubmitData {
    mt_id: number;
    server_flag: string;
  }

  const onSubmit: SubmitHandler<SubmitData> = (data) => {
    const updatedData: {
      mt_id: number;
      server_flag: number | undefined | null;
    } = {
      ...data,
      mt_id: Number(data.mt_id),
      server_flag:
        formData &&
        formData.server_list.find((server) => server.name === data.server_flag)
          ?.id
    };

    sendRequest(
      postMAMformData(selectedId, updatedData, i18n.language),
      (responseData: GetAddFormListResponse) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setResponseMessage({ type: 'success', content: t('addSuccess') });
        setIsResponseMessageToastOpen(true);
        setRows((prevState) => {
          const updatedRows = [...prevState];
          const updatedRowIndex = prevState.findIndex(
            (row) => row.id === selectedId
          );
          updatedRows[updatedRowIndex] = {
            ...prevState[updatedRowIndex],
            status: 2,
            mt_id: responseData.mt_id,
            mt_url: responseData.mt_url
          };
          return updatedRows;
        });
      },
      () => {
        setResponseMessage({ type: 'error', content: t('serverError') });
        setIsResponseMessageToastOpen(true);
      }
    );
    setShowForm(false);
    reset();
  };

  const uploadLicense = (id: AccountRowData['id'], file: File): void => {
    sendRequest(
      getMAMApplicationUploadLicenseRequestConfig(id, file, i18n.language),
      (responseData: {
        message: string;
        file_name: string;
        file_url: string;
      }) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setIsResponseMessageToastOpen(true);

        setRows((prevState) => {
          const updatedRowIndex = prevState.findIndex((row) => row.id === id);
          const updatedRows = [...prevState];
          updatedRows[updatedRowIndex] = {
            ...prevState[updatedRowIndex],
            licenseFileName: responseData.file_name,
            licenseUrl: responseData.file_url
          };
          return updatedRows;
        });

        setStagingRowData(null);
      },
      (errorMessage) => {
        setResponseMessage({ type: 'error', content: errorMessage });
        setIsResponseMessageToastOpen(true);
      }
    );
  };

  const deleteLicense = (id: AccountRowData['id']): void => {
    sendRequest(
      getMAMApplicationDeleteLicenseRequestConfig(id, i18n.language),
      (responseData: { message: string }) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setIsResponseMessageToastOpen(true);

        setRows((prevState) => {
          const updatedRowIndex = prevState.findIndex((row) => row.id === id);
          const updatedRows = [...prevState];
          updatedRows[updatedRowIndex] = {
            ...prevState[updatedRowIndex],
            licenseFileName: null,
            licenseUrl: null
          };
          return updatedRows;
        });

        setIsDeleteFileDialogOpen(false);
        setStagingRowData(null);
      },
      (errorMessage) => {
        setResponseMessage({ type: 'error', content: errorMessage });
        setIsResponseMessageToastOpen(true);
      }
    );
  };

  const cancelSetUpFeeSubmission = (): void => {
    setStagingRowData(null);
    setIsSetUpFeeDialogOpen(false);
    setShowForm(false);
  };

  const cancelDeleteFileSubmission = (): void => {
    setStagingRowData(null);
    setIsDeleteFileDialogOpen(false);
  };

  interface ValidationRules {
    required?: string;
    pattern?: {
      value: RegExp;
      message: string;
    };
  }

  const getValidationRules = (fieldName: string): ValidationRules => {
    switch (fieldName) {
      case 'mt_id':
        return {
          required: t('required'),
          pattern: {
            value: /^[0-9]+$/,
            message: t('AccountNumeric')
          }
        };
      default:
        return {};
    }
  };

  const handleClick = (id: number): void => {
    setSelectedId(id);
    setShowForm(true);
  };

  useEffect(() => {
    sendRequest(
      getMAMformData(i18n.language),
      (responseData: GetAddFormListResponse) => {
        setResponseMessage({
          type: 'success',
          content: responseData.message
        });
        setFormData(responseData);
        setNewFormData((prevFormData) => ({
          ...prevFormData,
          server_flag: responseData.server_list.map((i) => i.name)
        }));
      },
      (errorMessage) => {
        setResponseMessage({ type: 'error', content: errorMessage });
      }
    );
  }, []);

  const tableRows = useMemo(
    () => rows.map((row) => (
      <TableRow
        key={row.id}
        rowData={row}
        onSetUpFeeChange={(value) => {
          setStagingRowData(value);
          setIsSetUpFeeDialogOpen(true);
        }}
        onStatusChange={(value) => {
          submitRecord(value.id, value.status, value.setUpFee);
        }}
        onFileDelete={(value) => {
          setStagingRowData(value);
          setIsDeleteFileDialogOpen(true);
        }}
        onFileUpload={(id, file) => uploadLicense(id, file)}
        onClick={() => handleClick(row.id)}
      />
    )),
    [rows]
  );

  return (
    <StyledPageContainer>
      <StyledPageTitle>{t('pageTitle')}</StyledPageTitle>
      <FilterAndSearchBox
        filterSlot={(
          <FilterField label={t('status')}>
            <StyledOutlineDropdownSelect
              selectedValue={currentStatus}
              options={statusFilterOptions}
              onSelect={(value) => setCurrentStatus(value)}
              width="247px"
            />
          </FilterField>
        )}
        searchSlot={(
          <SearchBar
            placeholder={t('searchPlaceholder')}
            onSearch={(value) => setSearchInput(value)}
          />
        )}
      />
      <DataTable
        tableHeadSlot={<TableHeadRow />}
        tableBodySlot={tableRows}
        tableFooterSlot={(
          <>
            <Pagination
              total={totalPageCount}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
            />
            <RowsPerPageSelect
              count={currentRowsPerPage}
              onSelect={(value) => setCurrentRowsPerPage(value)}
            />
          </>
        )}
      />

      <AlertDialog
        isOpen={isSetUpFeeDialogOpen}
        handleClose={cancelSetUpFeeSubmission}
        title={t('setUpFeeDialog.title')}
        contentSlot={(
          <>
            {t('setUpFeeDialog.content1')}
            <b>{stagingRowData?.name}</b>
            {t('setUpFeeDialog.content2')}
            <b>{stagingRowData?.setUpFee}</b>
            {t('setUpFeeDialog.content3')}
          </>
        )}
        actionSlot={(
          <>
            <StyledDialogActionButton
              onClick={() => {
                if (stagingRowData) {
                  submitRecord(
                    stagingRowData.id,
                    stagingRowData.status,
                    stagingRowData.setUpFee
                  );
                }
              }}
            >
              {t('confirm')}
            </StyledDialogActionButton>
            <StyledDialogActionButton
              variant="text"
              onClick={cancelSetUpFeeSubmission}
            >
              {t('cancel')}
            </StyledDialogActionButton>
          </>
        )}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialog
          isOpen={showForm}
          handleClose={cancelSetUpFeeSubmission}
          title={t('createAccount')}
          contentSlot={(
            <StyledForm>
              {newFormData.server_flag.length > 0 &&
                Object.entries(newFormData).map(([name, value]) => (
                  <StyledLabelContainer key={name}>
                    <StyledLabel htmlFor={name}>{t(name)}</StyledLabel>
                    {Array.isArray(value) ? (
                      <Controller
                        defaultValue={value[0]}
                        name={name as keyof SubmitData}
                        control={control}
                        render={({
                          field: { value: fieldValue, onChange }
                        }) => (
                          <StyledSelectContainer>
                            <Select
                              value={fieldValue}
                              placeholder="Please Select"
                              onChange={(e) => {
                                // handleChange(e.target.value);
                                onChange(e.target.value);
                              }}
                              sx={customSelectStyles}
                            >
                              {value.map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  sx={customMenuItemStyles}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </StyledSelectContainer>
                        )}
                      />
                    ) : (
                      <Controller
                        name={name as keyof SubmitData}
                        control={control}
                        rules={getValidationRules(name)}
                        render={({ field, fieldState }) => (
                          <>
                            <StyledInput
                              {...field}
                              value={field.value || ''}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setNewFormData((prevFormData) => ({
                                  ...prevFormData,
                                  [name]: e.target.value
                                }));
                              }}
                            />
                            {name === 'mt_id' && fieldState.error?.message && (
                              <StyledError>
                                {fieldState.error.message}
                              </StyledError>
                            )}
                          </>
                        )}
                      />
                    )}
                  </StyledLabelContainer>
                ))}
            </StyledForm>
          )}
          actionSlot={(
            <StyledButtonContainer>
              <StyledDialogActionButton
                onClick={cancelSetUpFeeSubmission}
                variant="text"
              >
                {t('cancel')}
              </StyledDialogActionButton>
              <StyledDialogActionButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {t('save')}
              </StyledDialogActionButton>
            </StyledButtonContainer>
          )}
        />
      </form>

      <AlertDialog
        isOpen={isDeleteFileDialogOpen}
        handleClose={cancelDeleteFileSubmission}
        title={t('deleteFileDialog.title')}
        contentSlot={(
          <>
            {t('deleteFileDialog.content1')}
            <b>{stagingRowData?.licenseFileName}</b>
            {t('deleteFileDialog.content2')}
          </>
        )}
        actionSlot={(
          <>
            <StyledDialogActionButton
              onClick={() => {
                if (stagingRowData) {
                  deleteLicense(stagingRowData.id);
                }
              }}
            >
              {t('delete')}
            </StyledDialogActionButton>
            <StyledDialogActionButton
              variant="text"
              onClick={cancelDeleteFileSubmission}
            >
              {t('cancel')}
            </StyledDialogActionButton>
          </>
        )}
      />

      <AlertToast
        isOpen={isResponseMessageToastOpen}
        handleClose={() => setIsResponseMessageToastOpen(false)}
        severity={responseMessage.type}
        content={responseMessage.content}
      />
    </StyledPageContainer>
  );
};

export default MAMAccounts;
