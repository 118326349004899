import { SVGProps } from 'react';

const SvgTradingCup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1145_2372)">
      <path
        d="M14.0197 5L14.3707 7.96772H9.8892V19.1122H6.13047V7.96772H2V5H14.0197Z"
        fill="currentColor"
      />
      <path
        d="M18.5718 8.89203C20.0037 8.89203 21.1465 9.231 22.0001 9.90894L20.9468 12.5031C20.5475 12.2956 20.2033 12.1572 19.9142 12.088C19.6251 12.005 19.3015 11.9635 18.9435 11.9635C18.4204 11.9635 17.9591 12.178 17.5598 12.6069C17.1744 13.0358 16.9816 13.5961 16.9816 14.2879C16.9816 14.9658 17.1744 15.4985 17.5598 15.8859C17.9453 16.2733 18.4066 16.467 18.9435 16.467C19.2877 16.467 19.6113 16.4324 19.9142 16.3632C20.2171 16.2802 20.5613 16.1349 20.9468 15.9273L22.0001 18.5216C21.1465 19.1995 20.0037 19.5384 18.5718 19.5384C16.9196 19.5384 15.591 19.0819 14.5859 18.1688C13.5946 17.2418 13.0989 15.9482 13.0989 14.2879C13.0989 13.181 13.3261 12.2195 13.7805 11.4032C14.2486 10.5869 14.8957 9.96428 15.7218 9.53538C16.5478 9.10648 17.4979 8.89203 18.5718 8.89203Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1145_2372">
        <rect
          width="20"
          height="14.7297"
          fill="white"
          transform="translate(2 5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTradingCup;
