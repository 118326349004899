import * as React from "react";
import { SVGProps } from "react";

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.926c0-.197.077-.385.213-.524a.72.72 0 0 1 .514-.217h14.546a.72.72 0 0 1 .514.217.748.748 0 0 1 0 1.048.72.72 0 0 1-.514.217H.727a.72.72 0 0 1-.514-.217.748.748 0 0 1-.213-.524ZM0 7c0-.196.077-.385.213-.524a.72.72 0 0 1 .514-.217h14.546a.72.72 0 0 1 .514.217.748.748 0 0 1 0 1.048.72.72 0 0 1-.514.217H.727a.72.72 0 0 1-.514-.217A.748.748 0 0 1 0 7Zm0-5.926C0 .878.077.689.213.55A.72.72 0 0 1 .727.333h14.546a.72.72 0 0 1 .514.217.748.748 0 0 1 0 1.048.72.72 0 0 1-.514.217H.727a.72.72 0 0 1-.514-.217A.748.748 0 0 1 0 1.074Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMenu;
