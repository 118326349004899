import styled, { css } from 'styled-components';
import { Status } from 'pages/MAMAccounts/hooks/useMAMApplicationsData';

type StyledStatusCircle = {
  status: Status;
};

export const StyledStatusCircle = styled.svg<StyledStatusCircle>`
  ${({ theme, status }) => css`
    display: block;
    width: 6px;
    height: 6px;
    ${status === Status.Active && `color: ${theme.palette.G400}`};
    ${status === Status.Approve && `color: ${theme.palette.F400}`};
    ${status === Status.Pending && `color: ${theme.palette.N450}`};
    ${status === Status.Reject && `color: ${theme.palette.R400}`};
  `}
`;

export const StyledDownloadLink = styled.a`
  color: #145db5;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledUploadButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;

  svg {
    vertical-align: middle;
    cursor: pointer;
  }
`;

export const StyledCreateAccountButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    color: ${theme.palette.F400};
  `}
`;

export const StyledDocumentField = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing.s2};
  `}
`;

export const StyledDeleteButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
`;
