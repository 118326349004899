import axios from 'axios';
import { refreshToken, v1Logout } from 'api/common';
import { handleLogoutRedirect } from 'utils/common';
import { apiConfig } from './config';

let isRefreshing = false;
let retryAPI = false;
let logoutFlag = false;
let refreshSubscribers: string[] = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function subscribeTokenRefresh(cb:any):void {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token:string):void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshSubscribers.forEach((cb:any) => cb(token));
  refreshSubscribers = [];
}

const customInstance = axios.create({
  baseURL: apiConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 15000
});

customInstance.interceptors.request.use(
  (config) => {
    if (!localStorage.getItem('jwt-token')) {
      if (!logoutFlag) {
        logoutFlag = true;
        v1Logout().then((res) => {
          if (res) {
            handleLogoutRedirect();
          }
        }).catch(() => {
          logoutFlag = false;
        });
      }
      throw new axios.Cancel('Operation canceled by the user.');
    }
    return config;
  },
  (error) => Promise.reject(error)
);
customInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response: { status } } = error;
    const originalRequest = config;
    if (status === 401) {
      if (!retryAPI) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshToken().then((res) => {
            isRefreshing = false;
            if (res && res.data) {
              onRrefreshed(res.data.token);
              retryAPI = true;
              setTimeout(() => {
                retryAPI = false;
              }, 15000);
              localStorage.setItem('jwt-token', res.data.token);
            }
          }).catch(() => {
            // logout
            v1Logout().then((res) => {
              if (res) {
                localStorage.removeItem('jwt-token');
                handleLogoutRedirect();
              }
            });
          });
        }

        const retryOrigReq = new Promise((resolve) => {
          subscribeTokenRefresh((token:string) => {
            // replace the expired token and retry
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
        return retryOrigReq;
      }
      if (retryAPI) {
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('jwt-token')}`;
        return axios(originalRequest).then((res) => res).catch((err) => Promise.reject(err));
      }
    }
    return Promise.reject(error);
  }
);

export default customInstance;
