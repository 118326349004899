import * as React from "react";
import { SVGProps } from "react";

const SvgDocumentResubmissions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 34 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.3 22.747 0v35.381L0 46.682V11.301Z"
      fill="#C76B00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.326 11.96 24.073.659V36.04L1.326 47.341V11.96Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.748 6.61 10.653 5.293v24.796L2.652 48V12.619L14.748 6.61Z"
      fill="#FDCF99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.778 26.459-2.952 1.466v1.63l2.952-1.467v-1.63Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.066 11.937-6.24 3.1v8.758l6.24-3.1v-8.758Z"
      fill="#FBB666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.337 23.2-5.35 2.659v1.629l5.35-2.659V23.2ZM21.256 20.756l-3.71 1.844v1.63l3.71-1.844v-1.63ZM15.027 27.436l-2.808 1.395v1.63l2.808-1.396v-1.63ZM10.986 29.444l-4.16 2.065v1.63l4.16-2.066v-1.63ZM14.465 31.23l-4.387 2.18v1.629l4.387-2.18V31.23ZM8.906 33.99l-2.08 1.033v1.63l2.08-1.033v-1.63ZM19.126 25.4l-2.892 1.436v1.629l2.892-1.436V25.4ZM15.027 34.466l-2.808 1.394v1.63l2.808-1.396v-1.628ZM10.986 36.473l-4.16 2.065v1.63l4.16-2.066v-1.63ZM18.259 29.316l-2.425 1.205v1.63l2.425-1.206v-1.63Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.748 6.61 2.005 9.162 8.648-3.87L14.748 6.61Z"
      fill="#FBB666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.95 15.123-.202-8.513 1.715 7.837-1.513.676ZM9.946 21.182l1.352-2.141c.308-.084.622-.147.938-.189.493-.062.83.289.83.865v1.537l-6.238 2.882v-1.538a2.227 2.227 0 0 1 .829-1.629c.312-.247.625-.473.94-.68l1.35.893h-.001ZM9.947 15.149c.957-.441 1.74.073 1.74 1.147s-.776 2.303-1.74 2.746c-.965.443-1.732-.072-1.732-1.147 0-1.073.776-2.303 1.732-2.746ZM27.243 30.3v5.437l-11.805 5.92v-5.436l11.805-5.92Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.243 30.3v5.437l-11.805 5.92v-5.436l11.805-5.92Z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.375 25.8v11.18l-5.42 2.718V28.52l5.42-2.719Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.375 25.8v11.18l-5.42 2.718V28.52l5.42-2.719Z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.176 40.004v5.437l2.71 1.36v-5.437l-2.71-1.36Z"
      fill="#99E3AE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m31.29 29.495 2.71 1.36-4.695 2.353-2.71-1.36 4.694-2.353Z"
      fill="#00BA34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m31.29 29.495 2.71 1.36-4.695 2.353-2.71-1.36 4.694-2.353Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m21.173 34.568 2.711 1.36-4.517 2.265-2.71-1.36 4.516-2.265Z"
      fill="#00BA34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m21.173 34.568 2.711 1.36-4.517 2.265-2.71-1.36 4.516-2.265Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 30.854v5.437l-14.633 7.338v-5.437L34 30.854Z"
      fill="#00BA34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.656 36.833v5.437l2.711 1.36v-5.438l-2.71-1.359Z"
      fill="#99E3AE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m26.598 26.412 2.709 1.36-5.42 2.719-2.711-1.36 5.422-2.719Z"
      fill="#00BA34"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m26.598 26.412 2.709 1.36-5.42 2.719-2.711-1.36 5.422-2.719Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.176 29.13v5.438l2.71 1.359V30.49l-2.71-1.36Z"
      fill="#99E3AE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.307 27.771v16.312l-5.42 2.717V30.49l5.42-2.718Z"
      fill="#00BA34"
    />
  </svg>
);

export default SvgDocumentResubmissions;
