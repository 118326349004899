import { useEffect, useCallback } from 'react';
import { transferSideMenuKeys } from 'utils/common';
import { fetchPendingsDataConfig } from 'constant/common';
import { useNotificationStore, useStore } from 'store';
import useV1Http from 'hooks/useV1Http';
import { getFetchPendingRequestConfig } from 'api/getV1RequestConfig';

interface pendingData {
  callback: string;
  payload: object;
  status:number
}

const Polling = (): JSX.Element => {
  const { sendRequest: sendV1Request } = useV1Http();
  const changeNotification = useNotificationStore((state) => state.changeNotification);
  const { changeAuth, isAuthorized } = useStore();

  const notifyFetch = useCallback(() => {
    const fetchPendings = (resData:pendingData): void => {
      if (resData && resData.payload) {
        const finalObj = transferSideMenuKeys(resData.payload, fetchPendingsDataConfig);
        changeNotification(finalObj);
        if (!isAuthorized) changeAuth(true);
      }
    };
    sendV1Request(getFetchPendingRequestConfig(), fetchPendings);
  }, [sendV1Request, changeNotification, changeAuth, isAuthorized]);

  useEffect(() => {
    notifyFetch();
  }, [notifyFetch]);

  useEffect(() => {
    const pendingTimer = setInterval(() => {
      notifyFetch();
    }, 60000);
    return () => clearInterval(pendingTimer);
  }, [notifyFetch]);

  return (
    <div />
  );
};

export default Polling;
