import { SVGProps } from 'react';

const SvgCirclePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='24'
    viewBox='0 0 16 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2 12C2 12.7879 2.15519 13.5681 2.45672 14.2961C2.75825 15.0241 3.20021 15.6855 3.75736 16.2426C4.31451 16.7998 4.97595 17.2417 5.7039 17.5433C6.43185 17.8448 7.21207 18 8 18C8.78793 18 9.56815 17.8448 10.2961 17.5433C11.0241 17.2417 11.6855 16.7998 12.2426 16.2426C12.7998 15.6855 13.2417 15.0241 13.5433 14.2961C13.8448 13.5681 14 12.7879 14 12C14 11.2121 13.8448 10.4319 13.5433 9.7039C13.2417 8.97595 12.7998 8.31451 12.2426 7.75736C11.6855 7.20021 11.0241 6.75825 10.2961 6.45672C9.56815 6.15519 8.78793 6 8 6C7.21207 6 6.43185 6.15519 5.7039 6.45672C4.97595 6.75825 4.31451 7.20021 3.75736 7.75736C3.20021 8.31451 2.75825 8.97595 2.45672 9.7039C2.15519 10.4319 2 11.2121 2 12Z'
      stroke='#5C5C5C'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 12H10'
      stroke='#5C5C5C'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 10V14'
      stroke='#5C5C5C'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SvgCirclePlus;
