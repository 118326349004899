import styled from 'styled-components';
import { commonSnapShotWrap } from 'styles/common';

export const StyledLineChart = styled.div`

  .paper{
    ${commonSnapShotWrap}
  }

`;
