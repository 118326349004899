import { StyledPagination } from './style';

type PaginationProps = {
  total: number;
  page: number;
  onPageChange: (value: number) => void;
};

const Pagination = ({
  total,
  page,
  onPageChange
}: PaginationProps): JSX.Element => {
  const currentPage = page > total ? 1 : page;

  return (
    <StyledPagination
      count={total}
      page={currentPage}
      onChange={(event, value) => onPageChange(value)}
    />
  );
};

export default Pagination;
