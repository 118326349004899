import { useTranslation } from 'react-i18next';
import { StyledTableCell, StyledTableRow } from 'components/DataTable/style';
import logixPanelInstance from 'i18n';

const TableHeadRow = (): JSX.Element => {
  const { t } = useTranslation('tradingCupSubscription', {
    i18n: logixPanelInstance
  });

  return (
    <StyledTableRow aria-label="table head row">
      <StyledTableCell align="center" $width="121px">{t('transaction')}</StyledTableCell>
      <StyledTableCell $width="82px" align="left">{t('order')}</StyledTableCell>
      <StyledTableCell $width="165px" align="left">{t('chargeTime')}</StyledTableCell>
      <StyledTableCell $width="137px" align="left" $boxShadowPosition="right">{t('tradingCupID')}</StyledTableCell>
      <StyledTableCell align="left">
        {t('name')}
      </StyledTableCell>
      <StyledTableCell align="left">{t('email')}</StyledTableCell>
      <StyledTableCell align="left">{t('role')}</StyledTableCell>
      <StyledTableCell align="left">{t('mtId')}</StyledTableCell>
      <StyledTableCell align="left">{t('server')}</StyledTableCell>
      <StyledTableCell align="left">{t('platform')}</StyledTableCell>
      <StyledTableCell align="left">{t('subscriptionFee')}</StyledTableCell>
      <StyledTableCell align="left">
        {t('commission')}
        %
      </StyledTableCell>
      <StyledTableCell align="left">
        {t('commissionCharge')}
      </StyledTableCell>
      <StyledTableCell align="left">
        {t('actualCharge')}
      </StyledTableCell>
      <StyledTableCell align="left">{t('baseCurrency')}</StyledTableCell>
      <StyledTableCell align="left">
        {t('actualExchangeRate')}
      </StyledTableCell>
      <StyledTableCell align="left">
        {t('settleExchangeRate')}
      </StyledTableCell>
      <StyledTableCell align="left">
        {t('exchangeCharge')}
      </StyledTableCell>
      <StyledTableCell
        $width="112px"
        align="left"
      >
        {t('status')}
      </StyledTableCell>
      <StyledTableCell $width="77px" align="left">
        {t('admin')}
      </StyledTableCell>
      <StyledTableCell $width="157px" align="left">
        {t('comments')}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TableHeadRow;
