/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import { SubscriptionqueryOptions, getTradingCupSubscriptionExportRequestConfig, getTradingCupSubscriptionRequestConfig } from 'api/getV2RequestConfig';
import useHttp from 'hooks/useHttp';
import { Role } from 'pages/TradingCupAccounts/hooks/useTradingCupAccountsData';
import { statisticsValueGenerator } from 'utils/common';
import { Status } from '../typesUtils';

export type FilterData = {
  role: Role | 'all';
  status: Status | 'all';
  dateRange: { start: Date | null; end: Date | null };
};

export type RawData = {
    transactionId: number;
    orderId: number;
    trader: number | null;
    chargeTime?: string;
    tradingcupId: number;
    name: string;
    email: string;
    role: string;
    metaTraderId: string;
    metaTraderIdLink: string;
    serverName: string;
    platform: string;
    fee: number;
    commissionPercent: number;
    commissionCharge: number;
    actualCharge: number;
    exchangeRate: number;
    settledExchangeRate: number;
    exchangeCharge: number;
    baseCurrency: string;
    status: Status;
    admin: string;
    comments: string;
    nameLink: string | null;
  };
export type SubscriptionRowData = {
    transactionId: RawData['transactionId'];
    orderId: RawData['orderId'];
    trader: RawData['trader'];
    chargeTime?: RawData['chargeTime'];
    name: RawData['name'];
    email: RawData['email'];
    serverName: RawData['serverName'];
    metaTraderId: RawData['metaTraderId'];
    metaTraderIdLink: RawData['metaTraderIdLink'];
    role: RawData['role'];
    baseCurrency: RawData['baseCurrency'];
    commission: string;
    status: RawData['status'];
    admin: RawData['admin'];
    comments: RawData['comments'];
    nameLink: RawData['nameLink'];
    tradingCupId: RawData['tradingcupId'];
    subscriptionFee: RawData['fee'];
    commissionCharge: string;
    actualCharge: string;
    actualExchangeRate: string;
    settledExchangeRate: string;
    exchangeCharge: string;
    platform: RawData['platform'];
  };
export type ResponseDataType = {
  data: RawData[],
  paginate: {
    page: number;
    perPage: number;
    total: number;
    totalPages: number;
  }
}

const groupByTransactionId = (data: SubscriptionRowData[]) => {
  const transactionIdsSetObject = new Set(data.map((item) => item.transactionId));
  const uniqueTransactionIds = Array.from(transactionIdsSetObject);

  const result: SubscriptionRowData[] = [];

  uniqueTransactionIds.forEach((id) => {
    data.forEach((current) => {
      if (current.transactionId === id) {
        result.push(current);
      }
    });
  });

  return result;
};

const getQueryOptions = (inputData: {
  filterData: FilterData;
  currentPage: number;
  rowsPerPage: number;
  searchInput: string;
}): SubscriptionqueryOptions => {
  const formatDate = (date: Date | null): string | null => (date ?
    date.toLocaleDateString('en-AU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
      .split('/')
      .reverse()
      .join('-') :
    null);

  const {
    filterData,
    currentPage,
    rowsPerPage,
    searchInput
  } = inputData;

  const formattedStartDate = formatDate(filterData.dateRange.start);
  const formattedEndDate = formatDate(filterData.dateRange.end);

  const queryOptions: SubscriptionqueryOptions = {
    page: currentPage,
    perPage: rowsPerPage
  };

  if (filterData.role !== 'all') queryOptions.role = filterData.role;
  if (filterData.status !== 'all') queryOptions.status = filterData.status;
  if (formattedStartDate) queryOptions.startDate = formattedStartDate;
  if (formattedEndDate) queryOptions.endDate = formattedEndDate;
  if (searchInput) queryOptions.keyword = encodeURIComponent(searchInput);

  return queryOptions;
};

const useTradingCupSubscriptionData = (
  filterData: FilterData,
  searchInput: string,
  currentPage: number,
  rowsPerPage: number,
  lang: string
): {
  rows: Array<SubscriptionRowData>;
  setRows: Dispatch<SetStateAction<SubscriptionRowData[]>>;
  fetchData: () => void;
  onExportClick: () => void;
  totalPageCount: number;
} => {
  const [rows, setRows] = useState<SubscriptionRowData[]>([]);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);

  const { sendRequest } = useHttp();

  const queryOptions = getQueryOptions({
    filterData,
    searchInput,
    currentPage,
    rowsPerPage
  });

  const fetchData = async (): Promise<void> => {
    sendRequest(
      getTradingCupSubscriptionRequestConfig(
        lang,
        queryOptions
      ),
      (responseData: ResponseDataType) => {
        const subscriptionRowsData: SubscriptionRowData[] = responseData.data.map((item) => ({
          transactionId: item.transactionId,
          orderId: item.orderId,
          trader: item.trader,
          chargeTime: item.chargeTime,
          name: item.name,
          email: item.email,
          serverName: item.serverName,
          metaTraderId: item.metaTraderId,
          metaTraderIdLink: item.metaTraderIdLink,
          role: item.role,
          baseCurrency: item.baseCurrency,
          commission: statisticsValueGenerator(item.commissionPercent, { unit: '%', shouldDisplayDefaultDash: item.role === Role.Copier }),
          commissionCharge: statisticsValueGenerator(item.commissionCharge, {
            shouldDisplayDefaultDash: item.role === Role.Copier,
            precesion: 2
          }),
          status: item.status,
          admin: item.admin,
          comments: item.comments,
          nameLink: item.nameLink,
          tradingCupId: item.tradingcupId,
          subscriptionFee: item.fee,
          actualCharge: statisticsValueGenerator(item.actualCharge, {
            precesion: 2, shouldAddPlusSymbol: true
          }),
          actualExchangeRate: statisticsValueGenerator(item.exchangeRate, { precesion: 5 }),
          settledExchangeRate: statisticsValueGenerator(item.settledExchangeRate, { precesion: 5 }),
          exchangeCharge: statisticsValueGenerator(item.exchangeCharge, {
            precesion: 2, shouldDisplayDefaultDash: false
          }),
          platform: item.platform
        }));
        const groupByTransactionIdData = groupByTransactionId(subscriptionRowsData);
        setRows(groupByTransactionIdData);
        setTotalPageCount(responseData.paginate.totalPages);
      }
    );
  };

  const onExportClick = (): void => {
    sendRequest(
      getTradingCupSubscriptionExportRequestConfig(
        lang,
        queryOptions
      ),
      (responseData: Blob) => {
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);

        const objectUrl = window.URL.createObjectURL(responseData);

        anchor.href = objectUrl;
        anchor.download = 'tradingcup_subscription_.csv';
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage, searchInput, lang]);

  return {
    rows,
    setRows,
    totalPageCount,
    fetchData,
    onExportClick
  };
};

export default useTradingCupSubscriptionData;
