import * as React from "react";
import { SVGProps } from "react";

const SvgPendingWithdrawals = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 58 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.98 34.773 41.177 48V23.134L18.98 9.909v24.864Z"
      fill="#ED5656"
    />
    <path
      d="M20.215 34.05V12.102l19.727 11.755v21.95L20.215 34.05Z"
      fill="#8C1A1A"
    />
    <path
      d="M37.9 22.64 20.216 12.103v21.95L37.9 22.642ZM58 38.096 41.178 48V23.134L58 13.23v24.866Z"
      fill="#E92C2C"
    />
    <path
      d="m18.98 9.91 22.196 13.224 16.822-9.907L35.803 0 18.98 9.91Z"
      fill="#F6ABAB"
    />
    <path
      d="M31.343 29.48c-.305 0-.61.027-.91.081H28.8v.637c.042.55 1.074 1.146 2.542 1.146 1.469 0 2.5-.597 2.54-1.146v-.637H32.25a5.09 5.09 0 0 0-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__a)"
    />
    <path
      d="M29.088 30.687c.05.045.104.087.16.126v-1.259h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__b)"
    />
    <path
      d="M32.102 29.539V31.3l.163-.028v-1.699h-.015l-.148-.035Z"
      fill="url(#PendingWithdrawals_svg__c)"
    />
    <path
      d="m31.828 31.321.163-.018v-1.782l-.163-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__d)"
    />
    <path
      d="m33.2 30.951.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__e)"
    />
    <path
      d="M31.555 31.354h.163v-1.835h-.163v1.835Z"
      fill="url(#PendingWithdrawals_svg__f)"
    />
    <path
      d="M33.475 30.785c.056-.041.11-.087.16-.136v-1.088h-.16v1.224Z"
      fill="url(#PendingWithdrawals_svg__g)"
    />
    <path
      d="m32.648 31.168.163-.053v-1.553h-.163v1.606Z"
      fill="url(#PendingWithdrawals_svg__h)"
    />
    <path
      d="m32.375 31.238.163-.04V29.56h-.163v1.677Z"
      fill="url(#PendingWithdrawals_svg__i)"
    />
    <path
      d="m32.926 31.075.16-.068V29.56h-.16v1.514Z"
      fill="url(#PendingWithdrawals_svg__j)"
    />
    <path
      d="m29.922 31.13.16.048V29.56h-.16v1.569Z"
      fill="url(#PendingWithdrawals_svg__k)"
    />
    <path
      d="M31.006 31.334h.163v-1.858h-.163v1.858Z"
      fill="url(#PendingWithdrawals_svg__l)"
    />
    <path
      d="M31.28 29.48v1.874h.162v-1.86h-.163v-.013Z"
      fill="url(#PendingWithdrawals_svg__m)"
    />
    <path
      d="M28.8 30.198c.019.141.08.272.176.375v-1.007h-.175v.632Z"
      fill="url(#PendingWithdrawals_svg__n)"
    />
    <path
      d="M33.749 30.523a.624.624 0 0 0 .133-.325v-.637h-.136l.003.962Z"
      fill="url(#PendingWithdrawals_svg__o)"
    />
    <path
      d="M29.361 30.886c.052.03.104.058.16.085v-1.41h-.16v1.325Z"
      fill="url(#PendingWithdrawals_svg__p)"
    />
    <path
      d="m30.73 31.309.163.017v-1.825l-.163.018v1.79Z"
      fill="url(#PendingWithdrawals_svg__q)"
    />
    <path
      d="m29.637 31.024.16.066V29.56h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__r)"
    />
    <path
      d="M30.184 31.208c.053.016.108.028.162.038v-1.684h-.162v1.646Z"
      fill="url(#PendingWithdrawals_svg__s)"
    />
    <path
      d="m30.457 31.268.163.026V29.53l-.163.028v1.71Z"
      fill="url(#PendingWithdrawals_svg__t)"
    />
    <path
      d="M31.343 30.692c1.404 0 2.543-.528 2.543-1.178 0-.651-1.139-1.179-2.543-1.179-1.404 0-2.542.528-2.542 1.179 0 .65 1.138 1.178 2.542 1.178Z"
      fill="url(#PendingWithdrawals_svg__u)"
    />
    <path
      d="M29.676 30.392c.176.073.358.132.543.177l2.096-2.138a4.773 4.773 0 0 0-.632-.085l-2.007 2.046Z"
      fill="url(#PendingWithdrawals_svg__v)"
    />
    <path
      d="M31.137 30.687h.41l1.831-1.85a2.67 2.67 0 0 0-.267-.147l-1.974 1.997Z"
      fill="url(#PendingWithdrawals_svg__w)"
    />
    <path
      d="m30.53 30.599-.134-.033c-.086-.025-.175-.043-.261-.073-.087-.03-.173-.055-.247-.086l-.126-.05-.062-.025-.061-.028c-.084-.035-.163-.078-.247-.118a2.836 2.836 0 0 1-.454-.307c.103.16.238.298.394.405l.121.078c.04.025.082.045.124.068l.064.033.064.027c.045.02.087.04.131.056l.133.045c.045.015.092.025.136.038.092.022.185.039.279.05.091.015.185.015.279.023.094.007.185 0 .279-.013-.091-.025-.183-.04-.272-.06l-.14-.03Z"
      fill="url(#PendingWithdrawals_svg__x)"
    />
    <path
      d="m31.793 30.599-.366.037-.185.018c-.061 0-.126.018-.187.033.06.017.122.03.185.04l.094.015h.096a2.108 2.108 0 0 0 .75-.065 1.86 1.86 0 0 0 .358-.134c-.128 0-.247 0-.375.018l-.37.038Z"
      fill="url(#PendingWithdrawals_svg__y)"
    />
    <path
      d="M30.706 28.121c-.304 0-.608.027-.908.08h-1.634v.635c.04.547 1.071 1.146 2.54 1.146s2.5-.6 2.542-1.146v-.64h-1.634a5.113 5.113 0 0 0-.905-.075Z"
      fill="url(#PendingWithdrawals_svg__z)"
    />
    <path
      d="M28.455 29.34c.05.045.104.087.16.126v-1.26h-.16v1.134Z"
      fill="url(#PendingWithdrawals_svg__A)"
    />
    <path
      d="M31.469 28.18v1.762l.16-.028v-1.7h-.012l-.148-.035Z"
      fill="url(#PendingWithdrawals_svg__B)"
    />
    <path
      d="m31.195 29.962.16-.018v-1.78l-.16-.02v1.818Z"
      fill="url(#PendingWithdrawals_svg__C)"
    />
    <path
      d="M32.566 29.592c.057-.028.111-.06.16-.091v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__D)"
    />
    <path
      d="M30.922 29.982h.16v-1.835h-.16v1.835Z"
      fill="url(#PendingWithdrawals_svg__E)"
    />
    <path
      d="M32.84 29.425c.056-.041.11-.086.16-.136v-1.087h-.16v1.223Z"
      fill="url(#PendingWithdrawals_svg__F)"
    />
    <path
      d="m32.018 29.808.16-.05v-1.556h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__G)"
    />
    <path
      d="m31.744 29.879.16-.04v-1.637h-.16v1.677Z"
      fill="url(#PendingWithdrawals_svg__H)"
    />
    <path
      d="m32.293 29.715.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__I)"
    />
    <path
      d="m29.277 29.77.16.05v-1.618h-.16v1.568Z"
      fill="url(#PendingWithdrawals_svg__J)"
    />
    <path
      d="M30.373 29.974h.16V28.12h-.16v1.855Z"
      fill="url(#PendingWithdrawals_svg__K)"
    />
    <path
      d="M30.648 28.124v1.86h.16v-1.86h-.16Z"
      fill="url(#PendingWithdrawals_svg__L)"
    />
    <path
      d="M28.165 28.836c.017.14.078.272.175.373v-1.007h-.178l.003.634Z"
      fill="url(#PendingWithdrawals_svg__M)"
    />
    <path
      d="M33.112 29.164a.607.607 0 0 0 .136-.323v-.64h-.139l.003.963Z"
      fill="url(#PendingWithdrawals_svg__N)"
    />
    <path
      d="M28.73 29.526c.05.03.104.058.16.086v-1.41h-.16v1.324Z"
      fill="url(#PendingWithdrawals_svg__O)"
    />
    <path
      d="m30.1 29.95.16.017v-1.825l-.16.017v1.79Z"
      fill="url(#PendingWithdrawals_svg__P)"
    />
    <path
      d="M29.004 29.665c.052.022.104.045.16.065v-1.528h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__Q)"
    />
    <path
      d="m29.55 29.843.161.036v-1.685h-.16v1.65Z"
      fill="url(#PendingWithdrawals_svg__R)"
    />
    <path
      d="m29.826 29.909.16.025v-1.762l-.16.027v1.71Z"
      fill="url(#PendingWithdrawals_svg__S)"
    />
    <path
      d="M30.706 26.976c-1.498 0-2.542.62-2.542 1.178 0 .559 1.044 1.178 2.542 1.178 1.499 0 2.545-.622 2.545-1.178 0-.556-1.046-1.178-2.544-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M29.05 29.033c.178.073.36.132.546.176l2.093-2.138a4.754 4.754 0 0 0-.631-.085l-2.007 2.047ZM30.502 29.34h.41l1.826-1.86a2.452 2.452 0 0 0-.269-.147l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M29.895 29.226c-.045-.012-.089-.017-.131-.032-.089-.025-.178-.043-.264-.073-.087-.03-.17-.056-.247-.086-.042 0-.081-.033-.123-.048l-.064-.025-.062-.03c-.082-.033-.163-.078-.247-.118a2.73 2.73 0 0 1-.454-.308c.103.162.237.3.395.406.037.027.079.053.118.078.04.025.082.045.124.068l.064.032.064.028c.044.02.086.04.13.056l.136.045c.045.015.092.025.136.038.091.021.184.038.277.05.094.015.187.015.279.023.091.007.187 0 .279-.013-.09-.025-.18-.04-.27-.06l-.14-.03ZM31.157 29.24l-.366.034-.185.02a1.653 1.653 0 0 0-.19.03c.062.017.124.03.188.04l.09.016h.097a2.118 2.118 0 0 0 .753-.065c.123-.033.242-.077.355-.134-.128 0-.246 0-.375.018l-.367.04Z"
      fill="#fff"
    />
    <path
      d="M31.488 26.792c-.305 0-.609.026-.909.08h-1.634v.637c.04.55 1.072 1.146 2.54 1.146 1.469 0 2.5-.597 2.543-1.146v-.636h-1.64a5.108 5.108 0 0 0-.9-.081Z"
      fill="url(#PendingWithdrawals_svg__T)"
    />
    <path
      d="M29.236 27.998c.052.045.107.087.163.126v-1.259h-.163v1.133Z"
      fill="url(#PendingWithdrawals_svg__U)"
    />
    <path
      d="M32.252 26.85v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__V)"
    />
    <path
      d="m31.979 28.633.16-.018v-1.793l-.16-.017v1.828Z"
      fill="url(#PendingWithdrawals_svg__W)"
    />
    <path
      d="m33.348 28.262.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__X)"
    />
    <path
      d="M31.703 28.65h.16v-1.835h-.16v1.835Z"
      fill="url(#PendingWithdrawals_svg__Y)"
    />
    <path
      d="M33.625 28.094c.056-.041.11-.086.16-.134v-1.087h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__Z)"
    />
    <path
      d="m32.8 28.479.161-.053v-1.553h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__aa)"
    />
    <path
      d="m32.525 28.55.16-.041v-1.636h-.16v1.676Z"
      fill="url(#PendingWithdrawals_svg__ab)"
    />
    <path
      d="m33.074 28.386.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__ac)"
    />
    <path
      d="m30.059 28.441.162.048v-1.616h-.162v1.568Z"
      fill="url(#PendingWithdrawals_svg__ad)"
    />
    <path
      d="M31.156 28.645h.16v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__ae)"
    />
    <path
      d="M31.43 26.792v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__af)"
    />
    <path
      d="M28.948 27.51a.644.644 0 0 0 .175.375v-1.007h-.178l.003.632Z"
      fill="url(#PendingWithdrawals_svg__ag)"
    />
    <path
      d="M33.897 27.83a.656.656 0 0 0 .135-.325v-.632h-.135v.956Z"
      fill="url(#PendingWithdrawals_svg__ah)"
    />
    <path
      d="M29.51 28.194c.052.03.106.06.163.088v-1.41h-.163v1.322Z"
      fill="url(#PendingWithdrawals_svg__ai)"
    />
    <path
      d="m30.883 28.62.16.017v-1.815c-.054 0-.108 0-.16.015v1.783Z"
      fill="url(#PendingWithdrawals_svg__aj)"
    />
    <path
      d="M29.785 28.333c.05.022.104.045.16.065v-1.525h-.16v1.46Z"
      fill="url(#PendingWithdrawals_svg__ak)"
    />
    <path
      d="m30.334 28.52.16.037v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__al)"
    />
    <path
      d="m30.61 28.584.16.026v-1.763l-.16.026v1.711Z"
      fill="url(#PendingWithdrawals_svg__am)"
    />
    <path
      d="M31.488 25.644c-1.499 0-2.543.622-2.543 1.18 0 .56 1.044 1.179 2.543 1.179 1.498 0 2.542-.622 2.542-1.178 0-.557-1.044-1.181-2.542-1.181Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M29.832 27.703c.176.073.358.13.543.174l2.096-2.135a4.736 4.736 0 0 0-.632-.085l-2.007 2.046ZM31.285 27.998h.41l1.824-1.86a1.9 1.9 0 0 0-.267-.147l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m30.678 27.895-.133-.03c-.087-.026-.176-.043-.262-.073-.086-.03-.17-.058-.247-.086l-.126-.05-.061-.025-.062-.028c-.084-.036-.163-.078-.247-.121a2.584 2.584 0 0 1-.454-.307c.102.162.237.3.395.405.079.055.161.105.247.149l.064.032.064.028c.044.018.086.04.13.055l.137.046c.044.014.09.026.135.035.091.024.184.042.277.053.091.015.188.015.279.023.091.007.187 0 .279-.016a5.155 5.155 0 0 0-.272-.057l-.143-.033ZM31.944 27.907l-.368.038-.185.018c-.062 0-.124.017-.188.033.062.017.123.027.188.04l.091.015h.096a2.136 2.136 0 0 0 1.109-.199c-.126 0-.25.005-.375.018l-.368.037Z"
      fill="#fff"
    />
    <path
      d="M32.863 25.846c-.305-.001-.609.026-.909.083H30.32v.642c.04.55 1.072 1.148 2.54 1.148 1.469 0 2.5-.597 2.54-1.149v-.634H33.77a4.888 4.888 0 0 0-.906-.09Z"
      fill="url(#PendingWithdrawals_svg__an)"
    />
    <path
      d="M30.61 27.051c.051.046.106.088.162.126V25.92h-.163v1.132Z"
      fill="url(#PendingWithdrawals_svg__ao)"
    />
    <path
      d="M33.625 25.903v1.763l.16-.028V25.94h-.012l-.148-.038Z"
      fill="url(#PendingWithdrawals_svg__ap)"
    />
    <path
      d="m33.352 27.686.16-.018v-1.78l-.16-.02v1.818Z"
      fill="url(#PendingWithdrawals_svg__aq)"
    />
    <path
      d="M34.72 27.326c.058-.028.112-.06.161-.091v-1.296h-.16v1.387Z"
      fill="url(#PendingWithdrawals_svg__ar)"
    />
    <path
      d="M33.076 27.706h.16V25.87h-.16v1.835Z"
      fill="url(#PendingWithdrawals_svg__as)"
    />
    <path
      d="M34.994 27.15c.056-.042.11-.086.16-.134V25.93h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__at)"
    />
    <path
      d="m34.172 27.532.16-.05v-1.553h-.16v1.603Z"
      fill="url(#PendingWithdrawals_svg__au)"
    />
    <path
      d="m33.898 27.603.16-.038v-1.637h-.16v1.675Z"
      fill="url(#PendingWithdrawals_svg__av)"
    />
    <path
      d="m34.445 27.439.16-.068v-1.442h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__aw)"
    />
    <path
      d="m31.43 27.494.163.05v-1.616h-.163v1.566Z"
      fill="url(#PendingWithdrawals_svg__ax)"
    />
    <path
      d="M32.527 27.698h.16v-1.855h-.16v1.855Z"
      fill="url(#PendingWithdrawals_svg__ay)"
    />
    <path
      d="M32.803 25.848v1.86h.16v-1.86h-.16Z"
      fill="url(#PendingWithdrawals_svg__az)"
    />
    <path
      d="M30.319 26.57a.637.637 0 0 0 .175.375V25.94h-.178l.003.631Z"
      fill="url(#PendingWithdrawals_svg__aA)"
    />
    <path
      d="M35.268 26.888a.624.624 0 0 0 .133-.325v-.634h-.135l.002.959Z"
      fill="url(#PendingWithdrawals_svg__aB)"
    />
    <path
      d="M30.883 27.25c.052.03.106.058.163.086v-1.407h-.163v1.321Z"
      fill="url(#PendingWithdrawals_svg__aC)"
    />
    <path
      d="m32.254 27.673.16.018v-1.825l-.16.017v1.79Z"
      fill="url(#PendingWithdrawals_svg__aD)"
    />
    <path
      d="M31.156 27.389c.052.022.106.045.163.065v-1.526h-.163v1.46Z"
      fill="url(#PendingWithdrawals_svg__aE)"
    />
    <path
      d="m31.707 27.577.16.036V25.93h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__aF)"
    />
    <path
      d="m31.979 27.633.162.025v-1.762l-.162.027v1.71Z"
      fill="url(#PendingWithdrawals_svg__aG)"
    />
    <path
      d="M32.863 24.7c-1.498 0-2.543.622-2.543 1.178 0 .557 1.044 1.178 2.543 1.178 1.498 0 2.542-.621 2.542-1.178 0-.556-1.039-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M31.205 26.757c.176.073.358.131.543.176l2.096-2.137a4.353 4.353 0 0 0-.632-.084l-2.007 2.045ZM32.656 27.051h.41l1.824-1.86a1.907 1.907 0 0 0-.266-.144l-1.968 2.004Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M32.05 26.95c-.045 0-.09-.017-.134-.032-.087-.025-.175-.043-.262-.073-.086-.03-.17-.055-.247-.086-.042-.012-.084-.032-.125-.047l-.062-.026-.062-.03c-.084-.032-.163-.078-.247-.118a2.73 2.73 0 0 1-.454-.307c.103.161.238.299.395.405.037.028.079.053.118.078.04.025.082.045.124.068l.064.033.064.03c.045.018.087.038.131.053l.136.045c.044.015.089.025.136.038.182.043.368.067.555.073h.279c-.091-.025-.18-.04-.272-.06l-.138-.043ZM33.315 26.963l-.368.035-.185.02a1.68 1.68 0 0 0-.188.03c.062.018.124.031.185.044l.094.012h.096c.126.012.252.012.378 0a2.73 2.73 0 0 0 .375-.065 1.69 1.69 0 0 0 .356-.134c-.129 0-.247 0-.376.018l-.367.04Z"
      fill="#fff"
    />
    <path
      d="M35.023 27.64c-.305 0-.609.027-.909.08h-1.633v.638c.039.549 1.07 1.145 2.54 1.145 1.468 0 2.5-.596 2.54-1.145v-.637h-1.632c-.3-.054-.602-.08-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__aH)"
    />
    <path
      d="M32.768 28.836c.051.045.106.087.163.126v-1.259h-.163v1.133Z"
      fill="url(#PendingWithdrawals_svg__aI)"
    />
    <path
      d="M35.785 27.698v1.763l.16-.028v-1.7h-.012l-.148-.035Z"
      fill="url(#PendingWithdrawals_svg__aJ)"
    />
    <path
      d="m35.51 29.48.16-.017v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__aK)"
    />
    <path
      d="m36.88 29.11.161-.09v-1.299h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__aL)"
    />
    <path
      d="M35.236 29.501h.16v-1.835h-.16V29.5Z"
      fill="url(#PendingWithdrawals_svg__aM)"
    />
    <path
      d="M37.154 28.945c.057-.042.11-.087.16-.136V27.72h-.16v1.224Z"
      fill="url(#PendingWithdrawals_svg__aN)"
    />
    <path
      d="M36.34 29.34c.054-.018.108-.033.16-.053v-1.553h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__aO)"
    />
    <path
      d="m36.059 29.398.16-.04V27.72h-.16v1.677Z"
      fill="url(#PendingWithdrawals_svg__aP)"
    />
    <path
      d="m36.605 29.234.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__aQ)"
    />
    <path
      d="m33.59 29.29.163.05v-1.619h-.163v1.569Z"
      fill="url(#PendingWithdrawals_svg__aR)"
    />
    <path
      d="M34.688 29.493h.16v-1.855h-.16v1.855Z"
      fill="url(#PendingWithdrawals_svg__aS)"
    />
    <path
      d="M34.963 27.64v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__aT)"
    />
    <path
      d="M32.479 28.358a.645.645 0 0 0 .175.375v-1.007h-.177l.002.632Z"
      fill="url(#PendingWithdrawals_svg__aU)"
    />
    <path
      d="M37.428 28.683a.623.623 0 0 0 .134-.325v-.637h-.136l.002.962Z"
      fill="url(#PendingWithdrawals_svg__aV)"
    />
    <path
      d="M33.043 29.045c.052.03.106.058.163.086v-1.41h-.163v1.324Z"
      fill="url(#PendingWithdrawals_svg__aW)"
    />
    <path
      d="m34.414 29.468.16.018V27.66l-.16.017v1.79Z"
      fill="url(#PendingWithdrawals_svg__aX)"
    />
    <path
      d="M33.316 29.184c.052.023.107.045.163.065v-1.528h-.163v1.463Z"
      fill="url(#PendingWithdrawals_svg__aY)"
    />
    <path
      d="m33.871 29.37.16.035v-1.684h-.16v1.65Z"
      fill="url(#PendingWithdrawals_svg__aZ)"
    />
    <path
      d="m34.14 29.428.161.025v-1.762l-.16.028v1.71Z"
      fill="url(#PendingWithdrawals_svg__ba)"
    />
    <path
      d="M35.023 26.495c-1.498 0-2.543.62-2.543 1.178 0 .56 1.045 1.179 2.543 1.179 1.498 0 2.542-.622 2.542-1.179 0-.556-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M33.379 28.552c.176.073.358.131.543.174l2.096-2.135a4.736 4.736 0 0 0-.632-.086l-2.007 2.047Z"
      fill="#fff"
    />
    <path
      d="M34.816 28.836h.41l1.824-1.86a2.21 2.21 0 0 0-.266-.146l-1.968 2.006Z"
      fill="url(#PendingWithdrawals_svg__bb)"
    />
    <path
      opacity={0.25}
      d="m34.21 28.746-.134-.033c-.086-.025-.175-.043-.262-.073-.086-.03-.17-.056-.246-.086-.042 0-.084-.032-.126-.047l-.062-.026-.062-.03c-.084-.035-.163-.078-.247-.118a2.726 2.726 0 0 1-.454-.307c.103.16.238.299.395.405.037.028.08.053.119.078.04.025.081.045.123.068l.064.033.064.027c.045.02.087.04.131.056l.136.045c.044.015.089.025.136.038.09.022.183.038.276.05.092.015.185.015.28.023.093.007.187 0 .278-.013-.091-.025-.18-.04-.272-.06l-.138-.03Z"
      fill="#fff"
    />
    <path
      d="m35.475 28.756-.368.038-.185.02a1.72 1.72 0 0 0-.188.03c.061.017.123.03.185.04l.094.015h.097c.125.012.252.012.377 0 .127-.013.252-.035.375-.065.123-.032.242-.077.356-.134-.129 0-.247 0-.375.018l-.368.038Z"
      fill="url(#PendingWithdrawals_svg__bc)"
    />
    <path
      d="M27.15 31.475c-.305 0-.61.026-.911.08h-1.632v.64c.042.546 1.074 1.145 2.543 1.145s2.5-.599 2.54-1.145v-.64h-1.634c-.3-.053-.602-.08-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__bd)"
    />
    <path
      d="M24.896 32.68c.052.046.105.088.16.127v-1.26h-.16v1.134Z"
      fill="url(#PendingWithdrawals_svg__be)"
    />
    <path
      d="M27.91 31.533v1.762l.163-.028v-1.712h-.015l-.148-.022Z"
      fill="url(#PendingWithdrawals_svg__bf)"
    />
    <path
      d="m27.637 33.315.163-.018v-1.782l-.163-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__bg)"
    />
    <path
      d="M29.008 32.945c.055-.026.109-.057.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__bh)"
    />
    <path
      d="M27.363 33.335h.163V31.5h-.163v1.835Z"
      fill="url(#PendingWithdrawals_svg__bi)"
    />
    <path
      d="M29.281 32.779c.057-.04.111-.086.16-.136v-1.088h-.16v1.224Z"
      fill="url(#PendingWithdrawals_svg__bj)"
    />
    <path
      d="M28.46 33.162c.055-.015.11-.033.161-.053v-1.554h-.16v1.607Z"
      fill="url(#PendingWithdrawals_svg__bk)"
    />
    <path
      d="m28.195 33.232.163-.04v-1.637h-.163v1.677Z"
      fill="url(#PendingWithdrawals_svg__bl)"
    />
    <path
      d="m28.734 33.069.16-.069v-1.445h-.16v1.514Z"
      fill="url(#PendingWithdrawals_svg__bm)"
    />
    <path
      d="m25.727 33.116.163.05v-1.61h-.163v1.56Z"
      fill="url(#PendingWithdrawals_svg__bn)"
    />
    <path
      d="M26.814 33.328h.163V31.48h-.163v1.848Z"
      fill="url(#PendingWithdrawals_svg__bo)"
    />
    <path
      d="M27.088 31.477v1.86h.163v-1.86h-.163Z"
      fill="url(#PendingWithdrawals_svg__bp)"
    />
    <path
      d="M24.607 32.195c.018.14.08.27.176.372V31.56h-.176v.635Z"
      fill="url(#PendingWithdrawals_svg__bq)"
    />
    <path
      d="M29.557 32.517a.623.623 0 0 0 .134-.322v-.64h-.136l.002.962Z"
      fill="url(#PendingWithdrawals_svg__br)"
    />
    <path
      d="M25.172 32.88c.052.03.104.058.163.085v-1.41h-.163v1.325Z"
      fill="url(#PendingWithdrawals_svg__bs)"
    />
    <path
      d="m26.54 33.303.162.017v-1.825l-.163.017v1.79Z"
      fill="url(#PendingWithdrawals_svg__bt)"
    />
    <path
      d="m25.445 33.018.16.066v-1.529h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__bu)"
    />
    <path
      d="m25.992 33.204.163.036v-1.685h-.163v1.65Z"
      fill="url(#PendingWithdrawals_svg__bv)"
    />
    <path
      d="m26.266 33.262.163.025v-1.762l-.163.028v1.71Z"
      fill="url(#PendingWithdrawals_svg__bw)"
    />
    <path
      d="M27.15 30.33c-1.498 0-2.543.619-2.543 1.178 0 .558 1.045 1.178 2.543 1.178 1.498 0 2.542-.622 2.542-1.178 0-.557-1.044-1.179-2.542-1.179Z"
      fill="url(#PendingWithdrawals_svg__bx)"
    />
    <path
      opacity={0.25}
      d="M25.494 32.386c.176.073.358.131.543.174l2.096-2.135a4.769 4.769 0 0 0-.632-.086l-2.007 2.047ZM26.945 32.68h.41l1.824-1.86a2.68 2.68 0 0 0-.266-.146l-1.968 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m26.338 32.58-.134-.033c-.086-.025-.175-.043-.261-.073-.087-.03-.173-.055-.247-.085-.042-.013-.084-.033-.126-.048l-.062-.025-.061-.03c-.084-.033-.163-.079-.247-.119a2.833 2.833 0 0 1-.462-.31c.104.161.238.299.395.406l.121.078c.04.025.082.045.124.068l.064.032.064.03c.044.018.086.039.13.054l.134.045c.044.015.091.025.138.038.091.022.184.039.277.05.091.015.185.015.279.023.094.007.187 0 .279 0-.092-.025-.183-.04-.272-.06l-.133-.041ZM27.604 32.59l-.368.038-.185.02a1.586 1.586 0 0 0-.188.03c.061.017.123.03.185.04l.094.016h.096a2.108 2.108 0 0 0 .75-.066c.125-.03.245-.075.359-.133-.129 0-.247 0-.375.017l-.368.038Z"
      fill="#fff"
    />
    <path
      d="M27.406 30.397c-.305 0-.609.027-.909.08h-1.634v.638c.04.549 1.072 1.145 2.54 1.145 1.469 0 2.5-.596 2.543-1.145v-.637h-1.632c-.3-.054-.604-.081-.908-.08Z"
      fill="url(#PendingWithdrawals_svg__by)"
    />
    <path
      d="M25.156 31.606c.05.045.104.088.16.126v-1.26h-.16v1.134Z"
      fill="url(#PendingWithdrawals_svg__bz)"
    />
    <path
      d="M28.17 30.455v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__bA)"
    />
    <path
      d="m27.895 32.238.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__bB)"
    />
    <path
      d="m29.266 31.858.16-.091v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__bC)"
    />
    <path
      d="M27.621 32.258h.16v-1.836h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__bD)"
    />
    <path
      d="M29.54 31.701c.056-.041.11-.087.16-.136v-1.087h-.16V31.7Z"
      fill="url(#PendingWithdrawals_svg__bE)"
    />
    <path
      d="M28.719 32.084c.054-.018.108-.033.16-.053v-1.553h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__bF)"
    />
    <path
      d="m28.441 32.154.16-.04v-1.636h-.16v1.676Z"
      fill="url(#PendingWithdrawals_svg__bG)"
    />
    <path
      d="m28.992 31.99.16-.067v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__bH)"
    />
    <path
      d="m25.973 32.046.16.05v-1.618h-.16v1.568Z"
      fill="url(#PendingWithdrawals_svg__bI)"
    />
    <path
      d="M27.074 32.25h.16v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__bJ)"
    />
    <path
      d="M27.348 30.4v1.86h.16V30.4h-.16Z"
      fill="url(#PendingWithdrawals_svg__bK)"
    />
    <path
      d="M24.864 31.115a.66.66 0 0 0 .175.375v-1.007h-.178l.003.632Z"
      fill="url(#PendingWithdrawals_svg__bL)"
    />
    <path
      d="M29.813 31.44a.627.627 0 0 0 .135-.325v-.637h-.136v.961Z"
      fill="url(#PendingWithdrawals_svg__bM)"
    />
    <path
      d="M25.43 31.802c.05.03.103.058.16.086v-1.41h-.16v1.324Z"
      fill="url(#PendingWithdrawals_svg__bN)"
    />
    <path
      d="m26.799 32.225.16.018v-1.826c-.054 0-.108 0-.16.018v1.79Z"
      fill="url(#PendingWithdrawals_svg__bO)"
    />
    <path
      d="M25.703 31.94c.052.023.104.046.16.066v-1.528h-.16v1.462Z"
      fill="url(#PendingWithdrawals_svg__bP)"
    />
    <path
      d="M26.252 32.124c.052.015.106.028.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__bQ)"
    />
    <path
      d="m26.525 32.185.16.025v-1.762l-.16.027v1.71Z"
      fill="url(#PendingWithdrawals_svg__bR)"
    />
    <path
      d="M27.406 29.25c-1.499 0-2.543.621-2.543 1.18 0 .559 1.044 1.178 2.543 1.178 1.498 0 2.542-.622 2.542-1.178 0-.556-1.044-1.18-2.542-1.18Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M25.75 31.309c.177.073.36.131.546.174l2.093-2.135a4.736 4.736 0 0 0-.632-.086l-2.007 2.047ZM27.207 31.606h.41l1.826-1.86a2.476 2.476 0 0 0-.261-.154l-1.975 2.014Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M26.594 31.5c-.045 0-.09-.018-.131-.03-.09-.025-.178-.043-.262-.073-.084-.03-.173-.056-.247-.086l-.123-.05-.064-.025-.062-.028c-.081-.035-.163-.078-.247-.118a2.736 2.736 0 0 1-.454-.308c.103.162.237.3.395.406.037.027.079.053.118.078.04.025.084.045.124.068l.064.032.064.028c.045.02.087.04.13.056l.137.045c.044.015.091.025.136.038a2.7 2.7 0 0 0 .276.05c.094.015.188.015.279.023.091.007.187 0 .281-.013-.091-.025-.182-.04-.271-.06l-.143-.033ZM27.861 31.512l-.365.038-.185.018c-.064 0-.126.017-.188.032.061.018.123.031.185.04l.091.016h.097a2.135 2.135 0 0 0 1.108-.199c-.128 0-.247 0-.375.018l-.368.037Z"
      fill="#fff"
    />
    <path
      d="M32.675 32.686a4.865 4.865 0 0 0-.908.08h-1.634v.637c.04.55 1.071 1.146 2.54 1.146 1.468 0 2.5-.597 2.542-1.146v-.637h-1.631a4.89 4.89 0 0 0-.909-.08Z"
      fill="url(#PendingWithdrawals_svg__bS)"
    />
    <path
      d="M30.416 33.892c.05.045.104.087.16.126v-1.26h-.16v1.134Z"
      fill="url(#PendingWithdrawals_svg__bT)"
    />
    <path
      d="M33.438 32.741v1.75l.16-.028v-1.697h-.012l-.148-.025Z"
      fill="url(#PendingWithdrawals_svg__bU)"
    />
    <path
      d="m33.164 34.526.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__bV)"
    />
    <path
      d="M34.533 34.156c.057-.03.111-.06.16-.093v-1.297h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__bW)"
    />
    <path
      d="M32.885 34.544h.16v-1.838h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__bX)"
    />
    <path
      d="M34.809 33.987c.056-.04.11-.085.16-.133v-1.088h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__bY)"
    />
    <path
      d="m33.984 34.375.16-.05V32.77h-.16v1.604Z"
      fill="url(#PendingWithdrawals_svg__bZ)"
    />
    <path
      d="M33.71 34.44c.055 0 .11-.025.161-.037v-1.637h-.16v1.675Z"
      fill="url(#PendingWithdrawals_svg__ca)"
    />
    <path
      d="m34.258 34.28.16-.069v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__cb)"
    />
    <path
      d="m31.246 34.335.16.048v-1.617h-.16v1.569Z"
      fill="url(#PendingWithdrawals_svg__cc)"
    />
    <path
      d="M32.34 34.539h.16v-1.85h-.16v1.85Z"
      fill="url(#PendingWithdrawals_svg__cd)"
    />
    <path
      d="M32.613 32.686v1.863h.16v-1.863h-.16Z"
      fill="url(#PendingWithdrawals_svg__ce)"
    />
    <path
      d="M30.131 33.403c.017.14.079.271.176.373v-1.007h-.178l.002.634Z"
      fill="url(#PendingWithdrawals_svg__cf)"
    />
    <path
      d="M35.082 33.728a.637.637 0 0 0 .136-.325v-.637h-.136v.962Z"
      fill="url(#PendingWithdrawals_svg__cg)"
    />
    <path
      d="M30.695 34.088c.05.03.104.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__ch)"
    />
    <path
      d="m32.066 34.514.16.015v-1.826l-.16.018v1.793Z"
      fill="url(#PendingWithdrawals_svg__ci)"
    />
    <path
      d="m30.973 34.23.16.065v-1.529h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__cj)"
    />
    <path
      d="m31.52 34.413.16.038v-1.685h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__ck)"
    />
    <path
      d="m31.793 34.473.16.025v-1.762l-.16.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__cl)"
    />
    <path
      d="M32.675 31.538c-1.498 0-2.542.622-2.542 1.178 0 .556 1.044 1.18 2.542 1.18 1.499 0 2.543-.621 2.543-1.18 0-.559-1.044-1.178-2.543-1.178Z"
      fill="url(#PendingWithdrawals_svg__cm)"
    />
    <path
      d="M31.018 33.597c.177.072.36.13.545.174l2.093-2.135a4.74 4.74 0 0 0-.632-.086l-2.006 2.047Z"
      fill="url(#PendingWithdrawals_svg__cn)"
    />
    <path
      d="M32.469 33.892h.41l1.826-1.864a2.46 2.46 0 0 0-.269-.143l-1.967 2.006Z"
      fill="url(#PendingWithdrawals_svg__co)"
    />
    <path
      d="M31.862 33.788c-.045 0-.09-.017-.131-.03-.09-.028-.178-.043-.264-.075-.087-.033-.17-.056-.247-.086a1.283 1.283 0 0 1-.126-.048l-.062-.025-.061-.028c-.084-.035-.163-.078-.247-.12a2.584 2.584 0 0 1-.454-.308c.103.162.237.3.395.406.079.055.161.104.246.148l.064.033.065.028c.044.017.086.04.13.055l.136.045a2.68 2.68 0 0 0 .412.088c.094.013.188.016.28.02.092.002.186-.003.278-.012-.089-.023-.18-.04-.269-.058l-.145-.033Z"
      fill="url(#PendingWithdrawals_svg__cp)"
    />
    <path
      d="m33.131 33.8-.368.039-.185.017a1.732 1.732 0 0 0-.187.033c.061.018.126.028.187.04l.092.015h.096a2.118 2.118 0 0 0 .753-.065c.122-.031.241-.075.355-.13-.125-.001-.25.004-.375.017l-.368.035Z"
      fill="url(#PendingWithdrawals_svg__cq)"
    />
    <path
      d="M32.042 31.326a4.9 4.9 0 0 0-.91.08H29.5v.638c.042.549 1.074 1.145 2.542 1.145 1.47 0 2.5-.596 2.54-1.145v-.637h-1.634a4.864 4.864 0 0 0-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__cr)"
    />
    <path
      d="M29.79 32.532c.05.045.104.087.16.126v-1.259h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__cs)"
    />
    <path
      d="M32.803 31.384v1.75l.163-.028v-1.7h-.015l-.148-.022Z"
      fill="url(#PendingWithdrawals_svg__ct)"
    />
    <path
      d="m32.527 33.167.163-.018v-1.783l-.163-.017v1.818Z"
      fill="url(#PendingWithdrawals_svg__cu)"
    />
    <path
      d="m33.902 32.796.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__cv)"
    />
    <path
      d="M32.254 33.184h.163v-1.838h-.163v1.838Z"
      fill="url(#PendingWithdrawals_svg__cw)"
    />
    <path
      d="M34.176 32.628c.056-.04.11-.085.16-.134v-1.087h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__cx)"
    />
    <path
      d="m33.352 33.013.16-.053v-1.553h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__cy)"
    />
    <path
      d="m33.076 33.083.163-.04v-1.636h-.163v1.676Z"
      fill="url(#PendingWithdrawals_svg__cz)"
    />
    <path
      d="m33.625 32.92.163-.068v-1.445h-.163v1.513Z"
      fill="url(#PendingWithdrawals_svg__cA)"
    />
    <path
      d="m30.61 32.975.162.048v-1.616h-.163v1.568Z"
      fill="url(#PendingWithdrawals_svg__cB)"
    />
    <path
      d="M31.707 33.179h.163v-1.85h-.163v1.85Z"
      fill="url(#PendingWithdrawals_svg__cC)"
    />
    <path
      d="M31.98 31.326v1.863h.163v-1.86h-.163v-.003Z"
      fill="url(#PendingWithdrawals_svg__cD)"
    />
    <path
      d="M29.5 32.044c.018.14.08.272.175.375v-1.007H29.5v.632Z"
      fill="url(#PendingWithdrawals_svg__cE)"
    />
    <path
      d="M34.448 32.36a.632.632 0 0 0 .133-.324V31.4h-.136l.003.962Z"
      fill="url(#PendingWithdrawals_svg__cF)"
    />
    <path
      d="M30.063 32.728c.051.03.103.06.16.089v-1.41h-.16v1.321Z"
      fill="url(#PendingWithdrawals_svg__cG)"
    />
    <path
      d="M31.434 33.154c.054 0 .106.013.162.015v-1.815l-.162.018v1.782Z"
      fill="url(#PendingWithdrawals_svg__cH)"
    />
    <path
      d="m30.336 32.865.16.065v-1.528h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__cI)"
    />
    <path
      d="m30.885 33.053.163.038v-1.684h-.163v1.646Z"
      fill="url(#PendingWithdrawals_svg__cJ)"
    />
    <path
      d="m31.156 33.116.163.025V31.38l-.163.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__cK)"
    />
    <path
      d="M32.042 30.178c-1.498 0-2.542.622-2.542 1.181s1.044 1.178 2.542 1.178c1.499 0 2.543-.622 2.543-1.178 0-.556-1.044-1.18-2.543-1.18Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M30.387 32.238c.176.072.358.13.543.173l2.095-2.135a4.804 4.804 0 0 0-.631-.085l-2.007 2.047ZM31.836 32.532h.41l1.824-1.86a2.24 2.24 0 0 0-.267-.146l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m31.229 32.429-.133-.03c-.087-.028-.176-.043-.262-.073-.087-.03-.173-.058-.247-.086l-.126-.05-.062-.025-.061-.028c-.084-.035-.163-.078-.247-.12a2.538 2.538 0 0 1-.454-.308c.103.161.237.3.395.405.038.029.079.055.12.078.04.026.082.05.124.07a.51.51 0 0 0 .064.034l.064.027c.045.018.087.04.131.056l.134.045c.045.014.091.026.138.035a2.553 2.553 0 0 0 .834.06c-.091-.022-.183-.04-.271-.057l-.141-.033ZM32.496 32.441l-.367.038-.186.018c-.061 0-.125.017-.187.032.062.018.123.028.185.04l.094.016h.096a2.108 2.108 0 0 0 .75-.068c.124-.031.244-.075.358-.131a3.7 3.7 0 0 0-.375.018l-.368.037Z"
      fill="#fff"
    />
    <path
      d="M32.824 29.994a4.858 4.858 0 0 0-.911.084H30.28v.636c.042.55 1.072 1.146 2.543 1.146 1.47 0 2.5-.597 2.54-1.146v-.636H33.73a4.857 4.857 0 0 0-.906-.084Z"
      fill="url(#PendingWithdrawals_svg__cL)"
    />
    <path
      d="M30.57 31.203c.05.045.104.086.16.123v-1.259h-.16v1.136Z"
      fill="url(#PendingWithdrawals_svg__cM)"
    />
    <path
      d="M33.586 30.052v1.75l.163-.028v-1.697h-.015l-.148-.025Z"
      fill="url(#PendingWithdrawals_svg__cN)"
    />
    <path
      d="m33.313 31.837.162-.017v-1.783l-.163-.02v1.82Z"
      fill="url(#PendingWithdrawals_svg__cO)"
    />
    <path
      d="M34.68 31.467c.056-.03.11-.06.163-.093v-1.297h-.163v1.39Z"
      fill="url(#PendingWithdrawals_svg__cP)"
    />
    <path
      d="M33.035 31.858h.16v-1.836h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__cQ)"
    />
    <path
      d="M34.955 31.298c.057-.04.112-.085.163-.133v-1.088h-.163v1.221Z"
      fill="url(#PendingWithdrawals_svg__cR)"
    />
    <path
      d="m34.133 31.681.163-.05v-1.554h-.163v1.604Z"
      fill="url(#PendingWithdrawals_svg__cS)"
    />
    <path
      d="m33.871 31.752.163-.038v-1.637h-.163v1.675Z"
      fill="url(#PendingWithdrawals_svg__cT)"
    />
    <path
      d="M34.406 31.588c.056-.02.11-.042.163-.068v-1.443h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__cU)"
    />
    <path
      d="m31.402 31.643.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__cV)"
    />
    <path
      d="M32.488 31.858h.16V30h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__cW)"
    />
    <path
      d="M32.762 29.997v1.86h.163v-1.86h-.163Z"
      fill="url(#PendingWithdrawals_svg__cX)"
    />
    <path
      d="M30.281 30.715c.018.14.08.27.175.372V30.08h-.175v.635Z"
      fill="url(#PendingWithdrawals_svg__cY)"
    />
    <path
      d="M35.229 31.037a.6.6 0 0 0 .136-.323v-.637h-.138l.002.96Z"
      fill="url(#PendingWithdrawals_svg__cZ)"
    />
    <path
      d="M30.844 31.4c.05.03.103.057.16.087v-1.41h-.16V31.4Z"
      fill="url(#PendingWithdrawals_svg__da)"
    />
    <path
      d="m32.215 31.825.16.015v-1.825l-.16.017v1.793Z"
      fill="url(#PendingWithdrawals_svg__db)"
    />
    <path
      d="m31.12 31.54.16.066v-1.529h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__dc)"
    />
    <path
      d="m31.668 31.724.16.038v-1.685h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__dd)"
    />
    <path
      d="m31.941 31.782.16.027v-1.762l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__de)"
    />
    <path
      d="M32.824 28.849c-1.499 0-2.543.622-2.543 1.178 0 .556 1.044 1.178 2.543 1.178 1.498 0 2.542-.619 2.542-1.178 0-.559-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M31.168 30.906c.176.073.358.132.543.176l2.093-2.137a4.703 4.703 0 0 0-.632-.083l-2.004 2.044ZM32.62 31.203h.406l1.827-1.863a2.65 2.65 0 0 0-.267-.144l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M32.014 31.102a1.27 1.27 0 0 1-.133-.033c-.09-.025-.178-.042-.262-.073-.084-.03-.173-.055-.247-.085-.044 0-.084-.033-.126-.048l-.064-.025-.06-.028c-.083-.035-.162-.078-.246-.12a2.68 2.68 0 0 1-.454-.308c.103.161.237.3.395.405a1.973 1.973 0 0 0 .244.148l.062.034.066.027c.043.018.087.038.131.053l.134.046c.044.015.091.025.135.037.184.043.37.068.558.073h.28c-.092-.025-.183-.04-.272-.058l-.141-.045ZM33.277 31.102l-.365.035-.185.02a1.524 1.524 0 0 0-.188.03c.06.019.123.033.185.044l.094.015h.094c.126.01.253.01.38 0 .126-.012.25-.034.373-.066.123-.03.243-.075.358-.133-.129 0-.247 0-.378.02l-.368.035Z"
      fill="#fff"
    />
    <path
      d="M28.484 34.68a4.865 4.865 0 0 0-.909.08h-1.634v.637c.04.55 1.072 1.146 2.54 1.146 1.47 0 2.5-.597 2.543-1.146v-.637h-1.632a4.89 4.89 0 0 0-.908-.08Z"
      fill="url(#PendingWithdrawals_svg__df)"
    />
    <path
      d="M26.232 35.886c.05.045.105.087.16.125v-1.258h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__dg)"
    />
    <path
      d="M29.246 34.737v1.75l.16-.027v-1.7h-.012c-.05-.007-.098-.017-.148-.023Z"
      fill="url(#PendingWithdrawals_svg__dh)"
    />
    <path
      d="m28.973 36.52.16-.018V34.72l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__di)"
    />
    <path
      d="m30.342 36.15.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__dj)"
    />
    <path
      d="M28.688 36.538h.16V34.7h-.16v1.837Z"
      fill="url(#PendingWithdrawals_svg__dk)"
    />
    <path
      d="M30.615 35.981c.057-.04.11-.085.16-.133V34.76h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__dl)"
    />
    <path
      d="m29.793 36.367.16-.053V34.76h-.16v1.607Z"
      fill="url(#PendingWithdrawals_svg__dm)"
    />
    <path
      d="M29.52 36.434c.054 0 .108-.022.16-.037V34.76h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__dn)"
    />
    <path
      d="m30.066 36.273.16-.068V34.76h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__do)"
    />
    <path
      d="m27.055 36.329.16.047V34.76h-.16v1.569Z"
      fill="url(#PendingWithdrawals_svg__dp)"
    />
    <path
      d="M28.15 36.532h.16v-1.857h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__dq)"
    />
    <path
      d="M28.424 34.68v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__dr)"
    />
    <path
      d="M25.94 35.397a.66.66 0 0 0 .175.375v-1.007h-.177l.002.632Z"
      fill="url(#PendingWithdrawals_svg__ds)"
    />
    <path
      d="M30.89 35.722a.637.637 0 0 0 .136-.325v-.637h-.135v.962Z"
      fill="url(#PendingWithdrawals_svg__dt)"
    />
    <path
      d="M26.506 36.082c.05.03.104.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__du)"
    />
    <path
      d="M27.875 36.507c.052 0 .106 0 .16.016v-1.826l-.16.018v1.792Z"
      fill="url(#PendingWithdrawals_svg__dv)"
    />
    <path
      d="M26.78 36.223c.051.023.103.045.16.065V34.76h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__dw)"
    />
    <path
      d="m27.328 36.407.16.038V34.76h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__dx)"
    />
    <path
      d="m27.602 36.467.16.025V34.73l-.16.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__dy)"
    />
    <path
      d="M28.484 33.532c-1.498 0-2.543.622-2.543 1.18 0 .56 1.045 1.179 2.543 1.179 1.498 0 2.542-.622 2.542-1.178 0-.557-1.044-1.181-2.542-1.181Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M26.828 35.591c.177.072.36.13.546.174l2.093-2.135a4.74 4.74 0 0 0-.632-.086l-2.007 2.047ZM28.277 35.885h.41l1.827-1.86a2.1 2.1 0 0 0-.27-.146l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M27.67 35.782c-.044 0-.089-.017-.13-.03-.09-.028-.178-.043-.262-.076-.084-.032-.173-.052-.247-.083l-.128-.05-.062-.025-.062-.028c-.084-.035-.163-.078-.247-.12a2.59 2.59 0 0 1-.454-.308c.103.162.238.3.395.406.08.054.162.104.247.148l.064.033.064.027c.045.018.087.04.131.056l.136.045c.045.014.09.026.136.035.09.024.183.042.276.053.094.013.188.015.279.02.093.001.186-.003.279-.012-.089-.023-.18-.04-.27-.058l-.145-.033ZM28.936 35.795l-.366.038-.185.017c-.064 0-.126.018-.19.033.062.018.126.028.188.04l.091.015h.096a2.118 2.118 0 0 0 1.109-.199c-.125 0-.25.006-.375.018l-.368.038Z"
      fill="#fff"
    />
    <path
      d="M28.742 33.6c-.306-.001-.61.027-.911.083h-1.632v.637c.042.548 1.074 1.145 2.543 1.145 1.468 0 2.5-.597 2.54-1.145v-.637h-1.634a4.858 4.858 0 0 0-.906-.083Z"
      fill="url(#PendingWithdrawals_svg__dz)"
    />
    <path
      d="M26.488 34.808c.05.043.102.083.16.126v-1.259h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__dA)"
    />
    <path
      d="M29.502 33.657v1.763l.163-.03v-1.697h-.015l-.148-.035Z"
      fill="url(#PendingWithdrawals_svg__dB)"
    />
    <path
      d="m29.229 35.442.162-.017v-1.783l-.162-.017v1.817Z"
      fill="url(#PendingWithdrawals_svg__dC)"
    />
    <path
      d="M30.602 35.072c.056-.03.108-.06.16-.093v-1.296h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__dD)"
    />
    <path
      d="M28.953 35.46h.163v-1.84h-.163v1.84Z"
      fill="url(#PendingWithdrawals_svg__dE)"
    />
    <path
      d="M30.875 34.904c.056-.041.11-.086.16-.134v-1.087h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__dF)"
    />
    <path
      d="m30.05 35.286.164-.05v-1.553h-.163v1.603Z"
      fill="url(#PendingWithdrawals_svg__dG)"
    />
    <path
      d="M29.777 35.357c.055 0 .107-.025.16-.038v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__dH)"
    />
    <path
      d="M30.324 35.193c.056-.02.11-.042.163-.068v-1.442h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__dI)"
    />
    <path
      d="m27.31 35.249.163.05v-1.616h-.162v1.566Z"
      fill="url(#PendingWithdrawals_svg__dJ)"
    />
    <path
      d="M28.406 35.455h.163v-1.858h-.163v1.858Z"
      fill="url(#PendingWithdrawals_svg__dK)"
    />
    <path
      d="M28.688 33.602v1.863h.162v-1.86h-.163v-.003Z"
      fill="url(#PendingWithdrawals_svg__dL)"
    />
    <path
      d="M26.2 34.32c.017.14.078.27.175.372v-1.007h-.176v.635Z"
      fill="url(#PendingWithdrawals_svg__dM)"
    />
    <path
      d="M31.156 34.644a.63.63 0 0 0 .134-.324v-.637h-.134v.961Z"
      fill="url(#PendingWithdrawals_svg__dN)"
    />
    <path
      d="M26.762 35.004c.052.03.103.06.16.089v-1.41h-.16v1.321Z"
      fill="url(#PendingWithdrawals_svg__dO)"
    />
    <path
      d="m28.133 35.43.163.015V33.62l-.163.017v1.793Z"
      fill="url(#PendingWithdrawals_svg__dP)"
    />
    <path
      d="m27.035 35.145.16.066v-1.528h-.16v1.462Z"
      fill="url(#PendingWithdrawals_svg__dQ)"
    />
    <path
      d="m27.586 35.33.163.037v-1.684h-.163v1.646Z"
      fill="url(#PendingWithdrawals_svg__dR)"
    />
    <path
      d="m27.86 35.382.162.025v-1.762l-.163.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__dS)"
    />
    <path
      d="M28.742 32.454c-1.499 0-2.543.622-2.543 1.178 0 .557 1.044 1.181 2.543 1.181 1.498 0 2.542-.622 2.542-1.18 0-.56-1.044-1.179-2.542-1.179Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M27.086 34.514c.176.072.358.13.543.173l2.096-2.137a4.802 4.802 0 0 0-.632-.083l-2.007 2.047ZM28.537 34.808h.41l1.824-1.863a2.672 2.672 0 0 0-.267-.144l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M27.93 34.705c-.044 0-.089-.018-.133-.03-.087-.028-.175-.046-.262-.076-.086-.03-.173-.055-.247-.085-.042-.013-.084-.033-.126-.048l-.061-.026-.062-.027c-.084-.035-.163-.078-.247-.121a2.68 2.68 0 0 1-.454-.307c.104.16.238.298.395.405.039.029.079.055.12.078.04.025.082.048.124.07l.062.034.067.027.13.056.134.045c.044.015.091.022.138.035.091.023.183.04.276.053.092.013.186.015.28.02.092 0 .186-.003.278-.013-.091-.022-.182-.04-.271-.057l-.141-.033ZM29.198 34.718l-.368.037-.185.018c-.062 0-.126.017-.188.033.062.017.123.027.185.04l.094.015h.096a2.108 2.108 0 0 0 .75-.066c.124-.03.244-.075.358-.13-.125 0-.25.005-.375.017l-.367.035Z"
      fill="#fff"
    />
    <path
      d="M37.389 32.507a4.874 4.874 0 0 0-.911.08h-1.634v.637c.042.55 1.071 1.146 2.542 1.146 1.471 0 2.5-.597 2.54-1.146v-.636h-1.634a4.864 4.864 0 0 0-.903-.081Z"
      fill="url(#PendingWithdrawals_svg__dT)"
    />
    <path
      d="M35.137 33.713c.05.046.104.088.16.126V32.58h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__dU)"
    />
    <path
      d="M38.148 32.562v1.763l.163-.03v-1.708h-.014l-.149-.025Z"
      fill="url(#PendingWithdrawals_svg__dV)"
    />
    <path
      d="m37.875 34.347.16-.017v-1.783l-.16-.017v1.817Z"
      fill="url(#PendingWithdrawals_svg__dW)"
    />
    <path
      d="M39.246 33.977c.057-.03.111-.06.163-.093v-1.296h-.163v1.39Z"
      fill="url(#PendingWithdrawals_svg__dX)"
    />
    <path
      d="M37.602 34.375h.16v-1.838h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__dY)"
    />
    <path
      d="M39.52 33.809c.057-.04.111-.085.162-.134v-1.087h-.163v1.22Z"
      fill="url(#PendingWithdrawals_svg__dZ)"
    />
    <path
      d="m38.697 34.194.163-.053v-1.553h-.163v1.606Z"
      fill="url(#PendingWithdrawals_svg__ea)"
    />
    <path
      d="M38.422 34.262c.057 0 .109-.025.16-.038v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__eb)"
    />
    <path
      d="m38.973 34.1.163-.067v-1.445h-.163V34.1Z"
      fill="url(#PendingWithdrawals_svg__ec)"
    />
    <path
      d="m35.957 34.154.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__ed)"
    />
    <path
      d="M37.055 34.36h.16v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__ee)"
    />
    <path
      d="M37.328 32.507v1.868h.16v-1.863h-.16v-.005Z"
      fill="url(#PendingWithdrawals_svg__ef)"
    />
    <path
      d="M34.842 33.224c.018.14.08.271.176.373V32.59h-.178l.002.634Z"
      fill="url(#PendingWithdrawals_svg__eg)"
    />
    <path
      d="M39.797 33.55a.616.616 0 0 0 .136-.325v-.637h-.136v.961Z"
      fill="url(#PendingWithdrawals_svg__eh)"
    />
    <path
      d="M35.41 33.91c.05.03.104.06.16.088v-1.41h-.16v1.321Z"
      fill="url(#PendingWithdrawals_svg__ei)"
    />
    <path
      d="m36.78 34.335.16.015v-1.825l-.16.017v1.793Z"
      fill="url(#PendingWithdrawals_svg__ej)"
    />
    <path
      d="m35.684 34.05.16.066v-1.528h-.16v1.462Z"
      fill="url(#PendingWithdrawals_svg__ek)"
    />
    <path
      d="m36.23 34.234.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__el)"
    />
    <path
      d="m36.504 34.294.16.026v-1.763l-.16.026v1.712Z"
      fill="url(#PendingWithdrawals_svg__em)"
    />
    <path
      d="M37.389 31.354c-1.501 0-2.545.622-2.545 1.178 0 .557 1.044 1.181 2.545 1.181 1.5 0 2.542-.622 2.542-1.18 0-.56-1.044-1.179-2.542-1.179Z"
      fill="url(#PendingWithdrawals_svg__en)"
    />
    <path
      d="M35.732 33.418c.176.073.358.131.544.174l2.093-2.135a4.676 4.676 0 0 0-.632-.085l-2.005 2.046Z"
      fill="url(#PendingWithdrawals_svg__eo)"
    />
    <path
      d="M37.184 33.713H37.59l1.826-1.863a2.692 2.692 0 0 0-.266-.144l-1.967 2.007Z"
      fill="url(#PendingWithdrawals_svg__ep)"
    />
    <path
      d="M36.587 33.62c-.045 0-.092-.018-.134-.03-.088-.028-.177-.046-.261-.076-.084-.03-.173-.056-.247-.086a1.075 1.075 0 0 1-.126-.047l-.064-.026-.062-.027c-.081-.036-.16-.078-.247-.121a2.68 2.68 0 0 1-.454-.307c.103.16.238.299.395.405.038.028.078.054.119.078l.126.07.061.033.067.028c.042.018.086.04.13.055l.134.046a2.68 2.68 0 0 0 .412.088c.094 0 .188.015.282.02.093 0 .186-.004.278-.013-.09-.022-.182-.04-.271-.058l-.138-.032Z"
      fill="url(#PendingWithdrawals_svg__eq)"
    />
    <path
      d="m37.84 33.62-.366.038-.185.017a1.527 1.527 0 0 0-.187.033c.061.017.123.028.185.04l.093.015h.094a2.135 2.135 0 0 0 .753-.065c.123-.031.242-.075.356-.131-.126 0-.247 0-.376.017l-.367.036Z"
      fill="url(#PendingWithdrawals_svg__er)"
    />
    <path
      d="M36.755 31.148a4.865 4.865 0 0 0-.908.08h-1.634v.63c.04.548 1.071 1.145 2.54 1.145s2.5-.597 2.54-1.145v-.63H37.66a4.915 4.915 0 0 0-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__es)"
    />
    <path
      d="M34.5 32.36c.052.046.106.088.163.127v-1.259H34.5v1.133Z"
      fill="url(#PendingWithdrawals_svg__et)"
    />
    <path
      d="M37.516 31.205v1.75l.16-.028v-1.699h-.012l-.148-.023Z"
      fill="url(#PendingWithdrawals_svg__eu)"
    />
    <path
      d="m37.242 32.988.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__ev)"
    />
    <path
      d="m38.613 32.613.16-.09v-1.295h-.16v1.385Z"
      fill="url(#PendingWithdrawals_svg__ew)"
    />
    <path
      d="M36.969 33.005h.16v-1.838h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__ex)"
    />
    <path
      d="M38.887 32.45c.056-.042.11-.086.16-.134v-1.088h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__ey)"
    />
    <path
      d="m38.066 32.834.16-.053v-1.553h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__ez)"
    />
    <path
      d="m37.793 32.905.16-.04v-1.637h-.16v1.677Z"
      fill="url(#PendingWithdrawals_svg__eA)"
    />
    <path
      d="m38.34 32.741.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__eB)"
    />
    <path
      d="m35.324 32.797.163.047v-1.616h-.163v1.569Z"
      fill="url(#PendingWithdrawals_svg__eC)"
    />
    <path
      d="M36.422 33h.16v-1.858h-.16V33Z"
      fill="url(#PendingWithdrawals_svg__eD)"
    />
    <path
      d="M36.695 31.148v1.863h.16V31.15h-.16v-.002Z"
      fill="url(#PendingWithdrawals_svg__eE)"
    />
    <path
      d="M34.213 31.858a.645.645 0 0 0 .176.375v-1.007h-.178l.002.632Z"
      fill="url(#PendingWithdrawals_svg__eF)"
    />
    <path
      d="M39.16 32.19a.63.63 0 0 0 .14-.333v-.629h-.135l-.005.962Z"
      fill="url(#PendingWithdrawals_svg__eG)"
    />
    <path
      d="M34.773 32.55c.052.03.107.06.163.088v-1.41h-.163v1.322Z"
      fill="url(#PendingWithdrawals_svg__eH)"
    />
    <path
      d="M36.148 32.975c.052 0 .107.013.16.015v-1.825l-.16.018v1.792Z"
      fill="url(#PendingWithdrawals_svg__eI)"
    />
    <path
      d="M35.049 32.69c.052.023.106.046.163.066v-1.528h-.163v1.463Z"
      fill="url(#PendingWithdrawals_svg__eJ)"
    />
    <path
      d="m35.598 32.864.16.038v-1.674h-.16v1.636Z"
      fill="url(#PendingWithdrawals_svg__eK)"
    />
    <path
      d="m35.875 32.935.16.025v-1.762l-.16.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__eL)"
    />
    <path
      d="M36.755 30c-1.498 0-2.542.621-2.542 1.18 0 .56 1.044 1.178 2.542 1.178 1.499 0 2.543-.621 2.543-1.178 0-.556-1.044-1.18-2.543-1.18Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M35.105 32.059c.177.072.358.13.544.174l2.095-2.135a4.74 4.74 0 0 0-.632-.086l-2.007 2.047Z"
      fill="#fff"
    />
    <path
      d="M36.55 32.361h.411l1.824-1.86a1.921 1.921 0 0 0-.267-.146l-1.967 2.006Z"
      fill="url(#PendingWithdrawals_svg__eM)"
    />
    <path
      opacity={0.25}
      d="m35.944 32.25-.134-.03c-.086-.025-.175-.043-.261-.073l-.247-.085-.126-.05-.062-.026-.061-.028c-.084-.035-.163-.078-.247-.12a2.58 2.58 0 0 1-.454-.308c.102.162.237.3.395.406.079.054.161.104.246.148l.065.033.064.028c.044.017.086.04.13.055l.136.045c.045.015.09.026.136.036a2.604 2.604 0 0 0 .555.073c.094 0 .187-.004.28-.013a5.385 5.385 0 0 0-.272-.058l-.143-.033Z"
      fill="#fff"
    />
    <path
      d="m37.21 32.263-.368.038-.186.017c-.061 0-.123.018-.187.033.062.017.123.028.185.04l.094.015h.096a2.107 2.107 0 0 0 .75-.068c.124-.03.244-.074.358-.13-.125-.001-.25.005-.375.017l-.368.038Z"
      fill="url(#PendingWithdrawals_svg__eN)"
    />
    <path
      d="M37.537 29.816c-.306-.001-.61.027-.911.083h-1.632v.637c.04.549 1.071 1.145 2.54 1.145s2.5-.596 2.54-1.145v-.637H38.44a4.847 4.847 0 0 0-.903-.083Z"
      fill="url(#PendingWithdrawals_svg__eO)"
    />
    <path
      d="M35.285 31.024c.052.044.107.086.163.123V29.89h-.163v1.135Z"
      fill="url(#PendingWithdrawals_svg__eP)"
    />
    <path
      d="M38.3 29.873v1.75l.161-.027v-1.697h-.015l-.145-.026Z"
      fill="url(#PendingWithdrawals_svg__eQ)"
    />
    <path
      d="m38.027 31.659.16-.018v-1.782l-.16-.02v1.82Z"
      fill="url(#PendingWithdrawals_svg__eR)"
    />
    <path
      d="M39.395 31.288c.056-.03.11-.06.16-.093V29.9h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__eS)"
    />
    <path
      d="M37.75 31.676h.163v-1.832h-.163v1.832Z"
      fill="url(#PendingWithdrawals_svg__eT)"
    />
    <path
      d="M39.668 31.12c.056-.04.11-.085.16-.134V29.9h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__eU)"
    />
    <path
      d="m38.848 31.502.16-.05v-1.553h-.16v1.603Z"
      fill="url(#PendingWithdrawals_svg__eV)"
    />
    <path
      d="m38.574 31.573.16-.038V29.9h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__eW)"
    />
    <path
      d="M39.121 31.41c.054-.02.109-.044.16-.069V29.9h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__eX)"
    />
    <path
      d="m36.105 31.465.163.05v-1.616h-.163v1.566Z"
      fill="url(#PendingWithdrawals_svg__eY)"
    />
    <path
      d="M37.203 31.669h.163V29.81h-.163v1.858Z"
      fill="url(#PendingWithdrawals_svg__eZ)"
    />
    <path
      d="M37.477 29.818v1.86h.163v-1.86h-.163Z"
      fill="url(#PendingWithdrawals_svg__fa)"
    />
    <path
      d="M34.995 30.536a.65.65 0 0 0 .172.372v-1.007h-.175l.003.635Z"
      fill="url(#PendingWithdrawals_svg__fb)"
    />
    <path
      d="M39.944 30.85a.616.616 0 0 0 .133-.322v-.637h-.136l.003.96Z"
      fill="url(#PendingWithdrawals_svg__fc)"
    />
    <path
      d="M35.559 31.22c.051.03.106.06.163.088v-1.41h-.163v1.322Z"
      fill="url(#PendingWithdrawals_svg__fd)"
    />
    <path
      d="m36.926 31.646.163.015v-1.818l-.163.018v1.785Z"
      fill="url(#PendingWithdrawals_svg__fe)"
    />
    <path
      d="m35.832 31.354.163.065v-1.528h-.163v1.463Z"
      fill="url(#PendingWithdrawals_svg__ff)"
    />
    <path
      d="m36.379 31.545.163.038v-1.684h-.163v1.646Z"
      fill="url(#PendingWithdrawals_svg__fg)"
    />
    <path
      d="m36.652 31.606.163.027v-1.762l-.163.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__fh)"
    />
    <path
      d="M37.537 28.67c-1.499 0-2.543.622-2.543 1.178 0 .557 1.044 1.179 2.543 1.179 1.498 0 2.542-.62 2.542-1.179 0-.558-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M35.88 30.727c.177.074.358.133.544.176l2.096-2.137a4.804 4.804 0 0 0-.632-.083l-2.007 2.044Z"
      fill="#fff"
    />
    <path
      d="M37.328 31.024h.41l1.824-1.863a2.65 2.65 0 0 0-.267-.143l-1.967 2.006Z"
      fill="url(#PendingWithdrawals_svg__fi)"
    />
    <path
      opacity={0.25}
      d="M36.725 30.92a1.282 1.282 0 0 1-.133-.032c-.087-.025-.176-.043-.262-.073-.086-.03-.173-.055-.247-.086-.042-.012-.084-.032-.126-.047l-.062-.026-.061-.027c-.084-.036-.163-.078-.247-.121a2.683 2.683 0 0 1-.454-.307c.103.16.238.298.395.405a2.002 2.002 0 0 0 .244.148l.064.033.065.028c.044.018.086.038.13.053l.134.045.138.038c.091.023.183.038.276.053.094.015.185.015.28.02.093.005.187 0 .278-.013-.091-.025-.182-.04-.271-.058l-.141-.032Z"
      fill="#fff"
    />
    <path
      d="m37.99 30.934-.367.035-.185.02c-.062 0-.126.018-.188.03.06.018.123.032.185.043l.094.015h.096c.126.01.252.01.378 0 .126-.012.25-.033.373-.065.123-.03.244-.076.358-.134-.129 0-.247 0-.376.02l-.367.036Z"
      fill="url(#PendingWithdrawals_svg__fj)"
    />
    <path
      d="M37.21 27.902a4.867 4.867 0 0 0-.91.083h-1.632v.637c.042.55 1.074 1.146 2.542 1.146 1.47 0 2.5-.597 2.54-1.146v-.637h-1.634a4.865 4.865 0 0 0-.906-.083Z"
      fill="url(#PendingWithdrawals_svg__fk)"
    />
    <path
      d="M34.957 29.11c.05.046.104.087.16.124v-1.259h-.16v1.136Z"
      fill="url(#PendingWithdrawals_svg__fl)"
    />
    <path
      d="M37.973 27.96v1.75l.163-.028v-1.697h-.015l-.148-.025Z"
      fill="url(#PendingWithdrawals_svg__fm)"
    />
    <path
      d="m37.7 29.745.162-.017v-1.783l-.163-.02v1.82Z"
      fill="url(#PendingWithdrawals_svg__fn)"
    />
    <path
      d="M39.066 29.372c.056-.027.11-.057.163-.09v-1.297h-.163v1.387Z"
      fill="url(#PendingWithdrawals_svg__fo)"
    />
    <path
      d="M37.422 29.763h.16v-1.836h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__fp)"
    />
    <path
      d="M39.34 29.206a1.31 1.31 0 0 0 .163-.133v-1.088h-.163v1.221Z"
      fill="url(#PendingWithdrawals_svg__fq)"
    />
    <path
      d="m38.52 29.592.162-.05v-1.554h-.163v1.604Z"
      fill="url(#PendingWithdrawals_svg__fr)"
    />
    <path
      d="m38.246 29.66.163-.038v-1.637h-.163v1.675Z"
      fill="url(#PendingWithdrawals_svg__fs)"
    />
    <path
      d="M38.793 29.496c.056-.02.11-.042.163-.068v-1.443h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__ft)"
    />
    <path
      d="m35.781 29.551.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__fu)"
    />
    <path
      d="M36.875 29.755h.163v-1.857h-.163v1.857Z"
      fill="url(#PendingWithdrawals_svg__fv)"
    />
    <path
      d="M37.148 27.905v1.863h.16v-1.863h-.16Z"
      fill="url(#PendingWithdrawals_svg__fw)"
    />
    <path
      d="M34.668 28.622c.018.14.08.271.175.373v-1.007h-.175v.634Z"
      fill="url(#PendingWithdrawals_svg__fx)"
    />
    <path
      d="M39.616 28.945a.6.6 0 0 0 .136-.323v-.637h-.139l.003.96Z"
      fill="url(#PendingWithdrawals_svg__fy)"
    />
    <path
      d="M35.23 29.307c.052.03.104.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__fz)"
    />
    <path
      d="m36.602 29.73.16.018v-1.826l-.16.018v1.79Z"
      fill="url(#PendingWithdrawals_svg__fA)"
    />
    <path
      d="m35.504 29.448.16.066v-1.529h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__fB)"
    />
    <path
      d="m36.055 29.632.16.038v-1.685h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__fC)"
    />
    <path
      d="m36.34 29.69.16.027v-1.762l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__fD)"
    />
    <path
      d="M37.21 26.757c-1.498 0-2.542.622-2.542 1.178 0 .556 1.044 1.178 2.542 1.178 1.499 0 2.543-.619 2.543-1.178 0-.559-1.044-1.178-2.543-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M35.555 28.814c.176.073.358.132.543.176l2.093-2.137a4.704 4.704 0 0 0-.632-.084l-2.004 2.045ZM37.006 29.11h.407l1.827-1.862a2.693 2.693 0 0 0-.267-.144l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M36.4 29.008a1.27 1.27 0 0 1-.133-.033c-.089-.025-.175-.043-.261-.073-.087-.03-.173-.056-.247-.086-.044 0-.084-.032-.126-.048l-.064-.025-.06-.027c-.083-.036-.162-.079-.246-.121a2.68 2.68 0 0 1-.454-.307c.103.16.238.298.395.405.039.03.078.053.118.078l.126.07.062.033.066.028c.042.017.087.038.131.053l.133.045c.045.015.092.025.136.038.183.043.37.067.558.073.093 0 .186-.003.279-.013-.091-.025-.183-.04-.272-.06l-.14-.03ZM37.664 29.02l-.366.038-.185.018a1.586 1.586 0 0 0-.187.03c.061.017.123.03.185.042h.094c.03.007.062.011.093.013.127.01.254.01.38 0 .126-.012.251-.034.373-.065.124-.031.244-.076.358-.134-.128 0-.247 0-.375.02s-.247.026-.37.038Z"
      fill="#fff"
    />
    <path
      d="M35.9 26.148c-.306 0-.61.026-.911.08h-1.632v.637c.04.549 1.072 1.146 2.54 1.146 1.47 0 2.5-.597 2.54-1.146v-.637h-1.631c-.3-.054-.602-.08-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__fE)"
    />
    <path
      d="M33.647 27.354c.051.045.106.087.162.126v-1.26h-.162v1.134Z"
      fill="url(#PendingWithdrawals_svg__fF)"
    />
    <path
      d="M36.664 26.206v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__fG)"
    />
    <path
      d="m36.39 27.988.161-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__fH)"
    />
    <path
      d="m37.758 27.618.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__fI)"
    />
    <path
      d="M36.115 28.006h.16V26.17h-.16v1.835Z"
      fill="url(#PendingWithdrawals_svg__fJ)"
    />
    <path
      d="M38.033 27.452c.056-.043.11-.088.16-.136v-1.088h-.16v1.224Z"
      fill="url(#PendingWithdrawals_svg__fK)"
    />
    <path
      d="m37.21 27.83.161-.053v-1.554h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__fL)"
    />
    <path
      d="m36.938 27.905.16-.04v-1.637h-.16v1.677Z"
      fill="url(#PendingWithdrawals_svg__fM)"
    />
    <path
      d="m37.484 27.741.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__fN)"
    />
    <path
      d="m34.469 27.797.163.047v-1.616h-.163v1.569Z"
      fill="url(#PendingWithdrawals_svg__fO)"
    />
    <path
      d="M35.566 28h.16v-1.857h-.16V28Z"
      fill="url(#PendingWithdrawals_svg__fP)"
    />
    <path
      d="M35.846 26.148v1.863h.16V26.15h-.16v-.002Z"
      fill="url(#PendingWithdrawals_svg__fQ)"
    />
    <path
      d="M33.358 26.865a.646.646 0 0 0 .175.375v-1.007h-.178l.003.632Z"
      fill="url(#PendingWithdrawals_svg__fR)"
    />
    <path
      d="M38.315 27.19a.624.624 0 0 0 .133-.325v-.637h-.133v.962Z"
      fill="url(#PendingWithdrawals_svg__fS)"
    />
    <path
      d="m33.922 27.552.163.086v-1.41h-.163v1.324Z"
      fill="url(#PendingWithdrawals_svg__fT)"
    />
    <path
      d="m35.291 27.975.163.018v-1.825c-.054 0-.109 0-.163.015v1.792Z"
      fill="url(#PendingWithdrawals_svg__fU)"
    />
    <path
      d="M34.195 27.69c.052.023.106.046.163.066v-1.528h-.163v1.463Z"
      fill="url(#PendingWithdrawals_svg__fV)"
    />
    <path
      d="M34.742 27.875c.055.015.106.027.163.037v-1.684h-.163v1.646Z"
      fill="url(#PendingWithdrawals_svg__fW)"
    />
    <path
      d="m35.016 27.935.163.025v-1.762l-.163.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__fX)"
    />
    <path
      d="M35.9 25c-1.498 0-2.543.621-2.543 1.18 0 .56 1.044 1.178 2.543 1.178 1.498 0 2.542-.621 2.542-1.178 0-.556-1.044-1.18-2.542-1.18Z"
      fill="#F98600"
    />
    <path
      d="M34.244 27.059c.176.073.358.131.543.174l2.096-2.135a4.803 4.803 0 0 0-.632-.086l-2.007 2.047Z"
      fill="url(#PendingWithdrawals_svg__fY)"
    />
    <path
      d="M35.695 27.353h.41l1.824-1.86a2.07 2.07 0 0 0-.266-.146l-1.968 2.006Z"
      fill="url(#PendingWithdrawals_svg__fZ)"
    />
    <path
      d="M35.089 27.25c-.045 0-.09-.017-.134-.03-.086-.025-.175-.043-.261-.073-.087-.03-.17-.055-.247-.085l-.126-.05-.062-.026-.061-.028c-.084-.035-.163-.078-.247-.118a2.713 2.713 0 0 1-.457-.31c.103.163.239.302.398.408.037.028.079.05.118.076.04.026.081.05.123.07l.065.033.064.028c.044.017.086.04.13.055l.134.045a1.4 1.4 0 0 0 .138.035c.091.024.183.042.277.053.09.016.185.016.278.023.094.008.188 0 .28-.013-.092-.025-.18-.04-.272-.06l-.138-.033Z"
      fill="url(#PendingWithdrawals_svg__ga)"
    />
    <path
      d="m36.354 27.263-.368.038-.185.017c-.062 0-.123.018-.188.033.062.018.124.028.185.04l.094.015h.096c.126.012.252.012.378 0s.25-.034.373-.065c.123-.033.243-.078.358-.133-.126-.001-.25.005-.375.017l-.368.038Z"
      fill="url(#PendingWithdrawals_svg__gb)"
    />
    <path
      d="M34.906 24.881c-.306 0-.61.027-.911.083h-1.632v.637c.042.549 1.074 1.146 2.543 1.146 1.468 0 2.5-.597 2.54-1.146v-.637h-1.634a4.857 4.857 0 0 0-.906-.083Z"
      fill="url(#PendingWithdrawals_svg__gc)"
    />
    <path
      d="M32.652 26.09c.05.043.102.083.16.126v-1.26h-.16v1.134Z"
      fill="url(#PendingWithdrawals_svg__gd)"
    />
    <path
      d="M35.666 24.94v1.749l.163-.028v-1.697h-.015l-.148-.025Z"
      fill="url(#PendingWithdrawals_svg__ge)"
    />
    <path
      d="m35.393 26.724.163-.018v-1.782l-.163-.02v1.82Z"
      fill="url(#PendingWithdrawals_svg__gf)"
    />
    <path
      d="M36.762 26.352c.056-.027.11-.057.163-.091v-1.297h-.163v1.388Z"
      fill="url(#PendingWithdrawals_svg__gg)"
    />
    <path
      d="M35.117 26.742h.163v-1.835h-.163v1.835Z"
      fill="url(#PendingWithdrawals_svg__gh)"
    />
    <path
      d="M37.04 26.185a1.5 1.5 0 0 0 .16-.133v-1.088h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__gi)"
    />
    <path
      d="m36.215 26.57.163-.05v-1.553h-.163v1.604Z"
      fill="url(#PendingWithdrawals_svg__gj)"
    />
    <path
      d="m35.94 26.639.162-.038v-1.637h-.162v1.675Z"
      fill="url(#PendingWithdrawals_svg__gk)"
    />
    <path
      d="M36.488 26.475c.056-.02.11-.042.163-.068v-1.443h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__gl)"
    />
    <path
      d="m33.475 26.53.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__gm)"
    />
    <path
      d="M34.57 26.734h.163v-1.858h-.163v1.858Z"
      fill="url(#PendingWithdrawals_svg__gn)"
    />
    <path
      d="M34.844 24.884v1.863h.163v-1.863h-.163Z"
      fill="url(#PendingWithdrawals_svg__go)"
    />
    <path
      d="M32.363 25.601c.018.14.08.27.175.373v-1.007h-.175v.634Z"
      fill="url(#PendingWithdrawals_svg__gp)"
    />
    <path
      d="M37.311 25.924a.615.615 0 0 0 .133-.323v-.637h-.135l.002.96Z"
      fill="url(#PendingWithdrawals_svg__gq)"
    />
    <path
      d="M32.926 26.286c.052.03.103.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__gr)"
    />
    <path
      d="m34.297 26.712.163.015V24.9l-.163.018v1.793Z"
      fill="url(#PendingWithdrawals_svg__gs)"
    />
    <path
      d="m33.2 26.427.16.066v-1.529h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__gt)"
    />
    <path
      d="m33.748 26.61.16.039v-1.685h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__gu)"
    />
    <path
      d="m34.022 26.669.16.027v-1.762l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__gv)"
    />
    <path
      d="M34.906 23.736c-1.499 0-2.543.622-2.543 1.178 0 .556 1.044 1.178 2.543 1.178 1.498 0 2.542-.619 2.542-1.178 0-.559-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M33.25 25.793c.176.072.358.131.543.176l2.096-2.138a4.786 4.786 0 0 0-.635-.083l-2.004 2.045ZM34.701 26.09h.405l1.824-1.863a2.66 2.66 0 0 0-.266-.144L34.7 26.09Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M34.092 25.986a1.308 1.308 0 0 1-.133-.032c-.087-.026-.175-.043-.262-.073-.086-.03-.173-.056-.247-.086-.044 0-.084-.033-.126-.048l-.061-.025-.062-.028c-.084-.035-.163-.078-.247-.12a2.683 2.683 0 0 1-.454-.308c.104.161.238.299.395.406a1.953 1.953 0 0 0 .244.148l.062.033.067.028c.044.017.086.037.13.052l.134.046c.044.015.091.025.138.038.091.022.183.038.276.05.093.013.186.02.28.023.093 0 .186-.004.278-.013-.091-.025-.182-.04-.271-.06l-.14-.03ZM35.354 25.999l-.368.038-.185.017a1.607 1.607 0 0 0-.188.03c.061.018.123.033.185.043l.094.015h.094c.127.01.254.01.38 0 .126-.011.25-.033.373-.065a1.7 1.7 0 0 0 .358-.133c-.129 0-.247 0-.375.02-.129.02-.24.022-.368.035Z"
      fill="#fff"
    />
    <path
      d="M33.197 34.5a4.892 4.892 0 0 0-.91.081h-1.625v.637c.042.55 1.074 1.146 2.543 1.146 1.468 0 2.5-.597 2.54-1.146v-.637h-1.627a4.87 4.87 0 0 0-.92-.08Z"
      fill="url(#PendingWithdrawals_svg__gw)"
    />
    <path
      d="M30.943 35.707c.05.046.104.088.16.126v-1.259h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__gx)"
    />
    <path
      d="M33.957 34.559v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__gy)"
    />
    <path
      d="m33.684 36.341.16-.017V34.54l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__gz)"
    />
    <path
      d="m35.055 35.971.163-.09v-1.3h-.163v1.39Z"
      fill="url(#PendingWithdrawals_svg__gA)"
    />
    <path
      d="M33.41 36.359h.16V34.52h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__gB)"
    />
    <path
      d="M35.328 35.803c.057-.041.112-.086.163-.134v-1.087h-.163v1.22Z"
      fill="url(#PendingWithdrawals_svg__gC)"
    />
    <path
      d="m34.506 36.188.163-.053v-1.553h-.163v1.606Z"
      fill="url(#PendingWithdrawals_svg__gD)"
    />
    <path
      d="M34.23 36.256c.057 0 .11-.023.163-.038v-1.637h-.163v1.675Z"
      fill="url(#PendingWithdrawals_svg__gE)"
    />
    <path
      d="m34.78 36.095.162-.068V34.58h-.163v1.514Z"
      fill="url(#PendingWithdrawals_svg__gF)"
    />
    <path
      d="m31.766 36.15.16.048V34.58h-.16v1.569Z"
      fill="url(#PendingWithdrawals_svg__gG)"
    />
    <path
      d="M32.861 36.354h.16v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__gH)"
    />
    <path
      d="M33.13 34.5v1.864h.161v-1.86h-.16V34.5Z"
      fill="url(#PendingWithdrawals_svg__gI)"
    />
    <path
      d="M30.662 35.218c.018.141.08.272.175.375v-1.007h-.175v.632Z"
      fill="url(#PendingWithdrawals_svg__gJ)"
    />
    <path
      d="M35.598 35.543a.616.616 0 0 0 .135-.325v-.637h-.135v.962Z"
      fill="url(#PendingWithdrawals_svg__gK)"
    />
    <path
      d="M31.219 35.903c.052.03.103.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__gL)"
    />
    <path
      d="M32.588 36.329c.054 0 .106.012.16.015v-1.825l-.16.017v1.793Z"
      fill="url(#PendingWithdrawals_svg__gM)"
    />
    <path
      d="m31.492 36.044.16.066V34.58h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__gN)"
    />
    <path
      d="m32.04 36.228.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__gO)"
    />
    <path
      d="m32.315 36.288.16.026V34.55l-.16.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__gP)"
    />
    <path
      d="M33.197 33.353c-1.498 0-2.545.622-2.545 1.178 0 .556 1.047 1.18 2.545 1.18 1.499 0 2.543-.621 2.543-1.18 0-.559-1.044-1.178-2.543-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M31.543 35.412c.176.073.358.131.543.174l2.093-2.135a4.671 4.671 0 0 0-.632-.086l-2.004 2.047ZM32.992 35.707H33.4l1.826-1.86a2.253 2.253 0 0 0-.267-.147l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M32.39 35.604c-.044 0-.088-.018-.132-.03-.09-.028-.178-.043-.262-.076-.084-.033-.173-.053-.247-.083-.044-.015-.084-.033-.126-.05l-.064-.026-.06-.027c-.083-.036-.162-.078-.246-.121a2.678 2.678 0 0 1-.454-.308c.103.162.237.3.395.406.038.028.077.054.118.078.042.028.084.048.126.07l.062.033.067.028c.041.018.086.04.13.055l.134.046c.044.014.09.025.135.035.091.023.184.041.277.053a2.599 2.599 0 0 0 .56.008c-.091-.023-.183-.041-.271-.059l-.141-.032ZM33.648 35.616l-.365.038-.185.018c-.064 0-.126.017-.188.032.062.018.124.028.185.04l.094.016h.094a2.135 2.135 0 0 0 1.11-.2c-.128 0-.246 0-.377.018l-.368.038Z"
      fill="#fff"
    />
    <path
      d="M33.455 33.42c-.305 0-.609.028-.908.084H30.91v.637c.04.549 1.072 1.145 2.54 1.145 1.469 0 2.5-.596 2.54-1.145v-.637h-1.624a4.91 4.91 0 0 0-.91-.083Z"
      fill="url(#PendingWithdrawals_svg__gQ)"
    />
    <path
      d="M31.2 34.627c.051.045.105.087.162.126v-1.26H31.2v1.134Z"
      fill="url(#PendingWithdrawals_svg__gR)"
    />
    <path
      d="M34.217 33.479v1.762l.16-.028v-1.699l-.16-.035Z"
      fill="url(#PendingWithdrawals_svg__gS)"
    />
    <path
      d="m33.941 35.264.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__gT)"
    />
    <path
      d="M35.313 34.894c.056-.03.11-.06.16-.093v-1.297h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__gU)"
    />
    <path
      d="M33.668 35.281h.16v-1.838h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__gV)"
    />
    <path
      d="M35.598 34.725a1.5 1.5 0 0 0 .16-.133v-1.088h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__gW)"
    />
    <path
      d="m34.766 35.108.16-.05v-1.554h-.16v1.604Z"
      fill="url(#PendingWithdrawals_svg__gX)"
    />
    <path
      d="M34.492 35.178c.055 0 .109-.025.16-.038v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__gY)"
    />
    <path
      d="m35.04 35.014.16-.065v-1.445h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__gZ)"
    />
    <path
      d="m32.023 35.07.163.05v-1.616h-.163v1.566Z"
      fill="url(#PendingWithdrawals_svg__ha)"
    />
    <path
      d="M33.13 35.276h.161v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__hb)"
    />
    <path
      d="M33.395 33.423v1.863h.16v-1.863h-.16Z"
      fill="url(#PendingWithdrawals_svg__hc)"
    />
    <path
      d="M30.91 34.14a.64.64 0 0 0 .175.373v-1.007h-.175v.635Z"
      fill="url(#PendingWithdrawals_svg__hd)"
    />
    <path
      d="M35.862 34.466a.63.63 0 0 0 .133-.325v-.637h-.136l.003.962Z"
      fill="url(#PendingWithdrawals_svg__he)"
    />
    <path
      d="M31.475 34.826c.051.03.106.06.162.088v-1.41h-.162v1.322Z"
      fill="url(#PendingWithdrawals_svg__hf)"
    />
    <path
      d="m32.848 35.251.16.015v-1.825l-.16.017v1.793Z"
      fill="url(#PendingWithdrawals_svg__hg)"
    />
    <path
      d="m31.75 34.967.163.065v-1.528h-.163v1.463Z"
      fill="url(#PendingWithdrawals_svg__hh)"
    />
    <path
      d="m32.3 35.15.161.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__hi)"
    />
    <path
      d="m32.574 35.21.16.026v-1.762l-.16.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__hj)"
    />
    <path
      d="M33.455 32.275c-1.499 0-2.543.622-2.543 1.179 0 .556 1.044 1.18 2.543 1.18 1.498 0 2.542-.621 2.542-1.18 0-.56-1.044-1.179-2.542-1.179Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M31.799 34.335c.176.072.358.13.543.173l2.096-2.137a4.772 4.772 0 0 0-.632-.083l-2.007 2.047ZM33.25 34.627h.41l1.824-1.863a2.193 2.193 0 0 0-.267-.144l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m32.638 34.526-.134-.03c-.086-.028-.175-.045-.261-.076-.087-.03-.17-.055-.247-.085-.042 0-.084-.033-.126-.048l-.062-.025-.061-.028c-.084-.035-.163-.078-.247-.12a2.707 2.707 0 0 1-.457-.308c.104.162.24.3.397.406.038.028.078.054.119.077.04.026.081.048.123.071l.065.033.064.027c.044.018.086.04.13.056l.136.045c.045.015.09.023.136.035.091.023.183.04.276.053.092.013.188.015.28.02.093.001.186-.003.278-.012a5.254 5.254 0 0 0-.271-.058l-.139-.033ZM33.908 34.539l-.367.038-.185.017a1.728 1.728 0 0 0-.188.033c.062.017.123.027.185.04l.094.015h.096c.126.012.252.012.378 0 .126-.01.25-.032.373-.065.123-.031.243-.075.358-.131-.126 0-.251.005-.376.018l-.368.035Z"
      fill="#fff"
    />
    <path
      d="M37.992 35.256c-.305 0-.609.027-.909.08H35.45v.638c.04.548 1.072 1.145 2.54 1.145 1.469 0 2.5-.597 2.543-1.145v-.637h-1.634c-.3-.054-.603-.081-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__hk)"
    />
    <path
      d="M35.74 36.462c.05.046.104.088.16.126v-1.259h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__hl)"
    />
    <path
      d="M38.754 35.314v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__hm)"
    />
    <path
      d="m38.48 37.096.16-.017v-1.783l-.16-.017v1.817Z"
      fill="url(#PendingWithdrawals_svg__hn)"
    />
    <path
      d="m39.85 36.726.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__ho)"
    />
    <path
      d="M38.207 37.114h.16v-1.838h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__hp)"
    />
    <path
      d="M40.125 36.558c.056-.04.11-.086.16-.134v-1.087h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__hq)"
    />
    <path
      d="m39.3 36.943.161-.053v-1.553h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__hr)"
    />
    <path
      d="M39.027 37.01c.055 0 .109-.022.16-.037v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__hs)"
    />
    <path
      d="m39.576 36.85.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__ht)"
    />
    <path
      d="m36.563 36.905.16.048v-1.616h-.16v1.568Z"
      fill="url(#PendingWithdrawals_svg__hu)"
    />
    <path
      d="M37.658 37.109h.16V35.25h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__hv)"
    />
    <path
      d="M37.932 35.256v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__hw)"
    />
    <path
      d="M35.448 35.974a.66.66 0 0 0 .175.375v-1.007h-.178l.003.632Z"
      fill="url(#PendingWithdrawals_svg__hx)"
    />
    <path
      d="M40.397 36.298a.615.615 0 0 0 .136-.324v-.637h-.139l.003.961Z"
      fill="url(#PendingWithdrawals_svg__hy)"
    />
    <path
      d="M36.014 36.658c.049.03.103.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__hz)"
    />
    <path
      d="m37.383 37.084.16.018v-1.826c-.054 0-.108 0-.16.015v1.793Z"
      fill="url(#PendingWithdrawals_svg__hA)"
    />
    <path
      d="M36.29 36.8c.05.022.103.045.16.065v-1.528h-.16v1.462Z"
      fill="url(#PendingWithdrawals_svg__hB)"
    />
    <path
      d="m36.832 36.983.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__hC)"
    />
    <path
      d="m37.11 37.044.16.025v-1.763l-.16.026v1.712Z"
      fill="url(#PendingWithdrawals_svg__hD)"
    />
    <path
      d="M37.992 34.108c-1.499 0-2.543.622-2.543 1.178 0 .557 1.044 1.181 2.543 1.181 1.498 0 2.545-.622 2.545-1.18 0-.56-1.047-1.179-2.545-1.179Z"
      fill="url(#PendingWithdrawals_svg__hE)"
    />
    <path
      d="M36.34 36.168c.177.072.36.13.545.173l2.093-2.135a4.704 4.704 0 0 0-.631-.085l-2.007 2.047Z"
      fill="url(#PendingWithdrawals_svg__hF)"
    />
    <path
      d="M37.79 36.462h.406l1.827-1.86a2.1 2.1 0 0 0-.27-.146l-1.964 2.006Z"
      fill="url(#PendingWithdrawals_svg__hG)"
    />
    <path
      d="M37.18 36.359c-.044 0-.09-.018-.133-.03-.089-.025-.177-.043-.261-.073-.084-.03-.173-.056-.247-.086l-.129-.05-.061-.025-.062-.028c-.081-.035-.163-.078-.247-.12a2.589 2.589 0 0 1-.454-.308c.102.162.237.3.395.405.038.029.077.055.118.078.04.028.084.048.124.07l.064.033.064.028c.045.018.087.04.133.056l.134.045c.044.014.09.026.136.035.09.024.183.041.276.053.093.012.186.018.279.02h.281c-.091-.025-.182-.04-.271-.06l-.139-.043Z"
      fill="url(#PendingWithdrawals_svg__hH)"
    />
    <path
      d="m38.445 36.371-.365.038-.185.018c-.065 0-.126.017-.188.032.062.018.123.028.185.04l.091.016h.097a2.116 2.116 0 0 0 1.108-.199c-.125 0-.25.005-.375.018l-.368.037Z"
      fill="url(#PendingWithdrawals_svg__hI)"
    />
    <path
      d="M37.359 33.897c-.306 0-.61.026-.911.08h-1.632v.65c.04.549 1.072 1.145 2.54 1.145 1.47 0 2.5-.596 2.54-1.145v-.637h-1.631a5.144 5.144 0 0 0-.906-.093Z"
      fill="url(#PendingWithdrawals_svg__hJ)"
    />
    <path
      d="M35.105 35.103c.052.045.107.087.163.125V33.97h-.163v1.133Z"
      fill="url(#PendingWithdrawals_svg__hK)"
    />
    <path
      d="M38.121 33.955v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__hL)"
    />
    <path
      d="m37.848 35.737.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__hM)"
    />
    <path
      d="m39.219 35.367.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__hN)"
    />
    <path
      d="M37.574 35.755h.16v-1.836h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__hO)"
    />
    <path
      d="M39.492 35.2c.056-.041.11-.087.16-.135v-1.088h-.16v1.224Z"
      fill="url(#PendingWithdrawals_svg__hP)"
    />
    <path
      d="m38.67 35.584.16-.053v-1.554h-.16v1.607Z"
      fill="url(#PendingWithdrawals_svg__hQ)"
    />
    <path
      d="m38.395 35.654.16-.04v-1.637h-.16v1.677Z"
      fill="url(#PendingWithdrawals_svg__hR)"
    />
    <path
      d="m38.945 35.49.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__hS)"
    />
    <path
      d="m35.928 35.546.163.048v-1.617h-.163v1.569Z"
      fill="url(#PendingWithdrawals_svg__hT)"
    />
    <path
      d="M37.023 35.75h.163v-1.858h-.163v1.858Z"
      fill="url(#PendingWithdrawals_svg__hU)"
    />
    <path
      d="M37.297 33.897v1.863h.163V33.9h-.163v-.003Z"
      fill="url(#PendingWithdrawals_svg__hV)"
    />
    <path
      d="M34.817 34.627c.016.14.077.273.173.375v-1.007h-.175l.002.632Z"
      fill="url(#PendingWithdrawals_svg__hW)"
    />
    <path
      d="M39.764 34.939a.624.624 0 0 0 .133-.325v-.637h-.135l.002.962Z"
      fill="url(#PendingWithdrawals_svg__hX)"
    />
    <path
      d="M35.379 35.299c.052.03.106.06.163.088v-1.41h-.163V35.3Z"
      fill="url(#PendingWithdrawals_svg__hY)"
    />
    <path
      d="m36.75 35.724.163.018v-1.825c-.057 0-.109 0-.163.015v1.792Z"
      fill="url(#PendingWithdrawals_svg__hZ)"
    />
    <path
      d="M35.652 35.44c.052.023.106.045.163.066v-1.529h-.163v1.463Z"
      fill="url(#PendingWithdrawals_svg__ia)"
    />
    <path
      d="M36.203 35.634c.054.015.106.027.163.038v-1.685h-.163v1.647Z"
      fill="url(#PendingWithdrawals_svg__ib)"
    />
    <path
      d="m36.477 35.684.163.025v-1.762l-.163.025v1.712Z"
      fill="url(#PendingWithdrawals_svg__ic)"
    />
    <path
      d="M37.359 32.749c-1.498 0-2.543.622-2.543 1.18 0 .56 1.045 1.179 2.543 1.179 1.498 0 2.542-.622 2.542-1.178 0-.557-1.044-1.181-2.542-1.181Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M35.703 34.808c.176.072.358.13.543.174l2.096-2.135a4.804 4.804 0 0 0-.632-.086l-2.007 2.047ZM37.154 35.103h.41l1.824-1.86a2.07 2.07 0 0 0-.266-.147l-1.968 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m36.548 35-.134-.03c-.086-.026-.175-.044-.261-.074-.087-.03-.17-.055-.247-.086l-.126-.05-.062-.025-.061-.028c-.084-.035-.163-.078-.247-.118a2.714 2.714 0 0 1-.457-.31c.102.162.237.3.395.406a.89.89 0 0 0 .121.078c.04.026.081.05.123.07.021.013.043.023.065.033l.064.028c.044.017.086.04.13.055l.134.045c.045.014.092.026.138.035.091.024.183.042.277.053.09.015.185.015.279.023.093.008.187 0 .279 0-.092-.025-.18-.04-.272-.06l-.138-.046ZM37.82 35.012l-.367.038-.185.017c-.062 0-.126.018-.188.033.061.017.123.03.185.04l.094.015h.096c.126.012.252.012.378 0s.25-.034.373-.065c.123-.033.243-.078.358-.134-.129 0-.247 0-.376.018l-.367.038Z"
      fill="#fff"
    />
    <path
      d="M40.042 37.396c-.305 0-.61.027-.91.083H37.5v.637c.042.549 1.074 1.146 2.542 1.146 1.47 0 2.501-.597 2.54-1.146v-.645h-1.634a4.862 4.862 0 0 0-.906-.075Z"
      fill="url(#PendingWithdrawals_svg__id)"
    />
    <path
      d="M37.79 38.597c.05.044.104.085.16.123v-1.259h-.16v1.136Z"
      fill="url(#PendingWithdrawals_svg__ie)"
    />
    <path
      d="M40.803 37.447v1.75l.163-.029v-1.696h-.015l-.148-.026Z"
      fill="url(#PendingWithdrawals_svg__if)"
    />
    <path
      d="m40.535 39.231.163-.017V37.43l-.163-.017v1.817Z"
      fill="url(#PendingWithdrawals_svg__ig)"
    />
    <path
      d="M41.898 38.861c.057-.03.111-.06.163-.093v-1.296h-.163v1.39Z"
      fill="url(#PendingWithdrawals_svg__ih)"
    />
    <path
      d="M40.254 39.249h.163v-1.853h-.163v1.853Z"
      fill="url(#PendingWithdrawals_svg__ii)"
    />
    <path
      d="M42.172 38.693c.058-.04.112-.085.163-.134v-1.087h-.163v1.22Z"
      fill="url(#PendingWithdrawals_svg__ij)"
    />
    <path
      d="m41.352 39.075.163-.05v-1.553h-.163v1.603Z"
      fill="url(#PendingWithdrawals_svg__ik)"
    />
    <path
      d="m41.076 39.158.163-.037v-1.637h-.163v1.674Z"
      fill="url(#PendingWithdrawals_svg__il)"
    />
    <path
      d="M41.625 38.982c.056-.02.11-.042.163-.068v-1.442h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__im)"
    />
    <path
      d="m38.611 39.038.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__in)"
    />
    <path
      d="M39.707 39.244h.16v-1.848h-.16v1.848Z"
      fill="url(#PendingWithdrawals_svg__io)"
    />
    <path
      d="M39.98 37.396v1.863h.163v-1.863h-.163Z"
      fill="url(#PendingWithdrawals_svg__ip)"
    />
    <path
      d="M37.5 38.108c.018.14.08.271.175.373v-1.007H37.5v.635Z"
      fill="url(#PendingWithdrawals_svg__iq)"
    />
    <path
      d="M42.45 38.43a.615.615 0 0 0 .133-.321v-.637h-.136l.003.959Z"
      fill="url(#PendingWithdrawals_svg__ir)"
    />
    <path
      d="M38.066 38.793c.052.03.104.06.16.089v-1.41h-.16v1.321Z"
      fill="url(#PendingWithdrawals_svg__is)"
    />
    <path
      d="m39.434 39.219.16.015v-1.825l-.16.017v1.793Z"
      fill="url(#PendingWithdrawals_svg__it)"
    />
    <path
      d="m38.336 38.934.16.066v-1.528h-.16v1.462Z"
      fill="url(#PendingWithdrawals_svg__iu)"
    />
    <path
      d="m38.885 39.118.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__iv)"
    />
    <path
      d="m39.158 39.176.16.028V37.44l-.16.026v1.709Z"
      fill="url(#PendingWithdrawals_svg__iw)"
    />
    <path
      d="M40.042 36.243c-1.498 0-2.542.622-2.542 1.178 0 .557 1.044 1.179 2.542 1.179 1.499 0 2.543-.62 2.543-1.179s-1.044-1.178-2.543-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M38.387 38.302c.176.073.358.13.543.174l2.095-2.137a4.787 4.787 0 0 0-.634-.083l-2.004 2.046ZM39.836 38.597h.41l1.824-1.863a2.65 2.65 0 0 0-.267-.143l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M39.23 38.494a1.27 1.27 0 0 1-.133-.033c-.086-.025-.175-.043-.261-.073-.087-.03-.173-.055-.247-.086-.044-.012-.084-.032-.126-.047l-.064-.026-.06-.027c-.083-.036-.162-.078-.246-.121a2.683 2.683 0 0 1-.454-.307c.103.16.238.298.395.405a2.026 2.026 0 0 0 .244.149l.062.032.066.028c.042.018.087.038.131.053l.133.048.136.035c.092.023.185.038.277.053.091.015.187.015.281.02.093.005.186.005.279 0-.091-.025-.183-.04-.272-.058l-.14-.045ZM40.494 38.506l-.365.038-.185.018c-.062 0-.126.017-.188.03.06.018.123.032.185.043l.094.015h.094c.126.01.253.01.38 0 .126-.012.25-.034.373-.066.123-.03.243-.075.357-.133-.128 0-.246 0-.375.02l-.37.035Z"
      fill="#fff"
    />
    <path
      d="M39.41 36.032a4.865 4.865 0 0 0-.909.08h-1.634v.637c.04.549 1.072 1.146 2.54 1.146 1.469 0 2.5-.597 2.543-1.146v-.637h-1.632a4.899 4.899 0 0 0-.908-.08Z"
      fill="url(#PendingWithdrawals_svg__ix)"
    />
    <path
      d="M37.156 37.237c.05.046.104.088.16.126v-1.258h-.16v1.132Z"
      fill="url(#PendingWithdrawals_svg__iy)"
    />
    <path
      d="M40.172 36.087v1.75l.16-.028v-1.697h-.012l-.148-.025Z"
      fill="url(#PendingWithdrawals_svg__iz)"
    />
    <path
      d="m39.897 37.872.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__iA)"
    />
    <path
      d="M41.277 37.502c.057-.03.111-.06.16-.093v-1.297h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__iB)"
    />
    <path
      d="M39.623 37.9h.16v-1.838h-.16V37.9Z"
      fill="url(#PendingWithdrawals_svg__iC)"
    />
    <path
      d="M41.541 37.333c.056-.04.11-.085.16-.133v-1.088h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__iD)"
    />
    <path
      d="m40.719 37.716.16-.05v-1.554h-.16v1.604Z"
      fill="url(#PendingWithdrawals_svg__iE)"
    />
    <path
      d="M40.445 37.786c.055 0 .109-.025.16-.038v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__iF)"
    />
    <path
      d="M40.992 37.623c.055-.02.108-.043.16-.068v-1.443h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__iG)"
    />
    <path
      d="m37.978 37.678.161.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__iH)"
    />
    <path
      d="M39.074 37.884h.16v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__iI)"
    />
    <path
      d="M39.348 36.032V37.9h.16v-1.861h-.16v-.008Z"
      fill="url(#PendingWithdrawals_svg__iJ)"
    />
    <path
      d="M36.866 36.75c.017.14.078.27.175.372v-1.007h-.178l.003.634Z"
      fill="url(#PendingWithdrawals_svg__iK)"
    />
    <path
      d="M41.815 37.074a.657.657 0 0 0 .135-.325v-.637h-.136v.962Z"
      fill="url(#PendingWithdrawals_svg__iL)"
    />
    <path
      d="M37.43 37.434c.05.03.103.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__iM)"
    />
    <path
      d="m38.809 37.86.16.014V36.05l-.16.018v1.792Z"
      fill="url(#PendingWithdrawals_svg__iN)"
    />
    <path
      d="m37.705 37.575.16.065v-1.528h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__iO)"
    />
    <path
      d="m38.254 37.759.16.037v-1.684h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__iP)"
    />
    <path
      d="m38.527 37.817.16.027v-1.762l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__iQ)"
    />
    <path
      d="M39.41 34.878c-1.499 0-2.543.622-2.543 1.179 0 .556 1.044 1.178 2.543 1.178 1.498 0 2.542-.62 2.542-1.178 0-.56-1.044-1.179-2.542-1.179Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M37.752 36.943c.177.072.36.13.545.174l2.094-2.138a4.768 4.768 0 0 0-.632-.083l-2.007 2.047ZM39.203 37.237h.41l1.827-1.863a2.444 2.444 0 0 0-.27-.143l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m38.596 37.144-.133-.03c-.087-.028-.175-.045-.262-.075-.086-.03-.17-.056-.247-.086-.042-.013-.081-.033-.126-.048l-.061-.025-.062-.028c-.084-.035-.163-.078-.247-.12a2.585 2.585 0 0 1-.454-.308c.103.162.237.3.395.406.038.028.077.054.118.078.04.025.082.047.124.07l.064.033.064.028c.045.017.087.04.13.055l.137.045a2.546 2.546 0 0 0 .412.088c.094 0 .188.016.279.02.093.002.186-.003.279-.012-.09-.023-.18-.04-.27-.058l-.14-.033ZM39.862 37.144l-.368.038-.185.018a1.727 1.727 0 0 0-.188.032c.062.018.126.028.188.04l.091.016h.096a2.135 2.135 0 0 0 .753-.066 1.83 1.83 0 0 0 .356-.13c-.126-.001-.25.005-.376.017l-.367.035Z"
      fill="#fff"
    />
    <path
      d="M38.14 32.565c-.305 0-.61.027-.91.083h-1.632v.637c.042.549 1.073 1.146 2.542 1.146 1.469 0 2.5-.597 2.54-1.146v-.637h-1.627a4.85 4.85 0 0 0-.913-.083Z"
      fill="url(#PendingWithdrawals_svg__iR)"
    />
    <path
      d="M35.889 33.773c.05.045.104.087.16.126V32.64h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__iS)"
    />
    <path
      d="M38.902 32.613v1.762l.163-.03v-1.697h-.01l-.153-.035Z"
      fill="url(#PendingWithdrawals_svg__iT)"
    />
    <path
      d="m38.629 34.408.163-.018v-1.777l-.163-.018v1.813Z"
      fill="url(#PendingWithdrawals_svg__iU)"
    />
    <path
      d="M40 34.038c.057-.03.109-.06.16-.093v-1.297H40v1.39Z"
      fill="url(#PendingWithdrawals_svg__iV)"
    />
    <path
      d="M38.353 34.425h.163v-1.837h-.163v1.837Z"
      fill="url(#PendingWithdrawals_svg__iW)"
    />
    <path
      d="M40.273 33.871c.057-.04.11-.085.16-.133V32.65h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__iX)"
    />
    <path
      d="m39.45 34.252.162-.05v-1.554h-.163v1.604Z"
      fill="url(#PendingWithdrawals_svg__iY)"
    />
    <path
      d="m39.176 34.322.163-.038v-1.636h-.163v1.674Z"
      fill="url(#PendingWithdrawals_svg__iZ)"
    />
    <path
      d="M39.723 34.158c.056-.02.11-.042.163-.065v-1.445h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__ja)"
    />
    <path
      d="m36.71 34.216.161.048v-1.616h-.16v1.568Z"
      fill="url(#PendingWithdrawals_svg__jb)"
    />
    <path
      d="M37.805 34.42h.163v-1.858h-.163v1.858Z"
      fill="url(#PendingWithdrawals_svg__jc)"
    />
    <path
      d="M38.08 32.567v1.863h.163v-1.86h-.163v-.003Z"
      fill="url(#PendingWithdrawals_svg__jd)"
    />
    <path
      d="M35.598 33.285c.018.14.08.27.175.372V32.65h-.175v.635Z"
      fill="url(#PendingWithdrawals_svg__je)"
    />
    <path
      d="M40.535 33.62a.616.616 0 0 0 .136-.325v-.637h-.136v.962Z"
      fill="url(#PendingWithdrawals_svg__jf)"
    />
    <path
      d="M36.162 33.97c.052.03.104.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__jg)"
    />
    <path
      d="m37.531 34.395.163.015v-1.825l-.163.018v1.792Z"
      fill="url(#PendingWithdrawals_svg__jh)"
    />
    <path
      d="m36.435 34.123.161.066V32.66h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__ji)"
    />
    <path
      d="m36.984 34.294.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__jj)"
    />
    <path
      d="m37.258 34.352.163.028v-1.762l-.163.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__jk)"
    />
    <path
      d="M38.14 31.42c-1.498 0-2.542.621-2.542 1.177 0 .557 1.044 1.179 2.542 1.179 1.498 0 2.543-.62 2.543-1.179s-1.045-1.178-2.543-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M36.484 33.479c.177.072.358.13.543.173l2.096-2.137a4.789 4.789 0 0 0-.634-.083l-2.005 2.047ZM37.938 33.773h.41l1.823-1.863a2.702 2.702 0 0 0-.266-.143l-1.968 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m37.327 33.67-.134-.03c-.086-.028-.175-.046-.261-.076-.087-.03-.173-.055-.247-.085-.042-.013-.084-.033-.126-.048l-.064-.025-.06-.028c-.084-.035-.163-.078-.246-.12a2.68 2.68 0 0 1-.455-.308c.104.16.238.298.395.405.04.029.08.055.121.078.04.026.082.048.124.07l.061.033.067.028c.044.018.086.04.13.056l.134.045.136.035c.092.023.185.04.279.053.091.013.185.015.279.02.093.001.186-.003.279-.012-.092-.023-.183-.04-.272-.058l-.14-.033ZM38.596 33.683l-.368.037-.185.018c-.062 0-.126.018-.188.03.061.018.123.032.186.043l.093.015h.094a2.135 2.135 0 0 0 .753-.065c.124-.031.244-.076.358-.134-.128 0-.247 0-.375.02l-.368.036Z"
      fill="#fff"
    />
    <path
      d="M39.593 31.102c-.304 0-.608.027-.908.08H37.05v.638c.04.548 1.071 1.145 2.54 1.145 1.468 0 2.503-.597 2.542-1.145v-.637H40.5a5.087 5.087 0 0 0-.906-.08Z"
      fill="url(#PendingWithdrawals_svg__jl)"
    />
    <path
      d="M37.342 32.313c.05.046.104.088.16.126V31.18h-.16v1.133Z"
      fill="url(#PendingWithdrawals_svg__jm)"
    />
    <path
      d="M40.355 31.165v1.75l.16-.028v-1.7h-.011l-.148-.022Z"
      fill="url(#PendingWithdrawals_svg__jn)"
    />
    <path
      d="m40.082 32.948.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__jo)"
    />
    <path
      d="m41.453 32.577.163-.09v-1.3h-.163v1.39Z"
      fill="url(#PendingWithdrawals_svg__jp)"
    />
    <path
      d="M39.809 32.968h.16v-1.836h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__jq)"
    />
    <path
      d="M41.727 32.411c.056-.042.11-.087.163-.136v-1.087h-.163v1.223Z"
      fill="url(#PendingWithdrawals_svg__jr)"
    />
    <path
      d="m40.902 32.794.163-.053v-1.553h-.163v1.606Z"
      fill="url(#PendingWithdrawals_svg__js)"
    />
    <path
      d="m40.629 32.864.16-.04v-1.636h-.16v1.676Z"
      fill="url(#PendingWithdrawals_svg__jt)"
    />
    <path
      d="m41.178 32.7.16-.067v-1.445h-.16V32.7Z"
      fill="url(#PendingWithdrawals_svg__ju)"
    />
    <path
      d="m38.164 32.756.16.05v-1.618h-.16v1.568Z"
      fill="url(#PendingWithdrawals_svg__jv)"
    />
    <path
      d="M39.26 32.96h.16v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__jw)"
    />
    <path
      d="M39.535 31.102v1.863h.16v-1.863h-.16Z"
      fill="url(#PendingWithdrawals_svg__jx)"
    />
    <path
      d="M37.051 31.825a.66.66 0 0 0 .175.375v-1.007h-.177l.002.632Z"
      fill="url(#PendingWithdrawals_svg__jy)"
    />
    <path
      d="M41.999 32.15a.608.608 0 0 0 .135-.325v-.637h-.138l.003.961Z"
      fill="url(#PendingWithdrawals_svg__jz)"
    />
    <path
      d="M37.617 32.512c.05.03.104.058.16.086v-1.41h-.16v1.324Z"
      fill="url(#PendingWithdrawals_svg__jA)"
    />
    <path
      d="m38.986 32.935.16.017v-1.825l-.16.018v1.79Z"
      fill="url(#PendingWithdrawals_svg__jB)"
    />
    <path
      d="m37.89 32.65.161.066v-1.528h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__jC)"
    />
    <path
      d="M38.438 32.834c.051.015.106.028.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__jD)"
    />
    <path
      d="m38.71 32.895.161.025v-1.763l-.16.028v1.71Z"
      fill="url(#PendingWithdrawals_svg__jE)"
    />
    <path
      d="M39.593 29.962c-1.498 0-2.542.619-2.542 1.178 0 .559 1.044 1.178 2.542 1.178 1.499 0 2.545-.622 2.545-1.178 0-.556-1.046-1.178-2.545-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M37.938 32.019c.176.073.359.131.545.173l2.093-2.135a4.671 4.671 0 0 0-.632-.085l-2.007 2.047ZM39.39 32.313H39.799l1.826-1.86a2.672 2.672 0 0 0-.266-.146l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M38.782 32.21c-.044 0-.091-.018-.133-.03-.09-.025-.178-.043-.262-.073-.084-.03-.173-.056-.247-.086l-.126-.048-.064-.027-.062-.028c-.08-.035-.16-.078-.246-.118a2.73 2.73 0 0 1-.455-.308c.104.162.238.3.396.406.037.027.078.053.118.078l.123.068.065.033.066.027a1.2 1.2 0 0 0 .131.056l.133.045c.045.015.092.025.136.038.091.021.184.038.277.05.093.015.187.015.279.023.09.007.187 0 .28-.013-.09-.025-.182-.04-.27-.06l-.139-.033ZM40.043 32.223l-.366.037-.185.018c-.064 0-.126.018-.187.033.06.017.122.03.185.04l.094.015h.093a2.118 2.118 0 0 0 .753-.065c.123-.032.242-.077.356-.134-.129 0-.247 0-.376.018l-.367.038Z"
      fill="#fff"
    />
    <path
      d="M39.978 29.088a4.866 4.866 0 0 0-.91.083h-1.634v.637c.04.549 1.07 1.146 2.542 1.146 1.471 0 2.5-.597 2.54-1.146v-.637h-1.634a4.856 4.856 0 0 0-.904-.083Z"
      fill="url(#PendingWithdrawals_svg__jF)"
    />
    <path
      d="M37.725 30.297c.05.044.104.086.16.123V29.16h-.16v1.136Z"
      fill="url(#PendingWithdrawals_svg__jG)"
    />
    <path
      d="M40.738 29.146v1.762l.16-.027v-1.697l-.16-.038Z"
      fill="url(#PendingWithdrawals_svg__jH)"
    />
    <path
      d="m40.465 30.931.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__jI)"
    />
    <path
      d="M41.834 30.56c.057-.03.111-.06.163-.092V29.17h-.163v1.39Z"
      fill="url(#PendingWithdrawals_svg__jJ)"
    />
    <path
      d="M40.191 30.949h.16V29.11h-.16v1.838Z"
      fill="url(#PendingWithdrawals_svg__jK)"
    />
    <path
      d="M42.11 30.392c.057-.04.111-.085.162-.133V29.17h-.163v1.221Z"
      fill="url(#PendingWithdrawals_svg__jL)"
    />
    <path
      d="m41.277 30.775.163-.05V29.17h-.163v1.604Z"
      fill="url(#PendingWithdrawals_svg__jM)"
    />
    <path
      d="m41.012 30.85.16-.037v-1.637h-.16v1.675Z"
      fill="url(#PendingWithdrawals_svg__jN)"
    />
    <path
      d="M41.56 30.682c.056-.02.11-.043.163-.068V29.17h-.163v1.51Z"
      fill="url(#PendingWithdrawals_svg__jO)"
    />
    <path
      d="m38.547 30.737.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__jP)"
    />
    <path
      d="M39.643 30.944h.16v-1.856h-.16v1.856Z"
      fill="url(#PendingWithdrawals_svg__jQ)"
    />
    <path
      d="M39.916 29.088v1.863h.16v-1.863h-.16Z"
      fill="url(#PendingWithdrawals_svg__jR)"
    />
    <path
      d="M37.432 29.808c.018.14.08.27.175.373v-1.007h-.177l.002.634Z"
      fill="url(#PendingWithdrawals_svg__jS)"
    />
    <path
      d="M42.381 30.13a.6.6 0 0 0 .136-.322v-.637h-.138l.002.96Z"
      fill="url(#PendingWithdrawals_svg__jT)"
    />
    <path
      d="M37.998 30.493c.05.03.104.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__jU)"
    />
    <path
      d="m39.37 30.919.16.015v-1.826l-.16.018v1.793Z"
      fill="url(#PendingWithdrawals_svg__jV)"
    />
    <path
      d="m38.273 30.634.16.065v-1.528h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__jW)"
    />
    <path
      d="m38.82 30.818.16.037v-1.684h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__jX)"
    />
    <path
      d="m39.094 30.875.16.028v-1.762l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__jY)"
    />
    <path
      d="M39.978 27.943c-1.5 0-2.544.621-2.544 1.178 0 .556 1.044 1.178 2.544 1.178 1.501 0 2.543-.62 2.543-1.178 0-.559-1.044-1.178-2.543-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M38.315 30.002c.177.072.359.13.545.174l2.093-2.138a4.687 4.687 0 0 0-.632-.083l-2.006 2.047ZM39.773 30.297h.408l1.826-1.864a2.67 2.67 0 0 0-.266-.143l-1.968 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M39.165 30.193a1.157 1.157 0 0 1-.133-.033c-.09-.025-.178-.042-.262-.073-.084-.03-.173-.055-.247-.085-.044 0-.084-.033-.126-.048l-.064-.025-.062-.028c-.081-.035-.16-.078-.246-.12a2.585 2.585 0 0 1-.455-.308c.103.161.238.3.395.405.038.029.078.055.119.078l.126.071.061.033.067.027.13.056.134.045.136.035c.091.023.185.038.276.053.092.015.188.015.282.02.093.001.186-.003.279-.012-.092-.023-.183-.04-.272-.058l-.138-.033ZM40.43 30.206l-.366.038-.185.017c-.064 0-.126.018-.188.03.061.018.123.033.185.043l.094.015h.094a2.118 2.118 0 0 0 .753-.065 1.69 1.69 0 0 0 .355-.133c-.125 0-.246 0-.375.02l-.367.035Z"
      fill="#fff"
    />
    <path
      d="M38.652 27.019c-.305-.001-.609.027-.909.083H36.11v.637c.04.548 1.072 1.145 2.54 1.145 1.47 0 2.5-.597 2.543-1.145v-.637H39.56a4.883 4.883 0 0 0-.908-.083Z"
      fill="url(#PendingWithdrawals_svg__jZ)"
    />
    <path
      d="M36.402 28.227c.05.045.105.086.16.124v-1.26h-.16v1.136Z"
      fill="url(#PendingWithdrawals_svg__ka)"
    />
    <path
      d="M39.414 27.074v1.762l.16-.027v-1.697l-.16-.038Z"
      fill="url(#PendingWithdrawals_svg__kb)"
    />
    <path
      d="m39.14 28.861.161-.017v-1.77l-.16-.018v1.805Z"
      fill="url(#PendingWithdrawals_svg__kc)"
    />
    <path
      d="M40.512 28.489c.055-.027.109-.057.16-.09v-1.297h-.16v1.387Z"
      fill="url(#PendingWithdrawals_svg__kd)"
    />
    <path
      d="M38.867 28.879h.16v-1.835h-.16v1.835Z"
      fill="url(#PendingWithdrawals_svg__ke)"
    />
    <path
      d="M40.783 28.333c.057-.04.11-.085.16-.134v-1.087h-.16v1.22Z"
      fill="url(#PendingWithdrawals_svg__kf)"
    />
    <path
      d="m39.965 28.706.16-.05v-1.554h-.16v1.604Z"
      fill="url(#PendingWithdrawals_svg__kg)"
    />
    <path
      d="m39.69 28.776.16-.038v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__kh)"
    />
    <path
      d="M40.238 28.612c.055-.02.108-.042.16-.068v-1.442h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__ki)"
    />
    <path
      d="M37.223 28.668c.052.017.103.035.16.05v-1.616h-.16v1.566Z"
      fill="url(#PendingWithdrawals_svg__kj)"
    />
    <path
      d="M38.315 28.872h.16v-1.848h-.16v1.848Z"
      fill="url(#PendingWithdrawals_svg__kk)"
    />
    <path
      d="M38.594 27.021v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__kl)"
    />
    <path
      d="M36.11 27.739c.017.14.078.27.175.372v-1.007h-.178l.003.635Z"
      fill="url(#PendingWithdrawals_svg__km)"
    />
    <path
      d="M41.059 28.061a.642.642 0 0 0 .135-.322v-.637h-.135v.959Z"
      fill="url(#PendingWithdrawals_svg__kn)"
    />
    <path
      d="M36.676 28.424c.05.03.103.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__ko)"
    />
    <path
      d="m38.047 28.836.16.018v-1.825l-.16.017v1.79Z"
      fill="url(#PendingWithdrawals_svg__kp)"
    />
    <path
      d="m36.95 28.564.16.066v-1.528h-.16v1.462Z"
      fill="url(#PendingWithdrawals_svg__kq)"
    />
    <path
      d="m37.496 28.748.16.038v-1.684h-.16v1.646Z"
      fill="url(#PendingWithdrawals_svg__kr)"
    />
    <path
      d="m37.772 28.806.16.028v-1.762l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__ks)"
    />
    <path
      d="M38.652 25.873c-1.498 0-2.543.622-2.543 1.178 0 .557 1.044 1.179 2.543 1.179 1.498 0 2.542-.62 2.542-1.178 0-.56-1.044-1.179-2.542-1.179Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M36.996 27.93c.177.073.36.132.546.176l2.093-2.137a4.768 4.768 0 0 0-.632-.083l-2.007 2.044ZM38.45 28.227h.409l1.824-1.863a2.207 2.207 0 0 0-.267-.143l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M37.84 28.124a1.27 1.27 0 0 1-.133-.033c-.087-.025-.175-.043-.262-.073-.086-.03-.17-.055-.247-.085-.042 0-.084-.033-.126-.048l-.061-.026-.062-.027c-.084-.035-.163-.078-.247-.121a2.594 2.594 0 0 1-.454-.307c.103.161.238.3.395.405.038.028.078.054.118.078.04.025.082.048.124.07l.064.033.064.028c.045.018.087.038.131.053l.136.045c.044.015.089.026.136.038.09.023.182.038.276.053.094.015.188.015.279.02.093 0 .186-.003.279-.012-.089-.026-.18-.04-.27-.061l-.14-.03ZM39.108 28.136l-.368.038-.185.018a1.724 1.724 0 0 0-.188.03c.062.018.125.032.188.043l.091.015h.096c.25.013.499-.011.74-.073.124-.031.243-.076.356-.134-.128 0-.246 0-.375.02-.128.02-.232.03-.355.043Z"
      fill="#fff"
    />
    <path
      d="M37.884 25.286c-.304 0-.608.027-.908.081h-1.624v.637c.04.549 1.07 1.145 2.54 1.145 1.468 0 2.5-.596 2.54-1.145v-.637h-1.625a5.114 5.114 0 0 0-.923-.08Z"
      fill="url(#PendingWithdrawals_svg__kt)"
    />
    <path
      d="M35.633 26.492c.052.046.106.088.163.126V25.36h-.163v1.133Z"
      fill="url(#PendingWithdrawals_svg__ku)"
    />
    <path
      d="M38.648 25.345v1.762l.16-.028v-1.7l-.16-.035Z"
      fill="url(#PendingWithdrawals_svg__kv)"
    />
    <path
      d="m38.375 27.127.16-.018v-1.782l-.16-.018v1.818Z"
      fill="url(#PendingWithdrawals_svg__kw)"
    />
    <path
      d="m39.742 26.757.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__kx)"
    />
    <path
      d="M38.1 27.145h.16v-1.833h-.16v1.833Z"
      fill="url(#PendingWithdrawals_svg__ky)"
    />
    <path
      d="M40.018 26.59a1.87 1.87 0 0 0 .16-.135v-1.088h-.16v1.224Z"
      fill="url(#PendingWithdrawals_svg__kz)"
    />
    <path
      d="m39.195 26.973.16-.052v-1.554h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__kA)"
    />
    <path
      d="m38.922 27.044.16-.04v-1.637h-.16v1.677Z"
      fill="url(#PendingWithdrawals_svg__kB)"
    />
    <path
      d="m39.469 26.88.16-.068v-1.445h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__kC)"
    />
    <path
      d="m36.453 26.936.163.047v-1.616h-.163v1.569Z"
      fill="url(#PendingWithdrawals_svg__kD)"
    />
    <path
      d="M37.55 27.14h.161v-1.858h-.16v1.858Z"
      fill="url(#PendingWithdrawals_svg__kE)"
    />
    <path
      d="M37.82 25.287v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__kF)"
    />
    <path
      d="M35.352 26.004a.645.645 0 0 0 .175.375v-1.007h-.175v.632Z"
      fill="url(#PendingWithdrawals_svg__kG)"
    />
    <path
      d="M40.29 26.319a.624.624 0 0 0 .132-.325v-.637h-.133v.962Z"
      fill="url(#PendingWithdrawals_svg__kH)"
    />
    <path
      d="M35.908 26.689c.05.03.104.06.16.088v-1.41h-.16v1.322Z"
      fill="url(#PendingWithdrawals_svg__kI)"
    />
    <path
      d="m37.277 27.114.16.018v-1.82c-.053 0-.108 0-.16.015v1.787Z"
      fill="url(#PendingWithdrawals_svg__kJ)"
    />
    <path
      d="M36.18 26.822c.051.023.106.046.163.066V25.36h-.163v1.462Z"
      fill="url(#PendingWithdrawals_svg__kK)"
    />
    <path
      d="M36.73 27.014c.052.015.107.027.16.037v-1.684h-.16v1.647Z"
      fill="url(#PendingWithdrawals_svg__kL)"
    />
    <path
      d="m37.004 27.074.16.025v-1.762l-.16.028v1.709Z"
      fill="url(#PendingWithdrawals_svg__kM)"
    />
    <path
      d="M37.886 24.138c-1.498 0-2.542.622-2.542 1.181 0 .56 1.044 1.178 2.542 1.178 1.498 0 2.543-.621 2.543-1.178 0-.556-1.044-1.18-2.543-1.18Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M36.228 26.198c.177.072.358.13.544.174l2.095-2.135a4.736 4.736 0 0 0-.632-.086l-2.007 2.047ZM37.68 26.493h.409l1.825-1.86a1.91 1.91 0 0 0-.267-.147l-1.967 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M37.08 26.39c-.044 0-.088-.018-.133-.031-.086-.025-.175-.043-.262-.073-.086-.03-.17-.055-.246-.086l-.126-.05-.062-.025-.062-.028c-.084-.035-.163-.078-.246-.118a2.748 2.748 0 0 1-.455-.31c.102.163.237.3.395.405.038.03.077.056.119.079.04.026.08.049.123.07l.064.033.065.027c.044.018.086.04.13.056l.136.045c.045.015.09.026.136.035.09.024.183.042.276.053.092.015.188.015.28.023.09.008.187 0 .278 0-.091-.025-.18-.04-.271-.06l-.139-.046ZM38.338 26.402l-.368.038-.185.017c-.061 0-.123.018-.187.033.061.018.123.028.185.04l.094.015h.096a2.135 2.135 0 0 0 1.108-.199c-.125 0-.25.006-.375.018l-.368.038Z"
      fill="#fff"
    />
    <path
      d="M36.767 23.771c-.305 0-.609.027-.908.083h-1.634v.637c.04.549 1.07 1.146 2.54 1.146 1.468 0 2.5-.597 2.542-1.146v-.637h-1.632a4.883 4.883 0 0 0-.908-.083Z"
      fill="url(#PendingWithdrawals_svg__kN)"
    />
    <path
      d="M34.512 24.98c.052.044.106.085.163.123v-1.259h-.163v1.135Z"
      fill="url(#PendingWithdrawals_svg__kO)"
    />
    <path
      d="M37.531 23.829v1.75l.16-.028v-1.697l-.16-.025Z"
      fill="url(#PendingWithdrawals_svg__kP)"
    />
    <path
      d="m37.254 25.614.16-.018v-1.782l-.16-.02v1.82Z"
      fill="url(#PendingWithdrawals_svg__kQ)"
    />
    <path
      d="M38.625 25.241c.055-.026.109-.057.16-.09v-1.297h-.16v1.387Z"
      fill="url(#PendingWithdrawals_svg__kR)"
    />
    <path
      d="M36.98 25.631h.16v-1.835h-.16v1.835Z"
      fill="url(#PendingWithdrawals_svg__kS)"
    />
    <path
      d="M38.898 25.075c.057-.04.11-.085.16-.133v-1.088h-.16v1.221Z"
      fill="url(#PendingWithdrawals_svg__kT)"
    />
    <path
      d="m38.066 25.458.16-.05v-1.554h-.16v1.604Z"
      fill="url(#PendingWithdrawals_svg__kU)"
    />
    <path
      d="m37.805 25.528.16-.038v-1.636h-.16v1.674Z"
      fill="url(#PendingWithdrawals_svg__kV)"
    />
    <path
      d="M38.352 25.364c.054-.02.108-.042.16-.067v-1.443h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__kW)"
    />
    <path
      d="m35.336 25.42.163.05v-1.616h-.163v1.566Z"
      fill="url(#PendingWithdrawals_svg__kX)"
    />
    <path
      d="M36.434 25.624h.16v-1.848h-.16v1.848Z"
      fill="url(#PendingWithdrawals_svg__kY)"
    />
    <path
      d="M36.707 23.773v1.863h.16v-1.86h-.16v-.003Z"
      fill="url(#PendingWithdrawals_svg__kZ)"
    />
    <path
      d="M34.225 24.491a.64.64 0 0 0 .175.373v-1.007h-.177l.002.634Z"
      fill="url(#PendingWithdrawals_svg__la)"
    />
    <path
      d="M39.172 24.808a.642.642 0 0 0 .136-.322v-.637h-.136v.96Z"
      fill="url(#PendingWithdrawals_svg__lb)"
    />
    <path
      d="M34.79 25.176c.05.03.105.06.162.088v-1.41h-.163v1.322Z"
      fill="url(#PendingWithdrawals_svg__lc)"
    />
    <path
      d="m36.16 25.599.16.017v-1.815l-.16.018v1.78Z"
      fill="url(#PendingWithdrawals_svg__ld)"
    />
    <path
      d="m35.063 25.312.16.065V23.85h-.16v1.463Z"
      fill="url(#PendingWithdrawals_svg__le)"
    />
    <path
      d="m35.611 25.5.16.038v-1.684h-.16V25.5Z"
      fill="url(#PendingWithdrawals_svg__lf)"
    />
    <path
      d="m35.887 25.564.16.027V23.83l-.16.025v1.71Z"
      fill="url(#PendingWithdrawals_svg__lg)"
    />
    <path
      d="M36.767 22.625c-1.498 0-2.542.622-2.542 1.179 0 .556 1.044 1.178 2.542 1.178 1.498 0 2.542-.62 2.542-1.178 0-.56-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M35.105 24.683c.177.072.358.131.544.176l2.095-2.138a4.752 4.752 0 0 0-.632-.083l-2.007 2.044ZM36.563 24.98h.41l1.823-1.864a2.207 2.207 0 0 0-.266-.143l-1.968 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M35.955 24.876a1.277 1.277 0 0 1-.133-.033c-.086-.025-.175-.042-.262-.073-.086-.03-.17-.055-.246-.085-.042 0-.084-.033-.126-.048l-.062-.025-.062-.028c-.084-.035-.163-.078-.246-.12a2.585 2.585 0 0 1-.455-.308c.103.162.238.3.395.406a1.953 1.953 0 0 0 .242.149l.064.032.065.027c.044.018.086.038.13.053l.136.046c.044.015.089.025.136.038.091.021.183.038.276.05.093.012.186.02.28.023.093 0 .187 0 .278-.013-.091-.025-.18-.04-.271-.06l-.139-.03ZM37.221 24.889l-.368.035-.185.02a1.696 1.696 0 0 0-.188.03c.062.018.124.03.188.043l.091.015h.097c.253.015.507-.01.752-.073.123-.03.243-.075.356-.133-.128 0-.247 0-.375.02s-.247.03-.368.043Z"
      fill="#fff"
    />
    <path
      d="M34.652 36.817c-.306 0-.61.027-.911.083h-1.632v.637c.04.549 1.072 1.145 2.54 1.145 1.47 0 2.5-.596 2.54-1.145v-.645h-1.631a4.896 4.896 0 0 0-.906-.075Z"
      fill="url(#PendingWithdrawals_svg__lh)"
    />
    <path
      d="M32.39 38.023c.053.045.107.087.163.126V36.89h-.162v1.133Z"
      fill="url(#PendingWithdrawals_svg__li)"
    />
    <path
      d="M35.414 36.875v1.75l.16-.028v-1.704h-.012l-.148-.018Z"
      fill="url(#PendingWithdrawals_svg__lj)"
    />
    <path
      d="m35.14 38.655.161-.018v-1.782l-.16-.02v1.82Z"
      fill="url(#PendingWithdrawals_svg__lk)"
    />
    <path
      d="M36.51 38.287c.055-.026.109-.057.16-.09v-1.304h-.16v1.394Z"
      fill="url(#PendingWithdrawals_svg__ll)"
    />
    <path
      d="M34.86 38.678h.16v-1.836h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__lm)"
    />
    <path
      d="M36.785 38.121c.057-.04.11-.085.16-.133v-1.095h-.16v1.228Z"
      fill="url(#PendingWithdrawals_svg__ln)"
    />
    <path
      d="m35.963 38.504.16-.05v-1.561h-.16v1.61Z"
      fill="url(#PendingWithdrawals_svg__lo)"
    />
    <path
      d="m35.688 38.574.16-.037v-1.644h-.16v1.681Z"
      fill="url(#PendingWithdrawals_svg__lp)"
    />
    <path
      d="M36.236 38.403c.055-.02.109-.043.16-.068v-1.442h-.16v1.51Z"
      fill="url(#PendingWithdrawals_svg__lq)"
    />
    <path
      d="m33.219 38.466.163.05v-1.623h-.163v1.573Z"
      fill="url(#PendingWithdrawals_svg__lr)"
    />
    <path
      d="M34.318 38.67h.16v-1.855h-.16v1.855Z"
      fill="url(#PendingWithdrawals_svg__ls)"
    />
    <path
      d="M34.592 36.82v1.86h.16v-1.86h-.16Z"
      fill="url(#PendingWithdrawals_svg__lt)"
    />
    <path
      d="M32.108 37.537a.64.64 0 0 0 .175.373v-1.007h-.178l.003.634Z"
      fill="url(#PendingWithdrawals_svg__lu)"
    />
    <path
      d="M37.057 37.86a.623.623 0 0 0 .133-.323v-.644h-.135l.002.966Z"
      fill="url(#PendingWithdrawals_svg__lv)"
    />
    <path
      d="M32.672 38.222c.052.03.106.058.163.085v-1.414h-.163v1.329Z"
      fill="url(#PendingWithdrawals_svg__lw)"
    />
    <path
      d="m34.045 38.655.16.017v-1.825l-.16.018v1.79Z"
      fill="url(#PendingWithdrawals_svg__lx)"
    />
    <path
      d="M32.945 38.36c.052.023.106.046.163.066v-1.533h-.163v1.467Z"
      fill="url(#PendingWithdrawals_svg__ly)"
    />
    <path
      d="m33.494 38.547.163.035v-1.69h-.163v1.655Z"
      fill="url(#PendingWithdrawals_svg__lz)"
    />
    <path
      d="m33.768 38.605.163.025v-1.763l-.163.028v1.71Z"
      fill="url(#PendingWithdrawals_svg__lA)"
    />
    <path
      d="M34.652 35.672c-1.498 0-2.543.622-2.543 1.178 0 .556 1.044 1.178 2.543 1.178 1.498 0 2.542-.622 2.542-1.178 0-.556-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M32.996 37.728c.176.073.358.132.543.177l2.096-2.138a4.353 4.353 0 0 0-.632-.083l-2.007 2.044ZM34.445 38.026h.413l1.824-1.864a2.22 2.22 0 0 0-.267-.143l-1.97 2.007Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M33.838 37.922c-.044 0-.089-.017-.133-.032-.087-.026-.175-.043-.262-.073-.086-.03-.17-.056-.247-.086-.041-.013-.084-.033-.125-.048l-.062-.025-.062-.03a2.732 2.732 0 0 1-.701-.425c.103.16.237.299.395.405.038.028.078.054.119.078.039.025.081.048.123.07l.064.03.064.03c.045.018.087.038.131.053l.136.046c.044.015.089.025.136.038.182.042.368.067.555.073.094 0 .188 0 .279-.013-.091-.025-.18-.04-.272-.06l-.138-.03ZM35.106 37.935l-.368.035-.185.02a1.728 1.728 0 0 0-.188.03c.062.018.124.03.185.043l.094.013.096.012c.126.01.252.01.378 0a2.31 2.31 0 0 0 .373-.065c.124-.03.244-.075.358-.134-.129 0-.247 0-.375.018l-.368.028Z"
      fill="#fff"
    />
    <path
      d="M37.128 39.03c-.305 0-.61.027-.91.08h-1.632v.638c.04.548 1.071 1.145 2.54 1.145s2.5-.597 2.54-1.145v-.637h-1.632c-.299-.054-.602-.08-.906-.081Z"
      fill="url(#PendingWithdrawals_svg__lB)"
    />
    <path
      d="M34.875 40.236c.052.045.106.087.163.126v-1.259h-.163v1.133Z"
      fill="url(#PendingWithdrawals_svg__lC)"
    />
    <path
      d="M37.893 39.088v1.75l.16-.028v-1.7l-.16-.022Z"
      fill="url(#PendingWithdrawals_svg__lD)"
    />
    <path
      d="m37.62 40.87.16-.017V39.07l-.16-.017v1.817Z"
      fill="url(#PendingWithdrawals_svg__lE)"
    />
    <path
      d="m38.988 40.5.16-.09v-1.3h-.16v1.39Z"
      fill="url(#PendingWithdrawals_svg__lF)"
    />
    <path
      d="M37.344 40.89h.16v-1.835h-.16v1.836Z"
      fill="url(#PendingWithdrawals_svg__lG)"
    />
    <path
      d="M39.262 40.334c.056-.041.11-.087.16-.136v-1.087h-.16v1.223Z"
      fill="url(#PendingWithdrawals_svg__lH)"
    />
    <path
      d="M38.441 40.717c.055-.018.109-.033.16-.053V39.11h-.16v1.606Z"
      fill="url(#PendingWithdrawals_svg__lI)"
    />
    <path
      d="m38.166 40.787.16-.04v-1.636h-.16v1.676Z"
      fill="url(#PendingWithdrawals_svg__lJ)"
    />
    <path
      d="m38.715 40.624.16-.068V39.11h-.16v1.513Z"
      fill="url(#PendingWithdrawals_svg__lK)"
    />
    <path
      d="m35.7 40.669.162.05V39.1H35.7v1.569Z"
      fill="url(#PendingWithdrawals_svg__lL)"
    />
    <path
      d="M36.793 40.883h.163v-1.85h-.163v1.85Z"
      fill="url(#PendingWithdrawals_svg__lM)"
    />
    <path
      d="M37.08 39.032v1.861h.16v-1.86h-.16Z"
      fill="url(#PendingWithdrawals_svg__lN)"
    />
    <path
      d="M34.588 39.747a.645.645 0 0 0 .176.376v-1.008h-.178l.002.632Z"
      fill="url(#PendingWithdrawals_svg__lO)"
    />
    <path
      d="M39.549 40.072a.622.622 0 0 0 .133-.325v-.636h-.133v.961Z"
      fill="url(#PendingWithdrawals_svg__lP)"
    />
    <path
      d="M35.15 40.435c.052.03.107.058.163.085v-1.41h-.163v1.325Z"
      fill="url(#PendingWithdrawals_svg__lQ)"
    />
    <path
      d="m36.52 40.858.162.018V39.05l-.163.018v1.79Z"
      fill="url(#PendingWithdrawals_svg__lR)"
    />
    <path
      d="M35.424 40.573c.052.023.106.046.163.066V39.11h-.163v1.462Z"
      fill="url(#PendingWithdrawals_svg__lS)"
    />
    <path
      d="M35.973 40.757c.054.015.106.028.163.038V39.11h-.163v1.646Z"
      fill="url(#PendingWithdrawals_svg__lT)"
    />
    <path
      d="m36.246 40.817.163.026V39.08l-.163.028v1.71Z"
      fill="url(#PendingWithdrawals_svg__lU)"
    />
    <path
      d="M37.128 37.885c-1.498 0-2.542.619-2.542 1.178 0 .559 1.044 1.178 2.542 1.178 1.499 0 2.543-.622 2.543-1.178 0-.557-1.044-1.178-2.543-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M35.473 39.941c.176.073.357.132.543.174l2.095-2.135a4.74 4.74 0 0 0-.631-.085l-2.007 2.046ZM36.926 40.236h.41l1.824-1.86a2.67 2.67 0 0 0-.267-.146l-1.967 2.006Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="m36.317 40.135-.133-.033c-.086-.025-.175-.042-.262-.073-.086-.03-.17-.055-.246-.085l-.126-.05-.062-.026-.062-.028c-.084-.035-.163-.078-.247-.118a2.85 2.85 0 0 1-.456-.307c.136.197.312.361.516.483.04.026.081.046.123.068.02.013.042.024.064.033l.064.03c.045.018.087.038.131.053l.133.045.139.038c.09.022.183.039.276.05.092.016.185.016.28.023.093.008.187 0 .278-.012-.091-.026-.18-.04-.271-.06l-.139-.03ZM37.573 40.145l-.368.038-.185.018c-.062 0-.126.017-.188.032.06.017.123.03.185.04l.094.016h.096a2.108 2.108 0 0 0 .75-.066c.124-.031.244-.076.358-.133-.128 0-.246 0-.375.017l-.367.038Z"
      fill="#fff"
    />
    <path
      d="M39.98 34.672c-.305 0-.609.027-.908.083h-1.635v.627c.04.549 1.072 1.146 2.54 1.146 1.47 0 2.501-.597 2.543-1.146v-.637h-1.632a4.878 4.878 0 0 0-.908-.073Z"
      fill="#C76B00"
    />
    <path
      opacity={0.3}
      d="M37.73 35.886c.05.046.104.088.16.125v-1.258h-.16v1.133ZM40.742 34.73v1.75l.16-.028v-1.697l-.16-.025ZM40.469 36.515l.16-.018v-1.782l-.16-.02v1.82ZM41.84 36.137a1.4 1.4 0 0 0 .16-.09V34.75h-.16v1.387ZM40.195 36.533h.16v-1.836h-.16v1.836ZM42.113 35.976c.057-.04.11-.085.16-.133v-1.088h-.16v1.221ZM41.293 36.359l.16-.05v-1.554h-.16v1.604ZM41.031 36.43l.16-.038v-1.637h-.16v1.674ZM41.566 36.266c.055-.02.109-.043.16-.068v-1.443h-.16v1.51ZM38.563 36.321l.16.05v-1.616h-.16v1.566ZM39.648 36.525h.16v-1.848h-.16v1.848ZM39.922 34.675v1.86h.16v-1.86h-.16ZM37.438 35.382c.017.14.079.271.175.373v-1.007h-.178l.003.634ZM42.387 35.714a.628.628 0 0 0 .136-.322v-.637h-.136v.96ZM38.004 36.077c.05.03.104.058.16.088v-1.41h-.16v1.322ZM39.375 36.5l.16.017v-1.825l-.16.018v1.79ZM38.277 36.215c.052.023.104.046.16.066v-1.526h-.16v1.46ZM38.824 36.402l.16.035v-1.682h-.16v1.647ZM39.098 36.46l.16.025v-1.763l-.16.028v1.71Z"
      fill="#fff"
    />
    <path
      d="M39.98 33.527c-1.498 0-2.542.621-2.542 1.178 0 .556 1.044 1.178 2.542 1.178 1.498 0 2.542-.62 2.542-1.178 0-.56-1.044-1.178-2.542-1.178Z"
      fill="#F98600"
    />
    <path
      opacity={0.25}
      d="M38.315 35.584c.177.073.359.132.545.176l2.093-2.138a4.754 4.754 0 0 0-.632-.083l-2.006 2.045ZM39.777 35.886h.41l1.824-1.86a2.2 2.2 0 0 0-.266-.144l-1.968 2.004Z"
      fill="#fff"
    />
    <path
      opacity={0.25}
      d="M39.168 35.777a1.27 1.27 0 0 1-.133-.032c-.086-.026-.175-.043-.262-.073-.086-.03-.17-.056-.247-.086-.042-.012-.083-.033-.125-.048l-.062-.025-.062-.03a2.15 2.15 0 0 1-.247-.118 2.587 2.587 0 0 1-.454-.308c.103.162.238.3.395.406a1.973 1.973 0 0 0 .242.148l.064.033.064.028c.045.017.087.037.131.053l.136.045c.044.015.091.025.136.038.09.022.183.038.276.05.093.013.186.02.28.023.093 0 .187 0 .278-.013-.089-.025-.18-.04-.272-.06l-.138-.03Z"
      fill="#fff"
    />
    <path
      opacity={0.3}
      d="m40.436 35.79-.368.035-.185.02a1.704 1.704 0 0 0-.188.03c.062.018.124.03.188.043l.091.013.097.013c.253.014.507-.01.752-.074.123-.03.242-.075.356-.133-.129 0-.247 0-.375.02-.129.02-.247.02-.368.033Z"
      fill="#fff"
    />
    <path
      d="M20.216 34.05V12.102L1.777 22.922v21.949l18.44-10.82Z"
      fill="#ED5656"
    />
    <path d="M0 43.78V21.846l1.777 1.078V44.87L0 43.78Z" fill="#E92C2C" />
    <path
      d="m18.441 11.024 1.775 1.078-18.439 10.82L0 21.846l18.441-10.82Z"
      fill="#F6ABAB"
    />
    <path
      d="M12.126 28.892a.235.235 0 0 0 .043.161.227.227 0 0 0 .137.09.194.194 0 0 0 .153-.048.199.199 0 0 0 .067-.148.236.236 0 0 0-.041-.16.227.227 0 0 0-.136-.092.195.195 0 0 0-.154.049.202.202 0 0 0-.069.148Z"
      fill="#636363"
    />
    <path
      d="M4.25 32.021c0-4.151 2.876-9.212 6.418-11.301 1.935-1.138 3.668-1.158 4.845-.267l1.146.735.469.657c.508.778.8 1.843.8 3.16 0 4.151-2.879 9.211-6.418 11.301-1.235.72-2.37.994-3.343.871l-.74-.103-1.35-.884c-1.133-.695-1.827-2.135-1.827-4.169Z"
      fill="#8C1A1A"
    />
    <path
      d="M18.486 25.365c0 4.151-2.876 9.211-6.418 11.301-3.542 2.09-6.418.415-6.418-3.736 0-4.152 2.876-9.212 6.418-11.302 3.542-2.09 6.418-.415 6.418 3.737Z"
      fill="#ED5656"
    />
    <path
      d="M7.168 32.545c0-3.384 2.345-7.508 5.235-9.21 1.481-.876 2.824-.936 3.777-.335l1.454.952-.146.252c.403.745.6 1.587.573 2.437 0 3.384-2.345 7.507-5.236 9.21-.962.566-1.86.787-2.636.712l-.17.287-1.392-.912c-.901-.581-1.459-1.744-1.459-3.393Z"
      fill="#8C1A1A"
    />
    <path
      d="M8.517 33.426a.91.91 0 0 0 0 .116c.047 3.184 2.222 4.531 4.937 3.096.101-.053.195-.11.296-.166.102-.055.198-.12.294-.184a10.796 10.796 0 0 0 3.056-3.202c.106-.169.21-.342.311-.519.943-1.636 1.543-3.481 1.572-5.158v-.146c0-.183-.007-.362-.022-.536-.123-1.594-.822-2.659-1.86-3.064l-.159-.056c-.81-.251-1.81-.125-2.898.446l-.293.164c-.102.06-.2.12-.297.183a10.564 10.564 0 0 0-2.848 2.898 9.79 9.79 0 0 0-.329.504 11.646 11.646 0 0 0-1.727 5.035 5.53 5.53 0 0 0-.033.589Z"
      fill="#ED5656"
    />
    <path
      d="M9.27 32.983c0 1.047.261 1.868.71 2.422.798.976 2.192 1.12 3.775.186 2.468-1.46 4.482-4.99 4.482-7.885 0-1.747-.74-2.87-1.853-3.155-.741-.199-1.65-.03-2.63.55-2.475 1.454-4.484 4.994-4.484 7.882Z"
      fill="#111B5C"
    />
    <path
      d="M9.269 32.983c-.03.761.155 1.515.533 2.173a2.154 2.154 0 0 0 .637.675c.82.573 2.004.535 3.315-.237 2.468-1.46 4.482-4.99 4.482-7.886 0-1.661-.659-2.756-1.693-3.106-.05-.018-.106-.033-.16-.048a2.446 2.446 0 0 0-.864-.068 4.22 4.22 0 0 0-1.765.617c-2.476 1.452-4.485 4.992-4.485 7.88Z"
      fill="#E92C2C"
    />
    <path
      d="M9.27 32.983c-.023.699.131 1.393.447 2.014.075.143.164.279.264.405.21.056.428.083.646.08a3.806 3.806 0 0 0 1.642-.435c.113-.058.232-.118.348-.191.423-.25.818-.546 1.18-.881 1.673-1.51 2.962-3.915 3.246-6.12.038-.294.057-.59.056-.887 0-.153 0-.3-.017-.443a3.464 3.464 0 0 0-.698-1.976c-.74-.199-1.65-.03-2.63.549a7.21 7.21 0 0 0-1.252.944c-.08.07-.157.146-.245.224a9.98 9.98 0 0 0-1.311 1.626 10.685 10.685 0 0 0-1.392 3.036c0 .048-.028.094-.037.144-.114.43-.191.87-.23 1.314a5.292 5.292 0 0 0-.017.597Z"
      fill="#8C1A1A"
    />
    <path
      d="M10.62 31.13c0-1.453 1.007-3.225 2.249-3.955.387-.264.86-.363 1.318-.275.138.035.267.097.38.184l.879.534-.343.584c0 .09.014.184.014.282 0 1.45-1.007 3.222-2.248 3.952a2.647 2.647 0 0 1-.363.177l-.319.54-.89-.54a1.128 1.128 0 0 1-.324-.267 1.907 1.907 0 0 1-.353-1.216Z"
      fill="#E92C2C"
    />
    <path
      d="M12.502 26.044v1.377l.955.504.156-2.727a7.21 7.21 0 0 0-1.111.846Z"
      fill="#E92C2C"
    />
    <path
      d="M12.27 29.247v5.808c.098-.053 1.09.755 1.19.697.098-.058.246-1.44.345-1.51v-.273l-.022-1.007-.106-5.19a4.37 4.37 0 0 0-1.254 1.258c-.06.058-.107.143-.153.216ZM9.299 32.394l2.263-1.322-.876-.62-1.158.627c-.114.43-.19.87-.23 1.315Z"
      fill="#E92C2C"
    />
    <path
      d="m11.563 31.072 4.386-2.576 1.093-.64 1.168-.686-1.123-.642-1.728 1.007-3.162 1.855a4.942 4.942 0 0 0-.634 1.682ZM10.64 28.403l.63.489.96.42s.07-.123.183-.29l-.054-.04-.247-.198-.148-.116-.185-.144-.813-.63a4.904 4.904 0 0 0-.325.51Z"
      fill="#E92C2C"
    />
    <path
      d="m12.102 29.536.037.03.22.174 2.648 2.052.407.315 1.195.596-1.392-1.228-2.604-2.299-.186-.14h-.014a4.95 4.95 0 0 0-.311.5ZM14.188 26.9l.686.504 1.67-2.8c-.049-.017-.105-.032-.16-.048a2.448 2.448 0 0 0-.864-.068L14.188 26.9ZM9.715 35c.075.142.164.278.264.405.132.163.287.305.46.423l.187-.343 1.357-2.495-.89-.503-1.378 2.512Z"
      fill="#E92C2C"
    />
    <path
      d="M13.457 24.4v12.24c.101-.052.195-.11.296-.165.101-.056.198-.121.294-.184V24.053l-.294.164c-.101.06-.2.12-.296.183Z"
      fill="#ED5656"
    />
    <path
      d="M8.517 33.426a.91.91 0 0 0 0 .116l4.394-2.576.54-.32.285-.163.172-.103.129-.073.247-.151 4.69-2.747v-.146c0-.183-.008-.362-.023-.536l-4.49 2.633-.427.252-.247.15-.31.184h-.02l-.225.131-4.69 2.75a7.545 7.545 0 0 0-.025.599Z"
      fill="#ED5656"
    />
    <path
      d="m10.281 27.804 2.962 2.284.116.09.109.083.284.222.084.063.222.171 3.056 2.369c.106-.169.21-.342.31-.519l-3.112-2.412-.185-.143-.07-.05-.246-.2-.146-.117-.185-.142-2.848-2.205a3.945 3.945 0 0 0-.35.506Z"
      fill="#ED5656"
    />
    <path
      d="M10.133 36.052c.05.312.108.617.178.911l3.147-5.745.368-.672.088-.166.129-.234.069-.134.358-.652 2.92-5.335a4.554 4.554 0 0 0-.291-.362l-.158-.056-2.898 5.287-.405.756-.165.302-.02.032-.109.2-.432.787-2.78 5.08Z"
      fill="#ED5656"
    />
    <path
      d="M11.504 31.669c0 .115.007.23.02.345.029.315.144.616.333.868a.987.987 0 0 0 .41.312c.395.176.915.116 1.48-.219a.039.039 0 0 0 .02-.015 4.285 4.285 0 0 0 1.235-1.168c.071-.103.143-.207.21-.317.478-.728.749-1.577.782-2.452 0-.177-.017-.353-.052-.526a1.374 1.374 0 0 0-.202-.543 1.344 1.344 0 0 0-.405-.407.978.978 0 0 0-.27-.108.142.142 0 0 0-.034 0 1.624 1.624 0 0 0-1.108.189 1.68 1.68 0 0 0-.262.148 4.37 4.37 0 0 0-1.254 1.26c-.052.075-.098.15-.145.223-.034.05-.064.102-.09.156a4.947 4.947 0 0 0-.617 1.67 3.305 3.305 0 0 0-.051.584Z"
      fill="#ED5656"
    />
    <path
      d="M12.06 31.354c0 .084.006.168.015.252.02.237.106.464.247.654a.551.551 0 0 0 .092.096c.062.059.135.106.214.138a1.23 1.23 0 0 0 1.12-.163h.016a3.29 3.29 0 0 0 .928-.881c.057-.078.108-.156.158-.237.36-.549.565-1.188.59-1.848a1.95 1.95 0 0 0-.04-.395 1.02 1.02 0 0 0-.456-.715.72.72 0 0 0-.203-.083h-.027a1.238 1.238 0 0 0-.834.14 1.309 1.309 0 0 0-.195.114 3.266 3.266 0 0 0-.946.944c-.04.055-.074.113-.11.169a1.062 1.062 0 0 0-.067.116c-.023.035-.037.068-.057.1a3.78 3.78 0 0 0-.407 1.156 2.568 2.568 0 0 0-.037.443Z"
      fill="#8C1A1A"
    />
    <path
      d="M15.446 29.34c-.026.66-.23 1.3-.59 1.848-.049.081-.101.16-.158.236a3.22 3.22 0 0 1-.928.881 1.239 1.239 0 0 1-1.136.176.813.813 0 0 1-.353-.296 2.09 2.09 0 0 0 .403-.184h.017c.418-.26.782-.602 1.069-1.007.064-.088.126-.179.182-.272.417-.632.652-1.37.682-2.13a2.43 2.43 0 0 0-.042-.443c.043.003.086.01.128.02h.027c.072.017.14.045.203.083a.998.998 0 0 1 .456.713c.025.123.039.249.04.375Z"
      fill="#E92C2C"
    />
    <path
      d="M20.412 19.05a.695.695 0 0 1-.404-.03l-.065-.03a.23.23 0 0 1-.13-.189v-1.803a.237.237 0 0 0 .13.19.66.66 0 0 0 .422.067c.07-.01.14-.03.203-.063a.272.272 0 0 0 .138-.146.195.195 0 0 0 0-.053v1.81c.005.15-.163.214-.294.247Z"
      fill="#8C1A1A"
    />
    <path
      d="M20.575 17.185a.692.692 0 0 1-.64 0 .23.23 0 0 1-.13-.189h.412v-.264a.664.664 0 0 1 .358.075.201.201 0 0 1 0 .378Z"
      fill="#F6ABAB"
    />
    <path
      d="M20.412 31.684a.694.694 0 0 1-.469-.06.225.225 0 0 1-.13-.187v-1.805a.237.237 0 0 0 .13.189.68.68 0 0 0 .625.005.272.272 0 0 0 .138-.146.192.192 0 0 0 0-.05v1.81c.005.146-.163.219-.294.244Z"
      fill="#8C1A1A"
    />
    <path
      d="M20.575 29.818a.692.692 0 0 1-.64 0 .23.23 0 0 1-.13-.189h.412v-.264a.668.668 0 0 1 .358.078.202.202 0 0 1 0 .375Z"
      fill="#F6ABAB"
    />
    <defs>
      <linearGradient
        id="PendingWithdrawals_svg__a"
        x1={28.801}
        y1={30.412}
        x2={33.16}
        y2={30.412}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__b"
        x1={206.844}
        y1={346.641}
        x2={209.131}
        y2={346.735}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__c"
        x1={212.729}
        y1={468.849}
        x2={215.055}
        y2={468.919}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__d"
        x1={212.444}
        y1={483.18}
        x2={214.77}
        y2={483.247}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__e"
        x1={211.187}
        y1={373.33}
        x2={213.476}
        y2={373.415}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__f"
        x1={212.151}
        y1={488.164}
        x2={214.476}
        y2={488.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__g"
        x1={211.491}
        y1={331.471}
        x2={213.779}
        y2={331.568}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__h"
        x1={213.287}
        y1={428.561}
        x2={215.613}
        y2={428.637}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__i"
        x1={213.027}
        y1={446.824}
        x2={215.352}
        y2={446.897}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__j"
        x1={210.851}
        y1={404.68}
        x2={213.141}
        y2={404.758}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__k"
        x1={207.698}
        y1={436.199}
        x2={209.987}
        y2={436.273}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__l"
        x1={211.564}
        y1={494.935}
        x2={213.89}
        y2={495.001}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__m"
        x1={211.852}
        y1={498.141}
        x2={214.178}
        y2={498.206}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__n"
        x1={222.985}
        y1={283.138}
        x2={225.478}
        y2={283.278}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__o"
        x1={184.422}
        y1={266.112}
        x2={186.357}
        y2={266.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__p"
        x1={207.113}
        y1={384.642}
        x2={209.401}
        y2={384.726}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__q"
        x1={211.238}
        y1={487.329}
        x2={213.564}
        y2={487.396}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__r"
        x1={207.407}
        y1={414.279}
        x2={209.696}
        y2={414.356}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__s"
        x1={210.673}
        y1={453.002}
        x2={212.999}
        y2={453.075}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__t"
        x1={210.957}
        y1={472.115}
        x2={213.283}
        y2={472.184}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__u"
        x1={637.667}
        y1={298.667}
        x2={699.209}
        y2={344.539}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__v"
        x1={2959.93}
        y1={578.005}
        x2={3002.49}
        y2={595.443}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__w"
        x1={2520.4}
        y1={521.469}
        x2={2557.11}
        y2={535.67}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__x"
        x1={2248.97}
        y1={237.817}
        x2={2260.17}
        y2={247.171}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__y"
        x1={1688}
        y1={83.042}
        x2={1689.27}
        y2={86.182}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__z"
        x1={5643.64}
        y1={489.283}
        x2={5733.44}
        y2={489.283}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__A"
        x1={205.765}
        y1={338.5}
        x2={208.052}
        y2={338.594}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__B"
        x1={208.953}
        y1={457.971}
        x2={211.243}
        y2={458.038}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__C"
        x1={208.659}
        y1={472.007}
        x2={210.949}
        y2={472.072}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__D"
        x1={210.144}
        y1={364.467}
        x2={212.432}
        y2={364.553}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__E"
        x1={208.336}
        y1={476.882}
        x2={210.625}
        y2={476.947}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__F"
        x1={210.449}
        y1={323.504}
        x2={212.737}
        y2={323.601}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__G"
        x1={209.543}
        y1={418.526}
        x2={211.832}
        y2={418.599}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__H"
        x1={209.245}
        y1={436.412}
        x2={211.536}
        y2={436.483}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__I"
        x1={209.839}
        y1={395.15}
        x2={212.128}
        y2={395.229}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__J"
        x1={206.628}
        y1={426.734}
        x2={208.917}
        y2={426.807}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__K"
        x1={207.78}
        y1={482.926}
        x2={210.07}
        y2={482.99}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__L"
        x1={208.08}
        y1={483.589}
        x2={210.37}
        y2={483.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__M"
        x1={224.596}
        y1={276.334}
        x2={227.128}
        y2={276.478}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__N"
        x1={186.138}
        y1={259.563}
        x2={188.107}
        y2={259.654}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__O"
        x1={206.043}
        y1={375.683}
        x2={208.329}
        y2={375.767}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__P"
        x1={207.506}
        y1={476.112}
        x2={209.796}
        y2={476.177}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__Q"
        x1={206.356}
        y1={404.666}
        x2={208.646}
        y2={404.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__R"
        x1={206.912}
        y1={442.539}
        x2={209.201}
        y2={442.609}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__S"
        x1={207.21}
        y1={461.241}
        x2={209.499}
        y2={461.308}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__T"
        x1={5660.53}
        y1={478.719}
        x2={5750.34}
        y2={478.719}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__U"
        x1={209.821}
        y1={330.508}
        x2={212.143}
        y2={330.605}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__V"
        x1={210.241}
        y1={444.332}
        x2={212.53}
        y2={444.399}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__W"
        x1={209.953}
        y1={463.477}
        x2={212.243}
        y2={463.542}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__X"
        x1={211.438}
        y1={355.802}
        x2={213.726}
        y2={355.888}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__Y"
        x1={209.658}
        y1={465.857}
        x2={211.947}
        y2={465.922}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__Z"
        x1={211.739}
        y1={315.119}
        x2={214.026}
        y2={315.217}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aa"
        x1={210.832}
        y1={408.717}
        x2={213.121}
        y2={408.791}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ab"
        x1={210.498}
        y1={426.23}
        x2={212.789}
        y2={426.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ac"
        x1={211.131}
        y1={385.832}
        x2={213.42}
        y2={385.91}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ad"
        x1={210.631}
        y1={416.249}
        x2={212.955}
        y2={416.325}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ae"
        x1={209.072}
        y1={472.401}
        x2={211.362}
        y2={472.465}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__af"
        x1={209.362}
        y1={473.035}
        x2={211.651}
        y2={473.099}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ag"
        x1={225.947}
        y1={269.684}
        x2={228.478}
        y2={269.829}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ah"
        x1={184.654}
        y1={251.969}
        x2={186.589}
        y2={252.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ai"
        x1={210.108}
        y1={366.897}
        x2={212.432}
        y2={366.983}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aj"
        x1={208.804}
        y1={462.737}
        x2={211.094}
        y2={462.803}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ak"
        x1={207.639}
        y1={394.661}
        x2={209.928}
        y2={394.738}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__al"
        x1={208.178}
        y1={432.324}
        x2={210.467}
        y2={432.394}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__am"
        x1={208.502}
        y1={450.611}
        x2={210.791}
        y2={450.678}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__an"
        x1={5687.41}
        y1={473.178}
        x2={5777.17}
        y2={473.178}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ao"
        x1={212.074}
        y1={324.828}
        x2={214.396}
        y2={324.926}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ap"
        x1={212.499}
        y1={439.761}
        x2={214.789}
        y2={439.829}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aq"
        x1={212.214}
        y1={453.296}
        x2={214.504}
        y2={453.361}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ar"
        x1={213.699}
        y1={349.069}
        x2={215.987}
        y2={349.154}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__as"
        x1={211.923}
        y1={458.011}
        x2={214.212}
        y2={458.076}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__at"
        x1={213.974}
        y1={309.585}
        x2={216.261}
        y2={309.682}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__au"
        x1={213.095}
        y1={401.146}
        x2={215.384}
        y2={401.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__av"
        x1={212.798}
        y1={418.39}
        x2={215.089}
        y2={418.461}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aw"
        x1={213.39}
        y1={378.611}
        x2={215.679}
        y2={378.689}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ax"
        x1={212.949}
        y1={409.246}
        x2={215.273}
        y2={409.322}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ay"
        x1={211.312}
        y1={463.876}
        x2={213.601}
        y2={463.94}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__az"
        x1={211.599}
        y1={464.495}
        x2={213.888}
        y2={464.558}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aA"
        x1={228.3}
        y1={264.969}
        x2={230.83}
        y2={265.113}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aB"
        x1={186.748}
        y1={248.01}
        x2={188.682}
        y2={248.098}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aC"
        x1={212.387}
        y1={360.068}
        x2={214.711}
        y2={360.155}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aD"
        x1={211.052}
        y1={457.336}
        x2={213.342}
        y2={457.401}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aE"
        x1={212.654}
        y1={387.982}
        x2={214.98}
        y2={388.063}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aF"
        x1={210.479}
        y1={424.45}
        x2={212.769}
        y2={424.521}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aG"
        x1={213.518}
        y1={443.031}
        x2={215.843}
        y2={443.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aH"
        x1={5734.03}
        y1={485.847}
        x2={5823.79}
        y2={485.847}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aI"
        x1={215.657}
        y1={335.588}
        x2={217.979}
        y2={335.686}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aJ"
        x1={216.072}
        y1={454.124}
        x2={218.362}
        y2={454.192}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aK"
        x1={215.781}
        y1={468.054}
        x2={218.07}
        y2={468.12}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aL"
        x1={217.266}
        y1={361.331}
        x2={219.555}
        y2={361.416}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aM"
        x1={215.487}
        y1={472.892}
        x2={217.776}
        y2={472.957}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aN"
        x1={217.536}
        y1={320.683}
        x2={219.825}
        y2={320.78}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aO"
        x1={216.672}
        y1={414.993}
        x2={218.961}
        y2={415.067}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aP"
        x1={216.362}
        y1={432.73}
        x2={218.653}
        y2={432.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aQ"
        x1={216.963}
        y1={391.779}
        x2={219.252}
        y2={391.857}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aR"
        x1={216.526}
        y1={423.161}
        x2={218.853}
        y2={423.237}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aS"
        x1={214.871}
        y1={478.903}
        x2={217.16}
        y2={478.966}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aT"
        x1={215.163}
        y1={480.165}
        x2={217.452}
        y2={480.229}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aU"
        x1={232.015}
        y1={273.936}
        x2={234.545}
        y2={274.081}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aV"
        x1={190.088}
        y1={257.241}
        x2={192.022}
        y2={257.33}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aW"
        x1={215.964}
        y1={372.511}
        x2={218.287}
        y2={372.598}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aX"
        x1={214.616}
        y1={472.143}
        x2={216.906}
        y2={472.208}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aY"
        x1={216.244}
        y1={401.264}
        x2={218.568}
        y2={401.344}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__aZ"
        x1={214.043}
        y1={438.847}
        x2={216.332}
        y2={438.918}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ba"
        x1={214.327}
        y1={457.394}
        x2={216.616}
        y2={457.461}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bb"
        x1={2549.2}
        y1={507.428}
        x2={2585.86}
        y2={521.495}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bc"
        x1={1713.8}
        y1={79.681}
        x2={1715.08}
        y2={82.83}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bd"
        x1={5566.88}
        y1={518.716}
        x2={5656.64}
        y2={518.716}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__be"
        x1={199.928}
        y1={358.608}
        x2={202.216}
        y2={358.702}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bf"
        x1={205.775}
        y1={484.797}
        x2={208.1}
        y2={484.866}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bg"
        x1={205.484}
        y1={499.571}
        x2={207.81}
        y2={499.638}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bh"
        x1={204.24}
        y1={386.333}
        x2={206.528}
        y2={386.419}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bi"
        x1={205.192}
        y1={504.68}
        x2={207.518}
        y2={504.746}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bj"
        x1={204.579}
        y1={343.155}
        x2={206.868}
        y2={343.252}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bk"
        x1={203.674}
        y1={443.275}
        x2={205.963}
        y2={443.349}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bl"
        x1={206.081}
        y1={462.1}
        x2={208.406}
        y2={462.173}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bm"
        x1={203.975}
        y1={418.657}
        x2={206.264}
        y2={418.736}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bn"
        x1={203.469}
        y1={449.692}
        x2={205.794}
        y2={449.768}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bo"
        x1={204.577}
        y1={509.052}
        x2={206.903}
        y2={509.118}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bp"
        x1={204.9}
        y1={511.724}
        x2={207.226}
        y2={511.79}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bq"
        x1={215.816}
        y1={293.107}
        x2={218.311}
        y2={293.247}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__br"
        x1={177.934}
        y1={275.725}
        x2={179.869}
        y2={275.814}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bs"
        x1={202.9}
        y1={397.804}
        x2={205.226}
        y2={397.891}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bt"
        x1={204.289}
        y1={503.775}
        x2={206.613}
        y2={503.842}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bu"
        x1={200.485}
        y1={428.375}
        x2={202.774}
        y2={428.452}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bv"
        x1={203.745}
        y1={468.334}
        x2={206.069}
        y2={468.406}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bw"
        x1={204.031}
        y1={488.066}
        x2={206.357}
        y2={488.136}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bx"
        x1={5585.15}
        y1={631.485}
        x2={5646.69}
        y2={677.357}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__by"
        x1={5572.38}
        y1={509.007}
        x2={5662.19}
        y2={509.007}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bz"
        x1={200.355}
        y1={352.142}
        x2={202.644}
        y2={352.236}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bA"
        x1={203.505}
        y1={472.992}
        x2={205.794}
        y2={473.06}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bB"
        x1={203.179}
        y1={490.714}
        x2={205.469}
        y2={490.78}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bC"
        x1={204.7}
        y1={379.298}
        x2={206.988}
        y2={379.384}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bD"
        x1={202.89}
        y1={495.747}
        x2={205.179}
        y2={495.811}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bE"
        x1={204.997}
        y1={336.84}
        x2={207.284}
        y2={336.937}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bF"
        x1={204.096}
        y1={435.323}
        x2={206.385}
        y2={435.396}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bG"
        x1={203.797}
        y1={453.844}
        x2={206.088}
        y2={453.915}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bH"
        x1={204.404}
        y1={411.105}
        x2={206.693}
        y2={411.183}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bI"
        x1={201.185}
        y1={443.644}
        x2={203.475}
        y2={443.717}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bJ"
        x1={202.343}
        y1={502.613}
        x2={204.632}
        y2={502.677}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bK"
        x1={202.632}
        y1={502.682}
        x2={204.922}
        y2={502.746}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bL"
        x1={218.917}
        y1={287.718}
        x2={221.446}
        y2={287.862}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bM"
        x1={178.294}
        y1={270.53}
        x2={180.227}
        y2={270.618}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bN"
        x1={200.598}
        y1={390.691}
        x2={202.885}
        y2={390.775}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bO"
        x1={202.053}
        y1={494.887}
        x2={204.343}
        y2={494.952}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bP"
        x1={200.91}
        y1={420.756}
        x2={203.198}
        y2={420.833}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bQ"
        x1={201.474}
        y1={460.045}
        x2={203.763}
        y2={460.115}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bR"
        x1={201.764}
        y1={479.447}
        x2={204.053}
        y2={479.515}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bS"
        x1={5686.1}
        y1={528.247}
        x2={5775.91}
        y2={528.247}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bT"
        x1={209.03}
        y1={365.871}
        x2={211.319}
        y2={365.965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bU"
        x1={212.194}
        y1={491.165}
        x2={214.484}
        y2={491.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bV"
        x1={211.909}
        y1={509.523}
        x2={214.199}
        y2={509.588}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bW"
        x1={213.353}
        y1={394.228}
        x2={215.643}
        y2={394.313}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bX"
        x1={211.614}
        y1={515.375}
        x2={213.903}
        y2={515.439}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bY"
        x1={213.662}
        y1={349.584}
        x2={215.949}
        y2={349.681}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__bZ"
        x1={212.788}
        y1={451.559}
        x2={215.075}
        y2={451.633}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ca"
        x1={212.494}
        y1={470.718}
        x2={214.784}
        y2={470.788}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cb"
        x1={213.079}
        y1={427.13}
        x2={215.369}
        y2={427.208}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cc"
        x1={209.876}
        y1={459.982}
        x2={212.165}
        y2={460.055}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cd"
        x1={211}
        y1={519.81}
        x2={213.289}
        y2={519.874}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ce"
        x1={211.326}
        y1={522.543}
        x2={213.616}
        y2={522.607}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cf"
        x1={228.027}
        y1={299.147}
        x2={230.557}
        y2={299.292}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cg"
        x1={186.457}
        y1={281.552}
        x2={188.391}
        y2={281.64}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ch"
        x1={209.334}
        y1={405.796}
        x2={211.624}
        y2={405.88}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ci"
        x1={210.745}
        y1={513.763}
        x2={213.035}
        y2={513.828}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cj"
        x1={209.603}
        y1={436.935}
        x2={211.892}
        y2={437.013}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ck"
        x1={210.159}
        y1={477.646}
        x2={212.448}
        y2={477.716}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cl"
        x1={210.456}
        y1={497.737}
        x2={212.745}
        y2={497.805}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cm"
        x1={5704.46}
        y1={644.68}
        x2={5766.16}
        y2={690.511}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cn"
        x1={2975.57}
        y1={608.858}
        x2={3018.1}
        y2={626.282}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__co"
        x1={2528.36}
        y1={552.693}
        x2={2565.06}
        y2={566.788}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cp"
        x1={2263.88}
        y1={250.759}
        x2={2275.05}
        y2={260.132}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cq"
        x1={1697.35}
        y1={88.222}
        x2={1698.6}
        y2={91.344}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cr"
        x1={5672.47}
        y1={516.826}
        x2={5762.22}
        y2={516.826}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cs"
        x1={208.001}
        y1={357.716}
        x2={210.288}
        y2={357.811}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ct"
        x1={213.86}
        y1={480.378}
        x2={216.184}
        y2={480.448}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cu"
        x1={213.573}
        y1={498.351}
        x2={215.897}
        y2={498.418}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cv"
        x1={212.35}
        y1={385.363}
        x2={214.639}
        y2={385.449}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cw"
        x1={213.312}
        y1={504.09}
        x2={215.638}
        y2={504.157}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cx"
        x1={212.652}
        y1={341.631}
        x2={214.94}
        y2={341.729}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cy"
        x1={211.742}
        y1={442.178}
        x2={214.031}
        y2={442.252}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cz"
        x1={214.158}
        y1={460.96}
        x2={216.483}
        y2={461.033}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cA"
        x1={214.787}
        y1={417.614}
        x2={217.112}
        y2={417.695}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cB"
        x1={211.54}
        y1={449.894}
        x2={213.864}
        y2={449.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cC"
        x1={212.738}
        y1={508.458}
        x2={215.063}
        y2={508.524}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cD"
        x1={213.022}
        y1={511.123}
        x2={215.348}
        y2={511.189}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cE"
        x1={224.146}
        y1={292.353}
        x2={226.641}
        y2={292.494}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cF"
        x1={185.503}
        y1={275.003}
        x2={187.438}
        y2={275.091}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cG"
        x1={208.28}
        y1={396.822}
        x2={210.568}
        y2={396.907}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cH"
        x1={212.451}
        y1={499.958}
        x2={214.777}
        y2={500.025}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cI"
        x1={208.521}
        y1={427.32}
        x2={210.81}
        y2={427.398}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cJ"
        x1={211.86}
        y1={467.189}
        x2={214.184}
        y2={467.262}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cK"
        x1={212.144}
        y1={486.862}
        x2={214.471}
        y2={486.932}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cL"
        x1={5689.4}
        y1={506.287}
        x2={5779.15}
        y2={506.287}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cM"
        x1={209.291}
        y1={349.739}
        x2={211.578}
        y2={349.833}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cN"
        x1={215.194}
        y1={469.789}
        x2={217.519}
        y2={469.859}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cO"
        x1={214.907}
        y1={488.036}
        x2={217.235}
        y2={488.104}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cP"
        x1={216.355}
        y1={376.699}
        x2={218.676}
        y2={376.787}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cQ"
        x1={211.852}
        y1={492.428}
        x2={214.141}
        y2={492.493}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cR"
        x1={216.695}
        y1={333.858}
        x2={219.018}
        y2={333.958}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cS"
        x1={215.751}
        y1={431.74}
        x2={218.076}
        y2={431.816}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cT"
        x1={215.501}
        y1={450.151}
        x2={217.825}
        y2={450.224}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cU"
        x1={216.048}
        y1={407.67}
        x2={218.37}
        y2={407.751}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cV"
        x1={210.13}
        y1={440.032}
        x2={212.419}
        y2={440.106}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cW"
        x1={211.281}
        y1={499.267}
        x2={213.572}
        y2={499.331}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cX"
        x1={214.32}
        y1={499.324}
        x2={216.644}
        y2={499.389}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cY"
        x1={225.519}
        y1={285.704}
        x2={228.014}
        y2={285.845}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__cZ"
        x1={189.474}
        y1={267.97}
        x2={191.444}
        y2={268.062}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__da"
        x1={209.57}
        y1={388.052}
        x2={211.858}
        y2={388.136}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__db"
        x1={210.957}
        y1={491.565}
        x2={213.245}
        y2={491.63}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dc"
        x1={209.837}
        y1={417.928}
        x2={212.127}
        y2={418.006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dd"
        x1={210.408}
        y1={456.969}
        x2={212.699}
        y2={457.04}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__de"
        x1={210.666}
        y1={476.227}
        x2={212.955}
        y2={476.295}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__df"
        x1={5595.62}
        y1={544.993}
        x2={5685.43}
        y2={544.993}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dg"
        x1={202.097}
        y1={377.835}
        x2={204.384}
        y2={377.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dh"
        x1={205.28}
        y1={507.02}
        x2={207.571}
        y2={507.088}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__di"
        x1={204.997}
        y1={525.914}
        x2={207.287}
        y2={525.979}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dj"
        x1={206.466}
        y1={407.227}
        x2={208.755}
        y2={407.313}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dk"
        x1={204.69}
        y1={531.924}
        x2={206.979}
        y2={531.988}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dl"
        x1={206.747}
        y1={361.25}
        x2={209.034}
        y2={361.347}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dm"
        x1={205.885}
        y1={466.928}
        x2={208.174}
        y2={467.002}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dn"
        x1={205.568}
        y1={485.969}
        x2={207.857}
        y2={486.04}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__do"
        x1={206.171}
        y1={441.124}
        x2={208.46}
        y2={441.202}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dp"
        x1={202.967}
        y1={474.778}
        x2={205.256}
        y2={474.852}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dq"
        x1={204.121}
        y1={538.501}
        x2={206.41}
        y2={538.565}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dr"
        x1={204.376}
        y1={539.294}
        x2={206.665}
        y2={539.358}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ds"
        x1={220.771}
        y1={309.12}
        x2={223.303}
        y2={309.265}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dt"
        x1={179.969}
        y1={291.163}
        x2={181.904}
        y2={291.251}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__du"
        x1={202.407}
        y1={418.956}
        x2={204.695}
        y2={419.04}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dv"
        x1={203.829}
        y1={530.212}
        x2={206.119}
        y2={530.277}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dw"
        x1={202.685}
        y1={451.034}
        x2={204.976}
        y2={451.111}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dx"
        x1={203.252}
        y1={492.978}
        x2={205.541}
        y2={493.049}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dy"
        x1={203.54}
        y1={513.688}
        x2={205.829}
        y2={513.755}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dz"
        x1={5601.22}
        y1={536.605}
        x2={5690.97}
        y2={536.605}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dA"
        x1={202.555}
        y1={371.369}
        x2={204.842}
        y2={371.463}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dB"
        x1={208.395}
        y1={501.795}
        x2={210.722}
        y2={501.864}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dC"
        x1={208.119}
        y1={517.057}
        x2={210.444}
        y2={517.125}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dD"
        x1={206.894}
        y1={400.203}
        x2={209.182}
        y2={400.288}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dE"
        x1={207.836}
        y1={523.649}
        x2={210.161}
        y2={523.715}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dF"
        x1={207.222}
        y1={354.96}
        x2={209.509}
        y2={355.057}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dG"
        x1={209.018}
        y1={458.309}
        x2={211.343}
        y2={458.386}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dH"
        x1={205.967}
        y1={477.727}
        x2={208.256}
        y2={477.798}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dI"
        x1={209.318}
        y1={432.905}
        x2={211.642}
        y2={432.986}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dJ"
        x1={206.07}
        y1={466.784}
        x2={208.394}
        y2={466.86}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dK"
        x1={207.255}
        y1={529.47}
        x2={209.582}
        y2={529.536}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dL"
        x1={207.554}
        y1={530.24}
        x2={209.879}
        y2={530.306}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dM"
        x1={218.504}
        y1={303.73}
        x2={220.999}
        y2={303.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dN"
        x1={177.681}
        y1={285.97}
        x2={179.58}
        y2={286.055}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dO"
        x1={202.83}
        y1={411.845}
        x2={205.118}
        y2={411.929}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dP"
        x1={206.967}
        y1={521.324}
        x2={209.289}
        y2={521.391}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dQ"
        x1={203.076}
        y1={443.417}
        x2={205.364}
        y2={443.494}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dR"
        x1={206.355}
        y1={484.694}
        x2={208.679}
        y2={484.767}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dS"
        x1={206.676}
        y1={505.06}
        x2={209.002}
        y2={505.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dT"
        x1={5787.88}
        y1={526.747}
        x2={5877.69}
        y2={526.747}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dU"
        x1={216.822}
        y1={364.798}
        x2={219.109}
        y2={364.893}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dV"
        x1={222.772}
        y1={493.033}
        x2={225.099}
        y2={493.103}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dW"
        x1={219.679}
        y1={508.054}
        x2={221.968}
        y2={508.119}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dX"
        x1={223.966}
        y1={393.062}
        x2={226.29}
        y2={393.15}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dY"
        x1={219.394}
        y1={513.9}
        x2={221.683}
        y2={513.965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__dZ"
        x1={224.278}
        y1={348.551}
        x2={226.6}
        y2={348.651}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ea"
        x1={223.333}
        y1={450.893}
        x2={225.657}
        y2={450.969}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eb"
        x1={220.268}
        y1={469.347}
        x2={222.558}
        y2={469.418}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ec"
        x1={223.633}
        y1={425.892}
        x2={225.956}
        y2={425.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ed"
        x1={217.658}
        y1={458.655}
        x2={219.947}
        y2={458.729}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ee"
        x1={218.81}
        y1={520.293}
        x2={221.1}
        y2={520.357}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ef"
        x1={219.1}
        y1={522.363}
        x2={221.389}
        y2={522.427}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eg"
        x1={236.081}
        y1={298.254}
        x2={238.611}
        y2={298.398}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eh"
        x1={193.798}
        y1={280.69}
        x2={195.734}
        y2={280.778}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ei"
        x1={217.101}
        y1={404.616}
        x2={219.389}
        y2={404.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ej"
        x1={218.519}
        y1={512.289}
        x2={220.808}
        y2={512.354}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ek"
        x1={217.375}
        y1={435.671}
        x2={219.665}
        y2={435.749}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__el"
        x1={217.904}
        y1={476.271}
        x2={220.195}
        y2={476.342}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__em"
        x1={218.197}
        y1={496.307}
        x2={220.487}
        y2={496.374}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__en"
        x1={5809.09}
        y1={642.821}
        x2={5870.8}
        y2={688.681}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eo"
        x1={3027.83}
        y1={607.103}
        x2={3070.32}
        y2={624.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ep"
        x1={2572.95}
        y1={551.092}
        x2={2609.59}
        y2={565.159}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eq"
        x1={2304.13}
        y1={249.987}
        x2={2315.27}
        y2={259.359}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__er"
        x1={1727.58}
        y1={87.894}
        x2={1728.83}
        y2={91.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__es"
        x1={5771.41}
        y1={513.359}
        x2={5861.17}
        y2={513.359}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__et"
        x1={218.533}
        y1={356.65}
        x2={220.855}
        y2={356.747}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eu"
        x1={218.925}
        y1={478.956}
        x2={221.214}
        y2={479.023}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ev"
        x1={218.635}
        y1={496.879}
        x2={220.925}
        y2={496.945}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ew"
        x1={220.12}
        y1={382.919}
        x2={222.41}
        y2={383.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ex"
        x1={218.309}
        y1={502.607}
        x2={220.6}
        y2={502.671}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ey"
        x1={220.432}
        y1={340.6}
        x2={222.719}
        y2={340.697}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ez"
        x1={219.52}
        y1={440.861}
        x2={221.809}
        y2={440.935}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eA"
        x1={219.218}
        y1={459.593}
        x2={221.508}
        y2={459.664}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eB"
        x1={219.824}
        y1={416.363}
        x2={222.113}
        y2={416.442}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eC"
        x1={219.408}
        y1={448.57}
        x2={221.733}
        y2={448.646}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eD"
        x1={217.762}
        y1={508.901}
        x2={220.052}
        y2={508.965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eE"
        x1={218.056}
        y1={509.625}
        x2={220.346}
        y2={509.688}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eF"
        x1={234.995}
        y1={291.452}
        x2={237.527}
        y2={291.596}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eG"
        x1={198.373}
        y1={274.139}
        x2={200.38}
        y2={274.234}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eH"
        x1={218.808}
        y1={395.645}
        x2={221.133}
        y2={395.731}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eI"
        x1={217.477}
        y1={501.075}
        x2={219.767}
        y2={501.14}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eJ"
        x1={219.121}
        y1={426.061}
        x2={221.444}
        y2={426.141}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eK"
        x1={216.9}
        y1={463.218}
        x2={219.19}
        y2={463.289}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eL"
        x1={217.192}
        y1={485.431}
        x2={219.483}
        y2={485.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eM"
        x1={2566.62}
        y1={538.901}
        x2={2603.28}
        y2={552.969}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eN"
        x1={1725.95}
        y1={86.084}
        x2={1727.23}
        y2={89.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eO"
        x1={5788.3}
        y1={504.782}
        x2={5878.01}
        y2={504.782}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eP"
        x1={219.865}
        y1={348.662}
        x2={222.188}
        y2={348.759}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eQ"
        x1={220.223}
        y1={468.371}
        x2={222.512}
        y2={468.439}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eR"
        x1={219.927}
        y1={486.58}
        x2={222.218}
        y2={486.646}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eS"
        x1={221.41}
        y1={375.527}
        x2={223.698}
        y2={375.612}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eT"
        x1={222.44}
        y1={490.314}
        x2={224.765}
        y2={490.381}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eU"
        x1={221.72}
        y1={332.809}
        x2={224.009}
        y2={332.906}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eV"
        x1={220.813}
        y1={430.403}
        x2={223.104}
        y2={430.478}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eW"
        x1={220.484}
        y1={448.778}
        x2={222.771}
        y2={448.849}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eX"
        x1={221.114}
        y1={406.415}
        x2={223.401}
        y2={406.494}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eY"
        x1={220.667}
        y1={438.703}
        x2={222.991}
        y2={438.778}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__eZ"
        x1={221.856}
        y1={497.74}
        x2={224.181}
        y2={497.806}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fa"
        x1={222.146}
        y1={497.826}
        x2={224.471}
        y2={497.892}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fb"
        x1={233.541}
        y1={284.812}
        x2={236.036}
        y2={284.952}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fc"
        x1={194.024}
        y1={267.102}
        x2={195.96}
        y2={267.19}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fd"
        x1={220.145}
        y1={386.869}
        x2={222.468}
        y2={386.956}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fe"
        x1={221.567}
        y1={488.193}
        x2={223.892}
        y2={488.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ff"
        x1={220.42}
        y1={416.657}
        x2={222.744}
        y2={416.737}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fg"
        x1={220.954}
        y1={455.575}
        x2={223.279}
        y2={455.647}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fh"
        x1={221.273}
        y1={474.795}
        x2={223.598}
        y2={474.865}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fi"
        x1={2574.47}
        y1={526.952}
        x2={2611.13}
        y2={541.043}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fj"
        x1={1731.43}
        y1={83.409}
        x2={1732.69}
        y2={86.531}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fk"
        x1={5784.06}
        y1={488.692}
        x2={5873.81}
        y2={488.692}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fl"
        x1={216.522}
        y1={337.185}
        x2={218.81}
        y2={337.279}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fm"
        x1={222.48}
        y1={453.157}
        x2={224.805}
        y2={453.227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fn"
        x1={222.186}
        y1={470.835}
        x2={224.51}
        y2={470.903}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fo"
        x1={223.634}
        y1={362.451}
        x2={225.957}
        y2={362.539}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fp"
        x1={219.1}
        y1={475.079}
        x2={221.388}
        y2={475.143}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fq"
        x1={223.945}
        y1={321.619}
        x2={226.267}
        y2={321.719}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fr"
        x1={223.067}
        y1={416.306}
        x2={225.394}
        y2={416.383}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fs"
        x1={222.771}
        y1={434.145}
        x2={225.095}
        y2={434.218}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ft"
        x1={223.372}
        y1={393.01}
        x2={225.696}
        y2={393.091}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fu"
        x1={217.367}
        y1={424.509}
        x2={219.656}
        y2={424.582}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fv"
        x1={221.281}
        y1={481.706}
        x2={223.608}
        y2={481.772}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fw"
        x1={218.81}
        y1={482.384}
        x2={221.099}
        y2={482.448}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fx"
        x1={232.981}
        y1={275.244}
        x2={235.476}
        y2={275.384}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fy"
        x1={196.286}
        y1={257.904}
        x2={198.256}
        y2={257.996}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fz"
        x1={216.801}
        y1={374.244}
        x2={219.09}
        y2={374.328}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fA"
        x1={218.192}
        y1={474.303}
        x2={220.48}
        y2={474.368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fB"
        x1={217.081}
        y1={403.137}
        x2={219.37}
        y2={403.215}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fC"
        x1={217.648}
        y1={440.864}
        x2={219.94}
        y2={440.935}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fD"
        x1={217.952}
        y1={459.49}
        x2={220.243}
        y2={459.558}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fE"
        x1={5752.98}
        y1={473.304}
        x2={5842.75}
        y2={473.304}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fF"
        x1={217.151}
        y1={326.642}
        x2={219.473}
        y2={326.739}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fG"
        x1={217.483}
        y1={439.207}
        x2={219.772}
        y2={439.275}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fH"
        x1={217.228}
        y1={455.782}
        x2={219.518}
        y2={455.847}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fI"
        x1={218.711}
        y1={351.6}
        x2={220.999}
        y2={351.686}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fJ"
        x1={216.937}
        y1={460.516}
        x2={219.227}
        y2={460.58}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fK"
        x1={219.014}
        y1={311.939}
        x2={221.301}
        y2={312.036}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fL"
        x1={218.11}
        y1={403.956}
        x2={220.399}
        y2={404.03}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fM"
        x1={217.779}
        y1={421.295}
        x2={220.066}
        y2={421.365}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fN"
        x1={218.409}
        y1={381.315}
        x2={220.697}
        y2={381.393}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fO"
        x1={217.954}
        y1={411.472}
        x2={220.278}
        y2={411.548}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fP"
        x1={216.36}
        y1={467.002}
        x2={218.65}
        y2={467.066}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fQ"
        x1={216.65}
        y1={467.623}
        x2={218.941}
        y2={467.687}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fR"
        x1={233.525}
        y1={266.469}
        x2={236.056}
        y2={266.613}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fS"
        x1={188.705}
        y1={250.049}
        x2={190.606}
        y2={250.135}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fT"
        x1={217.423}
        y1={362.646}
        x2={219.748}
        y2={362.733}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fU"
        x1={218.848}
        y1={459.827}
        x2={221.173}
        y2={459.894}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fV"
        x1={217.673}
        y1={390.713}
        x2={219.996}
        y2={390.793}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fW"
        x1={218.233}
        y1={427.37}
        x2={220.557}
        y2={427.442}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fX"
        x1={218.561}
        y1={445.451}
        x2={220.885}
        y2={445.521}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fY"
        x1={3013.24}
        y1={544.654}
        x2={3055.78}
        y2={562.106}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__fZ"
        x1={2558}
        y1={494.045}
        x2={2594.66}
        y2={508.112}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ga"
        x1={2290.54}
        y1={224.126}
        x2={2301.76}
        y2={233.475}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gb"
        x1={1719.93}
        y1={76.966}
        x2={1721.2}
        y2={80.107}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gc"
        x1={5734.29}
        y1={463.282}
        x2={5824.05}
        y2={463.282}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gd"
        x1={212.688}
        y1={319.059}
        x2={214.976}
        y2={319.153}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ge"
        x1={218.646}
        y1={429.14}
        x2={220.972}
        y2={429.21}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gf"
        x1={218.326}
        y1={445.972}
        x2={220.652}
        y2={446.039}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gg"
        x1={219.847}
        y1={342.784}
        x2={222.171}
        y2={342.872}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gh"
        x1={218.034}
        y1={450.037}
        x2={220.36}
        y2={450.104}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gi"
        x1={217.378}
        y1={303.945}
        x2={219.665}
        y2={304.043}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gj"
        x1={219.241}
        y1={394.056}
        x2={221.566}
        y2={394.132}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gk"
        x1={218.947}
        y1={411.034}
        x2={221.271}
        y2={411.107}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gl"
        x1={219.54}
        y1={371.862}
        x2={221.865}
        y2={371.943}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gm"
        x1={213.557}
        y1={402.094}
        x2={215.846}
        y2={402.168}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gn"
        x1={217.491}
        y1={456.408}
        x2={219.814}
        y2={456.474}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__go"
        x1={217.775}
        y1={457.007}
        x2={220.101}
        y2={457.073}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gp"
        x1={229.046}
        y1={260.138}
        x2={231.543}
        y2={260.278}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gq"
        x1={189.915}
        y1={243.373}
        x2={191.849}
        y2={243.461}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gr"
        x1={212.963}
        y1={354.305}
        x2={215.251}
        y2={354.389}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gs"
        x1={217.196}
        y1={449.379}
        x2={219.522}
        y2={449.446}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gt"
        x1={213.276}
        y1={381.763}
        x2={215.568}
        y2={381.841}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gu"
        x1={213.844}
        y1={417.65}
        x2={216.133}
        y2={417.721}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gv"
        x1={214.132}
        y1={435.323}
        x2={216.42}
        y2={435.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gw"
        x1={5697.35}
        y1={543.513}
        x2={5787.16}
        y2={543.513}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gx"
        x1={209.906}
        y1={376.764}
        x2={212.194}
        y2={376.858}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gy"
        x1={213.052}
        y1={505.616}
        x2={215.343}
        y2={505.683}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gz"
        x1={212.764}
        y1={524.444}
        x2={215.053}
        y2={524.509}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gA"
        x1={216.974}
        y1={406.061}
        x2={219.297}
        y2={406.149}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gB"
        x1={212.473}
        y1={530.442}
        x2={214.763}
        y2={530.507}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gC"
        x1={217.314}
        y1={360.204}
        x2={219.637}
        y2={360.305}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gD"
        x1={216.41}
        y1={465.609}
        x2={218.734}
        y2={465.685}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gE"
        x1={216.106}
        y1={484.602}
        x2={218.43}
        y2={484.675}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gF"
        x1={216.669}
        y1={439.87}
        x2={218.993}
        y2={439.951}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gG"
        x1={210.746}
        y1={473.45}
        x2={213.036}
        y2={473.523}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gH"
        x1={211.897}
        y1={537}
        x2={214.186}
        y2={537.064}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gI"
        x1={212.178}
        y1={537.792}
        x2={214.469}
        y2={537.856}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gJ"
        x1={226.115}
        y1={308.226}
        x2={228.61}
        y2={308.366}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gK"
        x1={187.293}
        y1={290.301}
        x2={189.228}
        y2={290.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gL"
        x1={210.187}
        y1={417.776}
        x2={212.476}
        y2={417.861}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gM"
        x1={211.573}
        y1={528.739}
        x2={213.862}
        y2={528.804}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gN"
        x1={210.453}
        y1={449.77}
        x2={212.743}
        y2={449.848}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gO"
        x1={211.024}
        y1={491.604}
        x2={213.314}
        y2={491.674}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gP"
        x1={211.318}
        y1={512.258}
        x2={213.607}
        y2={512.325}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gQ"
        x1={5700.19}
        y1={535.104}
        x2={5789.96}
        y2={535.104}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gR"
        x1={213.044}
        y1={370.295}
        x2={215.366}
        y2={370.392}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gS"
        x1={213.485}
        y1={500.383}
        x2={215.774}
        y2={500.45}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gT"
        x1={213.155}
        y1={515.588}
        x2={215.444}
        y2={515.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gU"
        x1={214.674}
        y1={399.038}
        x2={216.964}
        y2={399.123}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gV"
        x1={212.865}
        y1={521.498}
        x2={215.154}
        y2={521.562}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gW"
        x1={214.997}
        y1={353.902}
        x2={217.285}
        y2={353.999}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gX"
        x1={214.079}
        y1={456.992}
        x2={216.368}
        y2={457.066}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gY"
        x1={213.778}
        y1={476.36}
        x2={216.068}
        y2={476.431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__gZ"
        x1={214.376}
        y1={431.639}
        x2={216.667}
        y2={431.718}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ha"
        x1={213.929}
        y1={465.455}
        x2={216.254}
        y2={465.531}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hb"
        x1={212.33}
        y1={527.971}
        x2={214.62}
        y2={528.035}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hc"
        x1={212.61}
        y1={528.739}
        x2={214.9}
        y2={528.803}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hd"
        x1={226.561}
        y1={302.836}
        x2={229.055}
        y2={302.976}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__he"
        x1={187.663}
        y1={285.106}
        x2={189.598}
        y2={285.195}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hf"
        x1={213.331}
        y1={410.666}
        x2={215.654}
        y2={410.752}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hg"
        x1={212.031}
        y1={519.849}
        x2={214.321}
        y2={519.914}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hh"
        x1={213.643}
        y1={442.15}
        x2={215.967}
        y2={442.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hi"
        x1={211.449}
        y1={483.32}
        x2={213.739}
        y2={483.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hj"
        x1={211.742}
        y1={503.639}
        x2={214.031}
        y2={503.706}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hk"
        x1={5800.9}
        y1={549.819}
        x2={5890.71}
        y2={549.819}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hl"
        x1={217.817}
        y1={381.293}
        x2={220.106}
        y2={381.388}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hm"
        x1={220.971}
        y1={511.62}
        x2={223.261}
        y2={511.688}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hn"
        x1={220.642}
        y1={530.654}
        x2={222.931}
        y2={530.719}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ho"
        x1={222.16}
        y1={410.986}
        x2={224.45}
        y2={411.072}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hp"
        x1={220.357}
        y1={536.71}
        x2={222.646}
        y2={536.775}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hq"
        x1={222.467}
        y1={364.635}
        x2={224.752}
        y2={364.732}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hr"
        x1={221.556}
        y1={471.182}
        x2={223.843}
        y2={471.256}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hs"
        x1={221.263}
        y1={490.38}
        x2={223.552}
        y2={490.451}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ht"
        x1={221.86}
        y1={445.163}
        x2={224.148}
        y2={445.241}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hu"
        x1={218.659}
        y1={479.054}
        x2={220.95}
        y2={479.127}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hv"
        x1={219.811}
        y1={543.33}
        x2={222.101}
        y2={543.394}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hw"
        x1={220.096}
        y1={544.134}
        x2={222.386}
        y2={544.198}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hx"
        x1={237.122}
        y1={312.002}
        x2={239.652}
        y2={312.146}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hy"
        x1={197.507}
        y1={293.951}
        x2={199.477}
        y2={294.043}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hz"
        x1={218.058}
        y1={422.761}
        x2={220.345}
        y2={422.845}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hA"
        x1={219.52}
        y1={534.971}
        x2={221.809}
        y2={535.036}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hB"
        x1={218.343}
        y1={455.11}
        x2={220.631}
        y2={455.187}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hC"
        x1={218.938}
        y1={497.413}
        x2={221.229}
        y2={497.483}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hD"
        x1={219.226}
        y1={518.3}
        x2={221.516}
        y2={518.368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hE"
        x1={5822.13}
        y1={671.335}
        x2={5883.83}
        y2={717.195}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hF"
        x1={3037.71}
        y1={634.1}
        x2={3080.23}
        y2={651.524}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hG"
        x1={2579.07}
        y1={575.75}
        x2={2615.71}
        y2={589.817}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hH"
        x1={2309.63}
        y1={261.704}
        x2={2320.8}
        y2={271.064}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hI"
        x1={1731.81}
        y1={93.584}
        x2={1733.08}
        y2={96.724}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hJ"
        x1={5784.5}
        y1={541.809}
        x2={5874.21}
        y2={541.809}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hK"
        x1={219.568}
        y1={373.137}
        x2={221.891}
        y2={373.234}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hL"
        x1={219.928}
        y1={500.814}
        x2={222.217}
        y2={500.882}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hM"
        x1={219.634}
        y1={519.477}
        x2={221.923}
        y2={519.543}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hN"
        x1={221.12}
        y1={402.123}
        x2={223.409}
        y2={402.208}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hO"
        x1={219.349}
        y1={524.755}
        x2={221.638}
        y2={524.819}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hP"
        x1={221.424}
        y1={357.334}
        x2={223.714}
        y2={357.431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hQ"
        x1={220.517}
        y1={461.147}
        x2={222.806}
        y2={461.221}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hR"
        x1={220.222}
        y1={480.649}
        x2={222.511}
        y2={480.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hS"
        x1={220.787}
        y1={435.633}
        x2={223.076}
        y2={435.712}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hT"
        x1={220.407}
        y1={468.966}
        x2={222.731}
        y2={469.042}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hU"
        x1={221.559}
        y1={531.94}
        x2={223.884}
        y2={532.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hV"
        x1={221.849}
        y1={532.715}
        x2={224.174}
        y2={532.781}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hW"
        x1={233.272}
        y1={305.228}
        x2={235.767}
        y2={305.368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hX"
        x1={193.748}
        y1={287.396}
        x2={195.683}
        y2={287.485}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hY"
        x1={219.848}
        y1={413.787}
        x2={222.171}
        y2={413.874}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__hZ"
        x1={221.237}
        y1={523.757}
        x2={223.563}
        y2={523.824}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ia"
        x1={220.123}
        y1={445.495}
        x2={222.447}
        y2={445.575}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ib"
        x1={220.696}
        y1={486.969}
        x2={223.021}
        y2={487.041}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ic"
        x1={220.945}
        y1={507.442}
        x2={223.269}
        y2={507.511}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__id"
        x1={5845.23}
        y1={568.551}
        x2={5934.98}
        y2={568.551}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ie"
        x1={221.203}
        y1={394.101}
        x2={223.491}
        y2={394.196}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__if"
        x1={227.175}
        y1={528.572}
        x2={229.5}
        y2={528.641}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ig"
        x1={226.894}
        y1={548.202}
        x2={229.22}
        y2={548.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ih"
        x1={228.37}
        y1={424.908}
        x2={230.693}
        y2={424.996}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ii"
        x1={226.596}
        y1={558.662}
        x2={228.923}
        y2={558.728}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ij"
        x1={228.676}
        y1={377.112}
        x2={230.998}
        y2={377.212}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ik"
        x1={227.732}
        y1={486.235}
        x2={230.058}
        y2={486.311}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__il"
        x1={227.474}
        y1={506.724}
        x2={229.798}
        y2={506.797}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__im"
        x1={228.036}
        y1={459.412}
        x2={230.361}
        y2={459.493}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__in"
        x1={222.042}
        y1={494.897}
        x2={224.331}
        y2={494.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__io"
        x1={223.187}
        y1={558.391}
        x2={225.477}
        y2={558.455}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ip"
        x1={226.302}
        y1={562.073}
        x2={228.627}
        y2={562.138}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iq"
        x1={237.863}
        y1={322.675}
        x2={240.358}
        y2={322.815}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ir"
        x1={197.914}
        y1={303.533}
        x2={199.849}
        y2={303.622}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__is"
        x1={221.478}
        y1={436.851}
        x2={223.766}
        y2={436.936}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__it"
        x1={222.895}
        y1={552.564}
        x2={225.183}
        y2={552.629}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iu"
        x1={221.758}
        y1={470.204}
        x2={224.047}
        y2={470.281}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iv"
        x1={222.289}
        y1={513.83}
        x2={224.578}
        y2={513.901}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iw"
        x1={222.571}
        y1={535.379}
        x2={224.862}
        y2={535.447}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ix"
        x1={5831.5}
        y1={556.356}
        x2={5921.3}
        y2={556.356}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iy"
        x1={220.123}
        y1={385.946}
        x2={222.41}
        y2={386.04}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iz"
        x1={223.306}
        y1={517.766}
        x2={225.595}
        y2={517.834}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iA"
        x1={223.014}
        y1={537.026}
        x2={225.304}
        y2={537.092}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iB"
        x1={224.513}
        y1={416.043}
        x2={226.803}
        y2={416.128}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iC"
        x1={222.689}
        y1={543.154}
        x2={224.979}
        y2={543.218}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iD"
        x1={224.806}
        y1={369.159}
        x2={227.094}
        y2={369.256}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iE"
        x1={223.893}
        y1={476.213}
        x2={226.182}
        y2={476.287}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iF"
        x1={223.602}
        y1={496.313}
        x2={225.891}
        y2={496.384}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iG"
        x1={224.197}
        y1={449.895}
        x2={226.485}
        y2={449.974}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iH"
        x1={220.996}
        y1={484.809}
        x2={223.285}
        y2={484.883}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iI"
        x1={222.148}
        y1={549.829}
        x2={224.438}
        y2={549.893}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iJ"
        x1={222.433}
        y1={552.041}
        x2={224.723}
        y2={552.104}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iK"
        x1={239.56}
        y1={315.877}
        x2={242.09}
        y2={316.022}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iL"
        x1={196.897}
        y1={297.679}
        x2={198.831}
        y2={297.767}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iM"
        x1={220.399}
        y1={427.879}
        x2={222.686}
        y2={427.963}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iN"
        x1={221.862}
        y1={541.367}
        x2={224.151}
        y2={541.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iO"
        x1={220.715}
        y1={460.591}
        x2={223.004}
        y2={460.668}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iP"
        x1={221.281}
        y1={503.376}
        x2={223.571}
        y2={503.446}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iQ"
        x1={221.569}
        y1={524.505}
        x2={223.859}
        y2={524.572}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iR"
        x1={5804.15}
        y1={527.91}
        x2={5893.91}
        y2={527.91}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iS"
        x1={218.032}
        y1={365.16}
        x2={220.319}
        y2={365.254}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iT"
        x1={224.023}
        y1={493.507}
        x2={226.349}
        y2={493.577}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iU"
        x1={223.729}
        y1={507.239}
        x2={226.055}
        y2={507.307}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iV"
        x1={222.41}
        y1={393.455}
        x2={224.697}
        y2={393.54}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iW"
        x1={223.408}
        y1={514.393}
        x2={225.731}
        y2={514.459}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iX"
        x1={222.716}
        y1={348.909}
        x2={225.002}
        y2={349.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iY"
        x1={224.611}
        y1={450.682}
        x2={226.937}
        y2={450.758}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__iZ"
        x1={224.315}
        y1={469.81}
        x2={226.639}
        y2={469.883}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ja"
        x1={224.915}
        y1={425.646}
        x2={227.24}
        y2={425.727}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jb"
        x1={218.901}
        y1={459.103}
        x2={221.19}
        y2={459.176}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jc"
        x1={222.86}
        y1={520.798}
        x2={225.186}
        y2={520.864}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jd"
        x1={223.112}
        y1={521.548}
        x2={225.438}
        y2={521.614}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__je"
        x1={234.576}
        y1={298.557}
        x2={237.072}
        y2={298.697}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jf"
        x1={194.955}
        y1={280.991}
        x2={196.89}
        y2={281.08}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jg"
        x1={218.342}
        y1={405.014}
        x2={220.631}
        y2={405.098}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jh"
        x1={222.566}
        y1={512.769}
        x2={224.892}
        y2={512.836}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ji"
        x1={218.622}
        y1={436.112}
        x2={220.911}
        y2={436.19}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jj"
        x1={219.189}
        y1={476.734}
        x2={221.478}
        y2={476.804}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jk"
        x1={222.276}
        y1={496.789}
        x2={224.602}
        y2={496.859}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jl"
        x1={5835.49}
        y1={514.964}
        x2={5925.3}
        y2={514.964}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jm"
        x1={220.424}
        y1={356.401}
        x2={222.713}
        y2={356.495}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jn"
        x1={223.611}
        y1={478.639}
        x2={225.899}
        y2={478.707}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jo"
        x1={223.325}
        y1={496.551}
        x2={225.614}
        y2={496.616}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jp"
        x1={227.634}
        y1={383.934}
        x2={229.958}
        y2={384.022}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jq"
        x1={223}
        y1={501.634}
        x2={225.289}
        y2={501.698}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jr"
        x1={227.936}
        y1={341.001}
        x2={230.258}
        y2={341.101}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__js"
        x1={227.024}
        y1={440.563}
        x2={229.348}
        y2={440.639}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jt"
        x1={223.91}
        y1={459.284}
        x2={226.199}
        y2={459.354}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ju"
        x1={224.508}
        y1={416.081}
        x2={226.797}
        y2={416.159}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jv"
        x1={221.293}
        y1={448.918}
        x2={223.584}
        y2={448.991}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jw"
        x1={222.454}
        y1={508.563}
        x2={224.744}
        y2={508.627}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jx"
        x1={222.746}
        y1={509.279}
        x2={225.035}
        y2={509.343}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jy"
        x1={239.879}
        y1={291.266}
        x2={242.409}
        y2={291.411}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jz"
        x1={200.009}
        y1={273.951}
        x2={201.978}
        y2={274.043}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jA"
        x1={220.703}
        y1={395.378}
        x2={222.991}
        y2={395.462}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jB"
        x1={222.16}
        y1={500.745}
        x2={224.45}
        y2={500.81}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jC"
        x1={221.016}
        y1={425.776}
        x2={223.307}
        y2={425.854}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jD"
        x1={221.581}
        y1={465.506}
        x2={223.872}
        y2={465.577}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jE"
        x1={221.873}
        y1={485.126}
        x2={224.163}
        y2={485.193}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jF"
        x1={5843.75}
        y1={498.662}
        x2={5933.56}
        y2={498.662}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jG"
        x1={221.097}
        y1={344.298}
        x2={223.385}
        y2={344.392}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jH"
        x1={224.237}
        y1={465.703}
        x2={226.528}
        y2={465.77}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jI"
        x1={223.952}
        y1={479.971}
        x2={226.241}
        y2={480.036}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jJ"
        x1={228.263}
        y1={370.788}
        x2={230.587}
        y2={370.876}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jK"
        x1={223.662}
        y1={485.537}
        x2={225.952}
        y2={485.602}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jL"
        x1={228.571}
        y1={328.553}
        x2={230.894}
        y2={328.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jM"
        x1={227.651}
        y1={425.057}
        x2={229.975}
        y2={425.133}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jN"
        x1={224.502}
        y1={443.219}
        x2={226.79}
        y2={443.29}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jO"
        x1={227.96}
        y1={401.307}
        x2={230.284}
        y2={401.388}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jP"
        x1={221.934}
        y1={433.306}
        x2={224.224}
        y2={433.38}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jQ"
        x1={223.086}
        y1={491.038}
        x2={225.375}
        y2={491.102}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jR"
        x1={223.371}
        y1={492.341}
        x2={225.66}
        y2={492.405}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jS"
        x1={240.54}
        y1={281.171}
        x2={243.071}
        y2={281.316}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jT"
        x1={200.634}
        y1={263.608}
        x2={202.604}
        y2={263.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jU"
        x1={221.372}
        y1={382.066}
        x2={223.66}
        y2={382.151}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jV"
        x1={222.796}
        y1={484.085}
        x2={225.086}
        y2={484.15}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jW"
        x1={221.652}
        y1={411.52}
        x2={223.943}
        y2={411.597}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jX"
        x1={222.183}
        y1={450.001}
        x2={224.471}
        y2={450.072}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jY"
        x1={222.505}
        y1={468.974}
        x2={224.794}
        y2={469.041}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__jZ"
        x1={5815.22}
        y1={481.259}
        x2={5905.03}
        y2={481.259}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ka"
        x1={218.904}
        y1={331.883}
        x2={221.192}
        y2={331.977}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kb"
        x1={222.057}
        y1={449.147}
        x2={224.346}
        y2={449.214}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kc"
        x1={221.772}
        y1={459.958}
        x2={224.061}
        y2={460.023}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kd"
        x1={223.257}
        y1={356.696}
        x2={225.547}
        y2={356.782}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ke"
        x1={221.482}
        y1={467.756}
        x2={223.772}
        y2={467.821}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kf"
        x1={223.558}
        y1={316.459}
        x2={225.845}
        y2={316.557}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kg"
        x1={222.622}
        y1={409.808}
        x2={224.91}
        y2={409.882}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kh"
        x1={222.357}
        y1={427.383}
        x2={224.648}
        y2={427.454}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ki"
        x1={222.922}
        y1={386.839}
        x2={225.208}
        y2={386.918}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kj"
        x1={219.741}
        y1={417.954}
        x2={222.031}
        y2={418.028}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kk"
        x1={220.889}
        y1={471.905}
        x2={223.177}
        y2={471.969}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kl"
        x1={221.188}
        y1={474.962}
        x2={223.478}
        y2={475.026}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__km"
        x1={238.26}
        y1={270.825}
        x2={240.79}
        y2={270.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kn"
        x1={195.756}
        y1={253.654}
        x2={197.691}
        y2={253.742}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ko"
        x1={219.184}
        y1={368.412}
        x2={221.472}
        y2={368.496}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kp"
        x1={220.609}
        y1={467.003}
        x2={222.899}
        y2={467.068}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kq"
        x1={219.464}
        y1={396.889}
        x2={221.752}
        y2={396.967}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kr"
        x1={220.028}
        y1={434.087}
        x2={222.316}
        y2={434.158}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ks"
        x1={220.283}
        y1={452.419}
        x2={222.572}
        y2={452.486}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kt"
        x1={5795.82}
        y1={466.083}
        x2={5885.58}
        y2={466.083}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ku"
        x1={220.447}
        y1={321.475}
        x2={222.77}
        y2={321.572}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kv"
        x1={220.792}
        y1={435.292}
        x2={223.082}
        y2={435.36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kw"
        x1={220.503}
        y1={448.704}
        x2={222.792}
        y2={448.77}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kx"
        x1={221.955}
        y1={345.984}
        x2={224.243}
        y2={346.069}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ky"
        x1={220.207}
        y1={452.792}
        x2={222.496}
        y2={452.857}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kz"
        x1={222.299}
        y1={306.89}
        x2={224.585}
        y2={306.987}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kA"
        x1={221.384}
        y1={397.607}
        x2={223.673}
        y2={397.681}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kB"
        x1={221.088}
        y1={414.697}
        x2={223.377}
        y2={414.767}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kC"
        x1={221.652}
        y1={375.276}
        x2={223.941}
        y2={375.354}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kD"
        x1={221.284}
        y1={405.08}
        x2={223.608}
        y2={405.156}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kE"
        x1={219.635}
        y1={459.784}
        x2={221.924}
        y2={459.848}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kF"
        x1={219.92}
        y1={460.392}
        x2={222.21}
        y2={460.456}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kG"
        x1={234.154}
        y1={262.166}
        x2={236.649}
        y2={262.307}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kH"
        x1={191.752}
        y1={245.887}
        x2={193.653}
        y2={245.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kI"
        x1={217.917}
        y1={356.963}
        x2={220.206}
        y2={357.047}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kJ"
        x1={219.306}
        y1={451.549}
        x2={221.595}
        y2={451.614}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kK"
        x1={220.998}
        y1={384.614}
        x2={223.323}
        y2={384.694}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kL"
        x1={218.764}
        y1={420.747}
        x2={221.053}
        y2={420.817}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kM"
        x1={219.056}
        y1={438.563}
        x2={221.345}
        y2={438.63}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kN"
        x1={5774.47}
        y1={453.946}
        x2={5864.28}
        y2={453.946}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kO"
        x1={218.588}
        y1={312.394}
        x2={220.911}
        y2={312.491}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kP"
        x1={218.953}
        y1={420.312}
        x2={221.242}
        y2={420.38}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kQ"
        x1={218.655}
        y1={436.833}
        x2={220.944}
        y2={436.898}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kR"
        x1={220.144}
        y1={335.552}
        x2={222.434}
        y2={335.637}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kS"
        x1={218.361}
        y1={440.832}
        x2={220.651}
        y2={440.897}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kT"
        x1={220.45}
        y1={297.446}
        x2={222.739}
        y2={297.544}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kU"
        x1={219.528}
        y1={385.855}
        x2={221.817}
        y2={385.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kV"
        x1={219.208}
        y1={402.537}
        x2={221.498}
        y2={402.608}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kW"
        x1={219.843}
        y1={364.089}
        x2={222.132}
        y2={364.167}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kX"
        x1={219.389}
        y1={393.853}
        x2={221.714}
        y2={393.929}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kY"
        x1={217.786}
        y1={444.82}
        x2={220.075}
        y2={444.884}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__kZ"
        x1={218.076}
        y1={447.681}
        x2={220.366}
        y2={447.745}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__la"
        x1={235.018}
        y1={254.587}
        x2={237.548}
        y2={254.732}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lb"
        x1={192.835}
        y1={238.028}
        x2={194.771}
        y2={238.116}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lc"
        x1={218.862}
        y1={346.975}
        x2={221.187}
        y2={347.062}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ld"
        x1={217.493}
        y1={437.933}
        x2={219.782}
        y2={437.998}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__le"
        x1={216.355}
        y1={373.906}
        x2={218.645}
        y2={373.984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lf"
        x1={216.883}
        y1={409.11}
        x2={219.17}
        y2={409.18}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lg"
        x1={217.207}
        y1={426.444}
        x2={219.497}
        y2={426.511}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lh"
        x1={5726.03}
        y1={563.706}
        x2={5815.79}
        y2={563.706}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__li"
        x1={215.066}
        y1={390.66}
        x2={217.39}
        y2={390.757}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lj"
        x1={215.452}
        y1={524.031}
        x2={217.741}
        y2={524.099}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lk"
        x1={215.166}
        y1={544.2}
        x2={217.455}
        y2={544.265}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ll"
        x1={216.654}
        y1={422.552}
        x2={218.945}
        y2={422.637}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lm"
        x1={214.869}
        y1={548.969}
        x2={217.159}
        y2={549.033}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ln"
        x1={216.928}
        y1={375.844}
        x2={219.215}
        y2={375.941}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lo"
        x1={216.05}
        y1={484.094}
        x2={218.339}
        y2={484.168}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lp"
        x1={215.75}
        y1={504.415}
        x2={218.041}
        y2={504.485}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lq"
        x1={216.347}
        y1={455.404}
        x2={218.637}
        y2={455.482}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lr"
        x1={215.911}
        y1={492.769}
        x2={218.235}
        y2={492.844}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ls"
        x1={214.261}
        y1={555.71}
        x2={216.551}
        y2={555.774}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lt"
        x1={214.551}
        y1={556.547}
        x2={216.84}
        y2={556.611}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lu"
        x1={231.376}
        y1={319.816}
        x2={233.907}
        y2={319.96}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lv"
        x1={189.52}
        y1={302.855}
        x2={191.456}
        y2={302.943}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lw"
        x1={215.349}
        y1={434.487}
        x2={217.674}
        y2={434.573}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lx"
        x1={214.007}
        y1={547.86}
        x2={216.296}
        y2={547.925}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__ly"
        x1={215.633}
        y1={467.553}
        x2={217.957}
        y2={467.633}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lz"
        x1={216.196}
        y1={510.823}
        x2={218.52}
        y2={510.895}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lA"
        x1={216.488}
        y1={530.805}
        x2={218.813}
        y2={530.874}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lB"
        x1={5779.51}
        y1={581.518}
        x2={5869.27}
        y2={581.518}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lC"
        x1={219.191}
        y1={403.938}
        x2={221.513}
        y2={404.035}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lD"
        x1={219.515}
        y1={541.623}
        x2={221.804}
        y2={541.691}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lE"
        x1={219.225}
        y1={561.675}
        x2={221.515}
        y2={561.74}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lF"
        x1={220.739}
        y1={435.592}
        x2={223.027}
        y2={435.677}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lG"
        x1={218.964}
        y1={567.314}
        x2={221.254}
        y2={567.379}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lH"
        x1={221.046}
        y1={387.43}
        x2={223.333}
        y2={387.527}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lI"
        x1={220.144}
        y1={499.034}
        x2={222.433}
        y2={499.108}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lJ"
        x1={219.844}
        y1={519.973}
        x2={222.133}
        y2={520.044}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lK"
        x1={220.437}
        y1={471.62}
        x2={222.727}
        y2={471.698}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lL"
        x1={220.031}
        y1={507.776}
        x2={222.355}
        y2={507.852}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lM"
        x1={221.177}
        y1={572.785}
        x2={223.502}
        y2={572.851}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lN"
        x1={218.685}
        y1={575.113}
        x2={220.974}
        y2={575.177}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lO"
        x1={235.641}
        y1={330.883}
        x2={238.17}
        y2={331.027}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lP"
        x1={190.611}
        y1={312.142}
        x2={192.509}
        y2={312.227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lQ"
        x1={219.433}
        y1={447.684}
        x2={221.755}
        y2={447.77}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lR"
        x1={220.888}
        y1={566.107}
        x2={223.213}
        y2={566.174}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lS"
        x1={219.713}
        y1={481.792}
        x2={222.036}
        y2={481.872}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lT"
        x1={220.31}
        y1={526.434}
        x2={222.634}
        y2={526.507}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
      <linearGradient
        id="PendingWithdrawals_svg__lU"
        x1={220.602}
        y1={548.51}
        x2={222.927}
        y2={548.579}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8B5B" />
        <stop offset={1} stopColor="#8B0F61" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgPendingWithdrawals;
