import { SVGProps } from 'react';

const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_529_7380)'>
      <path
        d='M3.49984 11.0833C3.49984 11.725 4.02484 12.25 4.6665 12.25H9.33317C9.97484 12.25 10.4998 11.725 10.4998 11.0833V4.08333H3.49984V11.0833ZM4.6665 5.25H9.33317V11.0833H4.6665V5.25ZM9.0415 2.33333L8.45817 1.75H5.5415L4.95817 2.33333H2.9165V3.5H11.0832V2.33333H9.0415Z'
        fill='#5C5C5C'
      />
    </g>
    <defs>
      <clipPath id='clip0_529_7380'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDelete;
