export function getCookie(cookieName: string): string | null {
  const name = `${cookieName}=`;
  if (typeof window !== 'undefined') {
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function setCookie(
  name: string,
  value: string,
  expireIn: number,
  domain?: string
): void {
  if (domain) {
    document.cookie = `${name}=${value};domain=${domain};path=/;max-age=${expireIn};`;
    return;
  }
  document.cookie = `${name}=${value};path=/;max-age=${expireIn};`;
}

export function deleteCookie(
  name: string,
  domain?: string
): void {
  if (domain) {
    document.cookie = `${name}=;domain=${domain};path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    return;
  }
  document.cookie = `${name}=;path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
