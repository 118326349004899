import cx from 'classnames';
import { StyledSpinnerBox, StyledLoadingWrap, StyledSpinnerContainer } from './style';

export interface SpinnerProps {
  bgColor?: string;
  size?:string;
  className?:string;
  type?: 'MoonLoader' | 'FadeLoader'
}

const LoadingSpinner = (props:SpinnerProps):JSX.Element => {
  const {
    bgColor, className, size = 'default', type = 'FadeLoader'
  } = props;

  return (
    <>
      {
        type === 'FadeLoader' && (
          <StyledSpinnerBox className={cx('loading', className)}>
            <StyledLoadingWrap bgColor={bgColor} size={size}>
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </StyledLoadingWrap>
          </StyledSpinnerBox>
        )
      }
      {
        type === 'MoonLoader' && <StyledSpinnerContainer />
      }
    </>
  );
};

export default LoadingSpinner;
