/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import cx from 'classnames';
import { Line } from 'react-chartjs-2';
import { pluginGenerator } from 'utils/chart';
import { StyledLineChart } from './style';

interface LineChartProps {
  chartData: any;
  className?: string;
  options?: any;
  ariaLabel?:string;
}

const defaultPlugin = pluginGenerator('line-chart-plugin');

const LineChart:React.FC<LineChartProps> = (props) => {
  const {
    chartData, className, options, ariaLabel
  } = props;

  return (
    <StyledLineChart
      className={cx('line-chart', className)}
      aria-label={`component bar
      chart ${ariaLabel}`}
    >
      <Line data={chartData} options={options} plugins={defaultPlugin} />
    </StyledLineChart>
  );
};

export default LineChart;
