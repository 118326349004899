import palette from './palette';
import type { Palette } from './palette';
import typography from './typography';
import type { Typography } from './typography';
import spacing from './spacing';
import type { Spacing } from './spacing';

type BasicTheme = {
  common?: {
    background: string;
    lightGrey: string;
  };
  sideMenu?: {
    background: string;
    primary: string;
    secondary: string;
    active: string;
    avatarActive: string;
  };
  notificationPanel?: {
    fontColor: string;
  };
  palette: Palette;
  typography: Typography;
  spacing: Spacing;
};

export const lightTheme: BasicTheme = {
  common: {
    background: `linear-gradient(180deg, ${palette.N600} 0, ${palette.N500} 100 %)`,
    lightGrey: palette.N300
  },
  sideMenu: {
    background: palette.N200,
    primary: palette.N600,
    secondary: palette.N500,
    active: palette.N75,
    avatarActive: palette.N100
  },
  notificationPanel: {
    fontColor: palette.N300
  },
  palette,
  typography,
  spacing
};

export const darkTheme: BasicTheme = {
  common: {
    background: `linear-gradient(180deg, ${palette.N600} 0%, ${palette.N500} 100%)`,
    lightGrey: palette.N300
  },
  sideMenu: {
    background: palette.N200,
    primary: palette.N600,
    secondary: palette.N500,
    active: palette.N75,
    avatarActive: palette.N100
  },
  palette,
  typography,
  spacing
};
