import React from 'react';

const EnchorWrap = React.forwardRef<HTMLDivElement, { children: JSX.Element }>(
  ({ children }, ref) => (
    <div ref={ref} style={{ display: 'flex' }}>
      {children}
    </div>
  )
);

export default EnchorWrap;
