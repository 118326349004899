import { useState, useEffect } from 'react';
import { SnapChartData } from 'type/snapshot';
import dayjs from 'dayjs';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';
import useHttp from 'hooks/useHttp';
import { emptyStrGenerator } from 'utils/common';
import SnapShot from 'components/SnapShot';
import LineChart from 'components/LineChart';
import Spinner from 'components/Spinner';
import { getTradersClientApplicationRequestConfig } from 'api/getV2RequestConfig';
import {
  DataPair, ClientData
} from './type';
import { commonOptions } from './index';
import { StyledLoadingContainer } from './style';

// client Application
const TransactionApprove: React.FC = () => {
  const { t } = useTranslation('dashboard', { i18n: logixPanelInstance });
  const { sendRequest } = useHttp();

  const [clientAppData, setClientAppData] = useState<SnapChartData>();

  useEffect(() => {
    const transformClientAppData = (transacRes:ClientData): void => {
      const transformData = transacRes.data;
      setClientAppData({
        labels: transformData.accountsApproved
          .map((ele:DataPair) => dayjs(ele.date).format('DD/MM')),
        datasets: [
          {
            label: `${emptyStrGenerator(1)}${t('accountsApproved')}${emptyStrGenerator(6)}`,
            fill: false,
            backgroundColor: '#00BA34',
            borderColor: '#00BA34',
            pointRadius: 3,
            borderRadius: 1,
            borderWidth: 2,
            data: transformData.accountsApproved.map((ele:DataPair) => ele.value)
          },
          {
            label: `${emptyStrGenerator(1)}${t('accountsRejected')}`,
            fill: false,
            backgroundColor: '#E92C2C',
            color: '#E92C2C',
            borderColor: '#E92C2C',
            pointRadius: 3,
            borderRadius: 1,
            borderWidth: 2,
            data: transformData.accountsRejected.map((ele:DataPair) => ele.value)
          }]
      });
    };
    sendRequest(
      getTradersClientApplicationRequestConfig(),
      transformClientAppData
    );
  }, [t]);

  return (

    <SnapShot title={t('clientApplication')}>

      { clientAppData ?
        <LineChart chartData={clientAppData} options={commonOptions} /> :
        (
          <StyledLoadingContainer>
            <Spinner />
          </StyledLoadingContainer>
        )}

    </SnapShot>
  );
};

export default TransactionApprove;
