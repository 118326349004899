import * as React from "react";
import { SVGProps } from "react";

const SvgPendingBankAccounts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m0 40.616 25.6-14.77L32 29.54 6.4 44.307 0 40.616Z"
      fill="#0084FF"
    />
    <path
      d="m0 40.616 25.6-14.77L32 29.54 6.4 44.307 0 40.616Z"
      fill="#000"
      fillOpacity={0.2}
    />
    <path d="M6.4 48v-3.693L32 29.54v3.691L6.4 48Z" fill="#0084FF" />
    <path
      d="M6.4 48v-3.693L32 29.54v3.691L6.4 48Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path d="M0 44.307v-3.691l6.4 3.691V48L0 44.307Z" fill="#0D5699" />
    <path d="M4.8 39.693V21.23l3.201 1.846v18.462l-3.2-1.846Z" fill="#BBB" />
    <path
      d="M11.196 39.693V21.23l-3.2 1.846v18.462l3.2-1.846Z"
      fill="#F4F4F4"
    />
    <path d="M12.799 35.077V16.616l3.2 1.845v18.462l-3.2-1.846Z" fill="#BBB" />
    <path d="M19.2 35.077V16.616L16 18.46v18.462l3.2-1.846Z" fill="#F4F4F4" />
    <path d="M20.803 30.461V12l3.2 1.846v18.461l-3.2-1.846Z" fill="#BBB" />
    <path
      d="M27.2 30.461V12L24 13.846v18.461l3.2-1.846ZM8 42.461v-2.768l4.803-2.77v2.77L8 42.46Z"
      fill="#F4F4F4"
    />
    <path d="m8 39.693-4.803-2.77v2.77l4.804 2.768v-2.768Z" fill="#BBB" />
    <path
      d="M12.8 36.923 11.197 36v.923l-3.2 1.846-3.2-1.846V36l-1.6.923 4.804 2.77 4.799-2.77Z"
      fill="#DDD"
    />
    <path d="M8.8 27.23v-2.769l4.804-2.768v2.768l-4.803 2.77Z" fill="#0084FF" />
    <path
      d="M8.8 27.23v-2.769l4.804-2.768v2.768l-4.803 2.77Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path d="M8.8 24.461 2.4 20.77v2.769l6.4 3.692v-2.77Z" fill="#0D5699" />
    <path
      d="m13.598 21.693-2.402-1.386v.924l-3.2 1.846-3.2-1.846v-1.847L2.395 20.77l6.4 3.692 4.803-2.768Z"
      fill="#C8D1D5"
    />
    <path d="M16.8 22.616v-2.77l4.8-2.77v2.77l-4.8 2.77Z" fill="#0084FF" />
    <path
      d="M16.8 22.616v-2.77l4.8-2.77v2.77l-4.8 2.77Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path d="m16.8 19.846-6.4-3.692v2.77l6.4 3.692v-2.77Z" fill="#0D5699" />
    <path
      d="m21.6 17.077-2.402-1.384v.923L16 18.46l-3.2-1.845V14.77l-2.402 1.384 6.4 3.692 4.802-2.769Z"
      fill="#C8D1D5"
    />
    <path d="M24.8 18v-2.77l4.804-2.769v2.77L24.801 18Z" fill="#0084FF" />
    <path
      d="M24.8 18v-2.77l4.804-2.769v2.77L24.801 18Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path d="m24.8 15.23-6.398-3.691v2.768L24.8 18v-2.77Z" fill="#0D5699" />
    <path
      d="M29.6 12.461 27.2 11.077V12l-3.2 1.846L20.803 12v-1.846l-2.402 1.385 6.4 3.692 4.799-2.77Z"
      fill="#C8D1D5"
    />
    <path d="M6.4 23.077 0 19.384v3.693l6.4 3.692v-3.692Z" fill="#0D5699" />
    <path
      d="M19.2 3.693 12.8 0 0 19.384l6.4 3.693L19.2 3.693Z"
      fill="#0084FF"
    />
    <path
      d="M19.2 3.693 12.8 0 0 19.384l6.4 3.693L19.2 3.693Z"
      fill="#000"
      fillOpacity={0.2}
    />
    <path d="m32 8.307-6.4-3.691L12.798 0l6.4 3.693L32 8.307Z" fill="#0D5699" />
    <path
      d="M6.4 26.77v-3.693L19.2 3.693 32 8.307V12L6.4 26.77Z"
      fill="#0084FF"
    />
    <path
      d="M6.4 26.77v-3.693L19.2 3.693 32 8.307V12L6.4 26.77Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path d="M16 37.846v-2.77l4.803-2.769v2.77L16 37.847Z" fill="#E7EFF9" />
    <path d="m16 35.077-4.803-2.77v2.77l4.804 2.77v-2.77Z" fill="#BBB" />
    <path
      d="m20.804 32.307-1.6-.923v.923l-3.203 1.847-3.2-1.847v-.923l-1.604.923 4.804 2.77 4.803-2.77Z"
      fill="#DDD"
    />
    <path d="M24 33.23v-2.769l4.803-2.768v2.768L24 33.231Z" fill="#F4F4F4" />
    <path d="m23.999 30.461-4.804-2.768v2.768L24 33.231v-2.77Z" fill="#BBB" />
    <path
      d="m28.799 27.693-1.6-.924v.924L24 29.539l-3.196-1.846v-.924l-1.6.924 4.804 2.768 4.792-2.768Z"
      fill="#DDD"
    />
    <path
      d="M21.463 6.573c-.694-.037-1.472.182-2.263.639-2.607 1.504-4.718 5.163-4.718 8.171 0 3.009 2.114 4.228 4.718 2.724 2.604-1.504 4.718-5.162 4.718-8.172.003-1.992-.94-3.281-2.455-3.362Zm-.584 1.57c1.123.06 1.823 1.016 1.823 2.494 0 2.229-1.568 4.946-3.502 6.062-1.934 1.116-3.503.212-3.503-2.02s1.568-4.947 3.503-6.062c.587-.337 1.164-.5 1.679-.474Z"
      fill="#0084FF"
    />
    <path
      d="M21.463 6.573c-.694-.037-1.472.182-2.263.639-2.607 1.504-4.718 5.163-4.718 8.171 0 3.009 2.114 4.228 4.718 2.724 2.604-1.504 4.718-5.162 4.718-8.172.003-1.992-.94-3.281-2.455-3.362Zm-.584 1.57c1.123.06 1.823 1.016 1.823 2.494 0 2.229-1.568 4.946-3.502 6.062-1.934 1.116-3.503.212-3.503-2.02s1.568-4.947 3.503-6.062c.587-.337 1.164-.5 1.679-.474Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      d="m18.953 16.012.718-.412v-.783c.674-.574 1-1.325 1-2.042 0-1.346-1.653-.422-1.653-.983 0-.296.134-.463.41-.622a.665.665 0 0 1 .666-.03l.546-1.039c-.258-.162-.565-.221-.969-.062v-.754l-.718.414v.771c-.62.514-.986 1.243-.986 1.99 0 1.294 1.654.421 1.654 1.013 0 .288-.129.488-.45.673a1.068 1.068 0 0 1-.884.096l-.474 1.132c.32.148.771.062 1.14-.096v.734Z"
      fill="#0084FF"
    />
    <path
      d="m18.953 16.012.718-.412v-.783c.674-.574 1-1.325 1-2.042 0-1.346-1.653-.422-1.653-.983 0-.296.134-.463.41-.622a.665.665 0 0 1 .666-.03l.546-1.039c-.258-.162-.565-.221-.969-.062v-.754l-.718.414v.771c-.62.514-.986 1.243-.986 1.99 0 1.294 1.654.421 1.654 1.013 0 .288-.129.488-.45.673a1.068 1.068 0 0 1-.884.096l-.474 1.132c.32.148.771.062 1.14-.096v.734Z"
      fill="#000"
      fillOpacity={0.4}
    />
    <path
      d="M21.919 6.831c-.695-.036-1.473.183-2.264.64-2.607 1.504-4.718 5.162-4.718 8.17 0 3.01 2.114 4.23 4.718 2.725 2.604-1.504 4.718-5.163 4.718-8.171.004-1.993-.938-3.282-2.454-3.364ZM21.335 8.4c1.124.06 1.823 1.017 1.823 2.494 0 2.229-1.568 4.946-3.502 6.062-1.934 1.116-3.502.212-3.502-2.02s1.568-4.946 3.502-6.062c.587-.335 1.164-.498 1.679-.474Z"
      fill="#F4F4F4"
    />
    <path
      d="m19.41 16.271.719-.414v-.784c.672-.574.999-1.325.999-2.042 0-1.346-1.654-.42-1.654-.983 0-.296.135-.462.411-.621a.66.66 0 0 1 .666-.03l.545-1.04c-.257-.162-.564-.221-.967-.062v-.754l-.718.414v.771c-.622.514-.988 1.243-.988 1.99 0 1.294 1.654.421 1.654 1.014 0 .288-.129.487-.449.672a1.07 1.07 0 0 1-.882.094l-.474 1.131c.32.149.772.063 1.14-.096l-.001.74Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgPendingBankAccounts;
