import styled from 'styled-components';

const OLD_PAGES_IFRAME_SIDEBAR_WIDTH = 282;
const OLD_PAGES_IFRAME_HEADER_WIDTH = 70;

export const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow : hidden;
`;

export const OldPagesIframe = styled.iframe<{ maximize: boolean }>`
  width: calc(100% + ${OLD_PAGES_IFRAME_SIDEBAR_WIDTH}px);
  height: 100vh;
  border: none;
  margin-left: ${(props) => (props.maximize ? 0 : `-${OLD_PAGES_IFRAME_SIDEBAR_WIDTH}px`)};
  margin-top: ${(props) => (props.maximize ? 0 : `-${OLD_PAGES_IFRAME_HEADER_WIDTH}px`)};
`;
