import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import IconComponent from 'components/Icons';
import { OptionType } from 'type/common';
import {
  StyledButtonPrefix,
  StyledDropdownButton,
  StyledDropdownMenu
} from './style';

export type DropdownSelectProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedValue: any;
  options: Array<OptionType & { disabled?: boolean }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (value: any) => void;
  isDisabled?: boolean;
  width?: `${number}px`;
  className?: string; // https://styled-components.com/docs/api#caveat-with-classname
  prefix?: JSX.Element;
};

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  selectedValue,
  options,
  onSelect,
  isDisabled = false,
  width,
  className,
  prefix
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <StyledDropdownButton
        id="dropdown-button"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<IconComponent name="ArrowDown" />}
        $width={width}
        $isGreyOut={selectedValue === null}
        $isDisabled={isDisabled}
      >
        <>
          {prefix && <StyledButtonPrefix>{prefix}</StyledButtonPrefix>}
          {options.find((option) => option.value === selectedValue)?.name ??
            options[0].name}
        </>
      </StyledDropdownButton>
      <StyledDropdownMenu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button'
        }}
        $width={width}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            className={option.disabled ? 'disabled' : ''}
            onClick={() => {
              onSelect(option.value);
              handleClose();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </StyledDropdownMenu>
    </div>
  );
};

export default DropdownSelect;
