import { StyledFilterField } from './style';

type FilterFieldProps = {
  label: string;
  children: JSX.Element;
};

const FilterField = ({ label, children }: FilterFieldProps): JSX.Element => (
  <StyledFilterField>
    {label}
    :
    {children}
  </StyledFilterField>
);

export default FilterField;
