import * as React from "react";
import { SVGProps } from "react";

const SvgLang = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.43 7.422C15.724 3.062 14.054 0 12.11 0c-1.944 0-3.614 3.062-4.322 7.422h8.643Zm-9.008 4.687c0 1.084.058 2.124.161 3.125h9.048a30.617 30.617 0 0 0 0-6.25H7.583a30.605 30.605 0 0 0-.161 3.125Zm15.854-4.687A12.135 12.135 0 0 0 15.562.508c1.19 1.65 2.011 4.136 2.44 6.914h5.274ZM8.652.508a12.127 12.127 0 0 0-7.71 6.914h5.274C6.64 4.644 7.46 2.158 8.652.508ZM23.8 8.984h-5.6c.102 1.026.16 2.076.16 3.125 0 1.05-.058 2.1-.16 3.125h5.595c.268-1 .42-2.04.42-3.125 0-1.084-.151-2.124-.415-3.125ZM5.859 12.11c0-1.05.059-2.1.162-3.125H.42A12.216 12.216 0 0 0 0 12.11c0 1.084.156 2.124.42 3.125h5.596a33.065 33.065 0 0 1-.157-3.125Zm1.93 4.688c.707 4.36 2.377 7.422 4.32 7.422 1.944 0 3.614-3.062 4.322-7.422H7.788Zm7.777 6.914a12.146 12.146 0 0 0 7.715-6.914h-5.273c-.43 2.778-1.25 5.264-2.442 6.914ZM.942 16.797a12.135 12.135 0 0 0 7.715 6.914c-1.191-1.65-2.011-4.136-2.441-6.914H.942Z"
      fill="#5C5C5C"
    />
  </svg>
);

export default SvgLang;
