import * as React from "react";
import { SVGProps } from "react";

const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 16a2 2 0 0 0 2-2H5a2 2 0 0 0 2 2Zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 2 5.999c0 1.099-.5 6-2 7h14c-1.5-1-2-5.901-2-7 0-2.42-1.72-4.44-4.005-4.9Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgNotification;
