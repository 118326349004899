/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';
import { logixPanelRootURL } from 'api/config';
import { persist, devtools } from 'zustand/middleware';
import { AppStore, NotificationStore, PersistStore } from './types';

export const useStore = create<AppStore>(
  devtools((set) => ({
    iframPageUrl: logixPanelRootURL as string,
    changeIframePagePath: (newPath: string) => set(() => ({ iframPageUrl: `${logixPanelRootURL}${newPath}` })),
    theme: 'dark',
    changeTheme: (newTheme: string) => set(() => ({ theme: newTheme })),
    isMenuOpen: true,
    isAuthorized: false,
    changeAuth: (auth) => set({ isAuthorized: auth }),
    toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen }))
  }), { name: 'Main Store' })
);

export const useNotificationStore = create<NotificationStore>(
  devtools((set) => ({
    notification: {},
    changeNotification: (obj: any) => set(() => ({ notification: obj }))
  }), { name: 'Notification Store' })
);

export const usePersistStore = create<PersistStore>(persist(
  (set) => ({
    lang: 'en',
    changeLang: (lang: string) => set({ lang })
  }),
  {
    name: 'langStorage',
    getStorage: () => localStorage
  }
));
