import * as React from "react";
import { SVGProps } from "react";

const SvgNewNotifications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#NewNotifications_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M36.176 7.617 43.337.425l2.24 2.25c.987.99.186 3.409-1.79 5.393-1.977 1.985-4.383 2.791-5.371 1.798l-2.24-2.25Z"
        fill="#F98600"
      />
      <path
        d="M36.176 7.617 43.337.425l2.24 2.25c.987.99.186 3.409-1.79 5.393-1.977 1.985-4.383 2.791-5.371 1.798l-2.24-2.25Z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        d="M37.967 2.223C39.941.24 42.35-.567 43.337.425c.989.992.186 3.409-1.79 5.394-1.975 1.985-4.382 2.79-5.37 1.798-.989-.993-.184-3.41 1.79-5.394Z"
        fill="#FDCF99"
      />
      <path
        d="M38.86 3.122c.987-.991 2.194-1.395 2.687-.899.494.496.093 1.707-.895 2.699-.987.991-2.193 1.395-2.687.9-.493-.497-.093-1.705.895-2.7Z"
        fill="#C76B00"
      />
      <path
        d="M33.206 38.6 9.357 14.649c8.253 1.419 18.654-20.087 31.105-7.582 12.45 12.505-8.668 23.246-7.256 31.535Z"
        fill="#F98600"
      />
      <path
        d="m15.174 18.995-3.012-3.025c6.691.505 16.854-17.387 26.189-9.996-9.694-2.79-13.305 9.746-23.177 13.021Z"
        fill="#F98600"
      />
      <path
        d="m15.174 18.995-3.012-3.025c6.691.505 16.854-17.387 26.189-9.996-9.694-2.79-13.305 9.746-23.177 13.021Z"
        fill="#fff"
        fillOpacity={0.8}
      />
      <path
        d="M4.245 14.87c2.846-2.86 11.596 1.286 19.527 9.252 7.931 7.966 12.06 16.754 9.213 19.613l-3.345 3.36-3.438-3.454c-3.747-1.534-8.37-4.767-12.745-9.16-4.374-4.391-7.591-9.036-9.12-12.798L.898 18.23l3.346-3.36Z"
        fill="#FDCF99"
      />
      <path
        d="M.9 18.23c2.846-2.86 11.596 1.287 19.527 9.253 7.931 7.966 12.059 16.754 9.213 19.613-2.846 2.86-11.597-1.287-19.527-9.253C2.183 29.877-1.946 21.09.9 18.23Zm2.536 2.548c2.345-2.354 9.55 1.06 16.08 7.62 6.531 6.559 9.93 13.795 7.587 16.149-2.345 2.353-9.55-1.06-16.08-7.619-6.532-6.559-9.93-13.796-7.587-16.15Z"
        fill="#F98600"
      />
      <path
        d="M3.437 20.778c2.344-2.354 9.55 1.06 16.08 7.62 6.53 6.56 9.93 13.795 7.586 16.149-2.344 2.353-9.549-1.06-16.08-7.619-6.531-6.559-9.93-13.796-7.586-16.15Z"
        fill="#C76B00"
      />
      <path
        d="M22.296 31.45a39.07 39.07 0 0 1 2.135 2.817l-4.68 7.264c-.052.069-.101.142-.156.207a1.699 1.699 0 0 1-1.906.41 1.702 1.702 0 0 1-.98-1.694c.018-.174.063-.344.134-.504.035-.079.082-.153.123-.23l5.33-8.27Z"
        fill="#DDD"
      />
      <path
        d="M17.21 33.908a6.721 6.721 0 0 1 5.906 1.908 6.807 6.807 0 0 1 .581 8.884 6.756 6.756 0 0 1-2.48 2.02 6.725 6.725 0 0 1-6.201-.219 6.692 6.692 0 0 0 3.656-1.89 6.8 6.8 0 0 0 1.976-4.795 6.8 6.8 0 0 0-1.976-4.794 6.692 6.692 0 0 0-1.463-1.114Z"
        fill="#3E3E3E"
      />
      <path
        d="M15.015 46.501a6.785 6.785 0 0 1-3.25-4.31 6.806 6.806 0 0 1 .13-3.663 6.781 6.781 0 0 1 2.01-3.06 6.735 6.735 0 0 1 3.304-1.56 6.694 6.694 0 0 1 1.463 1.114 6.8 6.8 0 0 1 1.976 4.794 6.8 6.8 0 0 1-1.976 4.795c-.992 1-2.27 1.66-3.657 1.89Z"
        fill="#BBB"
      />
      <path
        d="M33.141 37.564c-1.94-5.823-8.849-13.828-14.594-18.065 6.625 2.98 13.243 8.304 16.35 13.371-.749 1.194-1.671 2.928-1.756 4.697v-.003Z"
        fill="#F98600"
      />
    </g>
    <defs>
      <clipPath id="NewNotifications_svg__a">
        <path fill="#fff" d="M0 0h46v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNewNotifications;
