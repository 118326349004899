import styled, { css } from 'styled-components';
import Dialog from '@mui/material/Dialog';

export const StyledDialog = styled(Dialog)`
  ${({ theme }) => css`
    .MuiPaper-root {
      padding: ${theme.spacing.s6};
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.26),
        0px 1px 4px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      width: 800px;
      max-width: 980px;
    }
  `}
`;

export const StyledDialogHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${theme.spacing.s4};
    border-bottom: 1px solid ${theme.palette.N500};
    h5 {
      margin: 0;
      ${theme.typography.H5};
      font-weight: 600;
      color: ${theme.palette.N75};
    }
  `}
`;

export const StyledDialogCloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledDialogContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.s6} 0;
    ${theme.typography.Body2};
    color: ${theme.palette.N200};
  `}
`;

export const StyledDialogActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.s2};
  `}
`;

type StyledDialogActionButtonProps = {
  variant?: 'filled' | 'text';
};

export const StyledDialogActionButton = styled.button<StyledDialogActionButtonProps>`
  ${({ theme, variant = 'filled' }) => css`
    border: none;
    background-color: ${variant === 'filled' ? '#145DB5' : 'transparent'};
    color: ${variant === 'filled' ? theme.palette.N700 : '#145DB5'};
    ${theme.typography.Body2};
    border-radius: 4px;
    padding: ${theme.spacing.s2} ${theme.spacing.s6};
    cursor: pointer;
  `}
`;
