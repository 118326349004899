import * as React from "react";
import { SVGProps } from "react";

const SvgExclamation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.953 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.93-9.412-1 4.705c-.07.34.03.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.45-.081.081-.381 2.29-.287ZM7.953 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgExclamation;
