import * as React from "react";
import { SVGProps } from "react";

const SvgGlasses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 4a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 2c-.532 0-1.016.208-1.375.547ZM14 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgGlasses;
