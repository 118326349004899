import styled, { css } from 'styled-components';

export const StyledFilterField = styled.div`
  ${({ theme }) => css`
    ${theme.typography.Body2};
    color: ${theme.palette.N50};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.s2};
  `}
`;
