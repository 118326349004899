import styled from 'styled-components';

export const headerHeight = 70;

export const StyledHeaderWrap = styled.div`
  position: relative;
  z-index: 2;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content:space-between;
  box-shadow: 0px 0px 9.3169px rgba(0, 0, 0, 0.12), 0px 0px 13.4577px rgba(0, 0, 0, 0.2);
  background: #F4F4F4;
  box-sizing: border-box;
`;

export const StyledSettingWrap = styled.div`
  display:flex;

  >select{
    margin: 0 5px;
  }

  .dropDown{
    .title{
      display: flex;
      align-items: center;
      margin:0 6px;
      color: ${(props) => props.theme.common.lightGrey};
      justify-content: space-around;
      width:90px;
      >span:first-child{
        height:24px;
        svg{
          font-size:24px;
        }
      }
      >span:nth-child(2){
        width:28px;
        font-size:14px;
      }

      >span:nth-child(3) {
        svg{
          font-size:12px;
        }
      }
    }


    .menu-list{
      width:216px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      z-index: 2;
      top: 28px;
      left: -120px;
      .item{
        padding-left:12px;
        padding-right:12px;
        margin: 6px 8px;
        height:36px;
        border-radius: 8px;
        &:hover{
            background-color: ${(props) => props.theme.sideMenu.secondary};
        }

        &:active{
          background: #bbb;
        }

        >div{
          display: flex;
          align-items: center;
          justify-content: left;
          >span:nth-child(2) {
            width:56px;
          }
        }
      }
    }
  }

`;

export const StyledLangIcon = styled.span`
  display:flex;
  width:32px;
`;

export const StyledItemWrap = styled.div`
  height:100%;
`;
