import Avatar from 'components/Avatar';
import cx from 'classnames';
import { CommonProps } from 'type/component';
import {
  StyledGreetings, StyledMainGreetingWrap, StyledMainGreetings, StyledMessage
} from './style';

export interface GreetingsProps extends CommonProps {
  title: string;
  message?: string;
  children?: JSX.Element;
  avatarSrc:string;
}

const Greetings:React.FC<GreetingsProps> = (props) => {
  const {
    title, message, avatarSrc, children, className
  } = props;

  return (
    <StyledGreetings className={cx('lp-greetings', className)}>
      <StyledMainGreetingWrap>
        <Avatar src={avatarSrc} />
        <StyledMainGreetings>
          {title}
        </StyledMainGreetings>
      </StyledMainGreetingWrap>

      { message ? (
        <StyledMessage>
          {message}
        </StyledMessage>
      ) : (
        { children }
      )}

    </StyledGreetings>

  );
};

export default Greetings;
