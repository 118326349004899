import logixPanelInstance from '../i18n';
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const isObjEmpty = (obj: object): boolean => Object.keys(obj).length === 0;

// data type to be add after api data is confirmed
export const transferSideMenuKeys = (payloadData: any, config: any): object => {
  const mainKeys = Object.keys(payloadData); // compliance, task

  const sortedObj: any = {};
  mainKeys.forEach((mainKey: string) => {
    const subKeys = Object.keys(payloadData[mainKey]); // aml_kyc, total
    const mainKeyObj = payloadData[mainKey];
    subKeys.forEach((subKey: string) => {
      if (subKey === 'total') {
        sortedObj[config[mainKey]] = mainKeyObj[subKey];
      } else {
        sortedObj[config[subKey]] = mainKeyObj[subKey];
      }
    });
  });
  return sortedObj;
};

export const toggleReminder = (setStorage: boolean): void => {
  if (setStorage) {
    sessionStorage.setItem('reminder', 'closed');
  } else {
    sessionStorage.removeItem('reminder');
  }
};

export const emptyStrGenerator = (unitOfStr: number): string => {
  let finalLength = '';

  for (let i = 0; i < unitOfStr; i += 1) {
    finalLength += ' ';
  }
  return finalLength;
};

export const intlGenerator = (num: number, minFraction = 0, maxFraction = 2): string => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction
  });

  return internationalNumberFormat.format(num);
};

export const financialFormat = (x: number | string): string => Number
  .parseFloat(String(x)).toFixed(2);

export const numberWithCommas = (num: number): string => num.toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const colorGenerator = (defaultColorArr: string[], totalColorNum: number): string[] => {
  const colorArr = [...defaultColorArr];
  const colorGenHandler = (): string => {
    const array = new Uint16Array(1);
    const randomNumber = window.crypto.getRandomValues(array);
    // randomNumber is between 0 ~ 65536
    const randColor = `#${(randomNumber[0] * 256).toString(16).padStart(6, '0')}`;
    // #ffffff is 16777215
    return randColor;
  };

  for (let i = 0; i < totalColorNum - defaultColorArr.length; i += 1) {
    let generatedColor = colorGenHandler();

    while (colorArr.includes(generatedColor)) {
      generatedColor = colorGenHandler();
    }
    colorArr.push(generatedColor);
  }
  return colorArr;
};

export const calcTotalItem = (dataGroup: {
  [key: string]: { date: string; value: number }[]
}): number => {
  const groupKeys = Object.keys(dataGroup);
  let total = 0;
  groupKeys.forEach((eachKey: string) => {
    const eachTotal = dataGroup[eachKey]
      .reduce((previousValue, currentValue) => Number(previousValue) +
        Number(currentValue.value), 0);
    total += eachTotal;
  });

  return total;
};

export const handleLogoutRedirect = (
  lang = logixPanelInstance.language,
  defaultUrl = `${process.env.REACT_APP_LOGIX_PANEL_OLD_SITE_URL}`
): void => {
  window.location.href = `${defaultUrl}/${lang}/auth/login`;
};
export const capitalize = (str: string): string => str
  .trim().replace(/^\w/, (c) => c.toUpperCase());

export const tHeaderGenerator = (
  lang: string,
  headObj: { [key: string]: string }
): { title: string; key: string }[] => {
  const obj = headObj;

  const arr = [] as any;

  Object.keys(headObj).forEach((eachKey: string) => {
    arr.push({
      title: lang === 'en' ? capitalize(eachKey) : obj[eachKey],
      key: eachKey
    });
  });

  return arr;
};

export const strLen = (str: string): number => {
  let count = 0;
  for (let i = 0, len = str.length; i < len; i += 1) {
    count += str.charCodeAt(i) < 256 ? 1 : 2;
  }
  return count;
};

export const statisticsValueGenerator = (
  rawValue: number,
  params?: {
    unit?: string;
    precesion?: number;
    shouldDisplayDefaultDash?:
    boolean; shouldAddPlusSymbol?: boolean
  }
): string => {
  const shouldDisplayDefaultDash = (!params || params.shouldDisplayDefaultDash === undefined) ?
    true :
    params.shouldDisplayDefaultDash;
  if (!rawValue && shouldDisplayDefaultDash) {
    return '--';
  }

  if (!params) {
    return String(rawValue);
  }
  const { unit = '', precesion = 0, shouldAddPlusSymbol = false } = params;
  const preSymbol = shouldAddPlusSymbol && rawValue >= 0 ? '+' : '';
  return preSymbol + rawValue.toFixed(precesion) + unit;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toCamelCaseObject<K extends Record<string, any>, V>(obj?: K): V {
  if (typeof obj !== 'object' || obj === null || obj === undefined) return {} as V;
  const toCamelCase = (str: string): string => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
  return Object.keys(obj).reduce((result, key) => {
    const camelCaseKey = toCamelCase(key);
    return { ...result, [camelCaseKey]: obj[key as keyof K] };
  }, {}) as V;
}
