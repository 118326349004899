import styled, { css } from 'styled-components';

export const StyledPageContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.s8} ${theme.spacing.s6} ${theme.spacing.s12};
    height: 100%;
    overflow-y: auto;
    background-color: ${theme.palette.N650};
  `}
`;

export const StyledPageTitle = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.H4};
    font-weight: 600;
    color: ${theme.palette.N50};
    margin-bottom: ${theme.spacing.s8};
  `}
`;
