/*
 * Zerologix Design System - Spacing
 * https://www.figma.com/file/zOJ4pl5Hy103USPuCZRPQd/ZeroLogix-Design-System?type=design&node-id=41-35281&t=obwmdD1Gov5ME0ji-0
 */

// s0: 0
// s1: 0.25rem
// s2: 0.5rem
// s3: 0.75rem
// s4: 1rem
// s5: 1.25rem
// s6: 1.5rem
// s7: 1.75rem
// s8: 2rem
// s9: 2.25rem
// ...

const generateSpacers = (
  spacer: number,
  count: number,
  interval: number
): { [key: `s${number}`]: string } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj = {} as any;
  for (let i = 0; i <= count; i += 1) {
    obj[`s${i}`] = `${spacer * interval * i}rem`;
  }
  return obj;
};

const spacing = generateSpacers(1, 30, 0.25);

export type Spacing = typeof spacing;
export default spacing;
