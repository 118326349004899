import styled, { css } from 'styled-components';
import MUIPagination from '@mui/material/Pagination';

export const StyledPagination = styled(MUIPagination)`
  ${({ theme }) => css`
    .MuiPagination-ul li button {
      color: ${theme.palette.N200};

      &.Mui-selected {
        background-color: transparent;
        color: ${theme.palette.P400};
      }
    }
  `}
`;
