import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    all: inherit;
    cursor: pointer;
    ${theme.typography.Body2};
    color: ${theme.palette.N500};
    padding: ${theme.spacing.s1};
    width: 100%;
    display: flex;
    gap: ${theme.spacing.s4};
    align-items: center;
  `}
`;

export const StyledIconWrapper = styled.div`
  transform: rotate(-90deg);
  margin-left: auto;
`;

export const StyledMenu = styled(Menu)`
  ${({ theme }) => css`
    .MuiPaper-root {
      box-shadow: none;
      padding: ${theme.spacing.s2};
      background-color: ${theme.palette.N200};
      width: 248px;
    }

    .MuiList-root {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.s2};
    }
  `}
`;

export const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => css`
    ${theme.typography.Body2};
    color: ${theme.palette.N500};
    padding: ${theme.spacing.s2};
    border-radius: 4px;
    display: flex;
    gap: ${theme.spacing.s2};

    &:hover {
      background-color: ${theme.palette.N100};
    }
  `}
`;
