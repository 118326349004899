import styled, { css } from 'styled-components';

export const StyledRowsPerPageController = styled.div`
  ${({ theme }) => css`
    ${theme.typography.Body2};
    color: ${theme.palette.N200};
    display: flex;
    align-items: center;
  `}
`;
