import { useEffect, useState } from 'react';
import useHttp from 'hooks/useHttp';
import { getTradingCupAccountsRequestConfig } from 'api/getV2RequestConfig';

export type FilterData = {
  role: Role | 'all';
  status: Status | 'all';
  dateRange: { start: Date | null; end: Date | null };
};

export type AccountRowData = {
  id: string;
  tradingcupId: RawData['tradingcup_id'];
  createDate: RawData['join_date'];
  name: RawData['name'];
  traderUrl: string | null;
  email: RawData['email'];
  tradingAccountId: RawData['trading_account_id'];
  role: RawData['role'];
  signalUrl: RawData['signal_link'];
  accountNumber: RawData['account_number'];
  serverName: RawData['server_name'];
  platform: RawData['platform'];
  accountType: RawData['account_type'];
  group: RawData['group'];
  baseCurrency: RawData['base_currency'];
  leverage: RawData['leverage'];
  regulation: RawData['regulation'];
  category: RawData['category'];
  optInDate: RawData['opt_in'];
  optOutDate: RawData['opt_out'];
  status: RawData['status'];
  strategyName: RawData['strategy_name']
  accountLink: RawData['account_link']
};

export enum Role {
  Signal = 'signal',
  Copier = 'copier',
  PreSignal = 'pre-signal',
  Normal = 'normal'
}

export enum Status {
  OptIn = 'opt-in',
  OptOut = 'opt-out'
}

type RawData = {
  tradingcup_id: number;
  trader_id: number | null;
  join_date: string | null;
  name: string | null;
  email: string | null;
  trading_account_id: string | null;
  role: Role;
  account_number: number | '';
  server_name: string | null;
  platform: string | null;
  account_type: string | null;
  group: string | null;
  base_currency: string | null;
  leverage: number | null;
  regulation: string;
  category: string;
  opt_in: string | null;
  opt_out: string | null;
  status: Status | null;
  signal_link: string;
  strategy_name: string | null;
  account_link: string;
};

type ResponseData = {
  data: Array<RawData>;
  paginate: {
    totalPages: number;
    total: number;
    perPage: number;
    page: number;
  };
};

const useTradingCupAccountsData = (
  filterData: FilterData,
  searchInput: string,
  currentPage: number,
  rowsPerPage: number,
  lang: string
): {
  rows: Array<AccountRowData>;
  totalPageCount: number;
  fetchData: () => void;
} => {
  const { sendRequest } = useHttp();

  const [rows, setRows] = useState<Array<AccountRowData>>([]);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const formatDate = (date: Date | null): string | null => (date ?
    date.toLocaleDateString('en-AU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
      .split('/')
      .reverse()
      .join('-') :
    null);

  const formattedStartDate = formatDate(filterData.dateRange.start);
  const formattedEndDate = formatDate(filterData.dateRange.end);

  const fetchData = (): void => {
    sendRequest(
      getTradingCupAccountsRequestConfig(
        filterData.role,
        filterData.status,
        formattedStartDate,
        formattedEndDate,
        searchInput,
        currentPage,
        rowsPerPage,
        lang
      ),
      (responseData: ResponseData) => {
        // console.log(responseData);
        const accountRowsData: Array<AccountRowData> = responseData.data.map(
          (item) => ({
            id: `${item.tradingcup_id}-${item.account_number}`,
            tradingcupId: item.tradingcup_id,
            strategyName: item.strategy_name,
            createDate: item.join_date,
            name: item.name,
            traderUrl: item.trader_id ? `${process.env.REACT_APP_LOGIX_PANEL_OLD_SITE_URL}/${lang}/profile/individual/edit?id=${item.trader_id}` : null,
            email: item.email,
            tradingAccountId: item.trading_account_id,
            role: item.role,
            signalUrl: `${process.env.REACT_APP_COPY_TRADING_SITE_URL}${item.signal_link}`,
            accountNumber: item.account_number,
            serverName: item.server_name,
            platform: item.platform,
            accountType: item.account_type,
            group: item.group,
            baseCurrency: item.base_currency,
            leverage: item.leverage,
            regulation: item.regulation,
            category: item.category,
            optInDate: item.opt_in,
            optOutDate: item.opt_out,
            status: item.status,
            accountLink: item.account_link
          })
        );
        setRows(accountRowsData);
        setTotalPageCount(responseData.paginate.totalPages);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage, searchInput, lang]);

  return { rows, totalPageCount, fetchData };
};

export default useTradingCupAccountsData;
