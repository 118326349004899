import * as React from "react";
import { SVGProps } from "react";

const SvgPendingDeposits = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.714 20.442c-.989-.574-2.717-.506-3.862.155L.539 37.505a1.045 1.045 0 0 0-.539.919 1.053 1.053 0 0 0 .546.923l15.093 8.264c.99.574 2.718.505 3.862-.156L49.53 30.13A.942.942 0 0 0 50 29.31a.946.946 0 0 0-.492-.829l-14.794-8.04Z"
      fill="#004A15"
    />
    <path
      d="M49.51 28.477a.236.236 0 0 1 0 .408L19.515 46.152c-1.072.618-2.692.682-3.62.144L.673 37.83a.236.236 0 0 1-.028-.385L30.85 20.6c1.145-.661 2.873-.73 3.863-.156l14.793 8.028.003.005Z"
      fill="url(#PendingDeposits_svg__a)"
    />
    <path
      d="m30.94 31.02-10.286 5.758a.258.258 0 0 1-.252 0l-1.164-.67a.256.256 0 0 1-.096-.355.257.257 0 0 1 .096-.094l10.315-5.72a.249.249 0 0 1 .25 0l1.135.634a.258.258 0 0 1 .002.447ZM47.701 27.485l-.666.385c-.763.44-1.917.486-2.579.101l-10.07-5.508a.707.707 0 0 1-.364-.616.71.71 0 0 1 .36-.614l.89-.496 12.43 6.748ZM9.285 43.073l-1.736-.945c-.155-.087-.28 0-.275.22a.683.683 0 0 0 .292.531l1.735.945c.156.087.28 0 .276-.217a.677.677 0 0 0-.292-.534Z"
      fill="#004A15"
    />
    <path
      d="M16.43 6.976c-1.705.987-3.083 3.382-3.078 5.349 0 .973.346 1.653.9 1.984l.857.498c-.554-.321-.898-1.009-.9-1.982 0-1.966 1.373-4.361 3.078-5.348.857-.496 1.635-.543 2.198-.215l-.858-.5c-.563-.329-1.34-.282-2.197.214Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m14.29 13.56-.845-.454c.032.132.074.261.125.387l.839.449a2.506 2.506 0 0 1-.118-.383ZM16.037 8.511l-.881-.472c-.066.073-.13.144-.191.22l.88.472c.062-.078.128-.15.191-.22ZM15.72 8.884l-.88-.472a3.79 3.79 0 0 0-.172.236l.879.472c.056-.087.115-.16.174-.236ZM17.75 7.25l-.9-.472a3.475 3.475 0 0 0-.315.151l.895.472c.104-.055.211-.106.32-.15ZM15.432 9.274l-.878-.472a5.108 5.108 0 0 0-.156.236l.877.472c.051-.078.103-.158.157-.236ZM18.424 7.071l-.916-.491c-.14.022-.279.054-.414.097l.906.486c.139-.043.28-.074.424-.092ZM16.782 7.822l-.89-.472c-.078.063-.156.127-.236.196l.886.472c.08-.06.162-.135.24-.196ZM16.386 8.154l-.886-.472c-.07.066-.139.137-.207.208l.883.472a5.76 5.76 0 0 1 .21-.208ZM17.225 7.517l-.89-.472a4.06 4.06 0 0 0-.269.177l.888.472c.09-.066.182-.122.271-.177ZM14.3 11.932l-.867-.472c-.017.1-.031.199-.042.298l.862.472a4.16 4.16 0 0 1 .047-.298ZM14.938 10.098l-.874-.473c-.043.085-.083.17-.123.256l.874.472c.04-.085.08-.17.123-.255ZM15.17 9.68l-.875-.472c-.047.08-.092.163-.137.236l.874.472c.045-.068.092-.156.139-.236ZM14.557 14.246l-.825-.441c.133.206.31.378.52.503l.74.43v-.012a1.598 1.598 0 0 1-.435-.48ZM19.261 7.156v-.019l-.643-.375c-.229-.13-.486-.2-.749-.206l.942.505c.154.01.305.042.45.095ZM14.216 12.976l-.855-.458c.005.113.016.226.033.337l.853.456a2.988 2.988 0 0 1-.03-.335ZM14.73 10.532l-.87-.472c-.036.087-.071.175-.104.262l.869.472c.035-.087.068-.175.106-.262ZM14.227 12.445l-.86-.473c0 .107-.012.21-.012.315l.86.46c0-.102.005-.206.012-.302ZM14.41 11.446l-.867-.472a9.152 9.152 0 0 0-.07.283l.864.473c.012-.095.047-.19.073-.284ZM14.557 10.98l-.87-.472c-.032.09-.063.18-.091.27l.869.472c.028-.087.059-.18.092-.27Z"
      />
    </g>
    <path
      d="M17.287 7.487c-1.705.987-3.083 3.381-3.078 5.348.005 1.967 1.394 2.753 3.1 1.766 1.705-.987 3.082-3.381 3.078-5.341-.005-1.96-1.395-2.77-3.1-1.773Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M19.478 7.264a1.603 1.603 0 0 0-.784-.205l-4.24 4.25a5.53 5.53 0 0 0-.235 1.216l5.259-5.26ZM14.864 14.61l5.516-5.53a3.041 3.041 0 0 0-.167-.895l-5.787 5.802c.098.238.248.45.438.623Z"
      />
    </g>
    <path
      d="M10.346.422c-1.705.987-3.085 3.381-3.078 5.35 0 .974.346 1.654.9 1.982l.857.5c-.554-.323-.898-1.008-.9-1.983 0-1.967 1.373-4.361 3.078-5.348.857-.496 1.635-.544 2.198-.215l-.858-.501c-.565-.326-1.342-.276-2.197.215Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m8.201 7.014-.846-.472c.03.133.072.262.125.387l.839.451a2.623 2.623 0 0 1-.118-.366ZM9.947 1.957l-.88-.473c-.067.071-.13.142-.194.218l.883.472c.061-.073.118-.147.191-.217ZM9.639 2.332l-.881-.472a2.343 2.343 0 0 0-.172.236l.878.472c.048-.087.106-.163.175-.236ZM11.66.7 10.76.229a3.808 3.808 0 0 0-.314.154l.895.472c.104-.057.211-.108.32-.153ZM9.344 2.722l-.878-.472a6.772 6.772 0 0 0-.155.236l.876.472c.052-.08.103-.158.157-.236ZM12.335.519l-.917-.491c-.14.02-.28.052-.414.096l.907.487c.138-.043.28-.074.424-.092ZM10.69 1.27 9.802.797c-.078.062-.156.128-.236.194l.886.472c.08-.068.16-.132.238-.193ZM10.298 1.6l-.886-.472c-.07.068-.139.137-.207.21l.883.472c.069-.073.14-.141.21-.21ZM11.135.963l-.89-.473a4.011 4.011 0 0 0-.268.175l.887.472c.09-.06.186-.12.271-.174ZM8.208 5.378l-.865-.472c-.016.101-.03.198-.042.297l.862.473c.012-.1.028-.199.045-.298ZM8.85 3.543l-.874-.472c-.042.083-.082.17-.122.255l.873.472c.04-.09.08-.17.123-.255ZM9.082 3.126l-.877-.473c-.047.08-.091.163-.139.236l.877.473.139-.236ZM8.461 7.692l-.824-.442c.131.207.31.379.52.503l.737.43a1.607 1.607 0 0 1-.433-.491ZM13.17.602v-.02l-.644-.375c-.229-.13-.486-.2-.749-.205l.942.505c.154.01.305.042.45.095ZM8.127 6.421l-.846-.458c.005.113.016.226.033.338l.853.458a3.045 3.045 0 0 1-.04-.338ZM8.641 3.978l-.87-.472c-.039.087-.072.174-.107.264l.872.472c.035-.09.068-.177.105-.264ZM8.137 5.883l-.86-.472c0 .104-.011.208-.013.312l.86.472c.002-.102.004-.206.013-.312ZM8.32 4.89l-.867-.473c-.026.094-.05.189-.07.283l.864.473c.021-.092.047-.185.073-.284ZM8.46 4.417l-.869-.472-.09.271.87.473c.033-.08.064-.17.09-.272Z"
      />
    </g>
    <path
      d="M11.201.923C9.496 1.91 8.118 4.304 8.123 6.27c.005 1.967 1.394 2.754 3.097 1.769 1.703-.985 3.085-3.382 3.08-5.344C14.297.734 12.907-.069 11.202.923Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M13.39.712a1.612 1.612 0 0 0-.784-.207l-4.24 4.262c-.12.395-.195.802-.223 1.214L13.39.712ZM8.774 8.056l5.518-5.528a2.964 2.964 0 0 0-.17-.895L8.337 7.435c.1.236.25.448.438.621Z"
      />
    </g>
    <path
      d="M21.043 23.646c-1.705.987-3.083 3.381-3.078 5.349 0 .973.346 1.652.9 1.983l.86.498c-.557-.32-.9-1.008-.903-1.98 0-1.968 1.373-4.362 3.078-5.35.86-.495 1.637-.543 2.198-.214l-.857-.5c-.563-.33-1.34-.282-2.198.214Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m18.904 30.229-.845-.454c.031.132.073.262.124.387l.839.45a2.506 2.506 0 0 1-.118-.383ZM20.65 25.18l-.881-.472c-.064.073-.13.144-.19.22l.88.472c.064-.078.127-.149.19-.22ZM20.334 25.554l-.88-.473a3.847 3.847 0 0 0-.173.236l.879.473c.056-.088.115-.16.174-.236ZM22.356 23.924l-.9-.472a3.485 3.485 0 0 0-.315.151l.895.473c.103-.058.21-.109.32-.152ZM20.046 25.943l-.879-.472-.155.236.876.472c.052-.08.113-.158.158-.236ZM23.036 23.74l-.916-.491c-.141.022-.28.054-.417.097l.907.486c.139-.043.282-.074.426-.092ZM21.395 24.486l-.89-.472-.235.196.885.472c.08-.063.16-.13.24-.196ZM21 24.824l-.887-.472c-.07.066-.138.136-.207.207l.884.473a5.76 5.76 0 0 1 .21-.208ZM21.838 24.186l-.89-.472c-.09.057-.179.114-.268.177l.888.473c.082-.067.181-.114.27-.178ZM18.913 28.6l-.864-.473c-.02.1-.033.199-.045.298l.862.472c.014-.1.028-.198.047-.297ZM19.551 26.767l-.874-.472c-.042.085-.082.17-.122.255l.873.472c.04-.085.08-.175.123-.255ZM19.784 26.35l-.876-.473c-.047.08-.092.163-.137.236l.874.472.14-.236ZM19.17 30.916l-.824-.442c.133.205.311.377.52.503l.74.43a1.597 1.597 0 0 1-.436-.491ZM23.874 23.825v-.019l-.643-.375c-.229-.13-.486-.2-.749-.206l.942.506c.154.009.306.04.45.094ZM18.824 29.645l-.855-.458c0 .116.016.236.033.338l.852.456a6.176 6.176 0 0 0-.03-.336ZM19.344 27.201l-.869-.472a7.095 7.095 0 0 0-.106.262l.872.473c.032-.088.066-.175.103-.262ZM18.84 29.107l-.86-.473v.315l.86.46a.928.928 0 0 1 0-.302ZM19.023 28.115l-.866-.472a4.65 4.65 0 0 0-.071.283l.867.473c.018-.095.044-.19.07-.284ZM19.17 27.65l-.87-.472c-.032.09-.063.18-.091.269l.87.472c.027-.087.058-.18.09-.269Z"
      />
    </g>
    <path
      d="M21.9 24.146c-1.705.987-3.082 3.382-3.078 5.349.005 1.967 1.395 2.753 3.1 1.766 1.705-.987 3.083-3.381 3.078-5.341-.005-1.96-1.394-2.76-3.1-1.774Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M24.091 23.934a1.596 1.596 0 0 0-.784-.206l-4.24 4.251a5.535 5.535 0 0 0-.235 1.216l5.26-5.261ZM19.477 31.278l5.516-5.53a3.079 3.079 0 0 0-.167-.896l-5.787 5.805c.098.236.248.449.438.62Z"
      />
    </g>
    <path
      d="M20.82 8.195c-1.705.987-3.082 3.381-3.078 5.348 0 .973.346 1.653.9 1.981l.857.5c-.553-.323-.897-1.007-.9-1.983 0-1.967 1.374-4.361 3.079-5.348.857-.496 1.634-.54 2.197-.215l-.857-.5c-.563-.327-1.34-.282-2.198.217Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m18.68 14.775-.846-.441c.03.132.073.261.125.387l.838.45a2.538 2.538 0 0 1-.117-.396ZM20.424 9.727l-.881-.472c-.066.07-.13.141-.193.217l.883.472c.061-.073.127-.146.19-.217ZM20.11 10.102l-.881-.472a3.226 3.226 0 0 0-.172.236l.878.473c.066-.088.116-.163.174-.237ZM22.14 8.47 21.236 8a3.807 3.807 0 0 0-.313.153l.895.472c.104-.056.21-.107.32-.153ZM19.821 10.492l-.878-.472a5.08 5.08 0 0 0-.156.236l.876.472c.052-.08.102-.158.158-.236ZM22.811 8.289l-.916-.491c-.14.02-.28.052-.415.097l.907.486c.139-.043.28-.074.424-.092ZM21.178 9.04l-.888-.472c-.078.061-.155.127-.235.194l.885.472c.07-.069.151-.132.238-.194ZM20.774 9.375l-.885-.472c-.07.068-.14.14-.207.21l.883.472c.068-.075.141-.146.21-.21ZM21.614 8.733l-.89-.472a4.002 4.002 0 0 0-.269.174l.888.473c.09-.062.179-.118.27-.175ZM18.686 13.153l-.864-.472c-.017.1-.03.198-.043.295l.862.472c.012-.101.029-.2.045-.295ZM19.326 11.316l-.873-.472c-.043.085-.083.17-.123.255l.874.472c.04-.087.09-.172.122-.255ZM19.56 10.896l-.877-.472c-.047.08-.091.162-.136.236l.874.472.139-.236ZM18.945 15.464l-.824-.444c.132.207.31.38.52.504l.738.43a1.628 1.628 0 0 1-.434-.49ZM23.65 8.372v-.017l-.643-.378c-.23-.13-.486-.2-.75-.205l.943.508c.153.01.305.04.45.092ZM18.605 14.194l-.855-.46c.005.113.016.226.033.337l.853.458a3.06 3.06 0 0 1-.031-.335ZM19.12 11.748l-.872-.472a5.16 5.16 0 0 0-.104.264l.87.472c.044-.09.068-.177.106-.264ZM18.616 13.653l-.86-.472c0 .106-.012.21-.012.314l.857.473c.003-.107.005-.208.015-.315ZM18.799 12.664l-.867-.472a9.455 9.455 0 0 0-.07.283l.864.473a6.27 6.27 0 0 1 .073-.284ZM18.945 12.209l-.869-.473c-.033.09-.063.182-.092.272l.87.472c.028-.101.058-.191.091-.271Z"
      />
    </g>
    <path
      d="M21.678 8.693c-1.705.987-3.083 3.382-3.078 5.349.004 1.967 1.394 2.755 3.1 1.768 1.704-.987 3.082-3.381 3.077-5.344-.004-1.962-1.394-2.76-3.1-1.773Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M23.869 8.483a1.577 1.577 0 0 0-.785-.206l-4.24 4.25c-.12.396-.194.804-.223 1.217l5.248-5.261ZM19.255 15.826c1.837-1.845 3.676-3.688 5.516-5.53a3.035 3.035 0 0 0-.167-.895l-5.78 5.802c.097.237.244.45.431.623Z"
      />
    </g>
    <path
      d="M24.56 11.64c-1.702.986-3.083 3.383-3.076 5.35 0 .973.347 1.653.9 1.982l.857.5c-.553-.323-.897-1.008-.9-1.983 0-1.968 1.374-4.362 3.079-5.349.857-.496 1.634-.543 2.195-.215l-.857-.5c-.56-.326-1.34-.282-2.198.214Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m22.42 18.222-.846-.453c.03.133.073.262.127.387l.839.451a2.628 2.628 0 0 1-.12-.385ZM24.168 13.174l-.883-.472c-.064.07-.13.142-.191.217l.88.472c.064-.073.128-.146.194-.217ZM23.85 13.55l-.88-.473c-.058.076-.117.15-.171.236l.876.473c.056-.088.115-.16.174-.236ZM25.889 11.918l-.9-.472a3.476 3.476 0 0 0-.313.154l.895.472c.102-.066.208-.117.318-.154ZM23.563 13.94l-.879-.473a14.08 14.08 0 0 0-.153.236l.876.473c.05-.079.104-.159.156-.237ZM26.556 11.736l-.916-.49c-.142.019-.282.051-.417.096l.906.486a2.42 2.42 0 0 1 .427-.092ZM24.91 12.487l-.887-.472c-.08.061-.158.128-.236.194l.886.472c.08-.068.158-.132.238-.194ZM24.517 12.818l-.884-.473-.21.21.886.473.208-.21ZM25.356 12.18l-.892-.472a5.83 5.83 0 0 0-.269.175l.89.472c.097-.061.18-.118.271-.175ZM22.429 16.596l-.865-.473a4.519 4.519 0 0 0-.044.298l.864.472c.012-.1.026-.198.045-.297ZM23.063 14.763l-.877-.472c-.042.085-.08.17-.12.255l.872.472c.047-.087.087-.172.125-.255ZM23.298 14.334l-.876-.473c-.05.08-.094.163-.139.236l.876.473c.05-.064.095-.147.14-.236ZM22.688 18.91l-.825-.442c.132.206.31.378.52.503l.738.43a1.633 1.633 0 0 1-.433-.492ZM27.39 11.819V11.8l-.645-.375c-.229-.13-.486-.2-.749-.206l.942.506c.155.01.307.041.452.094ZM22.355 17.639l-.857-.472c0 .118.019.236.035.337l.85.458c-.028-.087-.028-.205-.028-.323ZM22.862 15.195l-.871-.472a5.097 5.097 0 0 0-.106.265l.871.472c.033-.09.07-.182.106-.265ZM22.356 17.1l-.862-.472c0 .104-.012.21-.012.312l.86.472c.014-.101.014-.205.014-.311ZM22.54 16.111l-.866-.472c-.026.095-.05.19-.069.284l.865.472c.021-.097.045-.19.07-.284ZM22.685 15.644l-.869-.472c-.03.09-.063.18-.09.271l.87.473c.028-.09.058-.18.09-.272Z"
      />
    </g>
    <path
      d="M25.418 12.14c-1.705.987-3.085 3.381-3.078 5.349.007 1.967 1.392 2.755 3.097 1.768 1.705-.987 3.085-3.381 3.078-5.344-.007-1.962-1.39-2.76-3.097-1.773Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M27.608 11.93a1.596 1.596 0 0 0-.782-.208l-4.251 4.25a5.62 5.62 0 0 0-.221 1.215l5.254-5.257ZM22.995 19.274l5.516-5.53a2.895 2.895 0 0 0-.168-.895l-5.786 5.802c.098.237.248.45.438.623Z"
      />
    </g>
    <path
      d="M33.334 2.748c-1.705.987-3.083 3.381-3.078 5.348 0 .973.346 1.653.9 1.984l.86.498c-.554-.321-.9-1.008-.903-1.981 0-1.967 1.373-4.362 3.078-5.349.86-.496 1.65-.543 2.198-.214l-.846-.506c-.574-.323-1.352-.276-2.209.22Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m31.193 9.33-.845-.453c.031.132.073.262.125.387l.838.45a2.516 2.516 0 0 1-.118-.383ZM32.939 4.283l-.881-.473c-.064.074-.13.144-.19.22l.88.472c.064-.085.127-.149.19-.22ZM32.623 4.653l-.88-.472c-.057.075-.116.151-.173.236l.879.472c.056-.085.115-.158.174-.236ZM34.653 3.026l-.9-.484a3.467 3.467 0 0 0-.316.151l.896.473c.104-.052.21-.099.32-.14ZM32.335 5.045l-.879-.472-.155.236.876.472.158-.236ZM35.325 2.842l-.914-.49c-.141.02-.28.053-.417.096l.907.486c.138-.043.28-.074.424-.092ZM33.684 3.593l-.89-.472c-.078.064-.155.127-.235.196l.885.472c.08-.068.16-.134.24-.196ZM33.288 3.926l-.885-.472c-.071.066-.14.137-.208.207l.883.473c.071-.071.14-.142.21-.208ZM34.13 3.289l-.893-.473c-.09.057-.179.114-.268.178l.888.472c.09-.066.181-.123.273-.177ZM31.202 7.704l-.864-.472a4.3 4.3 0 0 0-.045.297l.862.473c.014-.102.028-.201.047-.298ZM31.842 5.869l-.874-.472a6.1 6.1 0 0 0-.12.255l.871.472c.04-.085.08-.17.123-.255ZM32.076 5.451l-.879-.472c-.047.08-.092.163-.136.236l.873.472c.045-.073.092-.155.142-.236ZM31.461 10.018l-.824-.442c.131.206.308.378.518.503l.74.43a1.562 1.562 0 0 1-.434-.492ZM36.164 2.927v-.019l-.622-.38c-.229-.13-.486-.2-.749-.205l.942.505c.147.013.291.046.429.1ZM31.121 8.747l-.857-.458c0 .116.016.236.033.338l.852.456a3.716 3.716 0 0 1-.028-.336ZM31.636 6.306l-.872-.472a7.078 7.078 0 0 0-.106.262l.872.472c.033-.09.066-.177.106-.262ZM31.132 8.209l-.862-.472c0 .106-.012.21-.012.314l.86.472c0-.106.004-.21.014-.314ZM31.303 7.217l-.867-.472a4.65 4.65 0 0 0-.07.283l.866.473c.028-.095.054-.19.07-.284ZM31.459 6.752l-.87-.472c-.032.09-.063.18-.091.27l.87.472c.027-.088.058-.18.091-.27Z"
      />
    </g>
    <path
      d="M34.192 3.248c-1.706.987-3.083 3.382-3.079 5.349.005 1.967 1.395 2.753 3.1 1.766 1.705-.987 3.083-3.381 3.078-5.341-.005-1.96-1.394-2.76-3.1-1.774Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M36.382 3.036a1.596 1.596 0 0 0-.784-.206l-4.24 4.25a5.534 5.534 0 0 0-.235 1.217l5.26-5.261ZM31.776 10.382l5.516-5.533a3.078 3.078 0 0 0-.167-.895l-5.787 5.804c.099.237.248.45.438.624Z"
      />
    </g>
    <path
      d="M22.131 2.19c-1.705.988-3.083 3.382-3.078 5.349 0 .975.346 1.653.9 1.984l.857.5c-.554-.323-.898-1.01-.9-1.983 0-1.967 1.373-4.362 3.078-5.349.858-.496 1.635-.543 2.198-.215l-.858-.5c-.56-.326-1.34-.281-2.197.214Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m19.998 8.773-.846-.453c.031.132.073.262.125.387l.839.451a2.624 2.624 0 0 1-.118-.385ZM21.734 3.725l-.88-.472a5.62 5.62 0 0 0-.194.217l.883.472c.062-.073.128-.146.191-.217ZM21.412 4.1l-.88-.472a2.768 2.768 0 0 0-.173.236l.879.473c.064-.088.122-.156.174-.237ZM23.45 2.469l-.9-.472a4.25 4.25 0 0 0-.316.15l.895.473c.104-.056.211-.106.32-.151ZM21.132 4.49l-.879-.472a6.653 6.653 0 0 0-.155.236l.876.472c.052-.073.103-.158.158-.236ZM24.122 2.292l-.916-.491c-.141.02-.28.052-.415.097l.907.486c.138-.043.28-.074.424-.092ZM22.481 3.038l-.89-.472c-.078.061-.155.127-.236.194l.886.472c.08-.069.16-.132.24-.194ZM22.085 3.369l-.886-.473c-.07.066-.139.137-.207.208l.883.472c.069-.07.14-.141.21-.207ZM22.924 2.731l-.89-.472c-.09.056-.18.115-.268.177l.888.472c.089-.064.172-.123.27-.177ZM19.999 7.146l-.867-.472c-.017.1-.031.198-.042.298l.862.472c.012-.102.028-.194.047-.298ZM20.637 5.312l-.874-.473c-.042.083-.082.17-.122.255l.873.473c.04-.085.08-.17.123-.255ZM20.87 4.89l-.872-.473c-.047.08-.092.163-.137.236l.874.472c.04-.068.087-.15.134-.236ZM20.254 9.46l-.824-.441c.131.206.31.378.52.503l.737.43a1.607 1.607 0 0 1-.433-.492ZM24.96 2.37v-.02l-.643-.375c-.229-.13-.486-.2-.749-.205l.942.505c.154.01.305.043.45.095ZM19.916 8.195l-.86-.473c.005.113.016.226.034.338l.852.458a3.045 3.045 0 0 1-.026-.323ZM20.43 5.746l-.871-.472c-.036.087-.07.175-.104.262l.87.472c.035-.087.067-.174.105-.262ZM19.926 7.651l-.86-.472v.312l.858.472a1.72 1.72 0 0 1 .002-.312ZM20.11 6.662l-.867-.472c-.026.094-.05.189-.071.28l.864.473c.021-.094.047-.189.073-.28ZM20.256 6.195l-.87-.473a5.26 5.26 0 0 0-.091.272l.869.472c.028-.09.07-.18.092-.271Z"
      />
    </g>
    <path
      d="M22.99 2.691c-1.705.987-3.083 3.382-3.078 5.349.005 1.967 1.394 2.753 3.1 1.768 1.705-.984 3.083-3.381 3.078-5.344-.005-1.962-1.394-2.76-3.1-1.773Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M25.181 2.481a1.606 1.606 0 0 0-.784-.208l-4.24 4.262a5.483 5.483 0 0 0-.235 1.214l5.26-5.268ZM20.565 9.825c1.837-1.844 3.676-3.687 5.516-5.53a3.038 3.038 0 0 0-.167-.896l-5.789 5.802c.1.238.25.45.44.624Z"
      />
    </g>
    <path
      d="M14.293 16.078c-1.705.988-3.083 3.382-3.078 5.349 0 .973.346 1.653.9 1.983l.857.499c-.554-.324-.897-1.009-.9-1.981 0-1.968 1.373-4.362 3.078-5.349.858-.498 1.635-.543 2.198-.215l-.857-.5c-.563-.329-1.34-.282-2.198.214Z"
      fill="#C76B00"
    />
    <g opacity={0.3} fill="#fff">
      <path
        opacity={0.3}
        d="m12.15 22.661-.845-.455c.031.133.073.263.125.39l.838.448a2.506 2.506 0 0 1-.118-.383ZM13.896 17.613l-.881-.472a7.89 7.89 0 0 0-.19.217l.88.472c.061-.075.127-.146.19-.217ZM13.58 17.986l-.88-.472a3.817 3.817 0 0 0-.173.236l.879.473c.056-.088.115-.161.174-.237ZM15.61 16.355l-.9-.472a3.458 3.458 0 0 0-.316.15l.896.473c.104-.056.21-.106.32-.151ZM13.292 18.376l-.879-.472a5.08 5.08 0 0 0-.155.236l.876.472a8.43 8.43 0 0 1 .158-.236ZM16.282 16.173l-.916-.491c-.14.02-.28.053-.415.097l.907.486c.138-.043.28-.074.424-.092ZM14.641 16.931l-.89-.472a8.137 8.137 0 0 0-.235.196l.885.472c.08-.075.16-.142.24-.196ZM14.245 17.257l-.885-.473c-.071.067-.14.137-.208.208l.884.473c.075-.062.139-.142.21-.208ZM15.085 16.62l-.89-.473a3.987 3.987 0 0 0-.27.177l.889.472c.09-.066.181-.122.27-.177ZM12.16 21.032l-.868-.472c-.017.1-.031.198-.042.298l.862.472c.012-.1.028-.198.047-.298ZM12.797 19.2l-.874-.473c-.042.085-.082.17-.122.256l.874.472c.04-.085.08-.163.122-.255ZM13.03 18.782l-.876-.472c-.047.08-.092.163-.136.236l.873.472c.043-.075.092-.156.14-.236ZM12.416 23.348l-.824-.441a1.6 1.6 0 0 0 .52.503l.74.43a1.63 1.63 0 0 1-.436-.492ZM17.12 16.258v-.019l-.643-.375c-.228-.13-.486-.2-.748-.206l.942.505c.153.01.305.042.45.095ZM12.076 22.078l-.855-.46c.004.114.015.227.033.34l.852.456a3.014 3.014 0 0 1-.03-.336ZM12.59 19.634l-.871-.472-.104.262.87.473c.035-.088.068-.178.105-.263ZM12.086 21.54l-.86-.473c0 .107-.011.21-.011.314l.86.473c0-.107.004-.201.011-.314ZM12.27 20.548l-.867-.472c-.027.094-.05.189-.071.283l.864.473c.031-.095.047-.19.073-.284ZM12.416 20.083l-.87-.472a7.373 7.373 0 0 0-.09.271l.868.472c.028-.09.059-.181.092-.271Z"
      />
    </g>
    <path
      d="M15.15 16.58c-1.705.986-3.082 3.38-3.078 5.348.005 1.967 1.395 2.753 3.1 1.766 1.705-.987 3.083-3.382 3.078-5.341-.005-1.96-1.394-2.761-3.1-1.774Z"
      fill="#F98600"
    />
    <g opacity={0.4} fill="#fff">
      <path
        opacity={0.4}
        d="M17.34 16.366a1.602 1.602 0 0 0-.785-.205l-4.24 4.25c-.12.396-.194.804-.223 1.216l5.247-5.26ZM12.726 23.71l5.515-5.53a3.074 3.074 0 0 0-.167-.895l-5.789 5.804c.1.237.25.45.44.621Z"
      />
    </g>
    <defs>
      <linearGradient
        id="PendingDeposits_svg__a"
        x1={9.972}
        y1={42.994}
        x2={40.075}
        y2={44.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00952A" />
        <stop offset={1} stopColor="#66D685" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgPendingDeposits;
