import * as React from "react";
import { SVGProps } from "react";

const SvgPendingTickets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.742 28.796v-8.387c0-2.655 1.755-5.671 3.916-6.733L26.87 4.244c2.161-1.066 3.916.23 3.916 2.882v14.428c0 2.655-1.755 5.672-3.916 6.733l-16.366 8.039L3.742 48V28.796Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.742 48 0 46.163V18.568c0-2.654 1.755-5.671 3.916-6.733l19.212-9.432c.924-.454 1.773-.477 2.443-.149l3.742 1.837c-.67-.33-1.52-.306-2.443.149L7.658 13.674c-2.161 1.062-3.916 4.078-3.916 6.735V48Z"
      fill="#FDCF99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.878 25.622c.702-.345 1.271.04 1.271.858s-.57 1.762-1.271 2.107l-6.273 3.078c-.702.345-1.271-.04-1.271-.858s.57-1.76 1.27-2.108l6.274-3.078Zm9.042-9.883c.701-.344 1.271.04 1.271.859 0 .818-.57 1.762-1.271 2.107l-15.315 7.52c-.702.344-1.271-.04-1.271-.859 0-.818.57-1.762 1.27-2.107l15.318-7.522-.002.002Zm0-5.557c.701-.344 1.271.04 1.271.859 0 .818-.57 1.762-1.271 2.107l-15.315 7.52c-.702.344-1.271-.04-1.271-.859 0-.818.57-1.762 1.27-2.107l15.318-7.52h-.002Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.878 25.622c.702-.345 1.271.04 1.271.858s-.57 1.762-1.271 2.107l-6.273 3.078c-.702.345-1.271-.04-1.271-.858s.57-1.76 1.27-2.108l6.274-3.078Zm9.042-9.883c.701-.344 1.271.04 1.271.859 0 .818-.57 1.762-1.271 2.107l-15.315 7.52c-.702.344-1.271-.04-1.271-.859 0-.818.57-1.762 1.27-2.107l15.318-7.522-.002.002Zm0-5.557c.701-.344 1.271.04 1.271.859 0 .818-.57 1.762-1.271 2.107l-15.315 7.52c-.702.344-1.271-.04-1.271-.859 0-.818.57-1.762 1.27-2.107l15.318-7.52h-.002Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m21.654 6.8 5.215-2.56c2.161-1.062 3.916.233 3.916 2.886v5.996c-1.025 1.379-2.26 2.497-3.587 3.15-3.542 1.74-6.415-.48-6.415-4.956 0-1.49.319-3.042.875-4.517l-.005.002Z"
      fill="#C76B00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m23.315 16.526 1.607-.789c.701-.345 1.27.04 1.27.858v.072c-1.069.312-2.05.248-2.876-.14h-.001Zm-2.497-4.331 4.104-2.013c.701-.344 1.27.04 1.27.859 0 .818-.569 1.762-1.27 2.107l-3.368 1.651c-.387-.71-.643-1.586-.736-2.604Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m23.315 16.526 1.607-.789c.701-.345 1.27.04 1.27.858v.072c-1.069.312-2.05.248-2.876-.14h-.001Zm-2.497-4.331 4.104-2.013c.701-.344 1.27.04 1.27.859 0 .818-.569 1.762-1.27 2.107l-3.368 1.651c-.387-.71-.643-1.586-.736-2.604Z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.415 20.737 3.742 48V20.409c0-2.655 1.755-5.671 3.916-6.733L26.87 4.244c1.079-.53-18.538 9.765-18.538 9.765-2.162 1.061-3.916 4.078-3.916 6.732l-.001-.004Z"
      fill="#F98600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.415 20.737 3.742 48V20.409c0-2.655 1.755-5.671 3.916-6.733L26.87 4.244c1.079-.53-18.538 9.765-18.538 9.765-2.162 1.061-3.916 4.078-3.916 6.732l-.001-.004Z"
      fill="#fff"
      fillOpacity={0.8}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.586 1.227C33.127-.513 36 1.707 36 6.182s-2.875 9.522-6.415 11.263c-3.54 1.74-6.418-.48-6.418-4.956 0-4.475 2.876-9.522 6.418-11.262Z"
      fill="#E92C2C"
    />
    <path
      d="M28.97 11.324c-.25.213-.463.257-.551.112-.152-.246-.228-.576-.229-.99.004-.439.1-.873.28-1.274.157-.375.361-.728.61-1.05.225-.284.428-.582.609-.895.186-.321.28-.598.28-.83 0-.265-.08-.444-.238-.536-.158-.093-.396-.064-.712.087-.394.188-.752.511-1.074.97-.264.377-.582.572-.715.442l-.505-.497c-.124-.122-.047-.488.181-.853.617-.991 1.382-1.705 2.296-2.142.778-.372 1.395-.437 1.85-.195.455.242.683.739.682 1.49 0 .421-.07.84-.207 1.24a4.786 4.786 0 0 1-.505 1.065c-.199.306-.4.598-.602.874-.195.262-.366.54-.512.83a1.76 1.76 0 0 0-.207.78v.037c.003.178-.072.424-.207.676-.128.242-.297.46-.502.645l-.023.02v-.006Zm.901 2.737c-.195.347-.426.584-.694.712-.268.128-.5.112-.694-.05-.195-.16-.293-.414-.293-.763.005-.366.106-.725.293-1.042.195-.347.426-.584.694-.712.268-.128.5-.112.694.05.195.16.292.414.292.762a2.111 2.111 0 0 1-.292 1.043Z"
      fill="#BA2323"
    />
    <path
      d="M29.056 15.028a.625.625 0 0 1-.12-.079c-.195-.16-.292-.415-.292-.763a2.11 2.11 0 0 1 .292-1.042c.195-.347.426-.584.694-.712.219-.105.413-.113.584-.025l.556.273-.163.382c.006.06.01.12.01.18a2.106 2.106 0 0 1-.293 1.042c-.118.223-.28.42-.478.58l-.195.457-.597-.293h.002Zm-.126-3.307a.17.17 0 0 1-.059-.06c-.152-.245-.228-.575-.227-.99.003-.438.098-.872.28-1.273.155-.375.36-.729.609-1.052.225-.283.428-.582.608-.894.187-.322.28-.599.28-.831 0-.265-.078-.443-.237-.536-.158-.092-.396-.063-.713.087-.393.188-.75.512-1.072.97l-.003.005-.076.757-.589-.288a.11.11 0 0 1-.025-.013.1.1 0 0 1-.023-.018l-.505-.497c-.124-.122-.046-.489.181-.854.619-.993 1.384-1.707 2.296-2.143.779-.374 1.396-.44 1.85-.196l.012.007.543.266-.18.05c.205.274.307.662.307 1.164 0 .422-.07.841-.207 1.241a4.808 4.808 0 0 1-.505 1.065c-.2.306-.4.597-.603.874a5.7 5.7 0 0 0-.512.83 1.76 1.76 0 0 0-.207.78v.036c.004.178-.07.424-.206.676a2.39 2.39 0 0 1-.44.59l.022.541-.574-.283a.195.195 0 0 1-.024-.012v.001Z"
      fill="#F98600"
    />
    <path
      d="M30.01 11.837c-.248.213-.462.257-.55.112-.152-.246-.228-.576-.228-.99.003-.439.099-.872.28-1.273.156-.374.36-.728.606-1.051a7.28 7.28 0 0 0 .61-.894c.186-.32.28-.597.28-.83 0-.265-.08-.443-.238-.536-.158-.092-.396-.063-.712.087-.394.188-.752.512-1.073.97-.265.376-.583.572-.716.441l-.505-.496c-.124-.122-.046-.489.181-.854.62-.993 1.384-1.707 2.296-2.143.78-.373 1.396-.438 1.85-.196.455.242.683.738.683 1.49 0 .421-.07.84-.207 1.24a4.806 4.806 0 0 1-.505 1.066c-.199.305-.4.597-.603.873-.194.262-.365.54-.511.83a1.76 1.76 0 0 0-.207.78v.037c.003.178-.072.424-.207.676-.127.242-.297.46-.502.645l-.023.02.002-.004Zm.904 2.738c-.195.346-.426.584-.694.712-.268.128-.5.111-.694-.05-.195-.16-.293-.415-.293-.763.005-.366.106-.725.293-1.043.195-.346.426-.583.694-.71.268-.129.5-.112.694.049.195.16.292.414.292.763a2.112 2.112 0 0 1-.292 1.042Z"
      fill="#FDCF99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m25.65 17.674-1.288-.634c-1.51-.742-2.483-2.581-2.483-5.184 0-4.476 2.876-9.522 6.418-11.263 1.482-.729 2.848-.763 3.935-.229l1.289.634c-1.087-.534-2.455-.5-3.935.229-3.542 1.74-6.417 6.787-6.417 11.262 0 2.602.971 4.443 2.482 5.185Z"
      fill="#F6ABAB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.806 17.745c-1.599-.689-2.638-2.565-2.638-5.256 0-4.475 2.876-9.522 6.418-11.262 1.552-.763 2.976-.765 4.087-.15-1.061-.456-2.367-.392-3.78.302-3.541 1.74-6.414 6.787-6.414 11.262 0 2.514.909 4.32 2.33 5.105h-.003Z"
      fill="#E92C2C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.806 17.745c-1.599-.689-2.638-2.565-2.638-5.256 0-4.475 2.876-9.522 6.418-11.262 1.552-.763 2.976-.765 4.087-.15-1.061-.456-2.367-.392-3.78.302-3.541 1.74-6.414 6.787-6.414 11.262 0 2.514.909 4.32 2.33 5.105h-.003Z"
      fill="#fff"
      fillOpacity={0.8}
    />
  </svg>
);

export default SvgPendingTickets;
