import dayjs from 'dayjs';
import { intlGenerator } from 'utils/common';
import RegisAccType from './RegisAccType';
import RegisClientType from './RegisClientType';

import { StyledSnapshotContainer } from './style';
import {
  GeneralObj,
  FilteredCampaignGroup,
  GeneralKeyPair,
  WebinarCourseObj
} from './type';

import TeamCommission from './TeamCommission';
import TransactionApprove from './TransactionApprove';
import ClientApplication from './ClientApplication';
import MyCommissions from './MyCommissions';
import MyClientTransaction from './MyClientTransaction';
import ZhCampaign from './ZhCampaign';
import EnCampaign from './EnCampaign';
import EnWebinar from './EnWebinar';
import ZhWebinar from './ZhWebinar';
import LuckyDrawBonus from './LuckyDrawBonus';

export const webinarObj = {
  date: '开始日期',
  webinar: '线上课程',
  attendees: '参加人数'
};

export const campaignObj = {
  start: '开始日期',
  end: '结束日期',
  campaign: '活动名称'
};
interface SnapshotContainerProps {
  list: string[]
}

interface SnapshotPickerProps {
  id: string
}

export const scaleWithCustomSize = (stepSizeY: number, isMoneyUnit = false)
:{x:object, y:object} => ({
  x: {
    ticks: {
      stepSize: 1,
      autoSkip: false,
      padding: 5
    },
    grid: {
      display: false
    }
  },
  y: {
    min: 0,
    suggestedMax: 500,
    ticks: {
      stepSize: 100,
      type: 'linear',
      display: true,
      padding: 8,
      callback(value:number) {
        return isMoneyUnit ? `${intlGenerator(value)}` : value;
      }
    }
  }
});

const commonLegend = {
  align: 'start',
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    position: 'middle',
    padding: 10
  }
};

// Tran Apprv, Client App, My Client's Tran
export const commonOptions = {
  scales: {
    ...scaleWithCustomSize(5),
    y: {
      min: 0,
      suggestedMax: 60,
      ticks: {
        stepSize: 5,
        type: 'linear',
        display: true,
        padding: 8
      }
    }
  },
  plugins: {
    legend: commonLegend,

    tooltip: {
      mode: 'index',
      intersect: false,
      bodySpacing: 8,
      padding: 8,
      backgroundColor: '#F4F4F4',
      titleColor: '#585757',
      bodyColor: '#585757',
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label(context:any) {
          const label = context.dataset.label || '';
          return `  ${label.trim()} : ${context.parsed.y}`;
        }
      }
    }
  }
};

export const barOptions = {
  ...commonOptions,
  scales: {
    ...scaleWithCustomSize(5),
    y: {
      min: 0,
      suggestedMax: 30,
      ticks: {
        stepSize: 5,
        type: 'linear',
        display: true,
        padding: 8
      }
    }
  }
};

export const filterWebinarData = (webinarData:WebinarCourseObj[]):GeneralKeyPair[] => {
  const arr:GeneralKeyPair[] = [];
  const sortedData = webinarData
    .sort((
      a:WebinarCourseObj,
      b:WebinarCourseObj
    ):number => {
      // earlier startTime
      if (Date.parse((a.start_time).toString()) >
        Date.parse((b.start_time).toString())) return 1;
      if (Date.parse((a.start_time).toString()) <
        Date.parse((b.start_time).toString())) return -1;

      return 1;
    });

  sortedData.forEach((ele:WebinarCourseObj, idx:number) => {
    if (idx < 3) {
      arr.push({
        key: (ele.id).toString(),
        date: dayjs(new Date(ele.start_time)).format('DD/MM/YYYY'),
        webinar: (ele.title).toString(),
        attendees: (ele.enrol_count).toString()
      });
    }
  });

  return arr;
};

export const filterCampaignData = (campaignData:GeneralObj[]):FilteredCampaignGroup => {
  const arr:GeneralKeyPair[] = [];
  let expiringSoonNum = 0;

  const sortedData = campaignData
    .sort((
      a:GeneralObj,
      b:GeneralObj
    ):number => {
      // earlier endTime
      if (Date.parse((a.end_time).toString()) >
        Date.parse((b.end_time).toString())) return 1;
      if (Date.parse((a.end_time).toString()) <
        Date.parse((b.end_time).toString())) return -1;
      // earlier startTime
      if (Date.parse((a.start_time).toString()) >
        Date.parse((b.start_time).toString())) return 1;
      if (Date.parse((a.start_time).toString()) <
        Date.parse((b.start_time).toString())) return -1;

      return 1;
    });

  sortedData.forEach((ele:GeneralObj, idx:number) => {
    const userTZEndTime = new Date(ele.end_time);
    const expiringSoonPeriod = dayjs(userTZEndTime).subtract(10, 'day');
    const isExpiringSoon = dayjs().isAfter(expiringSoonPeriod);
    if (isExpiringSoon) {
      expiringSoonNum += 1;
    }
    if (idx < 3) {
      arr.push({
        key: (ele.id).toString(),
        start: dayjs(new Date(ele.start_time)).format('hh:mm A - DD/MM/YYYY'),
        end: dayjs(new Date(ele.end_time)).format('hh:mm A - DD/MM/YYYY'),
        campaign: (ele.title).toString(),
        rowClassName: isExpiringSoon ? 'expiringSoon' : ''
      });
    }
  });

  return {
    filteredCampaignData: arr,
    expiringSoonNum
  };
};

const SnapshotPicker = (props:SnapshotPickerProps): JSX.Element => {
  const { id } = props;
  const renderSanpshot = (sanpshotID: string):JSX.Element => {
    switch (sanpshotID) {
      case 'TransactionApprove':
        return <TransactionApprove />;
      case 'ClientApplication':
        return <ClientApplication />;
      case 'TeamCommission':
        return <TeamCommission />;
      case 'MyCommissions':
        return <MyCommissions />;
      case 'MyClientTransaction':
        return <MyClientTransaction />;
      case 'ActiveCampaign':
        return (
          <>
            <ZhCampaign />
            <EnCampaign />
          </>
        );
      case 'RegistrationAccountType':
        return <RegisAccType />;
      case 'RegistrationClientType':
        return <RegisClientType />;
      case 'LuckyDrawBonus':
        return <LuckyDrawBonus />;
      case 'Webinar':
        return (
          <>
            <EnWebinar />
            <ZhWebinar />
          </>
        );

      default:
        return <div />;
    }
  };
  return (
    renderSanpshot(id)
  );
};

const SnapshotContainer: React.FC<SnapshotContainerProps> = (props) => {
  const { list } = props;

  return (
    <StyledSnapshotContainer>
      {
        list.map((item) => (
          <SnapshotPicker id={item} key={item} />
        ))
      }
      <div id="chartjs-custom-tooltip" aria-label="on-bar" />

    </StyledSnapshotContainer>
  );
};

export default SnapshotContainer;
