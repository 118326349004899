import * as React from "react";
import { SVGProps } from "react";

const SvgBoxArrowUpLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#BoxArrowUpLeft_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#BBB"
    >
      <path d="M3.5 8.636a.5.5 0 0 1-.5-.5V1.5A1.5 1.5 0 0 1 4.5 0h10A1.5 1.5 0 0 1 16 1.5v10a1.5 1.5 0 0 1-1.5 1.5H7.864a.5.5 0 0 1 0-1H14.5a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5h-10a.5.5 0 0 0-.5.5v6.636a.5.5 0 0 1-.5.5Z" />
      <path d="M.5 16a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 1 0v3.793l8.146-8.147a.5.5 0 1 1 .708.708L1.707 15H5.5a.5.5 0 0 1 0 1h-5Z" />
    </g>
    <defs>
      <clipPath id="BoxArrowUpLeft_svg__a">
        <path fill="#fff" transform="rotate(-90 8 8)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBoxArrowUpLeft;
