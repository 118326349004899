import * as React from "react";
import { SVGProps } from "react";

const SvgTriangleDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.247 7.14.451 1.658C-.115 1.013.345 0 1.204 0h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0v.001Z"
      fill="#5C5C5C"
    />
  </svg>
);

export default SvgTriangleDown;
