import { SVGProps } from 'react';

const SvgSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C12.4011 4 12.7966 4.02971 13.1817 4.08686L13.7623 5.84571C14.2708 5.99086 14.7543 6.19771 15.2023 6.45829L16.8709 5.65371C17.4617 6.10743 17.9874 6.64229 18.4308 7.24114L17.5966 8.89486C17.8491 9.34743 18.048 9.83429 18.184 10.3463L19.9326 10.9554C20.0289 11.6949 20.0224 12.4441 19.9131 13.1817L18.1543 13.7623C18.0101 14.2657 17.8044 14.7493 17.5417 15.2023L18.3463 16.8709C17.892 17.4619 17.3577 17.9869 16.7588 18.4309L15.1051 17.5966C14.6477 17.852 14.1601 18.0494 13.6537 18.184L13.0446 19.9326C12.3051 20.0289 11.5559 20.0224 10.8183 19.9131L10.2377 18.1543C9.73433 18.0101 9.25067 17.8044 8.79771 17.5417L7.12914 18.3463C6.53812 17.892 6.0131 17.3577 5.56914 16.7589L6.40342 15.1051C6.14858 14.6474 5.95127 14.1599 5.81599 13.6537L4.06742 13.0434C3.97119 12.3044 3.97772 11.5556 4.08685 10.8183L5.84571 10.2377C5.99085 9.72914 6.19771 9.24572 6.45828 8.79771L5.65371 7.12914C6.10804 6.53812 6.64229 6.0131 7.24114 5.56914L8.89485 6.40343C9.35232 6.14797 9.8399 5.95063 10.3463 5.816L10.9566 4.06743C11.3026 4.02242 11.6511 3.99989 12 4V4Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.4286C13.8935 15.4286 15.4286 13.8936 15.4286 12C15.4286 10.1065 13.8935 8.57144 12 8.57144C10.1064 8.57144 8.57141 10.1065 8.57141 12C8.57141 13.8936 10.1064 15.4286 12 15.4286Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSetting;
