import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled, { css } from 'styled-components';
import DropdownSelect from 'components/DropdownSelect';

export const StyledDataTable = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.N500};
    border-radius: 8px;
    background-color: ${theme.palette.N700};
    overflow: hidden;
  `}
`;

type StyledTableCellProps = {
  $width?: `${number}px`;
  $stickyLeft?: `${number}px`;
  $stickyRight?: `${number}px`;
  $boxShadowPosition?: 'left' | 'right';
  $shouldEllipsis?: boolean
} & TableCellProps;

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  ${({
    theme, $width, $stickyLeft, $stickyRight, $boxShadowPosition, $shouldEllipsis
  }) => css`
    ${theme.typography.Body2};
    color: ${theme.palette.N200};
    background-color: ${theme.palette.N700};
    border: none;
    padding: ${theme.spacing.s2} ${theme.spacing.s4};
    white-space: nowrap;
    font-family: Noto Sans, 'Noto Sans SC', 'Poppins', sans-serif;
    ${$width &&
    `
        min-width: ${$width};
        max-width: ${$width};
    `};
    ${$stickyLeft &&
    `
        position: sticky;
        left: ${$stickyLeft};
        z-index: 1;
    `};
    ${$stickyRight &&
    `
        position: sticky;
        right: ${$stickyRight};
        z-index: 1;
    `};
    ${$boxShadowPosition === 'left' &&
    'box-shadow: -2px 2px 4px rgba(223, 223, 223, 0.5)'};
    ${$boxShadowPosition === 'right' &&
    'box-shadow: 2px 2px 4px rgba(223, 223, 223, 0.5)'};
    ${$shouldEllipsis && 'text-overflow: ellipsis; overflow: hidden;'};
  `}
`;

export const StyledTableRow = styled(TableRow)`
  ${({ theme }) => css`
    &:nth-of-type(even) {
      ${StyledTableCell} {
        background-color: ${theme.palette.N650};
      }
    }
  `}
`;

export const StyledTableHead = styled(TableHead)`
  ${({ theme }) => css`
    ${StyledTableCell} {
      font-weight: bold;
      color: ${theme.palette.N100};
      background-color: ${theme.palette.N650};
    }
  `}
`;

export const StyledDataTableFooter = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.s4};
    padding: ${theme.spacing.s4};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.spacing.s7};
  `}
`;

type StyledExternalLinkProps = {
  underline?: boolean;
};

export const StyledExternalLink = styled.a<StyledExternalLinkProps>`
  ${({ theme, underline = true }) => css`
    color: ${theme.palette.P400};
    ${!underline && 'text-decoration: none'};
  `}
`;

export const StyledCellDropdownSelect = styled(DropdownSelect)`
  ${({ theme }) => css`
    .MuiButton-root {
      width: 100%;
      padding: 0;
    }
    .MuiButton-endIcon {
      margin-left: auto;
      color: ${theme.palette.N350};
    }
  `}
`;
