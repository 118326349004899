import { StyledTableCell, StyledTableRow } from 'components/DataTable/style';
import logixPanelInstance from 'i18n';
import { useTranslation } from 'react-i18next';

const TableHeadRow = (): JSX.Element => {
  const { t } = useTranslation('tradingCupAccounts', {
    i18n: logixPanelInstance
  });

  return (
    <StyledTableRow>
      <StyledTableCell align="left">{t('tradingcupID')}</StyledTableCell>
      <StyledTableCell align="left">{t('strategyName')}</StyledTableCell>
      <StyledTableCell align="left">{t('createDate')}</StyledTableCell>
      <StyledTableCell align="left">{t('name')}</StyledTableCell>
      <StyledTableCell align="left">{t('email')}</StyledTableCell>
      <StyledTableCell align="left">{t('tradingAccountID')}</StyledTableCell>
      <StyledTableCell align="left">{t('role')}</StyledTableCell>
      <StyledTableCell align="left">{t('accountNumber')}</StyledTableCell>
      <StyledTableCell align="left">{t('serverName')}</StyledTableCell>
      <StyledTableCell align="left">{t('platform')}</StyledTableCell>
      <StyledTableCell align="left">{t('accountType')}</StyledTableCell>
      <StyledTableCell align="left">{t('group')}</StyledTableCell>
      <StyledTableCell align="left">{t('baseCurrency')}</StyledTableCell>
      <StyledTableCell align="left">{t('leverage')}</StyledTableCell>
      <StyledTableCell align="left">{t('regulation')}</StyledTableCell>
      <StyledTableCell align="left">{t('category')}</StyledTableCell>
      <StyledTableCell align="left">{t('optIn')}</StyledTableCell>
      <StyledTableCell align="left">{t('optOut')}</StyledTableCell>
      <StyledTableCell align="left">{t('status')}</StyledTableCell>
    </StyledTableRow>
  );
};

export default TableHeadRow;
