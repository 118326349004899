import { SVGProps } from 'react';

const SvgZerologix = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.13068 12.5482L9.13107 12.5476L9.68128 11.6847L9.68177 11.684L9.89844 11.3431C9.81403 11.3653 9.72689 11.3766 9.63921 11.3766C9.46915 11.3766 9.3011 11.334 9.15109 11.252L9.43905 10.7256L9.15109 11.252C9.0018 11.1703 8.87455 11.0517 8.78295 10.906L5.54028 5.80256C5.54017 5.8024 5.54007 5.80223 5.53996 5.80206C5.3209 5.45809 5.37855 5.06454 5.58518 4.79385C5.78075 4.53765 6.09172 4.4 6.41229 4.4H17.5883C17.9089 4.4 18.2206 4.53714 18.4165 4.79446C18.6235 5.06634 18.6789 5.45965 18.4606 5.80226C18.4605 5.80236 18.4604 5.80246 18.4604 5.80256L14.8707 11.4483L14.8685 11.4518L14.3215 12.3251L14.3192 12.3286L14.3192 12.3286L14.1077 12.6611C14.1902 12.64 14.2752 12.6293 14.3607 12.6293C14.5298 12.6293 14.697 12.6713 14.8466 12.7523C14.9962 12.8333 15.124 12.9513 15.2165 13.0965L15.2166 13.0967L18.4589 18.196C18.4589 18.1961 18.459 18.1962 18.4591 18.1964C18.6775 18.5392 18.6216 18.9324 18.4157 19.2039C18.2203 19.4615 17.9087 19.6 17.5868 19.6H6.41079C6.08897 19.6 5.78075 19.459 5.5871 19.2069C5.38289 18.9412 5.31569 18.5464 5.53838 18.1966L9.13068 12.5482ZM9.13068 12.5482L5.53878 18.196L9.13068 12.5482ZM11.9946 15.9828L12.0259 15.9337C12.03 15.9502 12.0347 15.9666 12.0399 15.9828H11.9946ZM12.0039 8.03034L11.9733 8.0785C11.9692 8.06231 11.9647 8.04625 11.9597 8.03034H12.0039Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </svg>
);

export default SvgZerologix;
