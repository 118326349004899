import { SVGProps } from 'react';

const SvgRightDoubleArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.85354 8.35378L3.85354 13.3538C3.75972 13.4476 3.63247 13.5003 3.49979 13.5003C3.36711 13.5003 3.23986 13.4476 3.14604 13.3538C3.05222 13.26 2.99951 13.1327 2.99951 13C2.99951 12.8674 3.05222 12.7401 3.14604 12.6463L7.79291 8.00003L3.14604 3.35378C3.05222 3.25996 2.99951 3.13272 2.99951 3.00003C2.99951 2.86735 3.05222 2.7401 3.14604 2.64628C3.23986 2.55246 3.36711 2.49976 3.49979 2.49976C3.63247 2.49976 3.75972 2.55246 3.85354 2.64628L8.85354 7.64628C8.90003 7.69272 8.93691 7.74786 8.96207 7.80856C8.98723 7.86926 9.00018 7.93433 9.00018 8.00003C9.00018 8.06574 8.98723 8.13081 8.96207 8.1915C8.93691 8.2522 8.90003 8.30735 8.85354 8.35378ZM13.8535 7.64628L8.85354 2.64628C8.75972 2.55246 8.63247 2.49976 8.49979 2.49976C8.36711 2.49976 8.23986 2.55246 8.14604 2.64628C8.05222 2.7401 7.99951 2.86735 7.99951 3.00003C7.99951 3.13272 8.05222 3.25996 8.14604 3.35378L12.7929 8.00003L8.14604 12.6463C8.05222 12.7401 7.99951 12.8674 7.99951 13C7.99951 13.1327 8.05222 13.26 8.14604 13.3538C8.23986 13.4476 8.36711 13.5003 8.49979 13.5003C8.63247 13.5003 8.75972 13.4476 8.85354 13.3538L13.8535 8.35378C13.9 8.30735 13.9369 8.2522 13.9621 8.1915C13.9872 8.13081 14.0002 8.06574 14.0002 8.00003C14.0002 7.93433 13.9872 7.86926 13.9621 7.80856C13.9369 7.74786 13.9 7.69272 13.8535 7.64628Z"
      fill="#BBBBBB"
    />
  </svg>
);

export default SvgRightDoubleArrow;
