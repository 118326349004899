import styled from 'styled-components';

export const StyledReminder = styled.div`
  margin:auto;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0084FF;;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(36px);
  border-radius: 8px;
  padding:12px;
  max-width:1024px;
  color:#fff;
  display:flex;
  justify-content:space-between;
`;

export const StyledMessageWrap = styled.div`
  display: flex;
  align-items:center;
  >svg{
    margin-right:10px;
    min-width:16px;
  }
  >div{
    font-size: 14px;
  }
`;

export const StyledDeleteIcon = styled.span`
  height:16px;
  cursor:pointer;
`;
