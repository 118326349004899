import { useEffect, useState } from 'react';
import Shortcut from 'components/Shortcut';
import { useNotificationStore } from 'store';
import useHttp from 'hooks/useHttp';
import { intlGenerator } from 'utils/common';
import {
  getDepositFundsMonthlyRequestConfig,
  getTradersRequestConfig
} from 'api/getV2RequestConfig';

interface ShortcutContainerProps {
  info: {
    name: string;
    url?: string;
    notificationID?: string;
    ariaLabel?: string;
  },
}

interface DataProps {
  data: {
    confirmed_deposit: number;
    top_up_amount: number;
  },
  message: string;
  status: string;
}

interface ResData {
  status: string;
  message: string;
  summarize: {
    type: string;
    period: string;
    data: {
      qualified: {date:string; value:number}[]
    }
  }
}

const ShortcutContainer: React.FC<ShortcutContainerProps> = (props) => {
  const { info } = props;
  const [netDepositsNumbers, setNetDepositsNumbers] = useState<null | number>(null);
  const [qualifiedTraders, setQualifiedTraders] = useState<null | number>(null);
  const { sendRequest } = useHttp();
  const notification = useNotificationStore(
    (state) => {
      if (info && info.notificationID === 'individualDocuments') {
        return state.notification[info.notificationID] + state.notification.corporateDocuments;
      }
      if (info && info.notificationID === 'individualAccounts') {
        return state.notification[info.notificationID] + state.notification.corporateAccounts;
      }
      return state.notification[info?.notificationID || ''];
    }
  );
  useEffect(() => {
    if (info.notificationID === 'netDeposits') {
      const updateNetDepositsNumbers = (data: DataProps): void => {
        if (data.data) setNetDepositsNumbers(data.data.confirmed_deposit || 0);
      };
      sendRequest(getDepositFundsMonthlyRequestConfig(), updateNetDepositsNumbers);
    }

    if (info.notificationID === 'newQualifiedTraders') {
      const updateQualifiedNums = (qualifiedResData: ResData):void => {
        if (qualifiedResData.summarize.data) {
          setQualifiedTraders(qualifiedResData.summarize.data.qualified[0].value);
        }
      };
      sendRequest(
        getTradersRequestConfig('qualified', 'monthly'),
        updateQualifiedNums
      );
    }
  }, []);

  const getNotificationNumbers = (): number | string => {
    if (info.notificationID === 'netDeposits') {
      if (!netDepositsNumbers && netDepositsNumbers !== 0) return '';
      return intlGenerator(netDepositsNumbers);
    }
    if (info.notificationID === 'newQualifiedTraders') {
      if (!qualifiedTraders && qualifiedTraders !== 0) return '';
      return qualifiedTraders;
    }
    return notification;
  };

  const checkLoadingHandler = (): boolean => {
    if (info.notificationID === 'netDeposits') {
      if (!netDepositsNumbers && netDepositsNumbers !== 0) return true;
    }
    if (info.notificationID === 'newQualifiedTraders') {
      if (!qualifiedTraders && qualifiedTraders !== 0) return true;
    }
    return false;
  };

  return (
    <Shortcut
      key={info.name}
      name={info.name}
      ariaLabel={info.ariaLabel}
      className="dashboar-shortcut"
      url={info.url}
      value={getNotificationNumbers()}
      isLoading={checkLoadingHandler()}
    />
  );
};

export default ShortcutContainer;
