/* eslint-disable  @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import Paper from 'components/Paper';
import IconComponent from 'components/Icons';
import { useNavigate } from 'react-router-dom';
import {
  StyledSnapShot, StyledCanvasWrap, StyledHeaderWrap, StyledTotalWrap, StyledIconWrapper
} from './style';

export interface SnapShotProps {
  title: string;
  link?:string;
  totalText?:string;
  totalNum?: number;
  width?: number;
  height?:number;
  className?: string;
  ariaLabel?:string;
  note?: JSX.Element |string | null;
}

const SnapShot: React.FC<SnapShotProps> = (props) => {
  const {
    className, width, height,
    title, children, link, note, ariaLabel
  } = props;
  const navigate = useNavigate();

  return (

    <StyledSnapShot>
      <Paper className={cx('snapShot', className)} width={width} height={height} elevation={6}>

        <StyledHeaderWrap>
          <div className="titleWrap">
            <span className={`${ariaLabel} snapshot title`}>
              {title}
            </span>
            { link &&
            (
            <span className="linkIcon">
              <StyledIconWrapper
                onClick={() => navigate(link)}
                aria-label={`${ariaLabel} snapshot link`}
              >
                <IconComponent name="BoxArrowUpLeft" />
              </StyledIconWrapper>
            </span>
            )}
          </div>
          <StyledTotalWrap aria-label={`${ariaLabel} snapshot note`}>
            {note}
          </StyledTotalWrap>
        </StyledHeaderWrap>

        <StyledCanvasWrap>
          {children}
        </StyledCanvasWrap>

      </Paper>
    </StyledSnapShot>

  );
};

export default SnapShot;
