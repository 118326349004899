import styled from 'styled-components';

export const StyledTable = styled.div`
  color:#2A2A2A;
`;

export const StyledTableWrap = styled.table`
  width:100%;
  border-collapse: collapse;

`;

export const StyledTHead = styled.thead`
    background: #F4F4F4;
    height:40px;
    tr{
      text-align:left;
      th:first-child{
          padding-left:16px;
          border-top-left-radius: 10px;
      }
      th:last-child{
          border-top-right-radius: 10px;
      }
    }
`;

export const StyledTBody = styled.tbody`

   tr{
    height:40px;
    position: relative;
    border-bottom: solid #F1F2F4 1px;

    &:hover{
      background: #fcfcfc;
    }

    td{
      font-size: 12px;
      max-width: 150px;
    }

   }

`;

export const StyledTdContent = styled.td < { toolTipTxt?: string; isShowToolTip?: boolean } >`
    position:relative;

    >span{
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      display:inline-block;
    }

    &:first-child{
       padding-left:16px;
     }

     &:hover{
       &:after {
          content:attr(data-content) ;
          display: ${(props) => ((props?.isShowToolTip) ? 'block' : 'none')} ;
          position:absolute;
          left:3px;
          background:#F4F4F4;
          padding:6px;
          border-radius:10px;
          z-index: 2;
       }
     }

`;
