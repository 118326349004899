import { FC } from 'react';
import { StyledNotification } from './style';

type NotificationBadgeProps = {
  messagesNumber: number;
} & React.HTMLAttributes<HTMLElement>;

const NotificationBadge: FC<NotificationBadgeProps> = (props) => {
  const { messagesNumber, ...HTMLAttributes } = props;
  return (
    <StyledNotification aria-label="notification number" {...HTMLAttributes}>
      {messagesNumber}
    </StyledNotification>
  );
};

export default NotificationBadge;
