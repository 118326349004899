import { V1_API_PATH } from 'api/apiPath';

interface requestType {
  method?: string,
  url: string,
  baseURL?: string,
  headers?: object,
  data?: object,
}

const commonObj = {
  baseURL: process.env.REACT_APP_LOGIX_PANEL_OLD_SITE_URL
};

export const getSideMenuRequestConfig = (): requestType => ({
  ...commonObj,
  url: V1_API_PATH.SIDEMENU_LIST
});

export const getDashboardListRequestConfig = (): requestType => ({
  ...commonObj,
  url: V1_API_PATH.DASHBOARD_LIST
});

export const getV1LogoutRequestConfig = (): requestType => ({
  ...commonObj,
  url: V1_API_PATH.USER_LOGOUT
});

export const getFetchPendingRequestConfig = (): requestType => ({
  ...commonObj,
  url: V1_API_PATH.FETCH_PENDING
});
